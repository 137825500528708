
<div class="container-fluid" style="padding: 25px">

    <div>
        <h6>CASE MANAGEMENT</h6>
        <h1>Log a New Case</h1>
        <h5>Create a new case by logging your case details</h5>
    </div>

    <hr class="mt-0">



    <div class="d-flex flex-column min-vh-100 align-items-center">

        <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">

            <div class="d-flex justify-content-between align-items-end">

                <h3 class="h3-grey">Case Description</h3>
                <br>
                <!-- <p class="purple"></p> -->

            </div>

            <h6>Select the case type and be specific about the case at hand.</h6>
            <!-- <h6 style ="float: right; margin-right: 15px; width:fit-content">Step 1/5</h6> -->
            <hr class="mt-0">
            <p class="purple"></p>

        <form [formGroup]="form">

            <div class="form-group">
                <label class="control-label" for="type">Logging Type</label><span style="color:#ff0000"> *</span>
                <mat-form-field appearance="fill" class="select">
                    <mat-select name="type" placeholder="Select Case Type" (selectionChange)="changeType($event.value)" [disableOptionCentering]="true">
                        <!-- <input class="custom-search-input" placeholder="Search" (keyup)="onKeyCC($event.target.value)"> -->
                        <mat-option *ngFor="let type of caseTypes" [value]="type">
                            {{type.name}}
                          </mat-option>
                        </mat-select>
                </mat-form-field>
            </div>

            <div class="form-group" [hidden]="caseTypeChosen">
                <label class="control-label" for="type">Case Type</label><span style="color:#ff0000"> *</span>
                <mat-form-field appearance="fill" class="select">
                    <mat-select name="type" placeholder="Select Case Type" (selectionChange)="changeCaseType($event.value)" [disableOptionCentering]="true">
                        <!-- <input class="custom-search-input" placeholder="Search" (keyup)="onKeyCC($event.target.value)"> -->
                        <mat-option *ngFor="let type of filteredDropdownListTypes" [value]="type">
                            {{type.name}}
                          </mat-option>
                        </mat-select>
                </mat-form-field>
            </div>

            <div class="form-group" [hidden]="caseTypeSelected">
                <label class="control-label" for="type">Case Subype</label><span style="color:#ff0000"> *</span>
                <mat-form-field appearance="fill" class="select">
                    <mat-select name="type" placeholder="Select Case Type" (selectionChange)="changeCaseSubtype($event.value)" [disableOptionCentering]="true">
                        <!-- <input class="custom-search-input" placeholder="Search" (keyup)="onKeyCC($event.target.value)"> -->
                        <mat-option *ngFor="let type of dropdownListSubTypes" [value]="type">
                            {{type.name}}
                          </mat-option>
                        </mat-select>
                </mat-form-field>
            </div>
    
        </form>

        <div class="p-5 ; width:100% margin-bottom: 12px; margin-left: 16px;">

            <button class="btn btn-primary"  style ="float: right; margin-right: 15px; width:fit-content" routerLink="/log-new-case" (click)="logCaseFunction()" [hidden]="hideLC">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Continue
            </button> 

            <button class="btn btn-primary"  style ="float: right; margin-right: 15px; width:fit-content" routerLink="/incident-process" (click)="logCaseIncidentFunction()" [hidden]="hideIM">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Continue
            </button> 

            <button class="btn btn-primary"  style ="float: right; margin-right: 15px; width:fit-content" routerLink="/create-billing-case" (click)="billingFunction()" [hidden]="hideBP">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Continue
            </button> 


        </div>
     </div>

         

    </div>

</div>

