<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="container-fluid" style="padding: 25px">
    <!-- <div class="ps-4 pe-4 pt-4 container-fluid "> -->
        <div>
            <h6 style="margin-bottom: -20px">ORGANISATION</h6>
            <h2>Add a New User</h2>
            <h5>Manually register an account on behalf of a user.</h5>
        </div>
        <hr class="mt-0">

        <div class="d-flex flex-column min-vh-100 align-items-center">
            <div class="row d-flex">
                <div class="p-2">
                    <ul class="nav nav-pills nav-underline">
                        <li class="active"><a class="pill-no-href">User Details</a></li>
                        <li><a class="pill-no-href">User Account Details</a></li>
                        <li><a class="pill-no-href">Access Requirements</a></li>
                    </ul>
                </div>
            </div>


            <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">
                <div class="d-flex justify-content-between align-items-end">
                    <h3 class="h3-grey">User Details</h3>
                    <p class="purple">Step 1/4</p>
                </div>
                <hr class="mt-0">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                    <div class="form-group">
                        <label class="control-label" for="name">Name</label><span style="color:#ff0000"> *</span>
                        <input type="text" formControlName="firstName" id="FirstName" class="form-control " placeholder="Name"
                            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">First name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="surname">Surname</label><span style="color:#ff0000"> *</span>
                        <input type="text" formControlName="lastName" id="Surname"class="form-control " placeholder="Surname"
                            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                            <div *ngIf="f.lastName.errors.required">Last name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="email">Email</label><span style="color:#ff0000"> *</span>
                        <input type="text" formControlName="email" id="email"class="form-control " placeholder="Email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                        </div>
                        <div class="invalid-feedback" *ngIf="f.email.errors?.pattern">
                            Invalid email format
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="contactNo">Contact Number</label><span style="color:#ff0000">
                            *</span>
                        <input type="text" mask='(000) 000-0000' id="contactNumber" formControlName="contactNo" class="form-control "
                            placeholder="Contact number"
                            [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }" />
                        <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
                            <div *ngIf="f.contactNo.errors.required">Contact number is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="organization">Organisation</label><span style="color:#ff0000">
                            *</span>
                        <input type="text"  formControlName="organization" id="company" class="form-control "
                            placeholder="Organisation"
                            [ngClass]="{ 'is-invalid': submitted && f.org.errors }" />
                        <div *ngIf="submitted && f.organization.errors" class="invalid-feedback">
                            <div *ngIf="f.organization.errors.required">Organisation is required</div>
                        </div>
                    </div>





                    <div class="form-group">
                        <div class="d-flex justify-content-between" style="padding-top: 1em;">
                            <button class="btn btn-default" routerLink="../user-management">
                                Back
                            </button>
                            <button [disabled]="loading" class="btn btn-primary" id="btnNext" (click)="onSubmit()">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Next
                            </button>
                        </div>
                        <!-- <button [disabled]="loading" class="rounded btn btn-primary w-100 ">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Verify Email
                        </button> -->
                    </div>
                </form>
            </div>
        </div>

    </div>

<!-- </div> -->