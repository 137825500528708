import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-incident-process',
  templateUrl: './incident-process.component.html',
  styleUrls: ['./incident-process.component.scss']
})
export class IncidentProcessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
