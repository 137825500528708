<!--
getting  page html code. including page links/info
--> =

<div class="container-fluid" style="text-align:left; background: #f6fafd">

    <h6>HELP CENTRE</h6>
    <h1 style="margin-top:0px;">Getting Started</h1>
    <h5>Your online guide to SBV Connect</h5>
    <hr/>
    
</div>

<nav aria-label="breadcrumb" style =" margin: 10px ">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/help-centre">Help Centre</a></li>
          <li class="breadcrumb-item active" aria-current="page">Getting Started</li>
        </ol>
      </nav>
<div class="box">

    <h2 style="margin-top:0px;">Welcome to SBV Connect</h2>
    
    <p>     
        SBV Connect is a fully integrated digital platform that provides access to our services, support, and information - on-demand and 
        in real-time.
    </p> 
    <p>     
        We have launched the first service of Case Management in version 
        1.0, with many additional services to follow soon.
    </p> 

    <i>
        Cash is evolving. So are we.
    </i>
    <p>
        Watch our video on the future of digital cash management - our 
        vision of the end-to-end services to be included in SBV Connect in 
        time:
    </p>
    <video  controls width = "640px" height = "480px" >
     <source   src="../../assets/videos/v1.mp4" type="video/mp4">

    </video>

    <h3>Case Management</h3>
    <p>
        The SBV Connect Case Management service lets you log all 
        queries centrally and control your cases via the dashboard. We 
        endeavour to resolve all cases within two business days.
    </p>

    <p>
        <a href="/help-case-management-guide" >Click here</a> to learn the following:
    </p> 
 
    <!--Meerchant users-->
    <ul *ngIf="isMerchant">
        <li>How to register, log in, change a password</li>
        <li>How to log a new operational case</li>
        <li>How to manage and resolve an ops case</li>
        <li>How to use your ops client dashboard</li>
        <li>Billing case management for merchant customers</li>
    </ul>

    <!--Billing Bank Users-->
    <ul *ngIf="isOpsUser">
        <li>How to register, log in, change a password</li>
        <li>Billing case management for Bank customers</li>
    </ul>

    <!--Logistics Users-->
    <ul *ngIf="isLogistics">
        <li>How to register, log in, change a password</li>
        <li>How To Manage and Resolve a Billing Case</li>
    </ul>

    <!--Billing Bank Users-->
    <ul *ngIf="isBillingOpsUser || isCLO">
        <li>How to register, log in, change a password</li>
        <li>How to log a new case</li>
        <li>How to manage and resolve a new ops case</li>
        <li>How to use your client dashboard</li>
    </ul>

    <!--Billing User-->
    <ul *ngIf="isBilling">
        <li>How to register, log in, change a password</li>
        <li>Understanding Your Dashboard</li>
        <li>Billing Reports</li>
        <!-- <li>How to Log a New Case</li>
        <li>How to Manage and Resolve a new Case</li> -->
        <li *ngIf="isFinancialAccountant || isBillingHOD">Billing Case Management</li>
        <li *ngIf="isCreditController || isBillingAnalyst">How To Log a New Billing Case</li>
        <li *ngIf="isCreditController || isBillingAnalyst">How To Manage and Resolve a Billing Case</li>
        <li *ngIf="isCashCentreHOD">Billing Case Management</li>
    </ul>

    <!--Super Usert-->
    <ul *ngIf="isSuperUser">
        <li>How to register, log in, change a password</li>
        <li>Understanding Your Dashboard (SBV Customers)</li>
        <li>Understanding Your Dashboard (Billing Users)</li>
        <li>Billing Reports</li>
        <li>How to Log a New Case</li>
        <li>How to Manage and Resolve a Case</li>
        <li>How to Log a Billing Case</li>
        <li>How to Manage and Resolve a Billing Case</li>
        <li>Billing Case Management</li>
        <li>Billing Case Management</li>
    </ul>

    <h3>Organisation</h3>

    <p>
        SBV Connect allows you to update and manage your profile and your password. If you have administrator rights, you can view and manage profiles for your team.
    </p>
    
    <p>
        <a href="/help-organisation" >Click here</a> to learn the following:
    </p>

   
    <ul>
    <li>My Profile</li>
    <ul>
    <li>Personal Details</li>
    <li>Business Details</li>
    <li>Cash Centres Permission</li>
    <li>Editing your Profile Details</li>
    <li>Changing your password</li>
    </ul>
    <li>Managing User Access </li>
    <ul>
    <li>User List</li>
    <li>Approving and Managing User Profiles</li>
    </ul>
    </ul>


    <div class="c-m" >
        <h3>Need help with something else?</h3>
        <hr/>
       
        <div></div>
        <button class="cmb cm" routerLink="/contact-us" >Contact Us</button>


    </div>


</div>