import { Component, OnInit, TemplateRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie';
import { stringify } from 'querystring';
import { Site } from 'src/app/_models/site';
import { AccountService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-all-sites',
  templateUrl: './all-sites.component.html',
  styleUrls: ['./all-sites.component.scss']
})
export class AllSitesComponent implements OnInit {

  siteName:string="Site Name";
  organisation:string="Organisation";
  dtOptions: DataTables.Settings = {};
  fullTable: boolean = true;
  rowId;
  userStatus:number=0;
  form: FormGroup;
  status:string="Active";
  hideCard:boolean=false;
  duplicateSite:boolean=false;

  orgName:string;
  orgCode:string;
  orgAcc:string

  selectedSite:number;

  canEdit:boolean=false;

  hideErrorBanner: boolean=true;
  errorMessage: string=" ";

  hideDeactivate:boolean=true;
  hideActivate:boolean=true;

  siteNames=[];
  citCodes=[];
  accNums=[];

  rowSelected: boolean = false;

  dropdownTest = ["Cash Centre 1","Cash Centre 2","Cash Centre 3"];

  dropdownSettingsCC = {};
  dropdownCCList = [];

  merchantGroups=[];

  selectedCC;
  ccChosen:boolean=true;
  ccName:string="";

  result:Site;

  sites: Site[] = [];

  nameFocusVar: boolean=true;
  codeFocusVar: boolean=true;
  accFocusVar: boolean=true;

  invalidName:boolean=false;
  invalidAcc:boolean=false;
  invalidCode:boolean=false;

  loading:boolean=false;
  submitted:boolean=false;
  cd:boolean=false;
  ca:boolean=false;

  nameError:string="";
  accError:string="";
  codeError:string="";

  hideSaveButton:boolean=true;
  invalidCC:boolean=false;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private cookieService: CookieService,
    private accountService: AccountService,
    private alertService: AlertService,
  ) { }

  editDetails(){
    this.canEdit=!this.canEdit;
    this.hideSaveButton=false;
  }

  get f() { return this.form.controls; }

  cancelEdit(){
    this.canEdit=false;
    this.hideSaveButton=true;
    this.invalidAcc=false;
    this.invalidName=false;
    this.invalidCode=false;
    this.showDetails();
  }
  showDetails(){

    this.duplicateSite=false;
    this.canEdit=false;
    this.hideSaveButton=true;
    this.invalidAcc=false;
    this.invalidName=false;
    this.invalidCode=false;

    let centre=[];
    let groupName='';
    this.ccChosen=true;

    this.hideCard=false;
    if (this.rowSelected == false) {
      return;
    }
    this.rowId = localStorage.getItem('rowId');


    if (this.fullTable == true) {
      this.fullTable = !this.fullTable;
    }

    this.rowSelected = true;

    this.result = this.sites.find(({ id }) => id === parseInt(localStorage.getItem('selectedSite')));

    this.siteName=this.result.name;

    if(this.result.status==1){
      this.status='Active';
      this.hideActivate=true;
      this.hideDeactivate=false;
    }
    if(this.result.status==0){
      this.status='Disabled';
      this.hideActivate=false;
      this.hideDeactivate=true;
    }

    console.log("result " + JSON.stringify(this.result));

    this.selectedCC=this.result.cc;

    const defaultCC = this.dropdownCCList.find(c => c.id === this.result.cc);

    for (let i: number =0; i < this.dropdownCCList.length; i++) {

      if(this.result.cc==this.dropdownCCList[i].id){
        console.log(JSON.stringify(this.dropdownCCList[i]))
        console.log("cc="+this.result.cc+"---ccList="+this.dropdownCCList[i].id)
        centre.splice(0);
        centre.push(this.dropdownCCList[i].name);
        console.log(JSON.stringify(centre));
      }
    }

    console.log('GROUPS : '+JSON.stringify(this.merchantGroups));
    for (let i: number =0; i < this.merchantGroups.length; i++){
      if(this.result.group==this.merchantGroups[i].id){
        groupName=(this.merchantGroups[i].name).replace("^","'");
      }
    }

    this.orgName=this.result.name;
    this.orgCode=this.result.code;
    this.orgAcc=this.result.accNum;


    console.log("--?")
    this.form = this.formBuilder.group({
      group: [groupName],
      name: [this.result.name],
      code: [this.result.code],
      accNo: [this.result.accNum],
      cashCentres:[centre],
      CC:[defaultCC],
    })


    console.log("Name"+this.f.name.value);


    let table = $('#siteTable').DataTable();
    table.column(3).visible(false);
    table.column(4).visible(false);
    table.column(5).visible(false);
  }

  hideDetails(){
    this.hideCard=true;
    this.fullTable = true;
    let table = $('#siteTable').DataTable();
    table.column(3).visible(true);
    table.column(4).visible(true);
    table.column(5).visible(true);
  }

  changeCC(item){
    this.selectedCC=item.id;
    this.ccName=item.name;
    console.log("Cash Centre selected : "+this.selectedCC);
    console.log("CC Name : "+this.ccName);
    this.ccChosen=true;
  }


  applyEdit(){
    this.loading=true;
    this.submitted=true;

    this.invalidCode=false;
    this.invalidAcc=false;
    this.invalidName==false;
    
    let name=this.f.name.value.replace("'","^");;
    let code=this.f.code.value;
    let acc=this.f.accNo.value;
    let id=localStorage.getItem("selectedSite");

    if(name=="" || name==" "){
      this.invalidName=true;
      this.nameError="Site name cannot be empty";
      this.loading=false;
      this.submitted=false;
    }

    if(this.orgName!=name){
      console.log("CHECK NAME");
      for(let i: number =0; i < this.siteNames.length; i++){
        if(this.siteNames[i]==name){
          console.log("Dup name "+this.siteNames[i]);
          this.invalidName=true;
          this.nameError="Site name already exists";
          this.loading=false;
          break;
        }
      }
    }

    if(code=="" || code==" "){
      this.invalidCode=true;
      this.codeError="CIT Code cannot be empty";
      this.loading=false;
      this.submitted=false;
    }

   if(this.orgCode!=code){
    console.log("CHECK CODE");
    for(let i: number =0; i < this.citCodes.length; i++){
      if(this.citCodes[i]==code){
        console.log("Dup code "+this.citCodes[i]);
        this.invalidCode=true;
        this.codeError="CIT Code already exists";
        this.loading=false;
        break;
      }
    }
   }

    if(acc=="" || acc==" "){
      this.invalidAcc=true;
      this.accError="Account number cannot be empty";
      this.loading=false;
      this.submitted=false;
    }

    if(this.orgAcc!=acc){
      console.log("CHECK ACC");
      for(let i: number =0; i < this.accNums.length; i++){
        if(this.accNums[i]==acc){
          console.log("Dup acc "+this.accNums[i]);
          this.invalidAcc=true;
          this.accError="Account number already exists";
          this.loading=false;
          break;
        }
      }
    }

    if(this.ccChosen=false){
      this.invalidCC=true;
      console.log("please select CC")
      this.loading=false;
      this.submitted=false;
    }


    if(this.loading==true){
      let details={
       "site":{
        "id": id,
        "name":name,
        "citCode":code,
        "accountNo":acc,
        "cashCentreId":this.selectedCC,
        "status":1,
       }
      }

      let js=this.cookieService.get("JSESSIONID");
      this.accountService.updateSite(details,js)
      .subscribe(data => {
        console.log("Update Done");
        window.location.reload();
      },
        error => {

          if (error.status==0){
            this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
            this.hideErrorBanner=false;
          }

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }
          if(error.status==409){
            this.duplicateSite=true;
            this.loading=false;
            
          }
          else{
          console.log(error.error.error.message);
          }
          this.loading=false;
          this.submitted=false;
        });
    }
  }

  deactivate(){
    this.loading=true;
    this.cd=true;
    let name=this.f.name.value;
    let code=this.f.code.value;
    let acc=this.f.accNo.value;
    let id=localStorage.getItem("selectedSite");

    let details=
    {
      "site":{
        "id": id,
        "name":name,
        "citCode":code,
        "accountNo":acc,
        "cashCentreId":this.selectedCC,
        "status":0,
      }
    }

 
    let js=this.cookieService.get("JSESSIONID");
    this.accountService.updateSite(details,js)
    .subscribe(data => {
      console.log("Site deactivated");
      window.location.reload();
    },
      error => {
        if (error.status==500){
          this.errorMessage="ERROR 500 OCCURRED";
          this.hideErrorBanner=false;
        }
        if (error.status==408 || error.status==504){
          this.errorMessage="ERROR 408/504 OCCURRED";
          this.hideErrorBanner=false;
        }
        console.log(error.error.error.message);
        this.loading=false;
        this.cd=false;
      });
  }

  activate(){
    this.loading=true;
    this.ca=true;
    let name=this.f.name.value;
    let code=this.f.code.value;
    let acc=this.f.accNo.value;
    let id=localStorage.getItem("selectedSite");

    let details=
    {
      "site":{
        "id": id,
        "name":name,
        "citCode":code,
        "accountNo":acc,
        "cashCentreId":this.selectedCC,
        "status":1,
      }
    }

    let js=this.cookieService.get("JSESSIONID");
    this.accountService.updateSite(details,js)
    .subscribe(data => {
      console.log("Site deactivated");
      window.location.reload();
    },
      error => {
        if (error.status==500){
          this.errorMessage="ERROR 500 OCCURRED";
          this.hideErrorBanner=false;
        }
        if (error.status==408 || error.status==504){
          this.errorMessage="ERROR 408/504 OCCURRED";
          this.hideErrorBanner=false;
        }
        this.loading=false;
        this.ca=false;
        console.log(error.error.error.message);
      });
  }

  onCCSelect(item: any){
    this.selectedCC=item.id;
    this.ccName=item.name;
    console.log("Cash Centre selected : "+this.selectedCC);
    console.log("CC Name : "+this.ccName);
    this.ccChosen=true;
  }

  onCCDeselect(){
    this.selectedCC=null;
    this.ccName="";
    console.log("Cash Centre deselected");
    this.ccChosen=false;
  }

  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit(): void {

    let Jsession=this.cookieService.get("JSESSIONID");
    this.accountService.getSites(Jsession)
    .subscribe(data => {
      console.log(JSON.stringify(data));
      for (let i: number =0; i < data.sites.site.length; i++) {
       
        this.siteNames.push(data.sites.site[i].name);
        this.citCodes.push(data.sites.site[i].citCode);
        this.accNums.push(data.sites.site[i].accountNo);
        
      }
     },

     error => {
      console.log("ERROR");
     });

    //let Jsession=this.cookieService.get("JSESSIONID");
    //let Jsession="687D3F177F86E97ADC2FFBCCC78B4C1B";
    let mail=sessionStorage.getItem("email");


    let jSession=this.cookieService.get("JSESSIONID");
    console.log(jSession);

    this.accountService.getCashCentres(jSession)
    .subscribe(data => {
      this.dropdownCCList = data.root.cashCentre;
    },
      error => {
        if (error.status==500){
          this.errorMessage="ERROR 500 OCCURRED";
          this.hideErrorBanner=false;
        }
        if (error.status==408 || error.status==504){
          this.errorMessage="ERROR 408/504 OCCURRED";
          this.hideErrorBanner=false;
        }
        this.alertService.error(error.error.error.message);
      });

      this.accountService.getMerchantGroups(mail,Jsession)
      .subscribe(data => {
        this.merchantGroups=data.customers.customer;
        console.log("GROUPS : "+JSON.stringify(this.merchantGroups));
       },
       error => {
        if (error.status==500){
          this.errorMessage="ERROR 500 OCCURRED";
          this.hideErrorBanner=false;
        }
        if (error.status==408 || error.status==504){
          this.errorMessage="ERROR 408/504 OCCURRED";
          this.hideErrorBanner=false;
        }
        console.log(error);
       });

    this.dropdownSettingsCC= {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    }

    //this.hideDetails();
    this.hideCard=true;
    localStorage.removeItem('selectedMerchId');

    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "ordering": true,
      order: [7],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'customerName' },//0
        { data: 'name' },//1
        { data: 'citCode' },//2
        { data: 'accountNo'},//3
        { data: 'cashCentreName'}, //4
        { data: 'status' },//5
        { data: 'created' },//6
        { data: 'updated' },//7
      ],
      "columnDefs": [

        {
          "targets": [0],
          "render": function (data, type, row) {
          if(row.customerId===-1){
            return '<span class="label label-danger">INVALID</span>';
          }

          return data;
        }
      },

        {
          "targets": [6, 7],
          "render": function (data, type, row) {
            var dateSplit = data.split('-');
            return data;
          }
        },
        {
          "targets": 5,
          "render": function (data, type, row) {
            if (row.status === 0) {
              return '<span class="label label-danger">Disabled</span>';
            }
            if (row.status === 1) {
              return '<span class="label label-success">Active</span>';
            }
            return data;
          }
        },
      ]
    };

    $('#siteTable').on('click', 'tr', function () {
      this.selectedSite = siteTable.row(this).data().id;
      if (this.selectedSite != localStorage.getItem('selectedSite')) {
        localStorage.removeItem('selectedSite');
        localStorage.setItem('selectedSite', this.selectedSite);
      }
    });

    let siteTable;
    
    this.accountService.getSites(Jsession)
    .subscribe(data => {
      console.log(JSON.stringify(data));
      for (let i: number =0; i < data.sites.site.length; i++) {
        let s =new Site;
        s.id=data.sites.site[i].id;
        s.group=data.sites.site[i].customerId;
        s.name=data.sites.site[i].name;
        s.code=data.sites.site[i].citCode;
        s.accNum=data.sites.site[i].accountNo;
        s.cc=data.sites.site[i].cashCentreId;
        s.groupName=(data.sites.site[i].customerName).replace("^","'" );
        s.ccName=data.sites.site[i].cashCentreName;
        s.status=data.sites.site[i].status;
        this.sites.push(s);
      }

      let display=data.sites.site;
      for (let i: number =0; i < data.sites.site.length; i++) {
        let tempName=(display[i].customerName).replace("^","'");
        display[i].customerName=tempName;
      }
      console.log("display - "+JSON.stringify(display));

      setTimeout(() => {
        siteTable = $('#siteTable').DataTable({
          initComplete: function () {
            if (!this.fullTable) {
            }
          },
          retrieve: true,
        }).rows.add(display).draw();
      }, 2000);

     },

     error => {
      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }
      console.log("ERROR");
     });

    //SITE TABLE FUNCTIONS
    $('#siteTable ').on('click', 'tr', () => {
      this.rowSelected=true;
      this.showDetails();
    });

    $('#siteTable ').on('click', 'tr', function () {
      if ($(this).hasClass('selected')) {
        $(this).removeClass('selected');
      }
      else {
        siteTable.$('tr.selected').removeClass('selected');
        $(this).addClass('selected');
      }
      localStorage.setItem('rowId', siteTable.row(this).index());
    });
    //SITE TABLE FUNCTIONS


  }
  xButton(){
    window.location.reload();
}

}
