import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-request-access-processing',
  templateUrl: './request-access-processing.component.html'
})
export class RequestAccessProcessingComponent implements OnInit {

  constructor(
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }

  ngOnInit(): void {
  }

}
