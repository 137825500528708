import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Site } from 'src/app/_models/site';
import { AccountService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-link-new-sites',
  templateUrl: './link-new-sites.component.html',
  styleUrls: ['./link-new-sites.component.scss']
})
export class LinkNewSitesComponent implements OnInit {

  clientOptions: DataTables.Settings = {};

  dropdownSettingsCC = {};
  dropdownCCList = ["Cash Centre 1","Cash Centre 2","Cash Centre 3"];

  addedSites: number[] = [];

  dropdownSettingsGroup = {};
  dropdownGroups=["Group 1","Group 2","Group 3"];

  sites: Site[] = [];
  selectedSite:number;

  hideErrorBanner: boolean=true;
errorMessage: string=" ";


  constructor(
    private router: Router,
    private cookieService: CookieService,
    private accountService: AccountService,
  ) { 
  }

  return(){
    this.router.navigate(['/merchant-management']);
  }

  test(){
    console.log("TEST");
  }
  xButton(){
    window.location.reload();
}

  ngOnInit(): void {

    let Jsession=this.cookieService.get("JSESSIONID");
    let mail=sessionStorage.getItem("email");

    this.dropdownSettingsCC= {
      singleSelection: true,
      //idField: 'id',
      //textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true"
    }

    this.dropdownSettingsGroup= {
      singleSelection: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      clearable: "false",
      closeDropDownOnSelection: "false",
      allowSearchFilter: true
    
    }

    this.clientOptions={
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "ordering": true,
      order: [0],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'customerId' },//0
        { data: 'name' },//1
        { data: 'citCode' },//2
        { data: 'accountNo'},//3
        { data: 'cashCentreId'}, //4
        { data: 'status' },//5
        { data: null, "defaultContent": '<input type="checkbox" id="addCB">' }
      ],
      "columnDefs": [
        {
          "targets": 5,
          "render": function (data, type, row) {
            if (row.status === 0) {
              return '<span class="label label-danger">Disabled</span>';
            }
            if (row.status === 1) {
              return '<span class="label label-success">Active</span>';
            }
            return data;
          }
        },
      ]

    };

    $('#siteTable').on('click', 'tr', function () {
      this.selectedSite = siteTable.row(this).data().id;
      if (this.selectedSite != localStorage.getItem('selectedSite')) {
        localStorage.removeItem('selectedSite');
        localStorage.setItem('selectedSite', this.selectedSite);
      }
    });

    let siteTable;
    
    this.accountService.getSites(Jsession)
    .subscribe(data => {
      console.log(JSON.stringify(data));
      for (let i: number =0; i < data.sites.site.length; i++) {
        let s =new Site;
        s.id=data.sites.site[i].id;
        s.group=data.sites.site[i].customerId;
        s.name=data.sites.site[i].name;
        s.code=data.sites.site[i].citCode;
        s.accNum=data.sites.site[i].accountNo;
        s.cc=data.sites.site[i].cashCentreId;
        s.status=data.sites.site[i].status;
        this.sites.push(s);
      }

      setTimeout(() => {
        siteTable = $('#siteTable').DataTable({
          initComplete: function () {
            if (!this.fullTable) {
            }
          },
          retrieve: true,
        }).rows.add(data.sites.site).draw();
      }, 2000);

     },

     error => {
      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }

      console.log("ERROR");
     });

     $('#siteTable ').on('click', 'tr', function () {
    
      if ($(this).hasClass('selected')) {
        console.log("row has been deselected");
        $(this).removeClass('selected');
        $(this).find('#addCB').prop("checked", false);
      }
      else {
        console.log("row has been selected");
        $(this).addClass('selected');
        $(this).find('#addCB').prop("checked", true); 
      }
      localStorage.setItem('rowId', siteTable.row(this).index());
    });

  }

  linkSites(){
    let tbl = $('#siteTable').DataTable();
    tbl.rows().every( function () {
      console.log("data - "+JSON.stringify(this.data()));
      } );
  }

}
