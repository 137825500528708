import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { AccountService } from '../_services';

@Component({
  selector: 'app-role-change-login',
  templateUrl: './role-change-login.component.html',
  styleUrls: ['./role-change-login.component.scss']
})
export class RoleChangeLoginComponent implements OnInit {
  //userMail:string = "";

  userMail: string = "";
  newJSession:string = "";
  newToken:string = "";
  newRoleName:string="";
  constructor(private cookieService:CookieService,private accountService:AccountService) { }

  ngOnInit(): void {
  }

  performLogin(){
    let JSession=this.cookieService.get("JSESSIONID")
    console.log('testing!')

  //  let email=this.userMail;
  
    this.accountService.doSwitch(JSession)
    .subscribe((data)=> {
      console.log('switch role activated')
      console.log(data)
      this.newJSession = data.root.jsessionId;
      this.newToken = data.root.xBonitaAPIToken;
      this.userMail = data.root.userId

      console.log("JSESSION - "+this.newJSession);
      console.log("TOKEN - "+this.newToken);
      console.log('usermail', this.userMail)

      this.cookieService.remove('JSESSIONID');
      this.cookieService.remove('X-Bonita-API-Token');


      
      this.cookieService.put('JSESSIONID', this.newJSession,{
        secure:true,
        sameSite:'none'
      });
      
      this.cookieService.put('X-Bonita-API-Token', this.newToken,{
        secure:true,
        sameSite:'none'
      });
      sessionStorage.setItem('email',this.userMail);

      

      
      setTimeout(() => {
        window.location.href = `./home`;
            }, 100);




    }, error => {
      
      if (error.status === 0) {
        this.accountService.doSwitch(JSession)
        .subscribe((data)=> {
          console.log('switch role activated')
          console.log(data)
          this.newJSession = data.root.jsessionId;
          this.newToken = data.root.xBonitaAPIToken;
          this.userMail = data.root.userId
    
          console.log("JSESSION - "+this.newJSession);
          console.log("TOKEN - "+this.newToken);
          console.log('usermail', this.userMail)
    
          this.cookieService.remove('JSESSIONID');
          this.cookieService.remove('X-Bonita-API-Token');
    
    
          
          this.cookieService.put('JSESSIONID', this.newJSession,{
            secure:true,
            sameSite:'none'
          });
          
          this.cookieService.put('X-Bonita-API-Token', this.newToken,{
            secure:true,
            sameSite:'none'
          });
          sessionStorage.setItem('email',this.userMail);
    
          
    
          
          setTimeout(() => {
            window.location.href =  `./home`;
                }, 100);
      })
    } else {
        console.log(error)
      
    }
  



    })
  } 

}
