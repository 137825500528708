<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="container-fluid" style="padding: 25px">
    <!-- <div class="ps-4 pe-4 pt-4 container-fluid "> -->
        <div>
            <h6 style="margin-bottom: -20px">ORGANISATION</h6>
            <h2>Add a New User</h2>
            <h5>Manually register an account on behalf of a user.</h5>
        </div>
        <hr class="mt-0">

        <div class="d-flex flex-column min-vh-100 align-items-center">
            <div class="row d-flex">
                <div class="p-2">
                    <ul class="nav nav-pills nav-underline">
                        <li><a class="pill-no-href">User Details</a></li>
                        <li class="active"><a class="pill-no-href">User Account Details</a></li>
                        <li><a class="pill-no-href">Access Requirements</a></li>
                    </ul>
                </div>
            </div>


            <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">
                <div class="d-flex justify-content-between align-items-end">
                    <h3 class="h3-grey">User Account Details</h3>
                    <p class="purple">Step 2/4</p>
                </div>
                <hr class="mt-0">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="control-label" for="organisation">User Type</label><span style="color:#ff0000">
                            *</span>
                        <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;"
                            [placeholder]="'Select Group'" [settings]="dropdownSettingsGroups"
                            [data]="dropdownListGroups" (onSelect)="onItemSelectGroup($event)" formControlName="group"
                            (onDeSelect)="onGroupItemDeSelect($event)" class="singleselect" clearable="true">
                        </ng-multiselect-dropdown>
                        <div *ngIf="!groupChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!groupChosen">User Type is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div style="height:10px">
                        </div>
                        <label class="control-label" for="organisation">Role</label><span style="color:#ff0000">
                            *</span>
                        <ng-multiselect-dropdown #rolesMultiSelect [formControl]="f.role" formControlName="role"
                            style="font-size: 12px; border-radius: 50px;" [placeholder]="'Select Role'"
                            [settings]="dropdownSettingsRoles" [data]="dropdownListRoles"
                            (onSelect)="onItemSelectRole($event)" class="singleselect"
                            (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll()">
                        </ng-multiselect-dropdown>
                        <div *ngIf="!roleChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!roleChosen">Role is required</div>
                        </div>

                    </div>

                    <!-- <div class="form-group">
                        <label class="control-label" for="organisation">Organisation</label><span style="color:#ff0000">
                            *</span>
                        <input type="text" formControlName="organisation" class="form-control " placeholder="Organisation"
                            [ngClass]="{ 'is-invalid': submitted && f.organisation.errors }" />
                        <div *ngIf="submitted && f.organisation.errors" class="invalid-feedback">
                            <div *ngIf="f.organisation.errors.required">Organisation is required</div>
                        </div>
                    </div> -->

                    <div class="form-group">
                        <label class="control-label" for="jobTitle">Job Title</label><span style="color:#ff0000">
                            *</span>
                        <input type="text" formControlName="jobTitle" class="form-control" placeholder="Job title"
                            [ngClass]="{ 'is-invalid': submitted && f.jobTitle.errors }" />
                        <div *ngIf="submitted && f.jobTitle.errors" class="invalid-feedback">
                            <div *ngIf="f.jobTitle.errors.required">Job title is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="department">Department</label><span style="color:#ff0000">
                            *</span>
                        <input type="text" formControlName="department" class="form-control" placeholder="Department"
                            [ngClass]="{ 'is-invalid': submitted && f.department.errors }" />
                        <div *ngIf="submitted && f.department.errors" class="invalid-feedback">
                            <div *ngIf="f.department.errors.required">Department is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="lineManagerName">Line Manager Name</label>
                        <input type="text" formControlName="lineManagerName" class="form-control"
                            placeholder="Line manager name" placeholder="Line Manager Name" />
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="lineManagerContact">Line Manager Contact Number</label>
                        <input type="text" mask='(000) 000-0000' formControlName="lineManagerContact"
                            class="form-control" placeholder="Line manager's contact number"
                            placeholder="Line Manager Contact Detail" />
                    </div>



                    <div class="form-group">
                        <div class="d-flex justify-content-between" style="padding-top: 1em;">
                            <button [disabled]="loading" class="btn btn-default" routerLink="../new-user">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Back
                            </button>
                            <button [disabled]="loading" class="btn btn-primary" (click)="onSubmit()">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Next
                            </button>
                        </div>
                        <!-- <button [disabled]="loading" class="rounded btn btn-primary w-100 ">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Verify Email
                        </button> -->
                    </div>
                </form>
            </div>
        </div>

    </div>

<!-- </div> -->