import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AccountService } from '../_services';
import { CookieService } from 'ngx-cookie';
import { Observable } from "rxjs";

// import { AccountService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService,
        private cookieService: CookieService
    ) {}

    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
     boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        //const user = this.accountService.userValue;
        let JSession = this.cookieService.get("JSESSIONID");
        const status = sessionStorage.getItem("login-status");

        // if user logged in AND session is active
        if (JSession && status == "logged in"){
            return true;
        }

        // not logged in so redirect to login page with the return url
        else {
            //this.router.navigate(['/login']);
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }
}

     // if (user && (sessionStorage.getItem('firstName') != null)) {
        //     // authorised so return true
        //     return true;
        // }