import { Component, OnInit, TemplateRef } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie';
import { ignoreElements } from 'rxjs/operators';
import { Client } from 'src/app/_models/client';
import { Group } from 'src/app/_models/group';
import { Site } from 'src/app/_models/site';
import { AccountService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-merchant-management',
  templateUrl: './merchant-management.component.html',
  styleUrls: ['./merchant-management.component.scss']
})
export class MerchantManagementComponent implements OnInit {

  groupName:string="Name";
  organisation:string="Organisation";
  dtOptions: DataTables.Settings = {};
  clientOptions: DataTables.Settings = {};
  fullTable: boolean = true;
  cFullTable:boolean=true;
  rowId;
  //siteTable;
  userStatus:number=0;
  form: FormGroup;
  status:string="Active";
  hideCard:boolean=false;

  fc:boolean=false;
  cleared:boolean=false;

  originalName: string='';

  rowSelected: boolean = false;
  invalidName:boolean=false;

  hideSaveButton:boolean=true;

  nameFocusVar: boolean=true;
  codeFocusVar: boolean=true;
  accFocusVar: boolean=true;

  selectedG: number;
  selectedS: number;

  hideDeactivate:boolean=true;
  hideActivate:boolean=true;
  result:Group;
  deleteSite:Site;
  cl:Client;
  clientArray:Client [];

  hideCheckBtn: boolean=true;
  loadSite: boolean=false;

  hideErrorBanner: boolean=true;
errorMessage: string=" ";


  groups: Group[] = [];
  sortedGroups: Group[] = [];

  name:string="Name";
  code:string="564654";
  accNum:string="123456";
  cc:string="CC";
  siteStatus:string="pending";
  containsRows:boolean=false;

  canEdit:boolean=false;
  num:number;
  duplicateGroup:boolean=false;

  sites: Site[] = [];
  toggleSite: Site[]=[];
  tempSites: Site[]=[];
  selectedSite:number;

  toggleStatus: boolean=false;
  changeName: boolean=false;
  checked: boolean=false;

  deleteArray: number[]=[];
  toggleArray: number[]=[];
  loading:boolean=false;
  submitted: boolean=false;
  cd:boolean=false;
  RowSelected:boolean=false;

  GroupName:String="";
  nameChange:string="";
  nameError:string="";


  hideEditSitesBtn: boolean=false;
  hideCheckSitesBtn: boolean=true;
  hideSaveSitesChangesbtn: boolean=true;
  hideCancelSiteEditBtn: boolean=true;

  dropdownSettingsCC = {};
  dropdownCCList = ["Cash Centre 1","Cash Centre 2","Cash Centre 3"];

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private accountService: AccountService,
    private alertService: AlertService,
    private cookieService: CookieService
  ) { }

  editDetails(){
    this.canEdit=!this.canEdit;
    this.hideSaveButton=false;
    this.originalName=this.f.name.value;
  }

  cancelEdit(){
    this.canEdit=false;
    this.hideSaveButton=true;
    this.invalidName=false;
    this.showDetails();
  }

  editSites(){
    this.hideEditSitesBtn=true;
    this.hideCancelSiteEditBtn=false;
    this.hideCheckSitesBtn=false;
  }

  cancelSiteEdit(){
    this.hideEditSitesBtn=false;
    this.hideCancelSiteEditBtn=true;
    this.hideCheckSitesBtn=true;
    this.hideSaveSitesChangesbtn=true;
    this.showDetails();
  }



  hideDetails(){
    this.hideCard=true;
    this.fullTable = true;
    let table = $('#groupTable').DataTable();
    table.column(3).visible(true);
    table.column(4).visible(true);
    table.column(5).visible(true);
  }

  toggleSites(){
    console.log("SITE BEING ACTIVATED/DEACTIVATED");
    let x=parseInt(localStorage.getItem('selectedSId'));
    console.log("X = "+x);

    if(this.toggleArray.includes(x)){
      const i=this.toggleArray.indexOf(x);
      if(i!==-1){
        this.toggleArray.splice(i,1);
      }
    }
    else{
      this.toggleArray.push(x);
    }
    console.log("toggleArray after method -- "+this.toggleArray);
  }

  showDetails(){

    if(localStorage.getItem('CSR')=="true"){
      console.log("same row clicked");
    }
    else{

      console.log("load new group details");

      let clearTable;
      clearTable=$('#siteTable').DataTable();
      clearTable.clear().draw();
  
      this.canEdit=false;
      this.hideSaveButton=true;
      this.invalidName=false;
      this.duplicateGroup=false;
  
      this.fc=false;
  
      this.toggleSite.splice(0);
      this.toggleStatus=false;
      this.checked=false;
  
      this.hideEditSitesBtn=false;
      this.hideCancelSiteEditBtn=true;
      this.hideCheckSitesBtn=true;
      this.hideSaveSitesChangesbtn=true;
  
      this.toggleArray.splice(0);
      this.deleteArray.splice(0);
      console.log("LENGTH - "+ this.deleteArray.length);
  
      this.sites.splice(0);
      console.log("Site Array - "+ JSON.stringify(this.sites));
  
      this.hideCard=false;
      if (this.rowSelected == false) {
        return;
      }
      this.rowId = localStorage.getItem('rowId');
  
      let groupName="";
  
      if (this.fullTable == true) {
        this.fullTable = !this.fullTable;
      }
  
      this.rowSelected = true;
  
      this.result = this.groups.find(({ id }) => id === parseInt(localStorage.getItem('selectedGId')));
  
      console.log("result " + JSON.stringify(this.result));
      
      console.log("this.selectedUser " + this.selectedG);
  
      this.GroupName=this.result.name;
      groupName=this.result.name;
      console.log("Name: "+groupName);
  
      console.log("--?")
      this.form = this.formBuilder.group({
        name: [this.result.name],
       
      })
  
    
      this.sites.splice(0);
      this.getSites();
  
      let table = $('#groupTable').DataTable();
      table.column(3).visible(false);
      table.column(4).visible(false);
      table.column(5).visible(false);

    }

  }

  deleteItem(){
    console.log("ITEM DELETED");
  }

  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  test(){
    console.log("Test");
  }

  linkSites(){
    localStorage.setItem("groupID",this.result.id.toString());
  }

  ngOnInit(): void {

    localStorage.removeItem('CSR');
    localStorage.removeItem('selectedGId');

    this.dropdownSettingsCC= {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    }

    this.hideCard=true;

    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "ordering": true,
      order: [1],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'name' },//0
        { data: 'updated' },//1
        { data: 'created' },//2
      ],
      "columnDefs": [
        {
          "targets": [1, 2],
          "render": function (data, type, row) {
            var dateSplit = data.split('-');
            return data;
          }
        },
      ]
    };

    $('#groupTable').on('click', 'tr', function () {
      this.selectedG = groupTable.row(this).data().id;
      if (this.selectedG != localStorage.getItem('selectedGId')) {
        localStorage.removeItem('selectedGId');
        localStorage.setItem('selectedGId', this.selectedG);
        localStorage.setItem('CSR',"false");
      }
      else{
        localStorage.setItem('CSR',"true");
      }
    });

    let groupTable;
    let JSession=this.cookieService.get("JSESSIONID");
    let mail=sessionStorage.getItem("email");

    this.accountService.getMerchantGroups(mail,JSession)
    .subscribe(data => {
      console.log(JSON.stringify(data));

      for (let i: number =0; i < data.customers.customer.length; i++) {
        let g =new Group;
        g.id=data.customers.customer[i].id;
        g.ownerId=data.customers.customer[i].ownerId;
        g.code=data.customers.customer[i].code;
        g.name=(data.customers.customer[i].name).replace("^", "'");
        g.created=data.customers.customer[i].created;
        g.updated=data.customers.customer[i].updated;
        g.lastSiteUpdated=data.customers.customer[i].lastSiteUpdated;

        this.groups.push(g);
      }

      for (let i: number =0; i < this.groups.length; i++) {
        for (let j: number =0; j < (this.groups.length)-1; j++) {

              let date1="";
              let date2="";

              if(this.groups[j].updated < this.groups[j].lastSiteUpdated){ //Check which date is more recent
                date1 = this.groups[j].lastSiteUpdated;
              }
              else{
                date1 = this.groups[j].updated;
              }

              if(this.groups[j+1].updated < this.groups[j+1].lastSiteUpdated){
                date2 = this.groups[j+1].lastSiteUpdated;
              }
              else{
                date2 = this.groups[j+1].updated;
              }

              if(date1 < date2){
                let temp = this.groups[j];
                this.groups[j] = this.groups[j+1];
                this.groups[j+1] = temp;
              }

        }
      }

      // for (let i: number =0; i < this.groups.length; i++) {
      //   console.log((i+1)+"- "+JSON.stringify(this.groups[i]));
      // }
      
      //console.log("SORTED ARRAY - " + JSON.stringify(this.groups));


      setTimeout(() => {
        groupTable = $('#groupTable').DataTable({
          initComplete: function () {
            if (!this.fullTable) {
            }
          },
          retrieve: true,
        }).rows.add(this.groups).draw();
      }, 2000);

     },

     error => {
      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }
      console.log("ERROR");
     });

    // GROUP TABLE FUNCTIONS
    $('#groupTable ').on('click', 'tr', () => {
      this.rowSelected=true;
      this.showDetails();

    });

    $('#groupTable ').on('click', 'tr', function () {
      // if ($(this).hasClass('selected')) {
      //   $(this).removeClass('selected');
      // }
      // else {
      //   groupTable.$('tr.selected').removeClass('selected');
      //  $(this).addClass('selected');
      // }
      localStorage.setItem('rowId', groupTable.row(this).index());
    });
    //GROUP TABLE FUNCTIONS

    this.clientOptions={
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "ordering": true,
      order: [0],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'name' },//0
        { data: 'code' },//1
        { data: 'accNum' },//2
        { data: 'ccName' },//3
        { data: 'status' },//4
        { data: null, "defaultContent": '<input type="checkbox" id="toggleCB">' }//5
      ],
      "columnDefs": [

        {
          "targets": 4,
          "render": function (data, type, row) {
            if (row.status === 0) {
              return '<span class="label label-danger">Disabled</span>';
            }
            if (row.status === 1) {
              return '<span class="label label-success">Active</span>';
            }
            return data;
          }
        },
      ]

    };

    //////////////////////////////////////////////////////////////////

    // $('#siteTable').on('click', 'tr', function () {
    //   this.selectedS = siteTable.row(this).data().id;
    //   if (this.selectedS != localStorage.getItem('selectedSId')) {
    //     localStorage.removeItem('selectedSId');
    //     localStorage.setItem('selectedSId', this.selectedS);
    //   }
    // });

    //////////////////////////////////////////////////////////////////
    
    // let siteTable;
    
    // let Jsession=this.cookieService.get("JSESSIONID");
    // this.accountService.getSites(Jsession)
    // .subscribe(data => {
    //   console.log(JSON.stringify(data));
    //   for (let i: number =0; i < data.sites.site.length; i++) {
    //     let s =new Site;
    //     s.id=data.sites.site[i].id;
    //     s.group=data.sites.site[i].customerId;
    //     s.name=data.sites.site[i].name;
    //     s.code=data.sites.site[i].citCode;
    //     s.accNum=data.sites.site[i].accountNo;
    //     s.cc=data.sites.site[i].cashCentreId;
    //     s.groupName=data.sites.site[i].customerName;
    //     s.ccName=data.sites.site[i].cashCentreName;
    //     s.status=data.sites.site[i].status;
    //     this.tempSites.push(s);
    //   }

    //   console.log("fetched sites");

    //   setTimeout(() => {
    //     siteTable = $('#siteTable').DataTable({
    //       initComplete: function () {
    //         if (!this.fullTable) {
    //         }
    //       },
    //       retrieve: true,
    //     }).rows.add(this.tempSites).draw();
    //   }, 2000);

    //  },

    //  error => {
    //   console.log("ERROR");
    //  });
  
///////////////////////////////////////////////////////////////////////////////

  //  $('#siteTable ').on('click', 'tr', () => {
  //    this.RowSelected=true;
  //    this.toggleSites();
  //   });

  // $('#siteTable ').on('click', 'tr', function () {
  //   if ($(this).hasClass('selected')) {
  //     $(this).removeClass('selected');
  //   }
  //   else {
  //     $(this).addClass('selected');
  //   }
  //   localStorage.setItem('siteRowId', siteTable.row(this).index());
  // });

///////////////////////////////////////////////////////////////////////////////

  }

  get f() { return this.form.controls; }

  testFunction(){
    console.log("FUNCTION IS WORKING");
  }


  manageSites(){
    if(this.toggleStatus==true){//Only site update

      this.loadSite=true;

      console.log("NumSites - "+this.toggleSite.length);
      for (let i: number =0; i < this.toggleSite.length; i++) {
        let newStatus=99;
        if(this.toggleSite[i].status==0){
          newStatus=1
        }
        else{
          newStatus=0;
        }


        let details=
    {
      "site":{
        "id": this.toggleSite[i].id,
        "name":this.toggleSite[i].name,
        "citCode":this.toggleSite[i].code,
        "accountNo":this.toggleSite[i].accNum,
        "cashCentreId":this.toggleSite[i].cc,
        "status":newStatus,
      }
    }


    console.log('New Site Details'+JSON.stringify(details));
    let js=this.cookieService.get("JSESSIONID");
    this.accountService.updateSite(details,js)
    .subscribe(data => {
      console.log("Site Toggled");
    },
      error => {
        if (error.status==500){
          this.errorMessage="ERROR 500 OCCURRED";
          this.hideErrorBanner=false;
        }
        if (error.status==408 || error.status==504){
          this.errorMessage="ERROR 408/504 OCCURRED";
          this.hideErrorBanner=false;
        }

        this.loadSite=false;
        console.log(error.error.error.message);
      });
        
      }
      
      this.toggleStatus=false;
      this.checked=false; 
      //this.loadSite=false;
      setTimeout(() => {
        window.location.reload();
    }, 2000);
      
    }
  }

  applyEdit(){
    this.invalidName=false;
    this.duplicateGroup=false;
    this.loading=true;
    this.submitted=true;

    this.nameChange=(this.f.name.value).replace("'","^");

    console.log("Original Name - "+this.originalName);
    console.log("New Name - "+this.nameChange);

    let groupID=localStorage.getItem("selectedGId");

    if(this.nameChange==this.originalName){//Name change did not occur
      this.changeName=false;
     }
    else{
      this.changeName=true;
    }

    if(this.changeName==true){//Name change did occur
      if(this.nameChange=="" || this.nameChange==" "){
        this.invalidName=true;
        this.nameError="Name cannot be empty";
        this.loading=false;
        this.submitted=false;
      }
      else{
        this.invalidName=false;
        let JSession=this.cookieService.get("JSESSIONID");
        console.log("ID : "+groupID);
        console.log("New name : "+this.nameChange);
  
        let newName={
          "customer": {
            "name": this.nameChange,
            "id": groupID
                    }
        }
  
        this.accountService.updateMerchantGroup(newName,JSession)
        .subscribe(data => {
          console.log("Edit Worked");
          window.location.reload();
         },
    
         error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          if(error.status==409){
            this.duplicateGroup=true;
            this.loading=false;
            
          }
         else{
          this.loading=false;
          this.submitted=false;
          console.log("ERROR");
         }
         });
      }

    }
    else{
      window.location.reload();
    }

  }

  deactivate(){
    this.loading=true;
    this.cd=true;
    let name=this.f.name.value;
    let groupID=localStorage.getItem("selectedGId");
    let JSession=this.cookieService.get("JSESSIONID");

    let details={
      "customer": {
        "name": this.nameChange,
        "id": groupID,
        "status":0,
                }
    }

    this.accountService.updateMerchantGroup(details,JSession)
    .subscribe(data => {
      console.log("Edit Worked");
      window.location.reload();
     },

     error => {
      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }

      this.loading=false;
      this.cd=false;
      console.log("ERROR");
     });
     
  }

  activate(){
    let name=this.f.name.value;
    let groupID=localStorage.getItem("selectedGId");
    let JSession=this.cookieService.get("JSESSIONID");

    let details={
      "customer": {
        "name": this.nameChange,
        "id": groupID,
        "status":0,
                }
    }

    this.accountService.updateMerchantGroup(details,JSession)
    .subscribe(data => {
      console.log("Edit Worked");
      window.location.reload();
     },

     error => {
      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }

      console.log("ERROR");
     });
     
  }

  getSites(){

    this.sites.splice(0);
   
    let siteTable;

    let JSession=this.cookieService.get("JSESSIONID");
    let g=localStorage.getItem('selectedGId');
    this.accountService.getGroupSites(JSession,g)
    .subscribe(data => {
      console.log(JSON.stringify(data));

      this.num=data.sites.site.length;
      if(this.containsRows){

        this.sites.splice(0);
        siteTable=$('#siteTable').DataTable();
        siteTable.clear().draw();
      }

      if(this.num==undefined && data.sites.site.id==0){
        this.sites.splice(0);
        console.log("No linked sites at all");
        siteTable=$('#siteTable').DataTable();
        siteTable.clear().draw();
      }

      if(this.num==undefined && data.sites.site.id!=0){//only 1 entry returned
        console.log("Only 1 site returned");
        let x = new Site;
        x.id=data.sites.site.id;
        x.group=data.sites.site.customerId;
        x.name=data.sites.site.name;
        x.code=data.sites.site.citCode;
        x.accNum=data.sites.site.accountNo;
        x.cc=data.sites.site.cashCentreId;
        x.groupName=data.sites.site.customerName;
        x.ccName=data.sites.site.cashCentreName;
        x.status=data.sites.site.status;
        this.sites.push(x);

        setTimeout(() => {

          siteTable = $('#siteTable').DataTable({
          initComplete: function () {
            
          },
          retrieve: true,
        }).rows.add(this.sites).draw();
      },);

      this.containsRows=true;
      console.log("site length - " + this.sites.length);

      }

      else{
        for (let i: number =0; i < data.sites.site.length; i++) {
          let s =new Site;
          s.id=data.sites.site[i].id;
          s.group=data.sites.site[i].customerId;
          s.name=data.sites.site[i].name;
          s.code=data.sites.site[i].citCode;
          s.accNum=data.sites.site[i].accountNo;
          s.cc=data.sites.site[i].cashCentreId;
          s.groupName=data.sites.site[i].customerName;
          s.ccName=data.sites.site[i].cashCentreName;
          s.status=data.sites.site[i].status;
          this.sites.push(s);
        }
        console.log("FLAG 2");

  
        setTimeout(() => {

            siteTable = $('#siteTable').DataTable({
            initComplete: function () {
              
            },
            retrieve: true,
          }).rows.add(this.sites).draw();
        },);
        this.containsRows=true;
        console.log("site length - " + this.sites.length);
      }
     },

     error => {
      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }
      siteTable=$('#siteTable').DataTable();
      siteTable.clear().draw();
      console.log("ERROR, No sites found");
     });

  }

  checkChecked(){
    if(this.fc==false){
      this.fc=true;
      this.sites.reverse();
    }
    //this.sites.reverse();
    this.toggleSite.splice(0);
    this.toggleStatus=false;
    this.checked=false;
    let siteTable = $('#siteTable').DataTable();
    let count=this.sites.length;
    var nodes = siteTable.rows().nodes();

    for ( let i: number =0; i < count; i++) {

      if($( nodes[i] ).find('#toggleCB').prop("checked")==true) { 
  
        console.log("I - "+i);
        console.log("Site must be toggled");
        console.log("Site - "+JSON.stringify(this.sites[i]));
        this.toggleSite.push(this.sites[i]);
      }

      else{
        console.log("Site remains same");
      }
  
       }
       console.log("Sites to be toggled - "+JSON.stringify(this.toggleSite));


       this.checked=true;
       if(this.toggleSite.length>0){
        this.toggleStatus=true;
        this.hideSaveSitesChangesbtn=false;
       }
       else{
        this.toggleStatus=false;
        this.hideSaveSitesChangesbtn=true;
       }


  }

  xButton(){
    window.location.reload();
}


  manageDelete(){

    if(this.sites.length==1){//only 1 site
    console.log("Delete required for 1 site only");
    let x=parseInt(localStorage.getItem('removeID'));
    console.log("DeleteArray -- "+this.deleteArray);

    if(this.deleteArray.includes(x)){
      //const i=this.deleteArray.indexOf(x);
      this.deleteArray.splice(0);
    }
    else{
      this.deleteArray.push(x);
    }
    console.log("DeleteArray after method -- "+this.deleteArray);
    }

    else{
    let x=parseInt(localStorage.getItem('removeID'));
    console.log("DeleteArray -- "+this.deleteArray);
    if(this.deleteArray.includes(x)){
      const i=this.deleteArray.indexOf(x);
      if(i!==-1){
        this.deleteArray.splice(i,1);
      }
    }
    else{
      this.deleteArray.push(x);
    }
    console.log("DeleteArray after method -- "+this.deleteArray);
    }
  }


  

}
