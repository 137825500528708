<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius:10px">
        <div class="container-fluid">

            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <div class="d-flex justify-content-start align-items-end">
                    <h3>Need Help?</h3>
                </div>
                <hr class="mt-0">
                <h5>Your Details</h5>
                <p>Let us know what your issue is and we'll get back to you ASAP.</p>
                <hr class="mt-0">
                <alert></alert>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="control-label" for="name">Name</label><span style="color:#ff0000"> *</span>
                        <input type="text" formControlName="name" class="form-control " placeholder="Name"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="email">Email</label><span style="color:#ff0000"> *</span>
                        <input type="text" formControlName="email" class="form-control " placeholder="Email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                        </div>
                        <div class="invalid-feedback" *ngIf="f.email.errors?.pattern">
                            Invalid email format
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="contactNo">Contact Number</label><span style="color:#ff0000">
                            *</span>
                        <input type="text" mask='(000) 000-0000' formControlName="contactNo" class="form-control "
                            placeholder="Contact number"
                            [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }" />
                        <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
                            <div *ngIf="f.contactNo.errors.required">Contact number is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="description">Describe Your Issue</label><span
                            style="color:#ff0000"> *</span>
                        <input type="text" formControlName="description" class="form-control"
                            placeholder="Please describe the issue that you are experiencing"
                            [ngClass]="{ 'is-invalid': submitted && f.description.errors }" />
                        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                            <div *ngIf="f.description.errors.required">Description is required</div>
                        </div>
                    </div>
                    <!-- <div class="form-group">
                <label class="control-label" for="organisation">Organisation</label>
                <input type="text" formControlName="organisation" class="form-control " placeholder="Organisation"
                    [ngClass]="{ 'is-invalid': submitted && f.organisation.errors }" />
                <div *ngIf="submitted && f.contorganisationctNo.errors" class="invalid-feedback">
                    <div *ngIf="f.organisation.errors.required">Contact number is required</div>
                </div>
            </div> -->


                    <div class="w-100">
                        <div class="form-group">
                            <button [disabled]="loading" class="rounded btn btn-primary w-100 ">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Request Help
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="d-flex justify-content-start  ms-5 me-5 p-3 mb-4">
                <a class="p-2 black-link" routerLink="../login">Back</a>
                <!-- <a class="p-2 blue-link" routerLink="../help">Need help?</a> -->
            </div>
        </div>
    </div>
</div>