import { Component, OnInit, Renderer2 } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styles: []
})
export class NotFoundComponent implements OnInit {

  constructor(
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }
  
  ngOnInit() { }
}