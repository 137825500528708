import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, CheckboxRequiredValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';

@Component({
  selector: 'app-register',
  templateUrl: './register-details.component.html',
  styleUrls: ['./register-details.component.scss']
})
export class RegisterDetailsComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  isChecked = false;
  closeResult = '';

  hideErrorBanner: boolean=true;
errorMessage: string=" ";

  model: any = {};
  // ipaddress: any;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    // private modalService: NgbModal
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }

  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit(): void {
    // this.accountService.getIpAddress().subscribe(res => {

    //   this.ipaddress = res['ip'];
    //   // this.ipaddress = res;
    //   console.log(res);

    // },
    //   error => {
    //     this.ipaddress = "UNDEFINED";
    //   }
    // );

    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(64)]],
      lastName: ['', [Validators.required, Validators.maxLength(64)]],
      // email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"), Validators.email]],
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],
      //^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,}$
      contactNo: ['', [Validators.required, Validators.pattern("^[0-9]{10}$")]],//"^((\\+91-?)|0)?[0-9]{10}$"
      tandc: [false, Validators.requiredTrue]
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'register-details-processing';


  }

  xButton(){
    window.location.reload();
}

  // open(content) {
  //   this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  get f() { return this.form.controls; }

  onSubmit() {
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.model, null, 4));

    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    // this.router.navigate([this.returnUrl]);
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.model, null, 4));

    // this.router.navigate([this.returnUrl]);
    // console.log('YOUR IP:');
    // console.log(this.ipaddress);
    this.loading = true;
    this.form.disable();
    console.log(""+this.form.value)
    this.accountService.registerDetails(this.form.value)//, this.ipaddress)
      .subscribe(data => {
        // console.log(data);
        // console.log(JSON.stringify(data));
        sessionStorage.setItem('email', this.f.email.value);
        localStorage.setItem('registrationDetails', JSON.stringify(this.form.value));
        this.loading = false;
        this.router.navigate([this.returnUrl]);
      },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          // console.log(error);
          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          this.form.enable();
          this.loading = false;
        });
    this.loading = false;
  }
}
