import { Customer } from "./customer";
import { Role } from "./role";

export class theUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    contactNo: string;
    organization: string;
    jobTitle: string;
    locationType: number;
    locationId: string;
    lineManagerName: string;
    lineManagerContactNo: string;
    department: string;
    created: string;
    group: string;
    role: Role;
    status: number;
    cashCentres: number[];
    comment: string;
    customer: Customer;
    hasSwitch: Boolean;
}