import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, CheckboxRequiredValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CashCentre } from 'src/app/_models/cash-centre';
import { CashCentres } from 'src/app/_models/cash-centres';
import { Profile } from 'src/app/_models/profile';
import { CookieService } from 'ngx-cookie';


@Component({
  selector: 'app-new-user-finish',
  templateUrl: './new-user-finish.component.html'
})
export class NewUserFinishComponent implements OnInit {
  
  loading = false;
  submitted = false;
  returnUrl: string;
  roleCheck:number;
  
  hideErrorBanner: boolean=true;
errorMessage: string=" ";


  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
    private cookieService: CookieService
  ) {
  }

  // cashCentres = [];

  ngOnInit() {

    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"), JSession)
    .subscribe(data => {
     this.roleCheck=data.users.user.roles.role.id;
     if(this.roleCheck==6 || this.roleCheck==22 || this.roleCheck==50){//User is either SBV or Bank admin
        console.log("User is admin, role = "+this.roleCheck)
     }
     else{//User is nt an admin
      console.log("User is not an admin, role = "+this.roleCheck)
      this.router.navigate(['/forbidden-access']);
     }

    },
    error => {
      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }


        console.log(error)
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'request-access-processing';
  }

 xButton(){
        window.location.reload();
    }


  onSubmit() {
    this.submitted = true;
    this.loading = true;
  }

}
