import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-super-user-logout',
  templateUrl: './super-user-logout.component.html',
  styleUrls: ['./super-user-logout.component.scss']
})
export class SuperUserLogoutComponent implements OnInit {

  userMail:string = "";
  superUserEmail:string = "";

  constructor(
    private cookieService: CookieService,
  ) { }

  ngOnInit(): void {

    this.userMail = sessionStorage.getItem('email'); // For display purpose only
    this.superUserEmail = sessionStorage.getItem('superUserMail');
  }

  performLogout(){
    sessionStorage.setItem('email', this.superUserEmail); // set top email back to super users email
    
    sessionStorage.removeItem('superUserMail');   // Remove stored Super users email
    sessionStorage.removeItem('superUserLogin');  // Remove check for Super user login using other users email
    this.cookieService.remove('SUPERUSERLOGIN');  // Remove check for Super user login using other users email on bonita side
    
    this.cookieService.remove('JSESSIONID');
    this.cookieService.remove('X-Bonita-API-Token');
    //this.cookieService.removeAll;

    this.cookieService.put('JSESSIONID', sessionStorage.getItem("superUserJsession"),{
     secure:true,
     sameSite:'none'
   });
   
   this.cookieService.put('X-Bonita-API-Token', sessionStorage.getItem("superUserToken"),{
     secure:true,
     sameSite:'none'
   });

   sessionStorage.removeItem('superUserJsession'); //Remove stored super users jsession
   sessionStorage.removeItem('superUserToken');    //Remove stored super users token
    
   sessionStorage.removeItem('Page');
   sessionStorage.removeItem('URL');

    setTimeout(() => {
      window.location.href = `./home`;
          }, 100);
          
  }

}
