export class Contact {
    jobTitle: string;
    department: string;
    lineManagerName: string;
    lineManagerContactNo: string;
    organization: string;
    location: string;

    locationId: number;
    locationType: number;

    firstName: string;
    lastName: string;
    contactNo: string;
}