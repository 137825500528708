<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <div class="d-flex justify-content-between align-items-end">
                    <h3>Super User Logout</h3>
                </div>
                <hr class="mt-0">
                <img src="assets/img/security.png" height="80px">
                <h5 class="p-green">Please Note</h5>
                <p>You are about to log out from user <b>{{userMail}}</b></p>

                <div class="w-100">
                    <div class="form-group">
                        <button class="rounded btn btn-primary w-100" (click)="performLogout()">
                            Log Out
                        </button>
                    </div>

                </div>
            </div>
            <div class="d-flex justify-content-between  ms-5 me-5 p-3 mb-4">
                <a class="p-2 black-link" routerLink="../home">Return to Home Page</a>
            </div>
        </div>
    </div>
</div>