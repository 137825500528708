import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../_services';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-log-new-case',
  templateUrl: './log-new-case.component.html',
  styleUrls: ['./log-new-case.component.scss']
})
export class LogNewCaseComponent implements OnInit {
  roleCheck:number;
  groupCheck:number;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"), JSession)
    .subscribe(data => {
     this.roleCheck=data.users.user.roles.role.id;
     this.groupCheck=data.users.user.roles.role.groupId;
     
     if(this.roleCheck==6 || this.roleCheck==22){
      this.router.navigate(['/forbidden-access']);
     }
    },
    error => {
        console.log(error)
    });
  }

}
