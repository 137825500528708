<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="container-fluid" style="padding: 25px">

    <div>
        <h2>Link New Sites</h2>
    </div>

    <hr class="mt-0">

    <div class="d-flex flex-column min-vh-100 align-items-center">

        <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">
            <br>
            <label class="control-label" for="group">Filter By Group</label>
                        <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;" 
                                        [placeholder]="'Groups'"
                                        [settings]="dropdownSettingsGroup" [data]="dropdownGroups"
                                        class="singleselect" clearable="true"
                                        formControlName="groups">
                         </ng-multiselect-dropdown>
            <br>
            <table id="siteTable" class="table  table-hover" datatable [dtOptions]="clientOptions"
            style="width: 100%">
            <thead>
                <tr>
                    <th scope="col" class="user-table-headings">Group</th>
                    <th scope="col" class="user-table-headings">Name</th>
                    <th scope="col" class="user-table-headings">CIT Code</th>
                    <th scope="col" class="user-table-headings">Account Number</th>
                    <th scope="col" class="user-table-headings">Cash Centre</th>
                    <th scope="col" class="user-table-headings" [hidden]="true"></th>
                </tr>
            </thead>
        </table>
            <br>

        <div class="p-5 ; width:100% margin-bottom: 12px; margin-left: 16px;">
            <button class="btn btn-primary" style = "text-align: justify; margin-right: 15px; width:fit-content" (click)="linkSites()">Link</button>
            <button class="btn btn-default"  style ="float: right; margin-right: 15px; width:fit-content" (click)="return()">Back</button>
        </div>
        
        </div>
     </div>

         

    <!-- <div class="card-body " style="border-radius: 14px;">
        <table id="userTable" class="table  table-hover" datatable [dtOptions]="dtOptions"
            style="width: 100%">
            <thead>
                <tr>
                    <th scope="col" class="user-table-headings">Group</th>
                    <th scope="col" class="user-table-headings">Name</th>
                    <th scope="col" class="user-table-headings">CIT Code</th>
                    <th scope="col" class="user-table-headings">Account Number</th>
                    <th scope="col" class="user-table-headings">Cash Centre</th>
                    <th scope="col" class="user-table-headings">Last Updated</th>
                    <th scope="col" class="user-table-headings">Created On</th>
                    <th scope="col" class="user-table-headings">Status</th>
                </tr>
            </thead>
            
        </table>
    </div> -->

</div>
