import { Component, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
// import { AccessRequest } from 'src/app/_models/access-request.interface';
import { AccessRequestUser } from 'src/app/_models/access-request-user';
// import { Role } from 'src/app/_models/roles';
import { Contact } from 'src/app/_models/contact';
import { RequestAccess } from 'src/app/_models/request-access';
import { Profile } from 'src/app/_models/profile';
import { Role } from 'src/app/_models/role';
import { Roles } from 'src/app/_models/roles';

@Component({
  selector: 'app-request-access',
  templateUrl: './request-access.component.html',
  styleUrls: ['./request-access.component.scss']
})
export class RequestAccessComponent implements OnInit {

  form: FormGroup;
  loading = false;
  rolesLoading = false;
  submitted = false;
  returnUrlCC: string;
  returnUrlComplete: string;
  // profileType: string;
  // therole: string;
  // roles: any[];
  requestAccessObj: RequestAccess;

  hideErrorBanner: boolean=true;
errorMessage: string=" ";
  // selectedRoles: number[] = [];
  selectedRole: number = 0;
  // requestDetails: AccessRequestUser;

  public accessRequest: AccessRequestUser;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private renderer: Renderer2
    ) { 
      this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
    // this.roles = [{ value: 'admin', display: 'Administrator' },
    // { value: 'guest', display: 'Guest' },
    // { value: 'custom', display: 'Custom' }];
  }

  // selected: any;

  selectedGroup: number = 0;
  stepNum: string;

  onItemSelectGroup(item: any) {
    this.resetForm();


    // console.log(item.id);
    // this.resetForm();
    // this.selectedRoles = [];
    this.selectedRole = 0;
    this.selectedGroup = item.id;
    this.dropdownListRoles = [];
    this.rolesLoading = true;

    //2 - get roles based on groups
    // this.dropdownListRoles = [{ id: 11, name: 'Direct User', description: '' }, { id: 11, name: 'Direct User', description: '' }];

    if (item.id != 3) {
      this.stepNum="Step 2/3";
      document.getElementById("steps").innerHTML = this.stepNum;
      this.accountService.getRoles(item.id)//this.groupId) // item
        .subscribe(data => {
          // console.log("TRYING TO GET ROLE")
          // console.log(data.roles.role);
          console.log(JSON.stringify(data.roles.role));
          // this.form.get("role").setValue([]);
          this.dropdownListRoles = data.roles.role;
          this.rolesLoading = false;
        },
          error => {

            if (error.status==0){
              this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
              this.hideErrorBanner=false;
            }

            if (error.status==500){
              this.errorMessage="ERROR 500 OCCURRED";
              this.hideErrorBanner=false;
            }
            if (error.status==408 || error.status==504){
              this.errorMessage="ERROR 408/504 OCCURRED";
              this.hideErrorBanner=false;
            }

            if (error.status == 0) {
              this.alertService.error('Something went wrong. Please contact technical support.')
            }
            else{
            this.alertService.error(error.error.error.message);
            }
            // console.error('There was an error!', error.error.error.message);
            // console.error(error.message);
          });
    } 
    else {
      //this.dropdownListRoles = [{ id: 11, name: 'Direct User', description: '' }];
      this.accountService.getRoles(item.id)//this.groupId) // item
        .subscribe(data => {
          this.dropdownListRoles = data.roles.role;
          this.dropdownListRoles = this.dropdownListRoles.filter(item => item.name !== "Administrator");
          this.rolesLoading = false;
        },
          error => {

            if (error.status==0){
              this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
              this.hideErrorBanner=false;
            }

            if (error.status==500){
              this.errorMessage="ERROR 500 OCCURRED";
              this.hideErrorBanner=false;
            }
            if (error.status==408 || error.status==504){
              this.errorMessage="ERROR 408/504 OCCURRED";
              this.hideErrorBanner=false;
            }

            if (error.status == 0) {
              this.alertService.error('Something went wrong. Please contact technical support.')
            }
            else{
            this.alertService.error(error.error.error.message);
            }
          });

      this.stepNum="Step 2/2";
      document.getElementById("steps").innerHTML = this.stepNum;
      this.rolesLoading = false;

    }
    console.log(""+this.stepNum)
  }

  onGroupItemDeSelect(item: any) {

    this.stepNum="Step 2/3";
    document.getElementById("steps").innerHTML = this.stepNum;

    //clear role selection
    this.selectedRole = 0;
    //reset roles list
    this.dropdownListRoles = [];
    this.resetForm();

    // Call to clear
    this.onDeSelectAll();

  }

  onItemDeSelect(item: any) {
    this.selectedRole = 0;
    sessionStorage.setItem("SOMultiBank","false");
  }

  @ViewChild('rolesMultiSelect') multiSelect;

  onDeSelectAll() {
    this.dropdownListRoles = null;
  }

  resetForm() {
    this.form.get("role").setValue([]);
  }

  onItemSelectRole(item: any) {
    this.selectedRole = item.id;
    if(item.id==26 || item.id==27  || item.id==28  || item.id==29  || item.id==30){
      sessionStorage.setItem("SOMultiBank","true");
    }
    else{
      sessionStorage.removeItem("SOMultiBank");
    }

    if(item.id==26 || item.id==30){
      sessionStorage.setItem("creditController","true");
    }
    else{
      sessionStorage.removeItem("creditController");
    }
  }

  settings = {};

  dropdownListGroups = [];
  dropdownListRoles = [];
  // selectedItems = [];
  dropdownSettingsGroups = {};
  dropdownSettingsRoles = {};


  ngOnInit() {

    this.settings = {
      singleSelection: true,
      text: "Select Group",
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: "myclass custom-class"
    };

    // this.selectedRoles =[];

    this.dropdownSettingsGroups = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
      // noDataAvailablePlaceholderText: "Loading...",
      // allowSearchFilter: true
    };
    this.dropdownSettingsRoles = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      clearable: "true",
      closeDropDownOnSelection: "true",
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      enableCheckAll: false,
      // noDataLabel: 'Please select group first',
      noDataAvailablePlaceholderText: "Please select group first",
      // allowSearchFilter: true
    };

    //1 - get groups
    this.accountService.getGroups()
      .subscribe(data => {
        // console.log(data.groups.group);
        // console.log(JSON.stringify(data.groups.group));
        // this.optionItems = data.groups.group;
        this.dropdownListGroups = data.groups.group;
      },
        error => {

           // if (error.status==0){
          //   this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
          //   this.hideErrorBanner=false;
          // }

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }
          this.alertService.error(error.error.error.message);         
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
        });




    this.form = this.formBuilder.group({
      organisation: ['', Validators.required],
      jobTitle: ['', Validators.required],
      department: ['', Validators.required],
      lineManagerName: [''],
      lineManagerContact: [''],
      role: [[]]

    });

    // get return url from route parameters or default to '/'
    this.returnUrlCC = this.route.snapshot.queryParams['returnUrl'] || 'cash-centre';
    this.returnUrlComplete = this.route.snapshot.queryParams['returnUrl'] || 'request-access-processing';
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {


    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.form.disable();
    this.loading = true;

    //build the body
    this.requestAccessObj = new RequestAccess;
    let requestDetails = new AccessRequestUser;  //USER section
    let contact = new Contact;
    let profile = new Profile;  // will have cash centres added on next screen if necessary. added empty for now.

    requestDetails.email = sessionStorage.getItem('email');
    contact.jobTitle = this.f.jobTitle.value;
    contact.department = this.f.department.value;
    contact.lineManagerName = this.f.lineManagerName.value;
    contact.lineManagerContactNo = this.f.lineManagerContact.value;
    contact.organization = this.f.organisation.value;
    contact.location = "";
    requestDetails.contact = contact;
    requestDetails.profile = profile;


    //for each role id, put it in a role object and add to array
    let roles: Roles[] = [];
    //ARRAY IS FOR IN CASE IN FUTURE
    // this.selectedRoles.forEach((role) => {
    let rs = new Roles;
    let r = new Role;
    r.id = this.selectedRole;
    rs.role = r;
    roles.push(rs);
    // roles.push(r);
    // });

    // let customer = new Customer;
    // customer.code = this.customerCode;
    // customer.name = this.customerName;
    // accessRequest.user.customer = customer;

    // if (localStorage.getItem('group') == '1') { //SBV user
    //   customer.id = 0;
    // } else {
    //   customer.id = this.customerId;
    // }

    requestDetails.roles = roles;
    // requestDetails.roles = r;

    // let role = new Role;
    // role.id = 
    // this.requestDetails.roles = 
    this.requestAccessObj.user = requestDetails;

    if (this.selectedRole == 0 || this.selectedGroup == 0) { //if group is selected but not role the error alert and do nothing
      // console.log("HERE");
      // console.log(this.selectedGroup);
      // console.log(this.selectedRole);
      this.alertService.error("No role selected");
    } else if (this.selectedGroup == 3) { //if merchant group then finish
      // console.log("Group: Merchant")
      this.requestAccess();
      this.router.navigate([this.returnUrlComplete]);
    }
      else if(this.selectedRole == 31) {
        this.requestAccess();
        this.router.navigate([this.returnUrlComplete]);
      } //)
    else if ((this.selectedGroup == 1) || (this.selectedGroup == 2)) { //if not merchant group then go to cash centre page
      // console.log("---NOT--- Merchant")
      // console.log(this.selectedGroup);
      localStorage.setItem('requestDetails', JSON.stringify(this.requestAccessObj));
      // console.log((this.requestAccessObj));
      this.router.navigate([this.returnUrlCC]);
    }
    this.loading = false;
    this.form.enable();

  }

  xButton(){
    window.location.reload();
}


  requestAccess() {
    
    let newStatus = {
      "user": {
        "email": (localStorage.getItem('email')),
        "status": 2
      }
    }

    let ottId=sessionStorage.getItem("ottId");
    let ottCode=sessionStorage.getItem("ottCode");

    this.accountService.requestAccess(this.requestAccessObj, ottId, ottCode)
      .subscribe(data => {
        // console.log(data);
        // console.log(JSON.stringify(data));

        // this.accountService.updateUserStatus(newStatus)
        // .subscribe(data => {
        //   console.log(data)
        // },
        //   error => {
        //     this.alertService.error("User not updated");
        //   });

        this.loading = false;
        this.router.navigate([this.returnUrlComplete]);
        localStorage.setItem('password-set', '0');
      },
        error => {

           // if (error.status==0){
          //   this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
          //   this.hideErrorBanner=false;
          // }
          
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          this.form.enable();
          this.loading = false;
        });
    this.loading = false;
  }

}
