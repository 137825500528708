<!-- <div class="box" [hidden]="hideContact">
    <div class="d-flex justify-content-start align-items-end">
    </div>
    <h5>Your Details</h5>
    <hr class="mt-0">
    <alert></alert>
    <form [formGroup]="helpForm">
        <div class="form-group">
            <label class="control-label" for="name">Name</label><span style="color:#ff0000"> *</span>
            <input type="text" formControlName="name" class="form-control " placeholder="Name"
                [ngClass]="{ 'is-invalid': submitted && h.name.errors }" />
            <div *ngIf="submitted && h.name.errors" class="invalid-feedback">
                <div *ngIf="h.name.errors.required">Name is required</div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label" for="email">Email</label><span style="color:#ff0000"> *</span>
            <input type="text" formControlName="email" class="form-control " placeholder="Email"
                [ngClass]="{ 'is-invalid': submitted && h.email.errors }" />
            <div *ngIf="submitted && h.email.errors" class="invalid-feedback">
                <div *ngIf="h.email.errors.required">Email is required</div>
            </div>
            <div class="invalid-feedback" *ngIf="h.email.errors?.pattern">
                Invalid email format
            </div>
        </div>
        <div class="form-group">
            <label class="control-label" for="contactNo">Contact Number</label><span style="color:#ff0000">
                *</span>
            <input type="text" mask='(000) 000-0000' formControlName="contactNo" class="form-control "
                placeholder="Contact number"
                [ngClass]="{ 'is-invalid': submitted && h.contactNo.errors }" />
            <div *ngIf="submitted && h.contactNo.errors" class="invalid-feedback">
                <div *ngIf="h.contactNo.errors.required">Contact number is required</div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label" for="description">Describe Your Issue</label><span
                style="color:#ff0000"> *</span>
            <input type="text" formControlName="description" class="form-control"
                placeholder="Please describe the issue that you are experiencing"
                [ngClass]="{ 'is-invalid': submitted && h.description.errors }" />
            <div *ngIf="submitted && h.description.errors" class="invalid-feedback">
                <div *ngIf="h.description.errors.required">Description is required</div>
            </div>
        </div>

        <div class="w-100">
            <div class="form-group">
                <button class="rounded btn btn-primary w-100 " (click)="sendHelp()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Request Help
                </button>
            </div>
        </div>
    </form>
</div> -->

<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px" [hidden]="hideMaintenance">
        <div class="container-fluid shadow card border-light" style="width: 660px; border-radius: 10px; margin-left: -130px;">
            <div class="ps-4 pe-4 pt-4 container-fluid">
                <div class="row">
                    <div class="col-md-4">
                        <h3 style="color: purple !important;">We'll be right back</h3>
                        <p>Sorry, we are down for scheduled maintenance, We'll be back up and running in no time. If you need help, please <a href="mailto:connect@sbv.co.za">contact us</a></p>
                        <!-- <button class="rounded btn btn-primary w-30 mb-5 mt-5" (click)="contact()">
                            Contact Us
                        </button> -->
                    </div>
                    <div class="col-md-8 d-flex flex-column justify-content-center align-items-center">
                        <img class="notfound-image" src="assets/img/Gears icon.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
