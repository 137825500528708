<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>
<div class="container-fluid">
    <div class="ps-4 pe-4 pt-4 container-fluid ">
        <div>
            <h6 style="margin-bottom: -20px">ORGANISATION</h6>
            <h2>Profile Settings</h2>
            <p>Manage your profile information.</p>
            
        </div>
        <hr class="mt-0">
        
        <form [formGroup]="form">
            <div class="d-flex flex-column min-vh-100 align-items-center">


                <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">
                    <div class="d-flex justify-content-between align-items-end">
                        <h3 class="h3-grey">Personal Information</h3>
                        
                        <p *ngIf="!pEdit" class="blue-link clickable" (click)="editDetails()">Edit</p>
                    </div>
                    <hr class="mt-0">

                    <!-- <div class="col">
                        <h6>PROFILE PICTURE</h6>
                       
                        <div>
                            <i class="fas fa-user-circle fa-5x"></i>
                        </div>
                        

                        <button class="ml-1 mt-3 mb-4 btn btn-primary">
                            Edit
                        </button>
                    </div> -->
                    

                    <div class="form-group">
                        <label class="control-label" for="name">Name</label>
                        <input [readonly]="!pEdit" type="text" formControlName="firstName" class="form-control " placeholder="Name"
                            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">First name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="surname">Surname</label>
                        <input [readonly]="!pEdit" type="text" formControlName="lastName" class="form-control "
                            placeholder="Surname" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                            <div *ngIf="f.lastName.errors.required">Last name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="email">Email</label>
                        <input readonly type="text" formControlName="email" class="form-control " placeholder="Email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                        </div>
                        <div class="invalid-feedback" *ngIf="f.email.errors?.pattern">
                            Invalid email format
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="contactNo">Contact Number</label>
                        <input [readonly]="!pEdit" type="text" mask='(000) 000-0000' formControlName="contactNo"
                            class="form-control " placeholder="Contact number"
                            [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }" />
                        <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
                            <div *ngIf="f.contactNo.errors.required">Contact number is required</div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="pEdit">
                        <div style="padding-top: 1em;">
                            <button class="btn btn-default" [hidden]="true" (click)="personalCancel()">
                                Cancel
                            </button>
                            <button [disabled]="loading" class="ml-3 btn btn-primary" [hidden]="true" (click)="savePersonalInfoEdit()">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Save Changes
                            </button>
                        </div>
                    </div>

                    <div *ngIf="personalEditSuccess" class="alert alert-dismissible success-alert">
                        <button type="button" class="close" data-dismiss="alert" style="color: #168f4b">
                            &times;
                        </button>
                        Your changes have been saved.
                    </div>
                </div>

                <div class="card card-blue-shadow w-50 border-0 p-5 " style="margin-top: 40px; border-radius: 10px;">
                    <div class="d-flex justify-content-between align-items-end">
                        <h3 class="h3-grey">Business Details</h3>
                        <p *ngIf="!bEdit" [hidden]="true" class="blue-link clickable" (click)="bEdit = true">Edit</p>
                    </div>
                    <hr class="mt-0">
                    <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()"> -->
                    <div class="form-group">
                        <label class="control-label" for="org">Organisation</label>
                        <input readonly type="text" formControlName="org" class="form-control "
                            placeholder="Organisation" [ngClass]="{ 'is-invalid': submitted && f.org.errors }" />

                    </div>


                    <div class="form-group">
                        <label class="control-label" for="group">User Type</label>
                        <ng-multiselect-dropdown disabled style="font-size: 12px; border-radius: 50px;"
                            [placeholder]="'Select Group'" [settings]="dropdownSettingsGroups"
                            [data]="dropdownListGroups" (onSelect)="onItemSelectGroup($event)" formControlName="group"
                            [formControl]="f.group" (onDeSelect)="onGroupItemDeSelect($event)" class="singleselect ms-disabled"
                            clearable="true">
                        </ng-multiselect-dropdown>
                        <div *ngIf="!groupChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!groupChosen">Group is required</div>
                        </div>
                    </div>

                    <div class="form-group" [hidden]="!isMerchant">
                        <label class="control-label" for="linkedSites">Linked Sites</label>
                        <table id="siteTable" class="table  table-hover" datatable [dtOptions]="dtOptions"
                        style="width: 100%">
                        <thead>
                            <tr>
                                <th scope="col" class="user-table-headings">Name</th>
                                <th scope="col" class="user-table-headings">CIT Code</th>
                                <th scope="col" class="user-table-headings">Account Number</th>
                                <th scope="col" class="user-table-headings">Cash Centre</th>
                            </tr>
                        </thead>
                        
                    </table>

                    </div>

                    <div class="form-group">
                        <div style="height:10px">
                        </div>
                        <label class="control-label" for="organisation">Role</label><span *ngIf="rolesLoading"
                            class="spinner-border spinner-border-sm mr-1"></span>
                        <ng-multiselect-dropdown #rolesMultiSelect [formControl]="f.role" formControlName="role"
                            style="font-size: 12px; border-radius: 50px;" [placeholder]="'Select Role'" [ngClass]="{'ms-disabled' : !bEdit, '': bEdit}"
                             [settings]="dropdownSettingsRoles" [data]="dropdownListRoles"
                            (onSelect)="onItemSelectRole($event)" class="singleselect"
                            (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll()">
                        </ng-multiselect-dropdown>
                        <div *ngIf="!roleChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!roleChosen">Role is required</div>
                        </div>
                    </div>

                    <div [hidden]="hideMultiBank" class="form-group">
                        <label [hidden]="hideMultiBank" class="control-label" for="customer">{{userDropdownheading}}</label>
                        <ng-multiselect-dropdown [hidden]="hideMultiBank" formControlName="linkedBanks"
                            [ngClass]="{'ms-disabled' : true}"
                            [formControl]="f.linkedBanks"
                            style="font-size: 12px; border-radius: 50px;" 
                            [placeholder]="'Banks'"
                            [settings]="dropdownSettingsMultiBank" 
                            [data]="dropdownListBanks">
                        </ng-multiselect-dropdown>
                    </div>

                    <!-- <div class="form-group" [hidden]="showMerchBox">
                        <div class="noPointer">
                            <label> 
                                <input 
                                type="checkbox" 
                                class="form-check-input" 
                                [formControl]="f.merchBox" 
                                formControlName="merchBox"
                                [checked]="merchChecked"
                                [readonly]="true"
                                [disabled]="true">
                                <span style="vertical-align:sub; margin-left: 20px;">Merchants</span> 
                            </label>
                        </div>
                    </div> -->

                    <div class="form-group">
                        <label class="control-label" for="jobTitle">Job Title</label>
                        <input [readonly]="!bEdit" type="text" formControlName="jobTitle" class="form-control"
                            placeholder="Job title" [ngClass]="{ 'is-invalid': submitted && f.jobTitle.errors }" />
                        <div *ngIf="submitted && f.jobTitle.errors" class="invalid-feedback">
                            <div *ngIf="f.jobTitle.errors.required">Job title is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="department">Department</label>
                        <input [readonly]="!bEdit" type="text" formControlName="department" class="form-control"
                            placeholder="Department" [ngClass]="{ 'is-invalid': submitted && f.department.errors }" />
                        <div *ngIf="submitted && f.department.errors" class="invalid-feedback">
                            <div *ngIf="f.department.errors.required">Department is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="lineManager">Line Manager Name</label>
                        <input [readonly]="!bEdit" type="text" formControlName="lineManager" class="form-control"
                            placeholder="Line manager name" placeholder="Line Manager Name" />
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="lineManagerContact">Line Manager Contact Number</label>
                        <input [readonly]="!bEdit" type="text" mask='(000) 000-0000'
                            formControlName="lineManagerContact" class="form-control"
                            placeholder="Line manager's contact number" placeholder="Line Manager Contact Detail" />
                    </div>



                    <div class="form-group" *ngIf="bEdit">
                        <div style="padding-top: 1em;">
                            <button [disabled]="loading" [hidden]="true" class="btn btn-default" (click)="businessCancel()">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Cancel
                            </button>
                            <button [disabled]="loading" [hidden]="true" class="ml-3 btn btn-primary"
                                (click)="saveBusinessDetailsEdit()">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Save Changes
                            </button>
                        </div>
                    </div>

                    <div *ngIf="businessEditSuccess" class="alert alert-dismissible success-alert">
                        <button type="button" class="close" data-dismiss="alert" style="color: #168f4b">
                            &times;
                        </button>
                        Your changes have been saved.
                    </div>
                    <div *ngIf="roleEditRequest" class="alert alert-dismissible update-alert">
                        <button type="button" class="close" data-dismiss="alert" style="color: #802a76">
                            &times;
                        </button>
                        Change request submitted.
                    </div>
                </div>


                <div [hidden]="isMerchant || !hideMultiBank" class="card card-blue-shadow w-50 border-0 p-5 " style="margin-top: 40px; border-radius: 10px;">
                    <div class="d-flex justify-content-between align-items-end"> 
                        <h3 class="h3-grey">Cash Centres Permission</h3>
                        <p *ngIf="!ccEdit" class="blue-link clickable" [hidden]="true" (click)="ccEdit = true">Edit</p>
                    </div>
                    <hr class="mt-0">
                    <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()"> -->
                    <label class="control-label" for="ccs">Cash Centres</label>
                    <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;" [ngClass]="{'ms-disabled' : !ccEdit, '': ccEdit}"
                        [placeholder]="'Cash centres'" [settings]="dropdownSettingsCC" 
                        [data]="dropdownCCList" (onSelect)="onCCItemSelect($event)" [formControl]="f.cashCentres"
                        formControlName="cashCentres" (onSelectAll)="onCCSelectAll($event)"
                        (onDeSelect)="onCcItemDeSelect($event)" (onDeSelectAll)="onCCItemDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                    <div *ngIf="" style="font-size: 80%; color: #dc3545">
                        <div *ngIf="">Cash Centre is required</div>
                    </div>
                </div>

                <div class="card card-blue-shadow w-50 border-0 p-5" *ngIf="ccEdit">
                    <div style="padding-top: 1em;">
                        <button [disabled]="loading" class="btn btn-default" (click)="cancelEdit()">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Cancel
                        </button>
                        <button [disabled]="loading" class="ml-3 btn btn-primary" (click)="saveDetails()">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Save Changes
                        </button>
                        <div [hidden]="!updateError" style="font-size: 120%; color: #dc3545; padding-top: 5px;">
                            <div [hidden]="!updateError">Your account has pending changes. Please contact your administrator</div>
                        </div>
                    </div>
                    
                </div>

                <div *ngIf="ccEditRequest" class="alert alert-dismissible update-alert">
                    <button type="button" class="close" data-dismiss="alert" style="color: #802a76">
                        &times;
                    </button>
                    Change request submitted.
                </div>

            </div>
        </form>

    </div>

</div>