<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>


<div class="container-fluid" style="text-align:left; background: #f6fafd">

    <h6>SBV CONNECT</h6>
    <h1 style="margin-top:0px;">Home</h1>
    <h5>Connect to our Support, Services, and Information</h5>
    <hr/>
    
</div>

<div class="box">

    <div class="c-m" [hidden]="hideCMBlock">
        <h3>Case Management</h3>
        <hr/>
        <img class="imageBox" src="assets/img/ic_twotone-cases.png" alt="CASES IMAGE">
        <b>Securely log, manage, track, and analyse <br>all of your cases</b>
        <br>
        <button class="cmb cm" routerLink="/dashboard" (click)="appDashboardFunction()">View Dashboard</button>
        <button class="cmb cm" routerLink="/task-list" (click)="taskListFunction()" [hidden]="hideTaskList">View Task List</button>
        <button class="cmb cm" routerLink="/case-list" (click)="caseListFunction()">View Case List</button>
        <button class="cmb cm" routerLink="/log-new-case" (click)="logCaseFunction()" [hidden]="hideLogCase">Log a new case</button>
        <!-- <button class="cmb cm" routerLink="/create-billing-case" (click)="billingFunction()" [hidden]="hideBP">Create Billing Case</button> -->
    </div>

    <div class="c-m" [hidden]="hideBP">
        <h3>Billing</h3>
        <hr/>
        <img class="imageBox" src="assets/img/ic_twotone-insert-chart.png" alt="CASES IMAGE">
        <b style="margin-right: 27px; margin-left: 27px;">Billing Functions</b>

        <button class="bpb bp" routerLink="/create-billing-case" (click)="billingFunction()" [hidden]="hideBP">Create Billing Case</button>
        

    </div>

    <div class="c-m" [hidden]="hideReportBlock">
        <h3>Reporting</h3>
        <hr/>
        <img class="imageBox" src="assets/img/ic_twotone-insert-chart.png" alt="CASES IMAGE">
        <b style="margin-right: 27px; margin-left: 27px;">View your insights and reports</b>

        <button class="rb r" routerLink="/reporting" (click)="reportFunction()" [hidden]="hideReports">Reports</button>
        <button class="rb r" routerLink="/national-dashboard" (click)="appPDashboardFunction()" [hidden]="hideNatButton ">View Performance Dashboard</button>
        <button class="rb r" routerLink="/customer-views" (click)="bankViewFunction()" [hidden]="hideBankViews">Customer Views</button>

    </div>

    <div class="c-m">
        <h3>Account Management</h3>
        <hr/>
        <img class="imageBox" src="assets/img/ic_twotone-person-search.png" alt="CASES IMAGE">
        <b>Manage user access and your profile</b>

        <button class="amb am" routerLink="/user-management" [hidden]="hideUserAccess">Manage User Access</button>
        <button class="amb am" routerLink="/profile-settings">My Profile Settings</button>
        <button class="amb am" routerLink="/merchant-management" [hidden]="hideMerchant">Manage Organisation</button>
    </div>

</div>