import { Banks } from "./banks";
import { CashCentres } from "./cash-centres";
import { Profiles } from "./Profiles";
import { Site } from "./site";
import { Sites } from "./sites";

export class Profile {
    cashCentres: CashCentres[];
    sites: Sites[];
    profiles:Profiles[];
}