<!-- <div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: whitesmoke; margin-top: 200px;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div> -->

<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius:10px">
        <div class="container-fluid">

            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <div class="d-flex justify-content-between align-items-end">
                    <h3>Register</h3>
                    <p class="purple">Step 3/3</p>
                </div>
                <hr class="mt-0">
                <h5 [hidden]="hideCashCentre">Cash Centres</h5>
                <p [hidden]="hideCashCentre">Select the cash centres that you work with:</p>
                <h5 [hidden]="hideBankMultiUser">{{h5}}</h5>
                <p [hidden]="hideBankMultiUser">Select the {{p}} that you work with:</p>
                <hr class="mt-0">
                <alert></alert>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                    <div class="form-group" [hidden]="hideBankMultiUser">
                        <label class="control-label" for="customer">{{userDropdownHeading}}</label><span [hidden]="hideBankMultiUser" style="color:#ff0000"></span>
                        <ng-multiselect-dropdown formControlName="banks"
                            style="font-size: 12px; border-radius: 50px;" [placeholder]="'Banks'"
                            [settings]="dropdownSettingsMultiBank" [data]="dropdownListBanks"
                            (onSelect)="onItemSelectMultiBank($event)" (onSelectAll)="onSelectAllMultiBanks($event)"
                            (onDeSelect)="onItemDeSelectMultiBank($event)" (onDeSelectAll)="onItemDeSelectAllMultiBank($event)">
                        </ng-multiselect-dropdown>
                        <div *ngIf="!banksChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!banksChosen">Banks are required</div>
                        </div>
                    </div>

                    <!-- <div class="d-flex justify-content-between align-items-center ms-5 me-5 pt-2 mb-4" [hidden]="showMerchCheckbox">
                        <div class="checkbox">
                            <label> <input type="checkbox" class="form-check-input" id="allowMerchant"
                                    (change)="merchantSelected()">
                                <span style="vertical-align: sub">Merchants</span> </label>
                        </div>
                    </div> -->

                    <label [hidden]="hideCashCentre" class="control-label" for="organisation">Cash Centres</label><span [hidden]="hideCashCentre" style="color:#ff0000">
                        *</span>
                    <ng-multiselect-dropdown [hidden]="hideCashCentre" style="font-size: 12px; border-radius: 50px;"
                        [placeholder]="'Cash centres'" [settings]="dropdownSettings" [data]="dropdownList"
                        (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                        (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                    </ng-multiselect-dropdown>


                    <div class="w-100 mt-4">
                        <div class="form-group">
                            <button [disabled]="loading" class="rounded btn btn-primary w-100">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Request Access
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="d-flex justify-content-between  ms-5 me-5 p-3 mb-4">
                <a class="p-2 black-link" routerLink="../request-access">Back</a>
                <a class="p-2 blue-link" routerLink="../help">Need help?</a>
            </div>
        </div>
    </div>
</div>