<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="container-fluid" style="padding: 25px">

    <div>
        <h2>Manage Organisation</h2>
        <!-- <h5>Manage all SBV Connect users</h5> -->
    </div>

    <div>
        <h4>Groups</h4>
        <!-- <h5>Manage all SBV Connect users</h5> -->
    </div>

    <hr class="mt-0">

    <div class="row d-flex ml-1">
        <div class="p-2">
            <ul class="nav nav-pills nav-underline">
                <li class="active"><a href="/merchant-management">Organisation</a></li>
                <li><a href="/all-sites">Sites</a></li>
                <!-- <li><a href="/merchant-management-awaiting-approval">Awaiting Approval</a></li> -->
            </ul>
        </div>
        <button class="rounded btn btn-primary ml-auto p-3 h-50 border-0" id="AddUser"style="width: 140px"
            routerLink="/add-group">
            <div class="row ml-2">
                <span>Add Organisation</span>
                <div class="circle"> + </div>
            </div>
        </button>
    </div>

    <div class="card card-blue-shadow" >

        <div class="row" style="border-radius: 14px;">

            <div class="col" style="border-radius: 14px;" [ngClass]="{'table-no-details' : fullTable, 'table-user-details': !fullTable}">

                <div class="card-header d-flex  user-management-card" style="border-radius: 14px;">
                    <h4 class="p-2">Organisation</h4>   
                </div>

                <div class="card-body " style="border-radius: 14px;">
                    <!-- <div class="card-body pt-0 scroll-box-table table-responsive" style="overflow-x:auto;"> -->
                    <table id="groupTable" class="table  table-hover" datatable [dtOptions]="dtOptions" style="width: 100%">
                        <thead>
                            <tr>
                                <th scope="col" class="user-table-headings">Name</th>
                                <th scope="col" class="user-table-headings">Created On</th>
                                <th scope="col" class="user-table-headings">Last Updated</th>
                               
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>

            <div [ngClass]="{'no-details' : fullTable, 'user-details': !fullTable}">

                <div class="col" *ngIf="fullTable">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <p class="clickable" (click)="showDetails()" style="margin-top: 10px;">
                            <i class="fa fa-angle-left fa-2x "></i>
                        </p>
                    </div>
                </div>


                <div class="col"  *ngIf="!fullTable" style="width:50%; background-color: #d5e6f3;" [hidden]="hideCard">

                    <p class="clickable" (click)="hideDetails()"
                            style="margin-top: 10px; margin-left: -2px; margin-bottom: -10px;">
                            <i class="fa fa-angle-right fa-2x "></i>
                    </p>

                    <h3 class="h3-grey user-detail-card">{{GroupName}}</h3>
                        <h4 class="user-detail-card row"><b>{{organisation}}
                                </b>
                        </h4>

                        <div class="p-5 card-blue-shadow user-detail-card">

                            <form [formGroup]="form">

                                <div class="d-flex justify-content-between align-items-end">
                                    <h4 class="mb-0">Organisation Details</h4>
                                    <a *ngIf="(status=='Active' || status=='Disabled')"
                                        class="text-align-centre p-2 blue-link"
                                        style="color: #589cd0; margin-bottom: -5px;"
                                        [hidden]="!hideSaveButton"
                                        (click)="editDetails()">Edit</a>

                                     <a class="text-align-centre p-2 blue-link" 
                                        style="color: #589cd0; margin-bottom: -5px;" 
                                        [hidden]="hideSaveButton"
                                        (click)="cancelEdit()">Cancel</a>
                                    
                                </div>

                                <hr style="margin-top: 10px">

                                <div class="form-group">
                                    <label class="control-label" for="name">Name</label>
                                    <input [readonly]="!(canEdit)" type="text" formControlName="name"
                                        [ngClass]="{'ms-disabled' : !(canEdit)}"
                                        class="form-control" placeholder="Please select group..." />

                                        <div *ngIf="invalidName" style="font-size: 80%; color: #dc3545">
                                            <div *ngIf="invalidName">{{nameError}}</div>
                                        </div>
                                        
                                        <div *ngIf="duplicateGroup" style="font-size: 12px; color: #dc3545">
                                            <div *ngIf="duplicateGroup">Error, a customer with this name already exists</div>
                                        </div>
                                </div>

                                <!-- <div class="form-group">
                                    <label class="control-label" for="code">Code</label>
                                    <input readonly (focus)="codeFocusVar" type="text" formControlName="code"
                                        class="form-control " placeholder="Please select merchant..." />
                                </div> -->

                                <!-- <div class="form-group">
                                    <label class="control-label" for="accNo">Account Number</label>
                                    <input readonly (focus)="accFocusVar" type="text" formControlName="accNo"
                                        class="form-control " placeholder="Please select merchant..." />
                                </div> -->

                            <br>
                            <br>
                             
                            </form>

                            <div class="d-flex justify-content-between" style="padding-top: 1em;">
                                <!-- <div>
                                    <a class="blue-link clickable"tabindex="-1" [hidden]="hideSaveButton" (click)="cancelEdit()">Cancel</a>
                                </div> -->
    
                                <button class="rounded btn btn-success w-10" [hidden]="hideSaveButton" (click)="applyEdit()">
                                    <span *ngIf="loading && submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                            Save Changes
                                </button>
    
                            </div>
                            <br>
                            
                        </div>
                        <br>
                        <br>
                        <div class="p-5 card-blue-shadow user-detail-card">

                        <div class="d-flex justify-content-between align-items-end">
                            <h4 class="mb-0">Linked Sites</h4>
                                    <a *ngIf="(status=='Active' || status=='Disabled')"
                                        class="text-align-centre p-2 blue-link"
                                        style="color: #589cd0; margin-bottom: -5px;"
                                        [hidden]="hideEditSitesBtn"
                                        (click)="editSites()">Manage Sites</a>

                                    <a class="text-align-centre p-2 blue-link" 
                                    style="color: #589cd0; margin-bottom: -5px;" 
                                    [hidden]="hideCancelSiteEditBtn"
                                    (click)="cancelSiteEdit()">Cancel</a>

                        </div>
                        <hr style="margin-top: 10px">
                            <br>
                            <br>
                            <!-- <button class="btn btn-primary" style = "text-align: justify; margin-right: 15px;"  routerLink="/linked-sites"> View All Linked Sites</button> -->
                            <table id="siteTable" class="table  table-hover" datatable [dtOptions]="clientOptions"
                            style="width: 100%">
                            <thead>
                                <tr>
                                    <th scope="col" class="user-table-headings">Name</th>
                                    <th scope="col" class="user-table-headings">CIT Code</th>
                                    <th scope="col" class="user-table-headings">Account Number</th>
                                    <th scope="col" class="user-table-headings">Cash Centre</th>
                                    <th scope="col" class="user-table-headings">Status</th>
                                </tr>
                            </thead>
                        </table>



                        <br>
                        <!-- <br> -->

                        <!-- <h4 class="mb-0">Add New Site</h4>
                        <br> -->
                        <!-- <br> -->

                        <button class="btn btn-primary" style = "text-align: justify; margin-right: 15px; width:fit-content" (click)="checkChecked()" [hidden]="hideCheckSitesBtn">Activate/Deactivate Sites</button>
                        <br>
                        <br>
                        <div *ngIf="toggleStatus  && checked" style="font-size: 80%; color: #43dc35">
                            <div *ngIf="toggleStatus  && checked">Sites have been queued for update</div>
                        </div>
                        <div *ngIf="!toggleStatus && checked" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!toggleStatus  && checked">No sites have been selected for update</div>
                        </div>

                        <br>
                        <br>
                        <button class="rounded btn btn-success w-10" [hidden]="hideSaveSitesChangesbtn" (click)="manageSites()"> 
                            <span *ngIf="loadSite" class="spinner-border spinner-border-sm mr-1"></span>
                            Save Changes 
                        </button>

                        <!-- <div class="form-group">
                            <label class="control-label" for="siteName">Name</label>
                            <input [readonly]="false" type="text"
                                formControlName="siteName" class="form-control "
                                placeholder="Enter Name" />
                        </div>

                        <div class="form-group">
                            <label class="control-label" for="CIT">CIT Code</label>
                            <input [readonly]="false" type="text"
                                formControlName="CIT" class="form-control "
                                placeholder="Enter CIT Code" />
                        </div>

                        <div class="form-group">
                            <label class="control-label" for="AccNo">Account Number</label>
                            <input [readonly]="false" type="text"
                                formControlName="AccNo" class="form-control "
                                placeholder="Enter Account Number" />
                        </div>

                        <label class="control-label" for="cc">Cash Centre </label>
                        <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;" 
                                        [placeholder]="'Cash centres'"
                                        [settings]="dropdownSettingsCC" [data]="dropdownCCList"
                                        class="singleselect" clearable="true"
                                        formControlName="cashCentres">
                         </ng-multiselect-dropdown> -->
                         
                         <br>
                         <br>

                         <!-- <div class="p-5 ; width:100% margin-bottom: 12px; margin-left: 16px;"> -->
                        <!-- <button class="btn btn-primary" style = "text-align: justify; margin-right: 15px; width:fit-content">Link Site</button> -->
                        <button class="rounded btn btn-success w-10" [hidden]="true"> Save Changes </button>
                        <!-- </div> -->

                    </div>
                    <br>
                    <button class="rounded btn btn-danger w-10" (click)="deactivate()" [hidden]="true">
                        <span *ngIf="loading && cd" class="spinner-border spinner-border-sm mr-1"></span>
                        Deactivate
                    </button>

                    <!-- <button class="rounded btn btn-danger w-10" (click)="activate()" [hidden]="hideActivate">
                        Activate
                    </button> -->
                
                
                </div>


            </div>
        
        
        </div>




    </div>













</div>

