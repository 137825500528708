export class Site {
    id: number;
    group: number;
    name:string;
    code:string;
    accNum:string;
    cc:number;
    groupName:string;
    ccName:string;
    status:number;
}