<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

    <div class="container-fluid" style="padding: 25px">
    
        <!-- <div class="ps-4 pe-4 pt-4 container-fluid "> -->
    
            <div>
    
                <h6 style="margin-bottom: -20px">ORGANISATION</h6>
                <h2>New Organisation </h2>
                <h5>Add Organisation</h5>
    
            </div>
    
            <hr class="mt-0">
    
    
    
            <div class="d-flex flex-column min-vh-100 align-items-center">
    
  
    
                <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">
    
                    <div class="d-flex justify-content-between align-items-end">
    
                        <h3 class="h3-grey">Organisation Details</h3>
    
                        <p class="purple"></p>
    
                    </div>
    
                    <hr class="mt-0">
  
               <form [formGroup]="form">     
                  <div class="form-group">
                      
                        <label class="control-label" for="name">Name</label><span style="color:#ff0000"> *</span>
                        <input type="text" formControlName="name" class="form-control"
                               placeholder="Please enter organisation name"
                               [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                               <div *ngIf="(submitted && f.name.errors) || invalidGN" class="invalid-feedback">
                                  <div *ngIf="f.name.errors.required || invalidGN">Name is required</div>
                              </div>
                    
                  </div> 
              </form>

              <div *ngIf="duplicateGroup" style="font-size: 12px; color: #dc3545">
                <div *ngIf="duplicateGroup">Organisation name already exists</div>
            </div>
                  <div class="p-5 ; width:100% margin-bottom: 12px; margin-left: 16px;">
                
                      <button class="btn btn-default" style = "text-align: justify; margin-right: 15px; width:fit-content" (click)="return()"> 
                          <!-- <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> -->
                          Back 
                      </button>  
                      <!-- <button class="btn btn-primary" style = " text-align: justify; margin-right: 15px; width:fit-content"> Save Changes </button> -->
                      <button class="btn btn-primary"  style ="float: right; margin-right: 15px; width:fit-content" (click)="AddGroup()">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>  
                        Add
                      </button>
          
                  
                  </div>
   
                </div>
    
            </div>
  
        </div>
  
    <!-- </div> -->
    