import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import * as shajs from 'sha.js';

export function createPasswordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // console.log("HERE");
    const value = control.value;
    if (!value) {
      return null;
    }
    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasSpecial = /[$@$!%*?&#]+/.test(value);
    const passwordValid = hasUpperCase && hasLowerCase && hasSpecial;
    return !passwordValid ? { passwordStrength: true } : null;
  }
}


export default class Validation {

  static match(controlName: string, checkControlName: string): ValidatorFn {
    // console.log("matching...");
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl.errors && !checkControl.errors.matching) {
        return null;
      }

      if (control.value !== checkControl.value) {
        controls.get(checkControlName).setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  hideErrorBanner: boolean=true;
errorMessage: string=" ";


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private renderer: Renderer2
  ) { 
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
    // this.renderer.setStyle(document.body, 'background', 'assets/img/lines.png');
    // this.renderer.setStyle(document.body, 'width', '100vw');
    // this.renderer.setStyle(document.body, 'height', '100vh');
    // this.renderer.setStyle(document.body, 'background-size', 'cover');
    // this.renderer.setStyle(document.body, 'overflow-y', 'auto');
    // this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
  }
  // style="width: 100vw; height: 100vh; background-size: cover; overflow-y: auto; overflow-x: hidden;"

  ngOnInit() {

    this.form = this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(8), createPasswordStrengthValidator()]],
      passwordConfirm: [null, [Validators.required]],

    },
      {
        validators: [Validation.match('password', 'passwordConfirm')]
      }
    );
  }


  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  readonly: boolean;

  xButton(){
    window.location.reload();
}


  onSubmit() {
    this.submitted = true;
    // console.log(this.f.errors)
    // reset alerts on submit
    this.alertService.clear();

    const hashed = shajs('sha256').update(this.f.password.value).digest('hex')
    // stop here if form is invalid
    if ((this.form.invalid)) {
      return;
    }
    if ((this.f.password.value != this.f.passwordConfirm.value)) {
      return;
    }

    this.form.disable();
    this.loading = true;

    let ottId=sessionStorage.getItem("ottId");
    let ottCode=sessionStorage.getItem("ottCode");

    this.accountService.resetPassword(hashed, ottId, ottCode)
      .pipe(
        first())
      .subscribe(
        data => {
          // console.log(data);
          this.router.navigate(['../password-set-complete'], { relativeTo: this.route });
        },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          if (error.status == 0) {
             this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          this.form.enable();
          this.loading = false;
        });
    this.loading = false;
  }

}

