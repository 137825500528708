import { Component, OnInit, Renderer2 } from '@angular/core';
import { timer } from 'rxjs/internal/observable/timer';
import { AccountService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-register-details-processing',
  templateUrl: './register-details-processing.component.html'
})
export class RegisterDetailsProcessingComponent implements OnInit {
  loading = false;
  submitted = false;
  resendNotAllowed: boolean = true;

  hideErrorBanner: boolean=true;
errorMessage: string=" ";



  constructor(
    private accountService: AccountService,
    private alertService: AlertService,
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }

  ngOnInit(): void {
    timer(60000).subscribe(x => (this.enableSubmit()));
  }


  enableSubmit() {
    this.resendNotAllowed = false;  //resend is allowed now
    // this.resendEmail();
  }

  xButton(){
    window.location.reload();
}

  resendEmail() {
    if (this.resendNotAllowed == true) {  //resend not allowed now
      return;
    }
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    this.loading = true;
    let reg = JSON.parse(localStorage.getItem('registrationDetails'));
    this.accountService.registerDetails(reg)//, this.ipaddress)
      .subscribe(data => {
        // console.log(data);
        // console.log(JSON.stringify(data));
        this.loading = false;
      },
        error => {

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          this.loading = false;
        });

    this.resendNotAllowed = true;
    timer(60000).subscribe(x => (this.enableSubmit()));  //resend will be allowed again
  }

}
