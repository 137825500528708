<div class="container-fluid" style="padding: 25px">

    <div>
        <h6 style="margin-bottom: -20px">EXISTING USERS</h6>
        <h2>Manage Merchant Groups/Sites</h2>
        <!-- <h5>Manage all SBV Connect users</h5> -->
    </div>

    <hr class="mt-0">

    <div class="row d-flex ml-1">
        <div class="p-2">
            <ul class="nav nav-pills nav-underline">
                <li><a href="/merchant-management">All Merchants</a></li>
                <li class="active"><a href="/merchant-management-existing">Existing Merchants</a></li>
                <li><a href="/merchant-management-awaiting-approval">Awaiting Approval</a></li>
            </ul>
        </div>
        <button class="rounded btn btn-primary ml-auto p-3 h-50 border-0" id="AddUser"style="width: 130px"
            routerLink="/add-group-merchant">
            <div class="row ml-2">
                <span> Add New Merchant </span>
                <div class="circle"> + </div>
            </div>
        </button>
    </div>

    <div class="card card-blue-shadow" >

        <div class="row" style="border-radius: 14px;">

            <div class="col" style="border-radius: 14px;" [ngClass]="{'table-no-details' : fullTable, 'table-user-details': !fullTable}">

                <div class="card-header d-flex  user-management-card" style="border-radius: 14px;">
                    <h4 class="p-2">Merchant Groups</h4>   
                </div>

                <div class="card-body " style="border-radius: 14px;">
                    <!-- <div class="card-body pt-0 scroll-box-table table-responsive" style="overflow-x:auto;"> -->
                    <table id="userTable" class="table  table-hover" datatable [dtOptions]="dtOptions"
                        style="width: 100%">
                        <thead>
                            <tr>
                                <th scope="col" class="user-table-headings">Site Name</th>
                                <th scope="col" class="user-table-headings">CIT Code</th>
                                <th scope="col" class="user-table-headings">Account Number</th>
                                <th scope="col" class="user-table-headings">Cash Centre</th>
                                <th scope="col" class="user-table-headings">Last Updated</th>
                                <th scope="col" class="user-table-headings">Created On</th>
                                <th scope="col" class="user-table-headings">Status</th>
                            </tr>
                        </thead>
                        
                    </table>
                </div>
            </div>

            <div [ngClass]="{'no-details' : fullTable, 'user-details': !fullTable}">

                <div class="col" *ngIf="fullTable">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <p class="clickable" (click)="showDetails()" style="margin-top: 10px;">
                            <i class="fa fa-angle-left fa-2x "></i>
                        </p>
                    </div>
                </div>

                <div class="col" style="width:50%; background-color: #d5e6f3;" [hidden]="hideCard">

                    <p class="clickable" (click)="hideDetails()"
                            style="margin-top: 10px; margin-left: -2px; margin-bottom: -10px;">
                            <i class="fa fa-angle-right fa-2x "></i>
                    </p>

                    <h3 class="h3-grey user-detail-card">{{merchantName}}</h3>
                        <h4 class="user-detail-card row"><b>{{organisation}} -
                                <span *ngIf="status=='Disabled'">Deactivated Merchant</span>
                                <span *ngIf="status=='Active'">Active Merchant</span>
                                </b>
                        </h4>

                        <div *ngIf="status=='Disabled'" class="m-4"><span class="label label-danger">Disabled</span>
                        </div>

                        <div *ngIf="status=='Active'" class="m-4"><span class="label label-warning">Active</span>
                        </div>

                        <div class="p-5 card-blue-shadow user-detail-card">

                            <form [formGroup]="form">

                                <div class="d-flex justify-content-between align-items-end">
                                    <h4 class="mb-0">Merchant Details</h4>
                                    <a *ngIf="(status=='Active' || status=='Disabled')"
                                        class="text-align-centre p-2 blue-link"
                                        style="color: #589cd0; margin-bottom: -5px;"
                                        (click)="editDetails()">Edit</a>
                                </div>

                                <hr style="margin-top: 10px">

                                <div class="form-group">
                                    <label class="control-label" for="name">Name</label>
                                    <input readonly (focus)="nameFocusVar" type="text" formControlName="name"
                                        class="form-control" placeholder="Please select merchant..." />
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="code">Code</label>
                                    <input readonly (focus)="codeFocusVar" type="text" formControlName="code"
                                        class="form-control " placeholder="Please select merchant..." />
                                    <!-- placeholder="Surname"   -->
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="accNo">Account Number</label>
                                    <input readonly (focus)="accFocusVar" type="text" formControlName="accNo"
                                        class="form-control " placeholder="Please select merchant..." />
                                    <!-- placeholder="Email" /> -->
                                </div>




                            </form>





                        </div>
                
                
                
                
                
                
                
                
                </div>







            </div>
        
        
        
        
        
        
        
        
        </div>






    </div>













</div>


