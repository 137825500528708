import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService, AlertService } from 'src/app/_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { theUser } from 'src/app/_models/theUser';
import { Role } from 'src/app/_models/role';
import { CashCentre } from 'src/app/_models/cash-centre';
import { CashCentres } from 'src/app/_models/cash-centres';
import { Contact } from 'src/app/_models/contact';
import { Profile } from 'src/app/_models/profile';
import { Roles } from 'src/app/_models/roles';
import { UpdatedUser } from 'src/app/_models/updated-user';
import { UpdatedUserUser } from 'src/app/_models/updated-user-user';
import { Customer } from 'src/app/_models/customer';
//import { jitOnlyGuardedExpression } from '@angular/compiler/src/render3/util';
import { CookieService } from 'ngx-cookie';
import { Site } from 'src/app/_models/site';
import { Sites } from 'src/app/_models/sites';
import { Bank } from 'src/app/_models/bank';
import { Profiles } from 'src/app/_models/Profiles';
import { myProfiles } from 'src/app/_models/myProfile';

// import { sbvUser } from 'src/app/_models/sbvUser';

@Component({
  selector: 'app-user-management-awaiting-approval',
  templateUrl: './user-management-awaiting-approval.component.html'
})
export class UserManagementAwaitingApprovalComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  bEditLoading: boolean = false;
  acceptLoading: boolean = false;

  organisationFocusVar: boolean;
  siteFocusVar: boolean;

  showCCs: boolean = true;
  selectedSite: number;

  updatedUser: UpdatedUser;
  updatedUserUser: UpdatedUserUser;

  fullName: string;
  organisation: string;
  status: string;
  subjectEmail: string;

  merchUpdate: boolean=false;

  settings = {};

  isChecked = false;
  closeResult = '';

  users: theUser[] = [];
  activeUser;
  dtOptions: DataTables.Settings = {};
  selectedUser: number;

  fullTable: boolean = true;

  group: number;

  isBankAdmin: boolean;
  model: any = {};

  roleCheck:number;
  selectedGroup: number = 0;
  selectedLoc: number = 0;
  selectedRole: number = 0;
  rowSelected: boolean = false;
  selectedBank: number = 0;

  dropdownListGroups = [];
  dropdownListRoles = [];
  dropdownSettingsGroups = {};
  dropdownSettingsRoles = {};
  dropdownSettingsLocs = {};
  dropdownSettingsCCSingle = {};
  dropdownSettingsRegions = {};
  dropdownSettingsBanks = {};

  dropdownCCList = [];
  dropdownCCListSingle = [];
  selectedCCItems = [];
  originalCCs = [];
  dropdownSettingsCC = {};
  ccentres: any[];
  rowId;

  newRole: string = "";
  oldRole: string = "";
  newCCs: string = "";
  roleUpdate: boolean = false;
  ccUpdate: boolean = false;

  canEditPersonal: boolean = false;
  canEditBusiness: boolean = false;
  canEditCC: boolean = false;
  noEdit: boolean = true;

  keyword = 'name';

  currentUserRole: number;
  currentUserGroup: number;

  selected: any;
  filtered: any;
  locations = [];
  usersArray=[];
  customerId;
  customerCode;
  customerName;

  ccReady: boolean = false;
  ccLoading: boolean = false;
  singleUser:boolean=false;
  theStatusValue;
  result: theUser;
  locType;
  locId;
  customer: Customer;

  suID:number=0;
  suGID:number=0;
  suName:string="";
  suGName:string="";
  userRole: number=0;

  data: any;
  errorMsg: string="";
  showPageError: boolean = false;
  showPersonalError: boolean = false;
  showBusinessError: boolean = false;
  showCcError: boolean = false;
  isLoadingResult: boolean;

  showSiteTypes: boolean;
  showRegions: boolean;
  showCCList: boolean;
  showChosenCustomer: boolean;
  merchCustExists: boolean;
  busCustExists: boolean;
  bsa:boolean=false;
  userSelectedGroup: number;

  hideMultiBank: boolean=true;
  dropdownSettingsMultiBank={};
  allSelectedMultiBank:boolean=false;
  multiBanks=[];
  
  hideComponents:boolean=true;

  selectedBanks:number[]=[];
  sBanks:Bank[]=[];
  selectedBankNames:any;

  hideLevel:boolean=false;
  hideMerchBox:boolean=true;
  SB=[];
  singleBankreturned:boolean=false;
  ccMerchAccess:boolean=false;
  noMultiBanks: boolean=false;

  dropdownListSingleBanks = [
    { id: 1, name: "ABSA" },
    { id: 2, name: "Nedbank" },
    { id: 3, name: "First National Bank" },
    { id: 4, name: "Standard Bank South Africa" },
    { id: 5, name: "African Bank" },
    { id: 6, name: "Capitec" }
  ];

  
  isMultiBank: boolean=false;
  isFMU: boolean=false;
  allowMerchants:boolean=false;

  pageReload: boolean;

  customerChosen: boolean = true;
  siteTypeChosen: boolean = true;
  regionChosen: boolean = true;
  ccSingleChosen: boolean = true;
  orgChosen: boolean = true;
  siteInfoSaved: boolean;

  loggedInRole: number=0;
  numUsers:number;

  errorMessage:string="";
  hideErrorBanner: boolean=true;

  ///////////////////////////////////////////////////////////////
  hideSiteBanner:boolean=true;
  hideActiveTable:boolean=true;
  US:number=0;
  addArray: number[]=[];
  containsRows:boolean=false;
  displayArray= [];
  displayRes:Site;
  merchGroup:number=0;
  groupName:string="group";
  name:string="name";
  code:string="code";
  accNum:string="accNum";
  cc:string="CC";
  hideSiteTable:boolean=true;
  isActiveMerch:boolean=false;
  x: string;
  approvalString:string='{"changes":{';
  updateString:string='[{';
  siteOptions: DataTables.Settings = {};
  addOptions: DataTables.Settings={};
  addOptions2: DataTables.Settings={};
  activeUserOptions:DataTables.Settings={};
  aFullTable: boolean=true;
  invalidSites:boolean=false;
  unlinkSites:boolean=false;
  fc:boolean=false;
  userStatus:number=0;
  merchStatus:number;
  AOM:boolean=false;
  checked:boolean=false;
  roleChange:boolean=false;
  ccChange:boolean=false;
  cashCentresChange: CashCentres[] = [];
  siteResult:Site;
  sites: Site[] = [];
  userSites:Site[]=[];
  SCSites:Site[]=[];
  slectedAddSite:number;
  sitesChange:boolean=false;
  toggleSite: number[]=[];
  SGId:number;
  numSites:number=0;
  reloadApp: boolean;
  singleCentre:CashCentre;
  hideAddSitebtn:boolean=true;
  siteLength:number;
  hideEditSitesBtn:boolean=false;
  hideCancelSiteEditBtn:boolean=true;
  hideCheckSitesBtn:boolean=true;
  hidesaveUnlinkBtn:boolean=true;
  hideEDBtn:boolean=false;
  linkedSites=[];
  changes = [];
  request_id:string="";
  approvalID:number;
  //////////////////////////////////////////////////////////////////

  dropdownListRegions: [];
  dropdownListBanks = [
    // { id: 1, name: "ABSA" },
    // { id: 2, name: "Nedbank" },
    // { id: 3, name: "First National Bank" },
    // { id: 4, name: "Standard Bank South Africa" },
    // { id: 5, name: "African Bank" },
    // { id: 6, name: "Capitec" }
  ];
  ccChanges: any[];

  userDropdownHeading:string = "Linked Banks"

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.ccentres = [];
  }

  editPersonalDetails() {
    this.canEditPersonal = !this.canEditPersonal;
    console.log(this.canEditPersonal)

    this.form = this.formBuilder.group({
      contactNo: [this.result.contactNo]
    });
  }

  editBusinessDetails() {
    this.canEditBusiness = !this.canEditBusiness;
    // this.showChosenCustomer = !this.showChosenCustomer;
    this.merchCustExists = true;
  }

  editCCDetails() {
    this.canEditCC = !this.canEditCC;
  }

  onItemSelectLoc(item: any) {
    this.selectedSite = item.id;
    this.showRegions = false;
    this.showCCList = false;

    if (item.id == 0) { //national
      this.selectedLoc = 0;
    }
    if (item.id == 1) { //regional
      //show regional dropdown
      this.showRegions = true;
      this.selectedLoc = this.locId;
    }
    if (item.id == 2) { //cc
      //show cc dropdown
      this.showCCList = true;
      this.selectedLoc = this.locId;
    }
    if (item.id == 3) { //site
      //customer id
      this.selectedLoc = this.customerId;
    }
  }

  onRegionsItemSelect(item: any) {
    this.selectedLoc = item.id;
    console.log("REGION this.selectedLoc: " + this.selectedLoc);
  }

  onBanksItemSelect(item: any) {
    this.customerId = item.id;
  }

  singleCC: number;
  onItemSelectCCSingle(item: any) {
    this.singleCC = item.id;
    this.selectedLoc = item.id;
    console.log("CC this.selectedLoc: " + this.selectedLoc);
  }

  onItemSelectGroup(item: any) {
    // this.resetForm();
    this.selectedRole = 0;
    this.selectedGroup = item.id;
    this.dropdownListRoles = [];

    // if (item.id != 3) {
      this.accountService.getRoles(item.id)//this.groupId) // item
        .subscribe(data => {
          this.dropdownListRoles = data.roles.role;
        },
          error => {
          });
    // } else {
    //   this.dropdownListRoles = [{ id: 11, name: 'Direct User', description: '' }];
    // }
  }

  onGroupItemDeSelect(item: any) {
    this.selectedRole = 0;
    this.dropdownListRoles = [];
    this.resetForm();
    // Call to clear
    this.onDeSelectAll();
  }

  onBankCustomerDeselect(item: any) {
    this.customerId = null;
    console.log("deselected bank")
  }

  onItemDeSelect(item: any) {
    this.selectedRole = 0;
  }

  dropdownListLocs = [
    { id: 0, name: "National" },
    { id: 1, name: "Regional" },
    { id: 2, name: "Cash Centre" },
    { id: 3, name: "Site" }
  ];

  @ViewChild('rolesMultiSelect') multiSelect;

  onDeSelectAll() {
    this.dropdownListRoles = null;
  }

  resetForm() {
    this.form.get("role").setValue([]);
  }

  onItemSelectRole(item: any) {
    this.selectedRole = item.id;
  }

  cancelOrganisationFocus() {
    this.organisationFocusVar = false;
  }
  cancelSiteFocus() {
    this.siteFocusVar = false;
  }
  

  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit(): void {
    let jsessionid=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"), jsessionid)
    .subscribe(data => {
     this.roleCheck=data.users.user.roles.role.id;
     if(this.roleCheck==6 || this.roleCheck==22 || this.roleCheck==50){//User is either SBV or Bank admin
        console.log("User is admin, role = "+this.roleCheck)
     }
     else{//User is nt an admin
      console.log("User is not an admin, role = "+this.roleCheck)
      this.router.navigate(['/forbidden-access']);
     }

    },
    error => {
        console.log(error)
    });

    let JSessionid=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"), JSessionid)
      .subscribe(data => {
        this.currentUserRole = data.users.user.roles.role.id;
        this.currentUserGroup = data.users.user.roles.role.groupId;
        let r = new Role;
        r = data.users.user.roles.role;
        localStorage.removeItem('currentUserRole');
        localStorage.setItem('currentUserRole', this.currentUserRole.toString());
      });

    localStorage.setItem('step1', 'false');
    localStorage.setItem('step2', 'false');
    // let currentUserRole;
    let JSessionID=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"), JSessionID)
      .subscribe(data => {
        console.log("Current User: "+data)
        this.currentUserRole = data.users.user.roles.role.id;
        this.currentUserGroup = data.users.user.roles.role.groupId;
        console.log(data.users.user.roles.role.id);
        if (data.users.user.roles.role.id == 22) {
          console.log("current user is bank admin")
          this.isBankAdmin = true;
        } else {
          console.log("current user is NOT bank admin")
          this.isBankAdmin = false;
        }
        
      });

    localStorage.removeItem('selectedUserId');

    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "ordering": true,
      order: [6],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'firstName' },  //0
        { data: 'lastName' },
        { data: 'email'},  //1
        { data: 'organization' },  //2 
        // { data: 'role.groupName' },  //3
        // { data: 'role.name' },  //4
        { data: 'roles.role.groupName' },  //3
        { data: 'roles.role.name' },  //4
        { data: 'updated' },  //5
        { data: 'created' },  //6
        { data: 'status' }  //7
      ],
      "columnDefs": [
        {
          "render": function (data, type, row) {
            return data + ' ' + row.lastName;
          },
          "targets": 0
        },
        {
          "targets": [6, 7],
          "render": function (data, type, row) {
            var dateSplit = data.split('-');
            return data;
          }
        },
        {
          "targets": 8,
          "render": function (data, type, row) {
            if (row.status === 0) {
              return '<span class="label label-danger">Disabled</span>';
            }
            if (row.status === 1) {
              return '<span class="label label-primary">In Flight</span>';
            }
            if (row.status === 2) {
              return '<span class="label label-warning">SBV Pending</span>';
            }
            if (row.status === 3) {
              return '<span class="label label-success">Active</span>';
            }
            if (row.status === 4) {
              return '<span class="label label-error">Bank Pending</span>';
            }
            if (row.status === 5) {
              return '<span class="label label-danger">Rejected</span>';
            }
            if (row.status === 6) {
              return '<span class="label label-info">Update</span>';
            }
            if (row.status === 77) {
              return '<span class="label label-warning">SBV Pending</span>';
            }
            return data;
          }
        },
        { "visible": false, "targets": [1] }
      ]
    };

    $('#userTable').on('click', 'tr', function () {
      this.selectedUser = table.row(this).data().id;
      if (this.selectedUser != localStorage.getItem('selectedUserId')) {
        localStorage.removeItem('selectedUserId');
        localStorage.setItem('selectedUserId', this.selectedUser);
      }
    });

    const e = sessionStorage.getItem("email");
    console.log("sessionStorage.getItem(email) " + e)

    let JSessionId=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(e, jsessionid)
       .subscribe(data => {
          this.loggedInRole=data.users.user.roles.role.id;
          console.log("role : "+this.loggedInRole)

          console.log("role before RP set : "+this.loggedInRole)
          let relevantPending;
          if (this.loggedInRole == 22) {
            relevantPending = 4;
          } 
          if (this.loggedInRole == 6 || this.loggedInRole == 50) {
            relevantPending = [2, 4, 77];
          }
      
          let Jsession=this.cookieService.get("JSESSIONID");
          this.accountService.getAllUsersByTokenAndStatus(e, relevantPending, Jsession)
            .subscribe(data => {
              
              this.numUsers=data.users.user.length;
              console.log("numUsers : "+this.numUsers);
              console.log("USER : "+JSON.stringify(data.users.user))
      
              if(this.numUsers==undefined){ 
      
                this.singleUser=true;
                this.suID=data.users.user.roles.role.id ? data.users.user.roles.role.id : '';
                this.suGID=data.users.user.roles.role.groupId ? data.users.user.roles.role.groupId : '';
                this.suName=data.users.user.roles.role.name ? data.users.user.roles.role.name : '';
                this.suGName=data.users.user.roles.role.groupName ? data.users.user.roles.role.groupName : '';
      
                console.log("Only 1 user awaiting approval"); 
                let u = new theUser;
                u=data.users.user;
                this.users.push(u);
      
                setTimeout(() => {
                  table = $('#userTable').DataTable({
                    initComplete: function () {
                      if (!this.fullTable) {
                      }
                    },
                    retrieve: true,
                  }).rows.add(this.users).draw();
                }, 3000);
              }   
      
              else{
                console.log("More than 1 user awaiting approval");
                this.singleUser=false;
                for (let i = 0; i < this.numUsers; i++) {
                  let u = new theUser;
                  u.id = data.users.user[i].id;
                  u.firstName = data.users.user[i].firstName;
                  u.lastName = data.users.user[i].lastName;
                  u.email = data.users.user[i].email;
                  u.contactNo = data.users.user[i].contactNo;
                  u.organization = data.users.user[i].organization ? data.users.user[i].organization : '';
                  u.locationId = data.users.user[i].locationId;
                  u.locationType = data.users.user[i].locationType;
                  u.jobTitle = data.users.user[i].jobTitle ? data.users.user[i].jobTitle : '';
                  u.department = data.users.user[i].department ? data.users.user[i].department : '';
                  u.lineManagerName = data.users.user[i].lineManagerName ? data.users.user[i].lineManagerName : '';
                  u.lineManagerContactNo = data.users.user[i].lineManagerContactNo ? data.users.user[i].lineManagerContactNo : '';
                  u.status = data.users.user[i].status;
                  let r = new Role;
                  r.id = data.users.user[i].roles.role.id ? data.users.user[i].roles.role.id : '';
                  r.name = data.users.user[i].roles.role.name ? data.users.user[i].roles.role.name : '';
                  r.groupId = data.users.user[i].roles.role.groupId ? data.users.user[i].roles.role.groupId : '';
                  r.groupName = data.users.user[i].roles.role.groupName ? data.users.user[i].roles.role.groupName : '';
                  u.role = r;
                  let c = new Customer;
                  c.id = data.users.user[i].customer.id ? data.users.user[i].customer.id : '';
                  c.code = data.users.user[i].customer.code ? data.users.user[i].customer.code : '';
                  c.name = data.users.user[i].customer.name ? data.users.user[i].customer.name : '';
                  u.customer = c;
                  this.users.push(u);
                }
                setTimeout(() => {
                  table = $('#userTable').DataTable({
                    initComplete: function () {
                      if (!this.fullTable) {
                      }
                    },
                    retrieve: true,
                  }).rows.add(data.users.user).draw();
                }, 3000);
              }
      
            },
              error => {
                console.log("ERROR")
                return;
              });
        },
        error => {
          console.log(error);
    });

    let table;
    //let ur: number = parseInt(localStorage.getItem('currentUserRole'));
    //this.loggedInRole= parseInt(sessionStorage.getItem("role"));

    $('#userTable ').on('click', 'tr', () => {
      var idx = table.row(this).index();
      if (localStorage.getItem('rowId') == idx) {
        this.hideDetails();
      }
      else {
        this.rowSelected = true;
        this.canEditPersonal = false;
        this.canEditBusiness = false;
        this.canEditCC = false;
        this.showDetails();
      }
    });

    $('#userTable ').on('click', 'tr', function () {
      if ($(this).hasClass('selected')) {
        $(this).removeClass('selected');
      }
      else {
        table.$('tr.selected').removeClass('selected');
        $(this).addClass('selected');
      }
      // console.log("ROW: " + table.row(this).index())
      localStorage.setItem('rowId', table.row(this).index());

    });

    this.activeUserOptions={
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "ordering": true,
      "pageLength": 5,
      order: [0],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'id' },//0
        { data: 'name' },//1
        { data: 'code' },//2
        { data: 'accNum' },//3
        { data: 'ccName' },//4
        { data: null, "defaultContent": '<input type="checkbox" id="toggleCB">' }
        // { data: 'status'}//5
      ],
      // "columnDefs": [

      //   {
      //     "targets": 5,
      //     "render": function (data, type, row) {
      //       if (row.status === 0) {
      //         return '<span class="label label-danger">Disabled</span>';
      //       }
      //       if (row.status === 1) {
      //         return '<span class="label label-success">Active</span>';
      //       }
      //       return data;
      //     }
      //   },
      // ]

    };

    this.addOptions2 = {
      pagingType: 'full_numbers',
      "pageLength": 5,
      dom: '<"table-wrapper"flr>tip',
      "autoWidth": true,
      "ordering": false,
      // order: [0],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'id' },//0
        { data: 'groupName' },//1
        { data: 'name' },//2
        { data: 'code' },//3
        { data: 'accNum'},//4
        { data: 'ccName'},//5
        { data: null, "defaultContent": '<input type="checkbox" id="addCB">' } //4
        // { data: 'status' },//5
        // { data: 'updated' },//6
        // { data: 'created' },//7
      ],
      "columnDefs": [

      ]
    };

    $('#addTable').on('click', 'tr', function () {
      this.slectedAddSite = addTable.row(this).data().id;
      if (this.slectedAddSite != localStorage.getItem('slectedAddSite')) {
        localStorage.removeItem('slectedAddSite');
        localStorage.setItem('slectedAddSite', this.slectedAddSite);
      }
    });

    let addTable;
    
    let Js= this.cookieService.get("JSESSIONID");
    this.accountService.getSites(Js)
    .subscribe(data => {
      console.log(JSON.stringify(data));
      this.numSites=data.sites.site.length;
      for (let i: number =0; i < data.sites.site.length; i++) {
        let s =new Site;
        s.id=data.sites.site[i].id;
        s.group=data.sites.site[i].customerId;
        s.name=data.sites.site[i].name;
        s.code=data.sites.site[i].citCode;
        s.accNum=data.sites.site[i].accountNo;
        s.cc=data.sites.site[i].cashCentreId;
        s.groupName=data.sites.site[i].customerName;
        s.ccName=data.sites.site[i].cashCentreName;
        s.status=data.sites.site[i].status;
        this.sites.push(s);
      }

      setTimeout(() => {
        addTable = $('#addTable').DataTable({
          initComplete: function () {
            if (!this.aFullTable) {
            }
          },
          retrieve: true,
        }).rows.add(this.sites).draw();
      }, 2000);

     },

     error => {
      console.log("ERROR");
     });

     $('#addTable ').on('click', 'tr', () => {
      var idx = addTable.row(this).index();
      this.addLS();
    });

     $('#addTable ').on('click', 'tr', function () {
    
      if ($(this).hasClass('selected')) {
        console.log("row has been deselected");
        $(this).removeClass('selected');
        $(this).find('#addCB').prop("checked", false);
      }
      else {
        console.log("row has been selected");
        $(this).addClass('selected');
        $(this).find('#addCB').prop("checked", true); 
      }
      localStorage.setItem('addRowId', addTable.row(this).index());
    });

    this.siteOptions = {
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "ordering": true,
      order: [0],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'id' },//0
        // { data: 'groupName' },//1
        { data: 'name' },//2
        { data: 'code' },//3
        { data: 'accNum'},//4
        { data: 'ccName'},//5
        
      ],
      "columnDefs": [

      ]
    };

    this.dropdownSettingsGroups = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    };

    this.dropdownSettingsLocs = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    };

    this.dropdownSettingsCCSingle = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    };

    this.dropdownSettingsRoles = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      clearable: "true",
      closeDropDownOnSelection: "true",
      itemsShowLimit: 1,
      enableCheckAll: false,
      noDataAvailablePlaceholderText: "Please select group first",
    };

    this.dropdownSettingsRegions = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    };

    this.dropdownSettingsBanks = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    };

    this.dropdownSettingsMultiBank = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };

    this.selectedCCItems = [
    ];
    this.selectedBankNames = [
    ];
    this.dropdownSettingsCC = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };

    this.settings = {
      singleSelection: true,
      text: "Select Group",
      enableSearchFilter: false,
      classes: "myclass custom-class"
    };

    //1 - get groups
    this.accountService.getGroups()
      .subscribe(data => {
        this.dropdownListGroups = data.groups.group;
      },
        error => {
          this.alertService.error(error.error.error.message);
        });


        let jSession=this.cookieService.get("JSESSIONID");
        console.log(jSession);

    this.accountService.getCashCentres(jSession)
      .subscribe(data => {
        this.loading = false;
        this.dropdownCCList = data.root.cashCentre;
        this.dropdownCCListSingle = data.root.cashCentre;
      },
        error => {
          this.alertService.error(error.error.error.message);
          this.loading = false;
        });

    let JSe=this.cookieService.get("JSESSIONID");
    this.accountService.getBanks(JSe)
    .subscribe(data => {

      console.log("Banks - "+JSON.stringify(data));
      this.dropdownListBanks = data.banks.bank;

    },
    error => {

        console.log(error)
    });
    
        let JSession=this.cookieService.get("JSESSIONID");
        console.log(jSession);
    this.accountService.getRegions(JSession)
      .subscribe(data => {
        this.loading = false;
        this.dropdownListRegions = data.root.region;
      },
        error => {
          if(error.status==0){
            console.log(error)
          }

          else{
          this.alertService.error(error.error.error.message);
          this.loading = false;
          }
          
        });
  }

  hideDetails() {
    this.fullTable = true;
    let table = $('#userTable').DataTable();
    table.column(2).visible(true);
    table.column(3).visible(true);
    table.column(5).visible(true);
  }

  showDetails() {
    //if no row selected, do nothing
    if (this.rowSelected == false) {
      return;
    }

    console.log("FLAG 1");
    this.addArray.splice(0);
    this.displayArray.splice(0);

    this.selectedBanks.splice(0);
    this.selectedBankNames.splice(0);
    this.sBanks.splice(0);
    this.multiBanks.splice(0);

    this.fc=false;
    this.checked=false;
    this.sitesChange=false;
    this.customerChosen=true;

    let clearTable;
    clearTable=$('#activeMerchTable').DataTable();
    clearTable.clear().draw();

    this.hideCancelSiteEditBtn=true;
    this.hideEditSitesBtn=false;
    this.hideCheckSitesBtn=true;
    this.hidesaveUnlinkBtn=true;

    this.hideEDBtn=false;

    this.merchUpdate=false;
    this.isActiveMerch=false;

    this.result = this.users.find(({ id }) => id === parseInt(localStorage.getItem('selectedUserId')));

    console.log("FLAG 2");
    let userGroup = this.result.role.groupId;
    console.log("USER GROUP : " + userGroup);

    if(this.result.status==6 && userGroup==3){ //Merchant user role update

      console.log("Merchant user with Role update");
      this.merchUpdate=true;
      this.isActiveMerch=true;
      this.hideAddSitebtn=true;
      this.AOM=true;
      this.hideSiteTable=true;
      this.hideActiveTable=false;

      this.hideEditSitesBtn=true;
      this.hideCancelSiteEditBtn=true;
      this.hideCheckSitesBtn=true;
      this.hidesaveUnlinkBtn=true;

      this.linkedSites.splice(0);
      this.addArray.splice(0);

      console.log("isActiveMerch - " + this.isActiveMerch);
      console.log("hideActiveTable - " + this.hideActiveTable);

      let js=this.cookieService.get("JSESSIONID");
      this.accountService.getUserSites(this.result.email,js)
          .subscribe((data :any) => {
            this.siteLength=data.user.sites.site.length;
            console.log('numSites - '+this.siteLength);

            if(this.siteLength==undefined){
              console.log("ONLY 1 SITE LINKED");
              this.siteLength=1;
              let x=new Site;
              x.id=data.user.sites.site.id;
              x.name=data.user.sites.site.name;
              x.code=data.user.sites.site.citCode;
              x.accNum=data.user.sites.site.accountNo;
              x.ccName=data.user.sites.site.cashCentreName;
              
              let id=parseInt(data.user.sites.site.id);
              this.addArray.push(id);

              console.log('Single site - '+JSON.stringify(x));
              this.linkedSites.push(x);

              console.log("ID ARRAY - "+this.addArray);

              setTimeout(() => {
                siteTable = $('#activeMerchTable').DataTable({
                  initComplete: function () {
                  },
                  retrieve: true,
                }).rows.add(this.linkedSites).draw();
              }, );
            }

            else
            {
              console.log("MULTIPLE SITES LINKED");
              for (let i = 0; i <this.siteLength ; i++) {
                let x=new Site;
                x.id=data.user.sites.site[i].id;
                x.name=data.user.sites.site[i].name;
                x.code=data.user.sites.site[i].citCode;
                x.accNum=data.user.sites.site[i].accountNo;
                x.ccName=data.user.sites.site[i].cashCentreName;

                let id=parseInt(data.user.sites.site[i].id);
                this.addArray.push(id);

                console.log('Single site - '+JSON.stringify(x));
                this.linkedSites.push(x);
              }
              console.log("ID ARRAY - "+this.addArray);

              setTimeout(() => {
              siteTable = $('#activeMerchTable').DataTable({
                initComplete: function () {
                },
                retrieve: true,
              }).rows.add(this.linkedSites).draw();
            },);
          }

          },
            error => {

              if (error.status==0){
                this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
                this.hideErrorBanner=false;
              }
              
              if (error.status==500){
                this.errorMessage="ERROR 500 OCCURRED";
                this.hideErrorBanner=false;
              }
              if (error.status==408 || error.status==504){
                this.errorMessage="ERROR 408/504 OCCURRED";
                this.hideErrorBanner=false;
              }

              console.log("ERROR FETCHING SITES");
            });

            //this.linkedSites.reverse();
    }
    else{
      this.merchUpdate=false;
    }

    console.log("FLAG 3");
    
    console.log("localStorage.setItem('selectedUserObj') " + JSON.stringify(localStorage.getItem('selectedUserObj')));
    this.ccReady = false;
    this.rowId = localStorage.getItem('rowId');

    let siteTable=$('#siteTable').DataTable();
    //siteTable.clear().draw();
    if(siteTable.rows().count()==0){
      console.log("Table is already empty")
    }
    else{
      console.log("Table needs to be cleared");
      siteTable.clear().draw();
    }

    //Instantiate all form fields to ""
    let firstName = "";
    let lastName = "";
    let email = "";
    let contactNo = "";
    let organisation = "";
    let jobTitle = "";
    let department = "";
    let lineManager = "";
    let lineManagerContactNo = "";

    let groupArr = "";
    let roleArr = "";

    let chosenCustomer = "";
    let bank = null;
    let regionName = "";
    let siteName = null;
    let bankName=null;
    let ccName = "";

    this.selectedSite = undefined;
    this.selectedLoc = undefined;
    this.customerId = undefined;

    if (this.fullTable == true) {
      this.fullTable = !this.fullTable;
    }
    this.rowSelected = true;

    this.result = this.users.find(({ id }) => id === parseInt(localStorage.getItem('selectedUserId')));
    
    console.log(" -> subject user: " + JSON.stringify(this.result));

    this.subjectEmail = this.result.email;

    //Set vars
    //immutable
    firstName = this.result.firstName;
    lastName = this.result.lastName;
    email = this.result.email;
    contactNo = this.result.contactNo;
    //mutable
    organisation = this.result.organization == "" ? "" : this.result.organization;
    jobTitle = this.result.jobTitle == "" ? "" : this.result.jobTitle;
    department = this.result.department == "" ? "" : this.result.department;
    lineManager = this.result.lineManagerName == "" ? "" : this.result.lineManagerName;
    lineManagerContactNo = this.result.lineManagerContactNo == "" ? "" : this.result.lineManagerContactNo;

    this.US=this.result.status;

    //if status = 6 () get update info
    if (this.result.status == 6) {
      let JSession=this.cookieService.get("JSESSIONID");
      this.accountService.getUpdateRequest(this.result.email, JSession)
        .subscribe(data => {
          console.log("ROLES --- " + JSON.stringify(data.update.changes.change))

          let changes = []
          //check only 1 entry
          if (data.update.changes.change[0] == undefined) {  //only 1 item returned so no array
            // changes = [data.update.changes.change]  //make single item into array
            // changes.push(data.update.changes.change);
            console.log("single update change");

            if (data.update.changes.change.type == 1) {
              console.log("type: 1");
              this.roleUpdate = true;
              let roleId = data.update.changes.change.value;
              this.oldRole = this.suName;
              this.newRole = ((this.dropdownListRoles.find(({ id }) => id === roleId).name));
              console.log(this.newRole);
            } else {
              console.log("type: 2");
              this.ccUpdate = true;
              //new ccs
              let ccId = data.update.changes.change.value;
              this.newCCs = ((this.dropdownCCList.find(({ id }) => id === ccId)).name);
            }

          } else {  //array of items returned
            changes = data.update.changes.change;
            console.log("many update changes");
            // //roles
            // //if first 
            if (data.update.changes.change[0].type == 1) {
              this.roleUpdate = true;
              this.oldRole = this.suName;
              let roleId = data.update.changes.change[0].value;
              this.newRole = ((this.dropdownListRoles.find(({ id }) => id === roleId).name));
              console.log(this.newRole);

              //check cc changes from change[1]
              this.ccUpdate = true;
              this.ccChanges = [];
              for (let i = 1; i < (data.update.changes).length - 1; i++) {
                let ccId = data.update.changes[i];
                this.ccChanges.push((this.dropdownCCList.find(({ id }) => id === ccId)).name);
              }
            }
            else {  //there are no role changes
              this.ccUpdate = true;
              this.ccChanges = [];
              for (let i = 0; i < (data.update.changes).length - 1; i++) {
                let ccId = data.update.changes[i];
                this.ccChanges.push((this.dropdownCCList.find(({ id }) => id === ccId)).name);
              }
            }
          }
        },
          error => {
          }
        );
    }

    //first hide everything
    let siteResult;
    siteResult = this.dropdownListLocs.find(({ id }) => id === this.result.locationType);
    console.log("siteResult " + JSON.stringify(siteResult));

    // let chosenCustomer = "";
    this.customerId = this.result.customer.id;
    this.customerCode = this.result.customer.code;
    this.customerName = this.result.customer.name;

    if(this.singleUser==true){
      this.selectedGroup = this.suGID;
      this.group = this.selectedGroup;
      this.userRole = (this.suID);
      this.selectedRole = this.suID;
    }
    else{
      this.selectedGroup = this.result.role.groupId;
      this.group = this.selectedGroup;
      this.userRole = (this.result.role.id);
      this.selectedRole = this.result.role.id;
      this.suGName=this.result.role.groupName;
      this.suName=this.result.role.name;
    }

    this.locType = this.result.locationType;
    this.locId = this.result.locationId;
    this.selectedSite = this.result.locationType;
    this.selectedLoc = parseInt(this.result.locationId);
    this.customer = this.result.customer;

    console.log("test")

    if(this.group==3){//Merchant user
      this.merchStatus=this.result.status;
      
      let clearTable;
      clearTable=$('#activeMerchTable').DataTable();
      clearTable.clear().draw();
    }
    else{
      this.hideAddSitebtn=true;
    }

    if((this.group==3 && this.result.status==2) && this.merchUpdate==false){//Pending Merchant User
      this.hideSiteTable=false;
      this.hideAddSitebtn=false;

      this.hideEditSitesBtn=true;
      this.hideCancelSiteEditBtn=true;
      this.hideCheckSitesBtn=true;
      this.hidesaveUnlinkBtn=true;

    }

    else{
      this.hideSiteTable=true;
      this.hideAddSitebtn=true;
    }

    if((this.group==3 && this.result.status==77) && this.merchUpdate==false){//Admin Onboarded Merchant

      this.isActiveMerch=true;
      this.hideAddSitebtn=true;
      this.AOM=true;
      this.hideSiteTable=true;
      this.hideActiveTable=false;

      this.hideEditSitesBtn=true;
      this.hideCancelSiteEditBtn=true;
      this.hideCheckSitesBtn=true;
      this.hidesaveUnlinkBtn=true;

      this.linkedSites.splice(0);
      this.addArray.splice(0);

      console.log("USER IS ACTIVE MERCHANT");

      let js=this.cookieService.get("JSESSIONID");
      this.accountService.getUserSites(this.result.email,js)
          .subscribe((data :any) => {
            this.siteLength=data.user.sites.site.length;
            console.log('numSites - '+this.siteLength);

            if(this.siteLength==undefined){
              console.log("ONLY 1 SITE LINKED");
              this.siteLength=1;
              let x=new Site;
              x.id=data.user.sites.site.id;
              x.name=data.user.sites.site.name;
              x.code=data.user.sites.site.citCode;
              x.accNum=data.user.sites.site.accountNo;
              x.ccName=data.user.sites.site.cashCentreName;
              
              let id=parseInt(data.user.sites.site.id);
              this.addArray.push(id);

              console.log('Single site - '+JSON.stringify(x));
              this.linkedSites.push(x);

              console.log("ID ARRAY - "+this.addArray);

              setTimeout(() => {
                siteTable = $('#activeMerchTable').DataTable({
                  initComplete: function () {
                  },
                  retrieve: true,
                }).rows.add(this.linkedSites).draw();
              },);
            }

            else
            {
              console.log("MULTIPLE SITES LINKED");
              for (let i = 0; i <this.siteLength ; i++) {
                let x=new Site;
                x.id=data.user.sites.site[i].id;
                x.name=data.user.sites.site[i].name;
                x.code=data.user.sites.site[i].citCode;
                x.accNum=data.user.sites.site[i].accountNo;
                x.ccName=data.user.sites.site[i].cashCentreName;

                let id=parseInt(data.user.sites.site[i].id);
                this.addArray.push(id);

                console.log('Single site - '+JSON.stringify(x));
                this.linkedSites.push(x);
              }
              console.log("ID ARRAY - "+this.addArray);

              setTimeout(() => {
              siteTable = $('#activeMerchTable').DataTable({
                initComplete: function () {
                },
                retrieve: true,
              }).rows.add(this.linkedSites).draw();
            },);
          }

          },
            error => {
              console.log("ERROR FETCHING SITES");
            });

            //this.linkedSites.reverse();
    }
    
    // else{//Not a merchant
    //   this.isActiveMerch=false;
    //   //this.hideAddSitebtn=true;
    // }


    this.userSelectedGroup=this.selectedGroup;
    console.log("Group of selected user : "+this.userSelectedGroup);
    console.log("Role of user logged in : "+this.currentUserRole);

    if(this.currentUserRole==6 && this.selectedGroup==2){
      this.bsa=true;
      console.log("admin viewing bank user update")
    }
    else{
      console.log("admin viewing non-bank user update")
      this.bsa=false;
    }

    this.showRegions = false;
    this.showCCList = false;
    this.showSiteTypes = false;
    this.showChosenCustomer = false;

    let groupItem;

    //---------------------------------------------------------------------------
    if (this.selectedGroup == 1) {  //SBV USER
      groupItem = [{ "id": 1, "name": "SBV User", "description": "" }]

      //show site types
      this.showSiteTypes = true;
      siteName = this.dropdownListLocs.find(({ id }) => id === this.result.locationType);

      //get type id then show reg or cc
      if (this.locType == 1) {
        this.showRegions = true;
        regionName = this.dropdownListRegions.find(({ id }) => id === this.result.locationId);
      }
      if (this.locType == 2) {
        this.showCCList = true;
        ccName = this.dropdownCCListSingle.find(({ id }) => id === this.result.locationId);
      }
      this.showCCs = true;
    }
    if (this.selectedGroup == 2) { //BANK USER
      groupItem = [{ "id": 2, "name": "SBV User", "description": "" }]

      //show bank customers
      //-- already shows coz group is 3
      //show site types
      this.showSiteTypes = true;
      siteName = this.dropdownListLocs.find(({ id }) => id === this.result.locationType);
      //then based on site type show reg or cc or none
      if (this.locType == 1) {
        this.showRegions = true;
        regionName = this.dropdownListRegions.find(({ id }) => id === this.result.locationId);
      }
      if (this.locType == 2) {
        this.showCCList = true;
        ccName = this.dropdownCCListSingle.find(({ id }) => id === this.result.locationId);
      }

      bank = this.dropdownListBanks.find(({ id }) => id === this.result.customer.id);
      console.log(" this.result.customer.id " + this.result.customer.id)
      console.log("bank " + bank)

      if (this.result.customer.id > 0) {
        this.busCustExists = true;
      }

      this.showCCs = true;
    }
    if (this.selectedGroup == 3) { //MERCHANT USER
      groupItem = [{ "id": 3, "name": "Merchant", "description": "" }]

      //show customer autocomplete label
      //-- already does coz group is 2
      this.showCCs = false;
      this.showChosenCustomer = true;
      chosenCustomer = this.result.customer.name == "" ? "" : this.result.customer.code + " - " + this.result.customer.name;

      if (this.result.customer.id > 0) {
        this.merchCustExists = true;
        //this.showChosenCustomer=false;
      }
    }

    console.log("chosen customer: " + chosenCustomer);

    groupArr = (this.selectedGroup).toString() == "" ? "" : JSON.parse('[{"id":' + this.selectedGroup + ',"name":"' + this.suGName + '", "description": "" }]');
    roleArr = (this.selectedRole).toString() == "" ? "" : JSON.parse('[{"id":' + this.selectedRole + ',"name":"' + this.suName + '"}]');

    if(this.selectedSite!= -1){ 
      siteName=this.dropdownListLocs.find(({ id }) => id === this.result.locationType);
      //siteName=null;
      console.log("Not pending user")
    }
    else{
      siteName="";
      console.log("Pending user")
    }

    if(String(this.result.customer.id) != ""){
      bankName = this.dropdownListSingleBanks.find(({ id }) => id === this.result.customer.id);
      console.log("Not a pending bank user")
    }
    else{
      bankName="";
      console.log("Pending bank user");
    }

    if(this.userRole == 31){
      this.isFMU=true;
    }
    else{
      this.isFMU=false;
    }

    if(this.userRole == 33){
      this.hideLevel=true;
    }
    else{
      this.hideLevel=false;
    }

    if(this.userRole==26){
      this.hideMerchBox=false;
    }
    else{
      this.hideMerchBox=true;
    }

    if( this.userRole==26 ||  this.userRole==27  ||  this.userRole==28  ||  this.userRole==29  ||  this.userRole==30){
      
      this.isMultiBank=true;
      //this.hideComponents=true;
      this.hideMultiBank=false;
      this.multiBanks.splice(0);

      if(this.userRole==26 || this.userRole == 30){
        this.userDropdownHeading = "Customer Groups"
        this.dropdownListBanks=[
          { id: 1, name: "ABSA" },
          { id: 2, name: "Nedbank" },
          { id: 3, name: "First National Bank" },
          { id: 4, name: "Standard Bank South Africa" },
          { id: 5, name: "African Bank" },
          { id: 6, name: "Capitec" },
          { id: 0, name: "Merchants"}
        ];
      }
      else{
        this.dropdownListBanks=[
          { id: 1, name: "ABSA" },
          { id: 2, name: "Nedbank" },
          { id: 3, name: "First National Bank" },
          { id: 4, name: "Standard Bank South Africa" },
          { id: 5, name: "African Bank" },
          { id: 6, name: "Capitec" }
        ];
      }

      let js=this.cookieService.get("JSESSIONID");
      this.accountService.getUserBanks(this.result.email,js)//this.groupId) // item
      .subscribe(data => {
        console.log("profiles - "+JSON.stringify(data));
       

        if(data.user.profiles.profile.length == undefined){
          this.selectedBanks.push(data.user.profiles.profile.targetId);
        }
        else{
          for(let i : number = 0; i < data.user.profiles.profile.length; i++){

            if(data.user.profiles.profile[i].targetId == 0){
              //this.ccMerchAccess=true;
              console.log("Merchant Linked")
            }
  
            
            this.selectedBanks.push(data.user.profiles.profile[i].targetId);
            
          }
        }
        

        console.log("selectedBanks: " + JSON.stringify(this.selectedBanks));

        for(let i : number = 0; i < this.selectedBanks.length; i++){
          let b=new Bank;

          for(let j : number = 0; j < this.dropdownListBanks.length; j++){
            
            if(this.selectedBanks[i]==this.dropdownListBanks[j].id){
              b.id = this.selectedBanks[i];
              b.name = this.dropdownListBanks[j].name;
              this.sBanks.push(b);
              this.selectedBankNames.push(b.name);
              break;
          }    
          this.multiBanks=this.selectedBanks;
        }
      }

      this.multiBanks=this.selectedBanks;
      console.log("sBanks - "+JSON.stringify(this.sBanks));
      console.log("Bank Names - "+JSON.stringify(this.selectedBankNames));

      this.form = this.formBuilder.group({
        customerText: [chosenCustomer],
        //banks: [[bank]],
        //regions: [[regionName]],
        //siteTypes: [[siteName]],
        //ccSingle: [[ccName]],
        name: [firstName],
        surname: [lastName],
        email: [email],
        contactNo: [contactNo],
        organisation: [organisation],
        group: [groupArr],
        role: [roleArr],
        jobTitle: [jobTitle],
        department: [department],
        lineManager: [lineManager],
        lineManagerContactNo: [lineManagerContactNo],
        comments: '',
        linkedBanks: [this.sBanks],
      });


      let a = [];
      if (data.user.profiles.profile.length == undefined || data.user.profiles.profile.length==1) {
          this.multiBanks.splice(0);
          this.singleBankreturned = true;
          let singleBank = new Bank;
          if(data.user.profiles.profile.targetId == 0){
            this.ccMerchAccess=true;
          }
      
          let name=((this.dropdownListBanks.find(({ id }) => id === data.user.profiles.profile.targetId).name));
          console.log("Bank Name - "+name);
          singleBank.id=data.user.profiles.profile.targetId;
          singleBank.name=name;
          console.log("1 bank"+JSON.stringify(singleBank));

          a.push(singleBank);
          this.sBanks = JSON.parse("[" + (JSON.stringify(singleBank)) + "]");
          this.sBanks = a;
          this.multiBanks.push(singleBank.id);
          this.SB=a;
          
          

          this.form = this.formBuilder.group({
            customerText: [chosenCustomer],
            //banks: [[bank]],
            //regions: [[regionName]],
            //siteTypes: [[siteName]],
            //ccSingle: [[ccName]],
            name: [firstName],
            surname: [lastName],
            email: [email],
            contactNo: [contactNo],
            organisation: [organisation],
            group: [groupArr],
            role: [roleArr],
            jobTitle: [jobTitle],
            department: [department],
            lineManager: [lineManager],
            lineManagerContactNo: [lineManagerContactNo],
            comments: '',
            linkedBanks: [a],
          });

      }


      },
        error => {

           // if (error.status==0){
          //   this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
          //   this.hideErrorBanner=false;
          // }

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          this.alertService.error(error.error.error.message);
        });

        
    }
    else{
      this.isMultiBank=false;
      this.hideComponents=false;
      this.hideMultiBank=true;
    }

    console.log("--?")
    this.form = this.formBuilder.group({
      customerText: [chosenCustomer],
      banks: [[bank]],
      regions: [[regionName]],
      siteTypes: [[siteName]],
      ccSingle: [[ccName]],
      name: [firstName],
      surname: [lastName],
      email: [email],
      contactNo: [contactNo],
      organisation: [organisation],
      group: [groupArr],
      role: [roleArr],
      jobTitle: [jobTitle],
      department: [department],
      lineManager: [lineManager],
      lineManagerContactNo: [lineManagerContactNo],
      comments: '',
      cashCentres: []
    });

    if (this.selectedGroup != 3) {
      let JSession=this.cookieService.get("JSESSIONID");
      this.accountService.getUserCashCentres(this.result.email, JSession)
        .subscribe(data => {
          this.ccLoading = true;
          this.selectedCCItems = data.user.cashCentres.cashCentre;
          this.originalCCs = this.selectedCCItems;
          let a = [];
          if (this.selectedCCItems.length == undefined) {
            a.push(this.selectedCCItems)
            this.selectedCCItems = JSON.parse("[" + (JSON.stringify(this.selectedCCItems)) + "]");
            this.selectedCCItems = a;
          }
          if (this.selectedGroup == 2) {
            console.log("hello");
          }

          this.form = this.formBuilder.group({
            customerText: [chosenCustomer],
            banks: [[bank]],
            regions: [[regionName]],
            siteTypes: [[siteName]],
            ccSingle: [[ccName]],
            name: [this.result.firstName],
            surname: [this.result.lastName],
            email: [this.result.email],
            contactNo: [this.result.contactNo],
            organisation: [this.result.organization == "" ? "" : this.result.organization, Validators.required],
            group: [groupArr],
            role: [roleArr],
            jobTitle: [this.result.jobTitle],
            department: [this.result.department],
            lineManager: [this.result.lineManagerName],
            lineManagerContactNo: [this.result.lineManagerContactNo],
            comments: '',
            cashCentres: [this.selectedCCItems.length == 1 ? a : this.selectedCCItems]
          });

          this.selectedCCItems = [];

          for (let i = 0; i < data.user.cashCentres.cashCentre.length; i++) {
            this.selectedCCItems.push(data.user.cashCentres.cashCentre[i].id);
            console.log(data.user.cashCentres.cashCentre[i].id);
          }

          this.updatedUser = new UpdatedUser;
          this.updatedUser.email = this.result.email;

          console.log("this.f.name.value: " + this.form.controls.name.value)
          console.log("original updatedUser: " + JSON.stringify(this.updatedUser));

          let contact = new Contact;
          contact.contactNo = this.result.contactNo;
          contact.jobTitle = this.result.jobTitle;
          contact.department = this.result.department;
          contact.lineManagerName = this.result.lineManagerName;
          contact.lineManagerContactNo = this.result.lineManagerContactNo;

          let roles: Roles[] = [];
          let rs = new Roles;
          let r = new Role;
          r.id = this.selectedRole;
          rs.role = r;
          roles.push(rs);

          this.updatedUser.contact = contact;
          this.updatedUser.roles = roles;

          this.ccReady = true;
          this.ccLoading = false;
        },
          error => {
            this.alertService.error(error.error.error.message);
          }
        );
    }
    this.ccLoading = false;
    console.log("USER CCS after: " + JSON.stringify(this.selectedCCItems))

    //populate roles list
    if (this.selectedGroup != 3) {
      this.accountService.getRoles(this.selectedGroup)//this.groupId) // item
        .subscribe(data => {
          this.dropdownListRoles = data.roles.role;
        },
          error => {
            this.alertService.error(error.error.error.message);
          });
    } else {
      this.dropdownListRoles = [{ id: 11, name: 'Direct User', description: '' }];
    }

    this.fullName = this.result.firstName + ' ' + this.result.lastName;
    this.organisation = this.result.organization;
    this.status = this.getStatus(this.result.status);
    this.theStatusValue = this.result.status;
    console.log('STATUS ' + this.result.status);

    let table = $('#userTable').DataTable();
    table.column(2).visible(false);
    table.column(3).visible(false);
    table.column(5).visible(false);
  }//end of show details

  isMerchant: boolean;

  getStatus(status) {
    if (status === 0) {
      return 'Disabled';
    }
    if (status === 1) {
      return 'In Flight';
    }
    if (status === 2) {
      return 'SBV Pending';
    }
    if (status === 3) {
      return 'Active';
    }
    if (status === 4) {
      return 'Bank Pending';
    }
    if (status === 5) {
      return 'Rejected';
    }
    if (status === 6) {
      return 'Update';
    }
    if (status === 77) {
      return 'SBV Pending';
    }
  }

  onSiteTypeDeselect() {
    this.selectedSite = null;
    // this.siteTypeChosen = false;
  }

  onItemSelectMultiBank(item: any) {
    // console.log(item.id);
    if ((this.allSelectedMultiBank == false) || (!this.multiBanks.includes(item.id))) {
      this.multiBanks.push(item.id);
      // console.log(this.selectedItems);
    }
    console.log("BANKS - "+JSON.stringify(this.multiBanks));
  }

  onSelectAllMultiBanks(items: any[]) {
    
    if (items.length == this.multiBanks.length) {
      this.multiBanks = [];
      this.allSelectedMulti = false;
    }
    else {
      // this.selectedItems = items;
      for (let i = 0; i < items.length; i++) {
        if (!this.multiBanks.includes(items[i].id)) {
          this.multiBanks.push(items[i].id);
        }
      }
      this.allSelectedMultiBank = true;
    }
    console.log("BANKS - "+JSON.stringify(this.multiBanks));
    }

    onItemDeSelectMultiBank(item: any) {
      // console.log("role already present in array - will be removed");
      // console.log("B4: " + this.selectedItems);
      if (this.allSelectedMultiBank == true) {
        this.multiBanks.splice(item.id, 1);
      }
      this.multiBanks.forEach((element, index) => {
        if (element == item.id)
          this.multiBanks.splice(index, 1);
      });
      console.log("BANKS - "+JSON.stringify(this.multiBanks));
  
    }

    onItemDeSelectAllMultiBank(items: any[]) {
      this.multiBanks = [];
  
      console.log("BANKS - "+JSON.stringify(this.multiBanks));
    }

  onSingleCCDeselect() {
    this.selectedLoc = null;
    // this.ccSingleChosen = false;
  }

  onRegionDeselect() {
    this.selectedLoc = null;
    // this.regionChosen = false;
  }

  onCCItemSelect(item: any) {
    // console.log(item.id);
    if ((this.allSelected == false) || (!this.selectedCCItems.includes(item.id))) {
      this.selectedCCItems.push(item.id);
      // console.log(this.selectedItems);
    }
  }

  allSelected: boolean = false;
  allSelectedMulti: boolean = false;

  onCCSelectAll(items: any[]) {

    if (items.length == this.selectedCCItems.length) {
      this.selectedCCItems = [];
      this.allSelected = false;
    }
    else {
      // this.selectedItems = items;
      for (let i = 0; i < items.length; i++) {
        if (!this.selectedCCItems.includes(items[i].id)) {
          this.selectedCCItems.push(items[i].id);
        }
      }
      this.allSelected = true;
    }
    // console.log(this.selectedItems);
  }

  onCcItemDeSelect(item: any) {
    console.log("DEselecting CC")
    console.log(this.selectedCCItems)
    if (this.allSelected == true) {
      this.selectedCCItems.splice(item.id, 1);
    }
    this.selectedCCItems.forEach((element, index) => {
      if (element == item.id)
        this.selectedCCItems.splice(index, 1);
    });
    console.log(this.selectedCCItems)
    // console.log("After: " + this.selectedItems);

  }

  onCCItemDeSelectAll(items: any[]) {
    this.selectedCCItems = [];
  }

  get f() { return this.form.controls; }

  approveUser() {
    console.log("approving user")
    console.log("Customer ID" + this.customerId)
    console.log("Selected Site" + this.selectedSite)

    this.acceptLoading = true;

    // VALIDATION
    this.regionChosen = true;
    this.ccSingleChosen = true;
    this.siteTypeChosen = true;
    this.customerChosen = true;

    // Form validation for site and org
    // if (this.form.invalid) {
    if (this.f.organisation.value == '') {
      console.log("invalid");
      // this.orgRequired = true;
      this.orgChosen = false;
      this.acceptLoading = false;
      return;
    }

    if(this.selectedGroup==3 &&this.US==77){
      this.aomDisplay();
      this.invalidSites=false;
    }

    if(this.selectedGroup==3 && (this.displayArray.length==0 || this.displayArray.length==null || this.displayArray.length==undefined)){
      this.invalidSites=true;
      this.acceptLoading=false;
      return;
    }
    else if(this.selectedGroup==3 &&this.US==77){
      this.invalidSites=false;
    }
    else{
      this.invalidSites=false;
    }

    if (this.selectedGroup == 2) {  //Bank
      if (this.customerId == undefined || this.customerId == null || this.customerId == "") {
        this.customerChosen = false;
        console.log("bank customer undefined")
        this.acceptLoading = false;
        return;
      }
    }
    //if merchant, only customer is req., if sbv then site type req., if bank then customer and site type
    if (this.selectedGroup == 3) {  //MERCHANT
      console.log("Merch")
      //check if customer selected
      if (this.customerId == undefined || this.customerId == "") {
        // console.log("Cust undefined");
        // this.customerChosen = false;
        // this.acceptLoading = false;
        // return;
        // this.siteInfoSaved = true;
      }
    } else { //SBV OR BANK
      console.log("Non merch")
      if (this.selectedSite == -1 || this.selectedSite == null || this.selectedSite == undefined) {
        console.log("Site type undefined");
        if( this.isFMU || this.hideLevel) {
          this.siteTypeChosen=true;
          this.acceptLoading = true;
          this.selectedSite=0;
          this.selectedLoc=0;
        }
        else{
          this.siteTypeChosen = false;
          this.acceptLoading = false;
          return;
          this.siteInfoSaved = true;
        }
      } 
      else {
        if (this.selectedSite == 1) { //if regional 
          if (this.selectedLoc == -1 || this.selectedLoc == undefined || this.selectedLoc == null) {
            // this.siteTypeChosen = false;
            this.regionChosen = false;
            this.acceptLoading = false;
            return;
            // this.siteInfoSaved = true;
          }
        }
        if (this.selectedSite == 2) { //if cc
          if (this.selectedLoc == -1 || this.selectedLoc == undefined || this.selectedLoc == null) {
            this.ccSingleChosen = false;
            this.acceptLoading = false;
            return;
          }
        }
        if(this.selectedSite==3 && this.selectedGroup==1){ //if site and SBV user
          console.log("Site and SBV user")
            this.selectedLoc=0;
        }
        if(this.selectedSite==3 && this.selectedGroup==2){//if site and bank user
          console.log("Site and Bank user")
          this.selectedLoc=this.customerId;
        }
      }
      //check if site type selected
    }

    console.log("validation complete")

    if (this.selectedGroup == 1) { //SBV
      this.customerId = 0;
    }

    let contact = new Contact;
    this.updatedUser = new UpdatedUser;
    this.updatedUserUser = new UpdatedUserUser;

    contact.firstName = this.f.name.value;
    contact.lastName = this.f.surname.value;
    contact.contactNo = this.f.contactNo.value;
    contact.jobTitle = this.f.jobTitle.value;
    contact.department = this.f.department.value;
    contact.organization = this.f.organisation.value;
    contact.lineManagerName = this.f.lineManager.value;
    contact.lineManagerContactNo = this.f.lineManagerContactNo.value;
    contact.locationType = this.selectedSite;
    contact.locationId = this.selectedLoc;

    let customer = new Customer;
    customer.id = this.customerId;
    customer.code = this.customerCode;
    customer.name = this.customerName;

    let c = new Customer;
    let BanksList:Profiles[]=[];

    let sites: Sites[] = [];
    let p=new Profile;

    if(this.isMultiBank==true){
      for(let i = 0; i < this.multiBanks.length; i++) {
        let child = new Profiles;
        let b = new myProfiles.Profile;
        b.targetId = this.multiBanks[i];
        b.typeId=1;
        child.profile=b;
        BanksList.push(child);
      }

      console.log("Bank Array Created - "+JSON.stringify(BanksList));

      if(this.hideMerchBox==false && this.allowMerchants==true){
        let m=new myProfiles.Profile;
        let child=new Profiles();
        m.targetId=0;
        m.typeId=2;
        child.profile=m;
        BanksList.push(child);
      }

      p.profiles=BanksList;
      this.updatedUser.profile=p;

    }

    console.log("FLAG 1");
    console.log("Sites - "+JSON.stringify(this.displayArray));

    if(this.selectedGroup==3){//Merchant
      //let sites: Sites[] = [];
      //customer.id = this.customerId;
      c.id=this.displayArray[0].group;

    for (let i : number = 0; i < this.addArray.length; i++) {
      let child = new Sites;
      let b = new Site;
      if (this.displayArray[i].id == null) {
        b.id = this.displayArray[i];
      } else {
        b.id = this.displayArray[i].id;
      }
      child.site=b;
      sites.push(child);
    }
    console.log("SITES - "+JSON.stringify(sites));
    //let p=new Profile;
    p.sites=sites;
    this.updatedUser.profile=p;
    }

    console.log("FLAG 2");

    if(this.selectedGroup==3){
      this.updatedUser.customer = c;
    }
    else {
      this.updatedUser.customer = customer;
        }

    //this.updatedUser.customer = customer;
    this.updatedUser.email = this.f.email.value;
    this.updatedUser.contact = contact;
    this.updatedUserUser.user = this.updatedUser;

    console.log("approveUser(): " + JSON.stringify(this.updatedUserUser))
    // return;

    let Jsession=this.cookieService.get("JSESSIONID");
    this.accountService.updateUserContact(this.updatedUserUser, Jsession)
      .subscribe(data => {

      },
        error => {
          this.acceptLoading = false;
          this.errorMsg = error.error.error.message;
          this.showBusinessError = true;
        });


    // if (this.siteInfoSaved == true) {
    //check if user is sbv or not.
    //if sbv then status -> 3 else status -> 4
    let newStatus;
    console.log("this.currentUserRole " + this.currentUserRole);
    console.log("this.selectedGroup " + this.selectedGroup);

    //IF current user ADMIN and subject user both SBV then 3
    //IF current user SBV ADMIN and subject is bank then 4
    //IF current user SBV ADMIN and subject merchant then 3
    //IF current user Bank ADMIN then 3
    // if (this.selectedGroup == 1) {  //is SBV
    if (this.currentUserRole == 6 && this.selectedGroup == 1) {
      newStatus = {
        "user": {
          "email": this.f.email.value,
          "status": 3
        }
      }
    } else if (this.currentUserRole == 6 && this.selectedGroup == 2 && this.userRole != 22) { //sbv approving a bank user
      newStatus = {
        "user": {
          "email": this.f.email.value,
          "status": 4
        }
      }
    } else if (this.currentUserRole == 6 && this.selectedGroup == 3) { //sbv approving a merchant user
      newStatus = {
        "user": {
          "email": this.f.email.value,
          "status": 3
        }
      }
    } else if (this.currentUserRole == 22) { //bank admin user approving a bank user
      newStatus = {
        "user": {
          "email": this.f.email.value,
          "status": 3
        }
      }
    } else if (this.currentUserGroup == 3 && this.userRole == 22) { //bank group approving a bank user
      newStatus = {
        "user": {
          "email": this.f.email.value,
          "status": 3
        }
      }
    } else if (this.currentUserRole == 6 && this.selectedGroup == 2 && this.userRole == 22) {
      newStatus = {
        "user": {
          "email": this.f.email.value,
          "status": 3
        }
      }
    }

    console.log(JSON.stringify(newStatus))
    // return;

    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.updateUserStatus(newStatus, JSession)
      .subscribe(data => {
        this.acceptLoading = true;
        console.log(data)
        window.location.reload();
      },
        error => {
          this.acceptLoading = false;
          this.alertService.error("User not updated");
          this.errorMsg = error.error.error.message;
          this.showPageError = true;
          // this.acceptLoading = false;
        });
    this.acceptLoading = false;
  }

  rejectUser() {
    let newStatus = {
      "user": {
        "email": this.f.email.value,
        "status": 5
      }
    }
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.updateUserStatus(newStatus, JSession)
      .subscribe(data => {
        window.location.reload();
      },
        error => {
          this.errorMsg = error.error.error.message;
          this.showPageError = true;
        });
  }

  deactivateUser() {
    let newStatus = {
      "user": {
        "email": this.f.email.value,
        "status": 0
      }
    }
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.updateUserStatus(newStatus,JSession)
      .subscribe(data => {
        location.reload();
      },
        error => {
          this.errorMsg = error.error.error.message;
          this.showPageError = true;
        });
  }

  activateUser() {
    let newStatus = {
      "user": {
        "email": this.f.email.value,
        "status": 3
      }
    }
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.updateUserStatus(newStatus, JSession)
      .subscribe(data => {
        window.location.reload();
      },
        error => {
          this.errorMsg = error.error.error.message;
          this.showPageError = true;
        });
  }

  reinstateUser() {
    let newStatus = {
      "user": {
        "email": this.f.email.value,
        "status": 2
      }
    }
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.updateUserStatus(newStatus, JSession)
      .subscribe(data => {
        location.reload();
      },
        error => {
          this.errorMsg = error.error.error.message;
          this.showPageError = true;
        });
  }

  savePersonalDetails() {
    let contact = new Contact;
    this.updatedUser = new UpdatedUser;
    this.updatedUserUser = new UpdatedUserUser;

    contact.contactNo = this.f.contactNo.value;

    if (this.selectedGroup == 1) { //SBV
      this.customerId = 0;
    }

    this.updatedUser.email = this.subjectEmail;
    this.updatedUser.contact = contact;
    this.updatedUserUser.user = this.updatedUser;


    console.log("savePersonalDetails(): " + JSON.stringify(this.updatedUserUser))

    let Jsession=this.cookieService.get("JSESSIONID");
    this.accountService.updateUserContact(this.updatedUserUser, Jsession)
      .subscribe(data => {
        this.alertService.success("User updated");
        this.canEditPersonal = false;
        window.location.reload();
      },
        error => {
          this.errorMsg = error.error.error.message;
          this.showPersonalError = true;
        });
  }

  saveBusinessDetails() {
    let contact = new Contact;
    this.updatedUser = new UpdatedUser;
    this.updatedUserUser = new UpdatedUserUser;
    
    this.pageReload=false;

    contact.jobTitle = this.f.jobTitle.value;
    contact.department = this.f.department.value;
    contact.organization = this.f.organisation.value;
    contact.lineManagerName = this.f.lineManager.value;
    contact.lineManagerContactNo = this.f.lineManagerContactNo.value;
    contact.locationType = this.selectedSite;
    contact.locationId = this.selectedLoc;

    this.updatedUser.email = this.f.email.value;
    this.updatedUser.contact = contact;
    this.updatedUserUser.user = this.updatedUser;

    if (this.selectedGroup == 1) { //SBV
      this.customerId = 0;
    }

    let customer = new Customer;
    customer.id = this.customerId;
    customer.name = this.customerName;
    customer.code = this.customerCode;

    this.updatedUser.customer = customer;

    let profile = new Profile;
    profile.cashCentres = this.originalCCs;

    this.updatedUser.profile = profile;

    console.log("saveBusinessDetails(): " + JSON.stringify(this.updatedUserUser))

    let Jsession=this.cookieService.get("JSESSIONID");
    this.accountService.updateUserContact(this.updatedUserUser, Jsession)
      .subscribe(data => {
        this.alertService.success("User updated successfully");
        this.canEditBusiness = false;

        let roles: Roles[] = [];
        let rs = new Roles;
        let r = new Role;
        r.id = this.selectedRole;
        rs.role = r;
        roles.push(rs);

        this.updatedUser.roles = roles;
        this.updatedUserUser.user = this.updatedUser;

        let Jsession=this.cookieService.get("JSESSIONID");
        this.accountService.updateUserRoles(this.updatedUserUser, Jsession)
          .subscribe(data => {
            this.alertService.success("User updated successfully");
             window.location.reload();
          },
            error => {
              this.errorMsg = error.error.error.message;
              this.showBusinessError = true;
            }
          );
        //window.location.reload();
      },
        error => {
          this.errorMsg = error.error.error.message;
          this.showBusinessError = true;
        }
      );
  }

  saveCCDetails() {
    let profile = new Profile;
    let contact = new Contact;
    this.updatedUser = new UpdatedUser;
    this.updatedUserUser = new UpdatedUserUser;

    contact.jobTitle = this.f.jobTitle.value;
    contact.department = this.f.department.value;
    contact.organization = this.f.organisation.value;
    contact.lineManagerName = this.f.lineManager.value;
    contact.lineManagerContactNo = this.f.lineManagerContactNo.value;
    contact.locationType = this.locType;
    contact.locationId = this.locId;

    let CCs: CashCentres[] = [];

    //PROFILE HAS ARRAY OF CASH CENTRES
    // let c = new CashCentres;
    for (let i = 0; i < this.selectedCCItems.length; i++) {
      let child = new CashCentres;
      let b = new CashCentre;
      if (this.selectedCCItems[i].id == null) {
        b.id = this.selectedCCItems[i];
      } else {
        b.id = this.selectedCCItems[i].id;
      }
      child.cashCentre = b;
      CCs.push(child);
    }
    profile.cashCentres = CCs;

    this.updatedUser.profile = profile;
    this.updatedUser.email = this.f.email.value;
    this.updatedUser.contact = contact;
    this.updatedUserUser.user = this.updatedUser;

    console.log("saveCCDetails(): " + JSON.stringify(this.updatedUserUser))

    let Jsession=this.cookieService.get("JSESSIONID");
    this.accountService.updateUserCC(this.updatedUserUser, Jsession)
      .subscribe(data => {
        this.alertService.success("User updated successfully");
        this.canEditCC = false;
        window.location.reload();
      },
        error => {
          this.errorMsg = error.error.error.message;
          this.showCcError = true;
        }
      );
  }

  getServerResponse(event) {

    console.log(event);
    this.isLoadingResult = true;

    let jSession=this.cookieService.get("JSESSIONID");
    console.log(jSession);

    this.accountService.getCustomerAutocomplete(10, event, jSession)
      .subscribe(data => {
        console.log(data['customers'].customer)
        if (data['customers'].customer == undefined) {
          console.log("undefined " + data)
          this.data = [];
        } else {
          this.data = data['customers'].customer;
        }
        this.isLoadingResult = false;
      });
  }

  searchCleared() {
    console.log('searchCleared');
    this.data = [];
    this.customerId=undefined;
    this.customerChosen=false;
  }

  onFocused(e) {
    // do something when input is focused
  }

  selectEvent(item) { //autocomplete
    // do something with selected item
    console.log(item)
    this.customerId = item.id;
    this.customerName = item.name;
    this.customerCode = item.code;
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onOptionsSelected() {
    console.log(this.selected);
    this.filtered = this.locations.filter(t => t.id == this.selected);
  }

  showSites(){
    if(this.merchStatus==3){
  
      this.testLoad2();
    }
    else{
      console.log("Dont load, pending user");
    }

   
    localStorage.setItem("perform","add");
    this.hideSiteBanner=false;
  }

  testLoad2(){

    
    //this.sites.splice(0);
    this.userSites.splice(0);
    this.SCSites.splice(0); //All sites excluding those linked to the user 

    let siteTable;

    siteTable=$('#addTable').DataTable();
    siteTable.clear().draw();

    let addTable;
    
    let Js= this.cookieService.get("JSESSIONID");
    this.accountService.getSites(Js)
    .subscribe(data => {
      //console.log(JSON.stringify(data));
      this.numSites=data.sites.site.length;
      for (let i: number =0; i < this.addArray.length; i++) {
        
        for (let x: number =0; x < data.sites.site.length; x++) {

          if(this.addArray[i]==data.sites.site[x].id){//SITE IS LINKED TO USER SO KEEP IN SEPARATE ARRAY
            console.log("SITE - "+(data.sites.site[i].name)+" IS LINKED TO USER");
            let ls =new Site;
            ls.id=data.sites.site[x].id;
            ls.group=data.sites.site[x].customerId;
            ls.name=data.sites.site[x].name;
            ls.code=data.sites.site[x].citCode;
            ls.accNum=data.sites.site[x].accountNo;
            ls.cc=data.sites.site[x].cashCentreId;
            ls.groupName=data.sites.site[x].customerName;
            ls.ccName=data.sites.site[x].cashCentreName;
            ls.status=data.sites.site[x].status;
            this.userSites.push(ls);
            //this.SGId=data.sites.site[x].customerId;
          }

        }
         
      }

      console.log("USER SITES - "+JSON.stringify(this.userSites));
      //console.log("SGId - "+this.SGId);

      for (let i: number =0; i < this.addArray.length; i++) {
        
        for (let x: number =0; x < this.sites.length; x++) {

         if(this.addArray[i]==this.sites[x].id){
          console.log("SITE LINKED - "+JSON.stringify(this.sites[x]));
          this.sites.splice(x,1);
         }

        }
         
      }

      setTimeout(() => {
        addTable = $('#addTable').DataTable({
          initComplete: function () {
            if (!this.aFullTable) {
            }
          },
          retrieve: true,
        }).rows.add(this.sites).draw();
      }, 2000);

     },

     error => {
      console.log("ERROR");
     });
 
  }

  xButton(){
    this.hideSiteBanner=true;
    this.addArray.splice(0);
  }

  xButtton(){
    window.location.reload();
}

  aomDisplay(){
    console.log("aomDisplay method called");

    this.displayArray.splice(0);
      console.log("Array length = "+this.addArray.length);
      
      for (let i: number =0; i < this.addArray.length; i++) {
        this.displayRes= this.sites.find(({ id }) => id === this.addArray[i]);
        this.displayArray.push(this.displayRes);
      }
  
      console.log("Display Array After Loop = "+JSON.stringify(this.displayArray));

  }

  displayAS(){

    this.hideActiveTable=true;
    this.sitesChange=true;
    
    this.hideSiteTable=false;
    this.hideSiteBanner=true;

    if(this.isActiveMerch){
      console.log("Do not clear display Array");
      this.displayArray.splice(0);
    }
    else{
      this.displayArray.splice(0);
    }
    

    console.log("Array length = "+this.addArray.length);
    console.log("Display Array = "+JSON.stringify(this.displayArray));

    $('#siteTable').on('click', 'tr', function () {
      console.log("SITE CLICKED");
    });

    let siteTable;

    siteTable=$('#siteTable').DataTable();
    siteTable.clear().draw();

    if(this.isActiveMerch){
      for (let i: number =0; i < this.addArray.length; i++) {
        this.displayRes= this.sites.find(({ id }) => id === this.addArray[i]);

        if(this.displayRes==null){
          this.displayRes= this.userSites.find(({ id }) => id === this.addArray[i]);
        }
        this.displayArray.push(this.displayRes);
    }

  }

  else{
    for (let i: number =0; i < this.addArray.length; i++) {
    this.displayRes= this.sites.find(({ id }) => id === this.addArray[i]);
    this.displayArray.push(this.displayRes);
  }
}

    console.log("Display Array After Loop = "+JSON.stringify(this.displayArray));


    setTimeout(() => {
      siteTable = $('#siteTable').DataTable({
        initComplete: function () {
        
        },
        retrieve: true,
      }).rows.add(this.displayArray).draw();
    }, 2000);
  }

  addLS(){

    console.log("SITE BEING ADDED/REMOVED");
    let x=parseInt(localStorage.getItem("slectedAddSite"));
    console.log("X = "+x);

    if(this.addArray.includes(x)){
      const i=this.addArray.indexOf(x);
      if(i!==-1){
        this.addArray.splice(i,1);
      }
    }
    else{
      this.addArray.push(x);
    }
    console.log("AddArray after method -- "+this.addArray);

  }

}