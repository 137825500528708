<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid">
                <div class="d-flex justify-content-between align-items-end">
                    <h3>Password Reset</h3>
                    <p class="purple">Verify Email</p>
                </div>
                <hr class="mt-0">
                <img src="assets/img/email-sent.png" height="80px">
                <h5 class="p-green">Password reset email sent!</h5>
                <p>An email has been sent to your inbox with a link to reset your password. Click on the link to
                    complete the
                    reset
                    process.</p>
                <p><small>If you haven't received an email, please check your spam folder.</small></p>
                <hr>
                <div class="d-flex justify-content-center">
                    <a class="text-align-centre p-2 blue-link"
                        [ngClass]="{'isDisabled' : resendNotAllowed, 'blue-link': !resendNotAllowed}"
                        [class.disabled]="resendNotAllowed" style="color: #589cd0" (click)="resend()">
                        Resend Password Reset Email
                        <countdown *ngIf="resendNotAllowed" [config]="{leftTime: 60}"></countdown>
                    </a>
                </div>
                <!-- <button id="resendBtn" class="rounded btn btn-primary w-100" [disabled]="resendAllowed">
            Resend Password Reset Email
        </button> -->
                <div class="d-flex justify-content-start me-5 pt-3 mb-4">
                    <a class=" black-link" routerLink="../login">Back</a>
                </div>
            </div>
        </div>
    </div>
</div>