import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-password-set-complete',
  templateUrl: './password-set-complete.component.html',
  styleUrls: ['./password-set-complete.component.scss']
})
export class PasswordSetCompleteComponent implements OnInit {

  constructor(
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }

  ngOnInit(): void {
  }

}
