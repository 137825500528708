<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid  align-items-end">
                <alert></alert>

            </div>
            <div class=" ms-5 me-5  d-flex justify-content-between  pt-4">
                <a class="p-2 black-link" routerLink="../../login">Back to login</a>
                <label class="p-2">1/3</label>
                <a class="p-2 blue-link" routerLink="../../help">Need help?</a>
            </div>
        </div>
    </div>
</div>