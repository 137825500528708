import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { AccountService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-super-user-login',
  templateUrl: './super-user-login.component.html',
  styleUrls: ['./super-user-login.component.scss']
})
export class SuperUserLoginComponent implements OnInit {

  userMail:string = "";

  newJSession:string = "";
  newToken:string = "";

  constructor(
    private cookieService: CookieService,
    private accountService: AccountService,
  ) { }

  ngOnInit(): void {

    this.userMail = sessionStorage.getItem('superUserSelectedMail'); // get the user email the super user sleected

  }

  performLogin(){
    sessionStorage.setItem('superUserMail',sessionStorage.getItem('email')); //save the super users email for log out 

    sessionStorage.setItem('superUserLogin','true'); // For Front end side
    this.cookieService.put("SUPERUSERLOGIN","true", {
      secure:true,
      sameSite:'none'
    }); // For Bonita Side 

    sessionStorage.setItem('superUserJsession',this.cookieService.get("JSESSIONID")); //Store super users orignial jsession
    sessionStorage.setItem('superUserToken',this.cookieService.get("X-Bonita-API-Token")); //Store super users bonita token

    // console.log("ORIGINAL JSESSION - "+this.cookieService.get("JSESSIONID"));
    // console.log("ORIGINAL TOKEN - "+this.cookieService.get("X-Bonita-API-Token"));

    let jsession=this.cookieService.get("JSESSIONID");
    let email=this.userMail;

    this.accountService.getSuperUserSession(email, jsession)
                        .subscribe(data => {
                         this.newJSession = data.root.jsessionId;
                         this.newToken = data.root.xBonitaAPIToken;

                         console.log("JSESSION - "+this.newJSession);
                         console.log("TOKEN - "+this.newToken);


                         this.cookieService.remove('JSESSIONID');
                         this.cookieService.remove('X-Bonita-API-Token');

                         this.cookieService.put('JSESSIONID', this.newJSession,{
                          secure:true,
                          sameSite:'none'
                        });
                        
                        this.cookieService.put('X-Bonita-API-Token', this.newToken,{
                          secure:true,
                          sameSite:'none'
                        });

                        sessionStorage.setItem('email',this.userMail); // set main mail to email of selected user 
                        sessionStorage.removeItem('superUserSelectedMail');
                        sessionStorage.removeItem('Page');
                        sessionStorage.removeItem('URL');

                        setTimeout(() => {
                          window.location.href = `./home`;
                              }, 100);

                        },

                        error => {
                            console.log(error)
                        });

    }

}
