<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid">

                <div class="d-flex justify-content-between align-items-end">
                    <h3>Login</h3>
                    <p class="purple">OTP</p>
                </div>
                <hr class="mt-0">
                <h5>One Time Pin</h5>
                <p>A One Time Pin (OTP) has been sent to your email. </p>
                <p>Please enter the OTP below</p>
                <hr>

                <alert></alert>
                <form [formGroup]="form">

                    <div class=" d-flex justify-content-center align-items-center">

                        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" id="otp" [config]="config">
                        </ng-otp-input>
                        <!-- <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"></ngx-otp-input> -->

                    </div>


                    <!-- <div class="d-flex flex-row-reverse">
                <a class="p-0 pb-4 blue-link text-align-right" (click)="resend()">resend OTP</a>
            </div> -->
                    <div class="w-100 mt-4">
                        <div class="form-group">
                            <button [disabled]="loading" class="btn btn-primary w-100">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Login
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="d-flex justify-content-center">
                <a class="text-align-centre p-2 blue-link" [class.disabled]="resendNotAllowed"
                    [ngClass]="{'isDisabled' : resendNotAllowed, 'blue-link': !resendNotAllowed}" style="color: #589cd0"
                    (click)="resendOTP()">
                    Didn't receive OTP? Resend OTP
                    <countdown *ngIf="resendNotAllowed" [config]="{leftTime: 60}"></countdown>
                </a>
            </div>
            <div class="d-flex justify-content-between  ms-5 me-5 p-3 mb-4">
                <a class="p-2 black-link" routerLink="../login">Return to login</a>
                <!-- <a class="p-2 blue-link" (click)="bypass()">bypass</a> -->
                <a class="p-2 blue-link" routerLink="../help">Need help?</a>
            </div>
        </div>
    </div>
</div>