import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, CheckboxRequiredValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, AlertService } from 'src/app/_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { AccessRequestUser } from 'src/app/_models/access-request-user';
import { Contact } from 'src/app/_models/contact';
import { Profile } from 'src/app/_models/profile';
import { RequestAccess } from 'src/app/_models/request-access';
import { Role } from 'src/app/_models/role';
import { Roles } from 'src/app/_models/roles';
import { User } from 'src/app/_models';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html'
})
export class NewUserComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  roleCheck:number;

  isChecked = false;
  closeResult = '';

  hideErrorBanner: boolean=true;
errorMessage: string=" ";


  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private cookieService: CookieService

  ) {
  }


  ngOnInit(): void {
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"), JSession)
    .subscribe(data => {
     this.roleCheck=data.users.user.roles.role.id;
     if(this.roleCheck==6 || this.roleCheck==22 || this.roleCheck==50){//User is either SBV or Bank admin
        console.log("User is admin, role = "+this.roleCheck)
     }
     else{//User is nt an admin
      console.log("User is not an admin, role = "+this.roleCheck)
      this.router.navigate(['/forbidden-access']);
     }

    },
    error => {
      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }

        console.log(error)
    });

    //always load from saved data if there is any
    //if storageitem is null then load blank
    
    // console.log("req: ---- "+JSON.stringify(req));
    if (localStorage.getItem('step1') == 'false'){
      this.form = this.formBuilder.group({
        firstName: ['', [Validators.required, Validators.maxLength(64)]],
        lastName: ['', [Validators.required, Validators.maxLength(64)]],
        // email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")], Validators.email],

        //^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,}$
        email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
          ,  Validators.email]],
        contactNo: ['', [Validators.required, Validators.pattern("^[0-9]{10}$")]],//"^((\\+91-?)|0)?[0-9]{10}$"
        organization: ['', Validators.required]
      });
    } else { //user has pressed back
      let req = JSON.parse(localStorage.getItem('newUserDetails')); 
      this.form = this.formBuilder.group({
        firstName: [req.firstName, [Validators.required, Validators.maxLength(64)]],
        lastName: [req.lastName, [Validators.required, Validators.maxLength(64)]],
        email: [req.email, [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],
        contactNo: [req.contactNo, [Validators.required, Validators.pattern("^[0-9]{10}$")]],//"^((\\+91-?)|0)?[0-9]{10}$"
        organization: [req.organization , Validators.required]
      });
    }

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'new-user-role';
  }




  get f() { return this.form.controls; }

  xButton(){
    window.location.reload();
}


  onSubmit() {

    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    console.log("1 email "+this.f.email);
//STORE FOR LATER SUBMIT
    localStorage.setItem('newUserEmail', this.f.email.value);
    localStorage.setItem('newUserOrg', this.f.organization.value);
    localStorage.removeItem('newUserDetails');
    localStorage.setItem('newUserDetails', JSON.stringify(this.form.value));

    // localStorage.setItem('requestDetails')); 

    localStorage.setItem('step1','true');

    this.router.navigate([this.returnUrl]);

    this.loading = true;
    this.form.disable();
    this.loading = false;
  }

  goTo(){
    this.router.navigate(['../new-user-merchant']);
    // return 
  }


}
