import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { AccountService } from '../_services';

@Component({
  selector: 'app-help-case-management',
  templateUrl: './help-case-management-guide.component.html',
  styleUrls: ['./help-case-management-guide.component.scss']
})
export class HelpCaseManagementGuideComponent implements OnInit {

  isMerchant:boolean=false;
  isOpsUser:boolean=false;
  isBillingOpsUser:boolean=false;
  isOps:boolean=false;
  isBilling:boolean=false;
  isExternalUser:boolean=false;
  isCreditController:boolean=false;
  isFinancialAccountant:boolean=false;
  isBillingAnalyst:boolean=false;
  isCashCentreHOD:boolean=false;
  isBillingHOD:boolean=false;
  isSuperUser:boolean=false;
  isCLO:boolean=false;
  isLogistics:boolean=false;
  UG:number=0;
  userRole:number=0;

  constructor(
    private accountService: AccountService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"), JSession)
    .subscribe(data => {
      console.log("USER : "+JSON.stringify(data));
      this.UG=data.users.user.roles.role.groupId;
      this.userRole=data.users.user.roles.role.id;

      // Super User
      if (this.UG==1 && this.userRole==50){
        this.isSuperUser=true;
      }

      // Ops Bank User
      else if(this.userRole==33 || this.userRole==34 || this.userRole==35 || this.userRole==38 || this.userRole==39 || this.userRole==40){
        this.isOpsUser = true;
      }

      //isLogistics
      else if(this.userRole==36 || this.userRole==37 || this.userRole==4 || this.userRole==55){
        this.isLogistics = true;
      }
      // CLO users
      else if(this.userRole==2 || this.userRole==15 || this.userRole==16 || this.userRole==14){
        this.isCLO = true;
      }

      // Billing Bank users
      else if(this.userRole==7 || this.userRole==8 || this.userRole==10 || this.userRole==20 || this.userRole==19 || this.userRole==21 || this.userRole==22){
        this.isBillingOpsUser=true;
      }

      else if(this.UG == 1 && (this.userRole != 2 && this.userRole != 14 && this.userRole != 15 && this.userRole != 16 && this.userRole != 4 && this.userRole != 36 && this.userRole != 37 && this.userRole != 55 )){
        this.isBilling=true;

        if (this.userRole==26){
          this.isCreditController=true;
        }

        else if(this.userRole==28){
          this.isFinancialAccountant=true;
        }

        else if(this.userRole==29){
          this.isBillingHOD=true;
        }

        else if(this.userRole==27){
          this.isBillingAnalyst=true;
        }
        
        else if(this.userRole==36 || this.userRole ==38 || this.userRole==39 || this.userRole==40){
          this.isCashCentreHOD=true;
        }
      }
      else if(this.UG==2){
        this.isOps=true;
      }
      else if(this.UG==3){
        this.isMerchant=true;
      }
   
  },
    error => {
     console.log(error);
      });
  }
}