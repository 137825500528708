

<div class="container-fluid" style="text-align:left; background: #f6fafd">

    <h6>HELP CENTRE</h6>
    <h1 style="margin-top:0px;">Getting Started</h1>
    <h5>Your online guide to SBV Connect</h5>
    <hr/>
    
</div>

<nav aria-label="breadcrumb" style =" margin: 10px ">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/help-centre">Help Centre</a></li>
          <li class="breadcrumb-item"><a routerLink="/getting-started">Getting Started</a></li>
          <li class="breadcrumb-item active" aria-current="page">Organisation</li>
        </ol>
      </nav>


<div class="box">
   <p>
    If your profile has administrator privileges for the SBV Connect portal, you will have a menu shortcut called Organisation on your men
    If not, you will still be able to access your profile details by selecting the username identification block which appears directly 
    below the SBV Connect Logo on your menu bar.
   </p>



    <h4>Managing your profile</h4>
    <div class="divider"></div>

    <p>
      Update and manage your profile.
    </p>
    <p>SBV Connect allows you to update and manage your profile, including requesting a password reset.Your profile information is arranged into 3 categories</p>
    
    <br/>
    <p><b>Personal Information</b></p>
    <p>This includes information about your name, email address and contact details.</p>
    <p>You are required to use an active work email address as your user identifier.
       Please note that once registered you are not able to change your email address or name for security reasons.</p>
    
   <br/>
   <p><b>Business Details</b></p>
   <p>This includes information about your organisation. 
      The group and role that you are allocated to will determine your level of access. 
      You are not able to change your organisation or group for security reasons.</p>

    <br/>
    <p><b>Cash Centres Permission</b></p>
    <p>This provides permission for the Cash Centres that you can access information for.</p>

    <br/>
    <p><b>Editing your Profile details</b></p>
    
    <ol>
    <li>To edit any editable fields in your profile, click on the edit button in the top right-hand corner of the profile information block. </li>
    <li>Once you have made the necessary changes, click on Save Changes. </li>
    <li>Note that some changes require administrator approval, SBV Connect automatically detects which fields these are and submits them for approval.
       You will receive an email confirmation once these changes are approved</li>
    </ol>

    <br/>
    <p><b>Changing your Password</b></p>
    
    <ol>
    <li>You can change your password at any time by selecting Forgot Password on the log-in screen</li>
    <li>You will receive an email containing a secure link.</li>
    <li>Please follow the instructions to reset the password.</li>
    </ol>
   

<br><h4>Manage User Access</h4>
<p>If your profile has administrator privileges, use this section to manage your team's profiles</p>
<div class="divider"></div>

<br/>
<p><b>User List:</b></p>
<p>If you an SBV Connect bank administrator, you will be able to see an overview of your teams’ profiles in the User List.</p>
<ul>
<li>This lists all the active and deactivated profiles associated to your group, along with their profile details.</li>
<li>It also shows any administrator actions you are required to perform, including to approve or decline profile updates and new users.</li>
<li>It gives you the option to deactivate profiles should a team member no longer require access to SBV Connect</li>
<li>You can choose to filter this list by name, profile, or date.</li>
</ul>

<p><b>Approving and Managing User Profiles</b></p>
<ul>
<li>User requests will be approved by both a bank administrator and a SBV Connect administrator.</li>
<li>If your profile includes bank administrator privileges, then you can both approve new user requests and review change requests for your team.</li>
<li>By clicking on the tab titled Awaiting Approval you can expand on each request to review the detail.</li>
<li>Once you have verified the information, click Approve..</li>
<li>The request will then route to SBV Connect admin for final approval and profile activation</li>
</ul>

</div>