
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import * as shajs from 'sha.js';

export function createPasswordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasSpecial = /[$@$!%*?&#]+/.test(value);
    const passwordValid = hasUpperCase && hasLowerCase && hasSpecial;
    return !passwordValid ? { passwordStrength: true } : null;
  }
}

export default class Validation {

  static match(controlName: string, checkControlName: string): ValidatorFn {
    // console.log("matching...");
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl.errors && !checkControl.errors.matching) {
        return null;
      }

      if (control.value !== checkControl.value) {
        controls.get(checkControlName).setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }
}



@Component({
  selector: 'app-finalise',
  templateUrl: './finalise.component.html'
})
export class FinaliseComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  errorMsg: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private renderer: Renderer2
    ) { 
      this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
     }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(8), createPasswordStrengthValidator()]],
      passwordConfirm: [null, [Validators.required]],

    },
      {
        validators: [Validation.match('password', 'passwordConfirm')]
      }
    );

  }

  get f() { return this.form.controls; }

  // mismatch: boolean = true;

  onSubmit() {
   
    
    this.submitted = true;
    // this.mismatch = false;
    // reset alerts on submit
    this.alertService.clear();

    const hashed = shajs('sha256').update(this.f.password.value).digest('hex')
    // stop here if form is invalid
    if ((this.form.invalid)) {
      return;
    }
    if ((this.f.password.value != this.f.passwordConfirm.value)) {
      // this.mismatch = true;
      return;
    }


    this.loading = true;

    let ottId=sessionStorage.getItem("ottId");
    let ottCode=sessionStorage.getItem("ottCode")

    this.accountService.registerPassword(sessionStorage.getItem('email'), hashed, ottId, ottCode)
      .pipe(
        first())
      .subscribe(
        data => {
          // console.log(data);
          this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'login';  //LOGIN
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.alertService.error(error.error.error.message);
          this.loading = false;
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
        });
        this.loading = false;
  }



}
