import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip'; 
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker'; 
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';


import { AppComponent } from './app.component';
import { LoginComponent } from './account/login/login.component';
import { RegisterDetailsComponent } from './account/register-details/register-details.component';
import { OtpComponent } from './account/otp/otp.component';
import { RequestAccessComponent } from './account/request-access/request-access.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AlertComponent } from './_components';
import { ForgotPasswordFinishComponent } from './account/forgot-password/forgot-password-finish.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { RegisterDetailsProcessingComponent } from './account/register-details/register-details-processing.component';
import { RequestAccessProcessingComponent } from './account/request-access/request-access-processing.component';
import { CashCentreComponent } from './account/cash-centre/cash-centre.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { OttComponent } from './account/ott/ott.component';
import { LogoutComponent } from './account/logout/logout.component';
import { FooterComponent } from './account/footer/footer.component';
import { DropdownListModule } from 'ngx-dropdown-list';
import { NgxMaskModule } from 'ngx-mask';
import { ResetPasswordSuccessComponent } from './account/reset-password/reset-password-success.component';
import { FinaliseComponent } from './account/finalise/finalise.component';
import { FinalisePasswordSetComponent } from './account/finalise-password-set/finalise-password-set.component';
import { HelpComponent } from './account/help/help.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgOtpInputModule } from 'ng-otp-input';
import { NotFoundComponent } from './account/not-found/not-found.component';
import { CookieModule } from 'ngx-cookie';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { HelpFinishComponent } from './account/help/help-finish.component';
import { CountdownModule } from 'ngx-countdown';
import { DataTablesModule } from 'angular-datatables';
import { UserManagementComponent } from './user-management/user-management/user-management.component';
import { NewUserRoleComponent } from './user-management/new-user-role/new-user-role.component';
import { NewUserAccessComponent } from './user-management/new-user-access/new-user-access.component';
import { AddnewSiteComponent } from './user-management/add-new-site/add-new-site.component';
import { NewUserFinishComponent } from './user-management/new-user-finish/new-user-finish.component';
import { NewUserComponent } from './user-management/new-user/new-user.component';
import { UserManagementAwaitingApprovalComponent } from './user-management/user-management-awaiting-approval/user-management-awaiting-approval.component';
import { UserManagementExistingUsersComponent } from './user-management/user-management-existing-users/user-management-existing-users.component';
import { environment } from './../environments/environment.prod';
import { ProfileSettingsComponent } from './user-management/profile-settings/profile-settings.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NewUserMerchantComponent } from './user-management/new-user-merchant/new-user-merchant.component';
import { DomSanitizer} from '@angular/platform-browser';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' 
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};

//
import { Injectable, ErrorHandler} from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TaskListComponent } from './task-list/task-list.component';
import { CaseListComponent } from './case-list/case-list.component';
import { LogNewCaseComponent } from './log-new-case/log-new-case.component';
import { EventEmitterService } from './_services/event-emitter.service';
import { ReportingComponent } from './reporting/reporting.component';
import { NationalDashboardComponent } from './national-dashboard/national-dashboard.component';
import { HelpCentreComponent } from './help-centre/help-centre.component';
import { BankViewsComponent } from './bank-views/bank-views.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { HelpCaseManagementComponent } from './help-case-management/help-case-management.component';
import { HelpCaseManagementGuideComponent} from './help-Case-Management-Guide/help-case-management-guide.component';
import { HelpOrganisationComponent } from './help-organisation/help-organisation.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/browser";
import { ForbiddenAccessComponent } from './account/forbidden-access/forbidden-access.component';
import { ServerErrorComponent } from './account/server-error/server-error.component';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
import { MerchantManagementComponent } from './user-management/merchant-management/merchant-management.component';
import { LinkedSitesComponent} from './user-management/linked-sites/linked-sites.component';
import { MerchantManagementExistingComponent } from './user-management/merchant-management-existing/merchant-management-existing.component';
import { MerchantManagementAwaitingApprovalComponent } from './user-management/merchant-management-awaiting-approval/merchant-management-awaiting-approval.component';
import { EditMerchantGroupComponent } from './user-management/edit-merchant-group/edit-merchant-group.component';
import { AllSitesComponent } from './user-management/all-sites/all-sites.component';
import { LinkNewSitesComponent } from './user-management/link-new-sites/link-new-sites.component';
import { AddGroupComponent } from './user-management/add-group/add-group.component';
import { AddSiteComponent } from './user-management/add-site/add-site.component';
import { PasswordSetCompleteComponent } from './account/password-set-complete/password-set-complete.component';
import { CaseTypesComponent } from './case-types/case-types.component';
import { EditCaseTypeComponent } from './edit-case-type/edit-case-type.component';
import { CreateBillingCaseComponent } from './create-billing-case/create-billing-case.component';
import { MatNativeDateModule } from '@angular/material/core';
import { VideoFootageComponent } from './video-footage/video-footage.component';
import { CaseListVideoFootageComponent } from './case-list-video-footage/case-list-video-footage.component';
import { SuperUserLoginComponent } from './super-user-login/super-user-login.component';
import { SuperUserLogoutComponent } from './super-user-logout/super-user-logout.component';
import { IncidentProcessComponent } from './incident-process/incident-process.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { CaseRouterComponent } from './case-router/case-router.component';
import { NewUserSwitchProfileComponent } from './user-management/new-user-switch-profile/new-user-switch-profile.component';
import { RoleChangeLogoutComponent } from './role-change-logout/role-change-logout.component';
import { RoleChangeLoginComponent } from './role-change-login/role-change-login.component';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

constructor(){
  Sentry.init({
    dsn: "https://5ddcd0a386494317b024aa2f958132dc@o1058862.ingest.sentry.io/6046784",
    environment:"Production",
    integrations:[
      new Integrations.BrowserTracing({
        tracingOrigins: ["./", /^\//]
      }),
    ],
    ignoreErrors: [
      'Error: unsafe value used in a resource URL context (see https://g.co/ng/security#xss)',
      'Error: unsafe value used in a resource URL context',
      'TypeError: n is null',
      'TypeError: t is undefined',
      'TypeError: t is null',
      'TypeError: Cannot read properties of undefined (reading "_rawValidators")',
      'TypeError: Cannot read properties of undefined',
      'TypeError: Cannot read properties of null (reading "_rawValidators")',
      'TypeError: Cannot read properties of null',
      'TypeError: Cannot read properties of undefined (reading "style")',
      'TypeError: _.error.error is undefined',
      'ResizeObserver loop limit exceeded',
      'Large Render Blocking Asset'
    ],
    tracesSampleRate: 0.2,
  });

  Sentry.setUser({ email: sessionStorage.getItem('email') });
}

handleError(error) {
  Sentry.captureException(error.originalError || error)
}

}


@NgModule({
  declarations: [
    AppComponent,

    LoginComponent,
    RegisterDetailsComponent,
    RegisterDetailsProcessingComponent,
    // RegisterPasswordComponent,
    OtpComponent,
    RequestAccessComponent,
    RequestAccessProcessingComponent,
    // ProcessingComponent,
    ForgotPasswordComponent,
    ForgotPasswordFinishComponent,
    ResetPasswordComponent,
    ResetPasswordSuccessComponent,
    OttComponent,
    CashCentreComponent,
    HomeComponent,
    LogoutComponent,
    FooterComponent,
    FinaliseComponent,
    HelpComponent,
    HelpFinishComponent,
    NotFoundComponent,
    FinalisePasswordSetComponent,
    AlertComponent,
    UserManagementComponent,
    UserManagementAwaitingApprovalComponent,
    UserManagementExistingUsersComponent,
    NewUserComponent,
    NewUserRoleComponent,
    NewUserAccessComponent,
    NewUserFinishComponent,
    NewUserMerchantComponent,
    ProfileSettingsComponent,
    DashboardComponent,
    TaskListComponent,
    CaseListComponent,
    LogNewCaseComponent,
    ReportingComponent,
    NationalDashboardComponent,
    HelpCentreComponent,
    BankViewsComponent,
    AddnewSiteComponent,
    GettingStartedComponent,
    LinkedSitesComponent,
    HelpCaseManagementComponent,
    HelpCaseManagementGuideComponent,
    HelpOrganisationComponent,
    ContactUsComponent,
    ForbiddenAccessComponent,
    ServerErrorComponent,
    UnderMaintenanceComponent,
    MerchantManagementComponent,
    MerchantManagementExistingComponent,
    MerchantManagementAwaitingApprovalComponent,
    EditMerchantGroupComponent,
    AllSitesComponent,
    LinkNewSitesComponent,
    AddGroupComponent,
    AddSiteComponent,
    PasswordSetCompleteComponent,
    CaseTypesComponent,
    EditCaseTypeComponent,
    CreateBillingCaseComponent,
    VideoFootageComponent,
    CaseListVideoFootageComponent,
    SuperUserLoginComponent,
    SuperUserLogoutComponent,
    IncidentProcessComponent,
    OrderManagementComponent,
    CaseRouterComponent,
    NewUserSwitchProfileComponent,
    RoleChangeLogoutComponent,
    RoleChangeLoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    NgbModule,
    CountdownModule,
    ShowHidePasswordModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxOtpInputModule,
    NgOtpInputModule,
    DropdownListModule,
    AngularMultiSelectModule,
    ModalModule.forRoot(),
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatExpansionModule,
    MatCardModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatProgressBarModule,
    //SidebarModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    CookieModule.forRoot(),
    DataTablesModule,
    AutocompleteLibModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserAnimationsModule
    
  ],
  providers: [
    EventEmitterService,
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    [{ provide: LocationStrategy, useClass: PathLocationStrategy }]
    // [{ provide: LocationStrategy, useClass: HashLocationStrategy }],

    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]

})

export class AppModule { }
