<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButtton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="container-fluid" style="padding: 25px">
    <!-- <div class="ps-4 pe-4 pt-4 container-fluid "> -->

        <div class="addSiteBlock card card-blue-shadow w-50 border-0 p-5" [hidden]="hideSiteBanner">
            <button class="xButton" (click)="xButton()">X</button>
    
            <h5>Select sites to add</h5>
            <hr class="mt-0">
    
            <table id="addTable" class="table  table-hover" datatable [dtOptions]="addOptions2"
            style="width: 100%">
            <thead>
                <tr>
                    <th scope="col" class="user-table-headings">ID</th>
                    <th scope="col" class="user-table-headings">Organisation</th>
                    <th scope="col" class="user-table-headings">Site Name</th>
                    <th scope="col" class="user-table-headings">CIT Code</th>
                    <th scope="col" class="user-table-headings">Account Number</th>
                    <th scope="col" class="user-table-headings">Cash Centre</th>
                </tr>
            </thead>
        </table>
        <br>
                <button class="btn btn-primary" style = "width: 150px;" (click)="displayAS()">Add Selected Sites</button>
        </div>
        <div class="addBanner" [hidden]="hideSiteBanner"></div>

        <div>
            <h6 style="margin-bottom: -20px">ORGANISATION</h6>
            <h2>Manage User Access</h2>
            <h5>Manage all SBV Connect users</h5>
        </div>
        <hr class="mt-0">

        <div class="row d-flex">
            <div class="p-2">
                <ul class="nav nav-pills nav-underline">
                    <li><a href="/user-management">All Users</a></li>
                    <li><a href="/user-management-existing-users">Existing Users</a></li>
                    <li class="active"><a href="/user-management-awaiting-approval">Awaiting Approval</a></li>
                </ul>
            </div>
            <button class="rounded btn btn-primary ml-auto p-3 h-50 border-0" style="width: 130px"
                routerLink="../new-user/">
                <div class="row ml-2">
                    <span> Add New User </span>
                    <div class="circle"> + </div>
                </div>
                <!-- style="color:#589cd0; background-color: white; height: 50px; width: 50px; border-radius: 5%;" -->
            </button>
        </div>


        <div class="card card-blue-shadow" >


            <div class="row" style="border-radius: 14px;">
                <div class="col" style="border-radius: 14px;" [ngClass]="{'table-no-details' : fullTable, 'table-user-details': !fullTable}">
                    <!-- <div class="card"> -->
                    <div class="card-header d-flex  user-management-card" style="border-radius: 14px;">
                        <h4 class="p-2">All Users</h4>
                        <!-- <input type="text" value="" name="suburbFilter" class="form-control" placeholder="Search..." [(ngModel)]="user"> -->
                        <!-- <input type="text" value="" name="suburbFilter" class="form-control ml-auto p-2"
                            style="width: 20%" placeholder="This is actually a sort dropdown"> -->
                    </div>
                    <div class="card-body " style="border-radius: 14px;">
                        <!-- <div class="card-body pt-0 scroll-box-table table-responsive" style="overflow-x:auto;"> -->
                        <table id="userTable" class="table  table-hover" datatable [dtOptions]="dtOptions"
                            style="width: 100%">
                            <thead>
                                <!-- <b> -->
                                <tr>
                                    <th scope="col" class="user-table-headings">Name</th>
                                    <th scope="col" class="user-table-headings">Last Name</th>
                                    <th scope="col" class="user-table-headings">Email</th>
                                    <!-- <div [ngClass]="{'d-block' : fullTable, 'd-none': !fullTable}"> -->
                                    <th scope="col" class="user-table-headings">Organisation</th>
                                    <!-- </div> -->
                                    <th scope="col" class="user-table-headings">User Type</th>
                                    <th scope="col" class="user-table-headings">Role</th>
                                    <!-- <div [ngClass]="{'d-block' : fullTable, 'd-none': !fullTable}"> -->
                                    <!-- <th scope="col" class="user-table-headings">Request Type</th> -->
                                    <!-- </div> -->
                                    <th scope="col" class="user-table-headings">Last Updated</th>
                                    <th scope="col" class="user-table-headings">Created On</th>
                                    <th scope="col" class="user-table-headings">User Status</th>
                                </tr>
                                <!-- </b> -->
                            </thead>
                            <!-- <tr *ngFor="let item of users | searchFilter: user">
                        <td scope="col" style="font-weight: normal; color: #a1a4b5;">{{ item.name }}
                        </td>
                        <td>{{ item.organisation }}</td>
                        <td>{{ item.profileType }}</td>
                        <td>{{ item.requestType }}</td>
                        <td>{{ item.submittedOn }}</td>
                        <td>{{ item.status }}</td> 
                    </tr>-->
                        </table>
                        <!-- </div> -->
                    </div>
                </div>
                <div [ngClass]="{'no-details' : fullTable, 'user-details': !fullTable}">
                    <div class="col" *ngIf="fullTable">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <p class="clickable" (click)="showDetails()" style="margin-top: 10px;">
                                <i class="fa fa-angle-left fa-2x "></i>
                            </p>
                        </div>
                    </div>


                    <div class="col" *ngIf="!fullTable" style="width:50%; background-color: #d5e6f3;">
                        <p class="clickable" (click)="hideDetails()"
                            style="margin-top: 10px; margin-left: -2px; margin-bottom: -10px;">
                            <i class="fa fa-angle-right fa-2x "></i>
                        </p>
                        <!-- <br> -->
                        <h3 class="h3-grey user-detail-card">{{fullName}}</h3>
                        <h4 class="user-detail-card row"><b>{{organisation}} -
                                <span *ngIf="status=='Disabled'">Deactivated User</span>
                                <span *ngIf="status=='SBV Pending'">User Approval</span>
                                <span *ngIf="status=='In Flight'">User In Flight</span>
                                <span *ngIf="status=='Active'">Approved User</span>
                                <span *ngIf="status=='Bank Pending'">User Approval</span>
                                <span *ngIf="status=='Rejected'">Rejected User</span>
                                <span *ngIf="status=='SBV Update'">Update User</span>
                                <span *ngIf="status=='Update'">Update User</span>
                            </b></h4>
                        <div *ngIf="status=='Disabled'" class="m-4"><span class="label label-danger">Disabled</span>
                        </div>
                        <div *ngIf="status=='SBV Pending'" class="m-4"><span class="label label-warning">SBV
                                Pending</span>
                        </div>
                        <div *ngIf="status=='In Flight'" class="m-4"><span class="label label-primary">In Flight</span>
                        </div>
                        <div *ngIf="status=='Active'" class="m-4"><span class="label label-success">Active</span></div>
                        <div *ngIf="status=='Bank Pending'" class="m-4"><span class="label label-error">Bank
                                Pending</span></div>
                        <div *ngIf="status=='Rejected'" class="m-4"><span class="label label-danger">Rejected</span>
                        </div>
                        <div *ngIf="status=='Update'"
                            class="panel panel-info card-blue-shadow user-detail-card border-0"
                            style="border-radius: 14px;">
                            <div class="panel-heading">
                                <h3 class="panel-title">Confirm User Requested Update</h3>
                            </div>
                            <div class="panel-body">
                                <p>This user has requested an update to their profile. Please review their request and
                                    confirm access.</p>
                                <p><b>Change Requests:</b></p>
                                <div *ngIf="roleUpdate" class="row">
                                    <div class="col-6">
                                        <p><b>Role:</b></p>
                                        <p>{{newRole}}</p>
                                    </div>
                                    <div class="col-6">
                                        <p><b>Previous Role:</b></p>
                                        <p>{{oldRole}}</p>
                                    </div>
                                </div>
                                <div *ngIf="ccUpdate" class="row">
                                    <div class="col-6">
                                        <p><b>New Cash Centres:</b></p>
                                        <p>{{newCCs}}</p>
                                    </div>
                                    <div class="col-6">
                                        <!-- <p><b>Previous Role:</b></p> -->
                                        <!-- <p>{{oldRole}}</p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-5 card-blue-shadow user-detail-card">
                            <form [formGroup]="form">
                                <div class="d-flex justify-content-between align-items-end">
                                    <h4 class="mb-0">Personal Details</h4>
                                    <a *ngIf="(status=='Active' || status=='Disabled') && !canEditPersonal"
                                        class="text-align-centre p-2 blue-link"
                                        style="color: #589cd0; margin-bottom: -5px;"
                                        (click)="editPersonalDetails()">Edit</a>
                                </div>
                                <hr style="margin-top: 10px">

                                <div class="form-group">
                                    <label class="control-label" for="name">Name</label>
                                    <input readonly (focus)="nameFocusVar=true" type="text" formControlName="name"
                                        class="form-control" placeholder="Please select user..." />
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="surname">Surname</label>
                                    <input readonly (focus)="surnameFocusVar=true" type="text" formControlName="surname"
                                        class="form-control " placeholder="Please select user..." />
                                    <!-- placeholder="Surname"   -->
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="email">Email</label>
                                    <input readonly (focus)="emailFocusVar=true" type="text" formControlName="email"
                                        class="form-control " placeholder="Please select user..." />
                                    <!-- placeholder="Email" /> -->
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="contactNo">Contact Number</label>
                                    <input [readonly]="!canEditPersonal" (focus)="contactNoFocusVar=true" type="text"
                                        mask='000 000 0000' formControlName="contactNo" class="form-control "
                                        placeholder="Please select user..." />
                                    <!-- placeholder="Please select user..."/> -->
                                </div>

                                <div *ngIf="canEditPersonal" class="d-flex justify-content-between"
                                    style="padding-top: 1em;">
                                    <div>
                                        <a class="blue-link clickable" (click)="editPersonalDetails()"
                                            tabindex="-1">Cancel</a>
                                    </div>
                                    <button [disabled]="loading" class="rounded btn btn-success w-10"
                                        (click)="savePersonalDetails()">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Save Changes
                                    </button>
                                </div>
                                <div *ngIf="showPersonalError" class="mt-3 alert alert-dismissible error-alert">
                                    <button type="button" class="close" data-dismiss="alert" style="color: #bf3a1c">
                                        &times;
                                    </button>
                                    {{errorMsg}}
                                </div>

                                <!-- </form>
                            <form [formGroup]="businessDetailsForm" (ngSubmit)="onSubmit()"> -->
                                <div class="d-flex justify-content-between align-items-end">
                                    <h4 class="mb-0">Business Details</h4>
                                    <a *ngIf="(status=='Active' || status=='Disabled') && !canEditBusiness"
                                        class="text-align-centre p-2 blue-link"
                                        style="color: #589cd0; margin-bottom: -5px;"
                                        (click)="editBusinessDetails()">Edit</a>
                                </div>
                                <hr style="margin-top: 10px">
                                <div class="form-group">
                                    <label class="control-label" for="organisation">Organisation</label>
                                    <input [readonly]="!(status=='SBV Pending' || canEditBusiness)" type="text"
                                        formControlName="organisation" class="form-control "
                                        placeholder="Organisation" />
                                    <!-- placeholder="Organisation" /> -->
                                    <div *ngIf="!orgChosen" style="font-size: 80%; color: #dc3545">
                                        <div *ngIf="!orgChosen">Organisation is required</div>
                                    </div>
                                </div>
                                <!-- <div class="form-group" *ngIf="status=='SBV Pending'"> -->
                                    <div class="form-group" [hidden]="hideSiteTable">
                                        <label class="control-label" for="linkedSites">Linked Sites</label>
                    
                                            <table id="siteTable" class="table  table-hover" datatable [dtOptions]="siteOptions" style="width: 100%">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="user-table-headings">ID</th>
                                                        <!-- <th scope="col" class="user-table-headings">Group</th> -->
                                                        <th scope="col" class="user-table-headings">Name</th>
                                                        <th scope="col" class="user-table-headings">CIT Code</th>
                                                        <th scope="col" class="user-table-headings">Account Number</th>
                                                        <th scope="col" class="user-table-headings">Cash Centre</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div *ngIf="invalidSites" style="font-size: 80%; color: #dc3545">
                                            <div *ngIf="invalidSites">Please select sites</div>
                                        </div>
                    
                                        <div class="form-group" [hidden]="!isActiveMerch || hideActiveTable">
                                            <div class="d-flex justify-content-between align-items-end">
                                                <h5 *ngIf="group==3" class="mb-0">Linked Sites</h5>
                    
                                                        <!-- <a class="text-align-centre p-2 blue-link"
                                                        style="color: #589cd0; margin-bottom: -5px;"
                                                        [hidden]="hideEditSitesBtn"
                                                        (click)="editSites()">Manage Linked Sites</a>
                    
                                                        <a class="text-align-centre p-2 blue-link" 
                                                        style="color: #589cd0; margin-bottom: -5px;" 
                                                        [hidden]="hideCancelSiteEditBtn"
                                                        (click)="cancelSiteEdit()">Cancel</a> -->
                                             </div>
                                             <hr style="margin-top: 10px">
                                            <table id="activeMerchTable" class="table  table-hover" datatable [dtOptions]="activeUserOptions" style="width: 100%">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="user-table-headings">ID</th>
                                                    <th scope="col" class="user-table-headings">Name</th>
                                                    <th scope="col" class="user-table-headings">CIT Code</th>
                                                    <th scope="col" class="user-table-headings">Account Number</th>
                                                    <th scope="col" class="user-table-headings">Cash Centre</th>
                                                </tr>
                                            </thead>
                                        </table>
                    
                                        <!-- <button class="btn btn-primary" style = "text-align: justify; margin-right: 15px;" (click)="checkChecked()" [hidden]="hideCheckSitesBtn">Remove Selected Sites</button>
                                            <br>
                                            <br>
                                            <div *ngIf="unlinkSites  && checked" style="font-size: 80%; color: #43dc35">
                                                <div *ngIf="unlinkSites  && checked">Sites have been queued for unlinking</div>
                                            </div>
                                            <div *ngIf="!unlinkSites && checked" style="font-size: 80%; color: #dc3545">
                                                <div *ngIf="!unlinkSites  && checked">No sites have been selected for unlinking</div>
                                            </div>
                                            <br>
                                            <button class="rounded btn btn-success w-10" (click)="saveDetails()" [hidden]="hidesaveUnlinkBtn">Save Changes</button>
                                            <br> -->
                    
                                        </div>
                    
                                        <button class="btn btn-primary" style = "text-align: justify; margin-right: 15px;" (click)="showSites()"[hidden]="hideAddSitebtn">Add</button>
                                        
                                        <br *ngIf="group==3">

                                <!-- BANK CUSTOMER-->
                                <div class="form-group">
                                    <label *ngIf="group==2" class="control-label" for="customer">Customer</label>
                                    <ng-multiselect-dropdown *ngIf="group==2" formControlName="banks"
                                     [ngClass]="{'ms-disabled' : !(canEditBusiness || status=='SBV Pending'), '': (canEditBusiness || status=='SBV Pending')}"
                                        style="font-size: 12px; border-radius: 50px;" [placeholder]="'Banks'"
                                        [settings]="dropdownSettingsBanks" [data]="dropdownListSingleBanks" (onDeSelect)="onBankCustomerDeselect($event)"
                                        (onSelect)="onBanksItemSelect($event)" class="singleselect">
                                    </ng-multiselect-dropdown>
                                    
                                    <div *ngIf="!customerChosen" style="font-size: 80%; color: #dc3545">
                                        <div *ngIf="!customerChosen">Customer is required</div>
                                    </div>
                                </div>

                                <div [hidden]="hideMultiBank" class="form-group">
                                    <label [hidden]="hideMultiBank" class="control-label" for="customer">{{userDropdownHeading}}</label>
                                    <ng-multiselect-dropdown [hidden]="hideMultiBank" formControlName="linkedBanks"
                                        [ngClass]="{'ms-disabled' : !(canEditBusiness || status=='SBV Pending')}"
                                        [formControl]="f.linkedBanks"
                                        style="font-size: 12px; border-radius: 50px;" 
                                        [placeholder]="'Banks'"
                                        [settings]="dropdownSettingsMultiBank" 
                                        [data]="dropdownListBanks"
                                        (onSelect)="onItemSelectMultiBank($event)" 
                                        (onSelectAll)="onSelectAllMultiBanks($event)"
                                        (onDeSelect)="onItemDeSelectMultiBank($event)" 
                                        (onDeSelectAll)="onItemDeSelectAllMultiBank($event)">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="noMultiBanks" style="font-size: 80%; color: #dc3545">
                                        <div *ngIf="noMultiBanks">No banks selected</div>
                                    </div>
                                </div>

                                <!-- <div class="form-group" *ngIf="status=='SBV Pending'">
                                    <label *ngIf="group==2" class="control-label" for="customer">Customer</label>
                                    <ng-multiselect-dropdown *ngIf="group==2"
                                        style="font-size: 12px; border-radius: 50px;" [placeholder]="'Banks'"
                                        [settings]="dropdownSettingsBanks" [data]="dropdownListBanks"
                                        formControlName="customer" (onSelect)="onBanksItemSelect($event)"
                                        class="singleselect">
                                    </ng-multiselect-dropdown>
                                </div> -->



                                <!-- <div *ngIf="status=='SBV Pending' && customerFocusVar"
                                class="d-flex justify-content-between" style="padding-top: 1em;">
                                <div>
                                    <a class="blue-link clickable" (click)="cancelOrganisationFocus()"
                                        tabindex="-1">Cancel</a>
                                </div>
                                <button [disabled]="loading" class="rounded btn btn-success w-10"
                                    (click)="saveOrg()">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    Save Changes
                                </button>
                            </div> -->


                                <!-- MERCHANT -->
                                <!-- <div class="form-gorup">
                                    <label *ngIf="group==3" class="control-label" for="locType">Site Type</label>
                                    <ng-multiselect-dropdown *ngIf="group==3"
                                        style="font-size: 12px; border-radius: 50px;" [placeholder]="'Select Site Type'"
                                        [settings]="dropdownSettingsLocs" [data]="dropdownListLocsM" formControlName="siteTypesM"
                                        (onSelect)="onItemSelectLocM($event)" formControlName="loc" class="singleselect"
                                        clearable="true">
                                    </ng-multiselect-dropdown>
                                </div> -->

                                <!-- NON-MERCHANT -->
                                <div class="form-gorup" [hidden]="isFMU || hideLevel">
                                    <!-- && showSiteTypes -->
                                    <label *ngIf="group!=3" class="control-label" for="siteTypes">Site
                                        Type</label>
                                    <ng-multiselect-dropdown *ngIf="group!=3" [ngClass]="{'ms-disabled' : !(canEditBusiness || status=='SBV Pending'), '': (canEditBusiness || status=='SBV Pending')}"
                                        style="font-size: 12px; border-radius: 50px;" [placeholder]="'Select Site Type'"
                                        [settings]="dropdownSettingsLocs" [data]="dropdownListLocs"
                                        (onDeSelect)="onSiteTypeDeselect()" (onSelect)="onItemSelectLoc($event)"
                                        formControlName="siteTypes" class="singleselect" clearable="true">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="!siteTypeChosen" style="font-size: 80%; color: #dc3545">
                                        <div *ngIf="!siteTypeChosen">Site Type is required</div>
                                    </div>
                                </div>

                                <div class="form-group mt-1" [hidden]="isFMU || hideLevel">
                                    <label *ngIf="showRegions && group!=3" class="control-label"
                                        for="site">Region</label>
                                    <ng-multiselect-dropdown *ngIf="showRegions" [ngClass]="{'ms-disabled' : !(canEditBusiness || status=='SBV Pending'), '': (canEditBusiness || status=='SBV Pending')}"
                                        style="font-size: 12px; border-radius: 50px;" [placeholder]="'Regions'"
                                        [settings]="dropdownSettingsRegions" [data]="dropdownListRegions"
                                        (onDeSelect)="onRegionDeselect()" formControlName="regions"
                                        (onSelect)="onRegionsItemSelect($event)" class="singleselect">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="!regionChosen" style="font-size: 80%; color: #dc3545">
                                        <div *ngIf="!regionChosen">Region is required</div>
                                    </div>
                                </div>

                                <!-- was REMOVED FROM ADD NEW USER -->
                                <div class="form-group" style="margin-top: -10px;" [hidden]="isFMU || hideLevel">
                                    <label *ngIf="showCCList && group!=3" class="control-label" for="ccSingle">Cash
                                        Centre</label>
                                    <ng-multiselect-dropdown *ngIf="showCCList"
                                    [ngClass]="{'ms-disabled' : !(canEditBusiness || status=='SBV Pending'), '': (canEditBusiness || status=='SBV Pending')}"
                                        
                                        style="font-size: 12px; border-radius: 50px;" [placeholder]="'Cash centres'"
                                        [settings]="dropdownSettingsCCSingle" [data]="dropdownCCListSingle"
                                        (onDeSelect)="onSingleCCDeselect()" class="singleselect"
                                        (onSelect)="onItemSelectCCSingle($event)" formControlName="ccSingle">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="!ccSingleChosen" style="font-size: 80%; color: #dc3545">
                                        <div *ngIf="!ccSingleChosen">Cash Centres is required</div>
                                    </div>
                                </div>




                                <!-- <div *ngIf="status=='SBV Pending' && customerFocusVar"
                                    class="d-flex justify-content-between" style="padding-top: 1em;">
                                    <div>
                                        <a class="blue-link clickable" (click)="cancelOrganisationFocus()"
                                            tabindex="-1">Cancel</a>
                                    </div>
                                    <button [disabled]="loading" class="rounded btn btn-success w-10"
                                        (click)="saveOrg()">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Save Changes
                                    </button>
                                </div> -->


                                <!-- <div *ngIf="(status=='SBV Pending' || status=='Bank Pending')"
                                    class="d-flex justify-content-between" style="padding-top: 1em;">
                                    <div>
                                        <a class="blue-link clickable" (click)="cancelOrganisationFocus()"
                                            tabindex="-1">Cancel</a>
                                    </div>
                                    <button [disabled]="loading" class="rounded btn btn-success w-10"
                                        (click)="saveOrg()">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Save Changes
                                    </button>
                                </div> -->


                                <div class="form-group mt-3">
                                    <label class="control-label" for="organisation">User Type</label>
                                    <ng-multiselect-dropdown (focus)="groupFocusVar=true" 
                                        style="font-size: 12px; border-radius: 50px;" [placeholder]="'Select Group'"
                                        [settings]="dropdownSettingsGroups" [data]="dropdownListGroups"
                                        (onSelect)="onItemSelectGroup($event)" formControlName="group"
                                        (onDeSelect)="onGroupItemDeSelect($event)" class="singleselect ms-disabled"
                                        clearable="true">
                                    </ng-multiselect-dropdown>
                                </div>

                                <div class="form-group">
                                    <div style="height:10px">
                                    </div>
                                    <label class="control-label" for="organisation">Role</label>
                                    <ng-multiselect-dropdown (focus)="roleFocusVar=true" #rolesMultiSelect
                                        [formControl]="f.role" formControlName="role" 
                                        style="font-size: 12px; border-radius: 50px;" [placeholder]="'Select Role'"
                                        [settings]="dropdownSettingsRoles" [data]="dropdownListRoles"
                                        (onSelect)="onItemSelectRole($event)" class="singleselect" [ngClass]="{'ms-disabled' : !canEditBusiness, '': canEditBusiness}"
                                        (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll()">
                                    </ng-multiselect-dropdown>
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="jobTitle">Job Title</label>
                                    <input [readonly]="!canEditBusiness" (focus)="jobTitleFocusVar=true" type="text"
                                        formControlName="jobTitle" class="form-control "
                                        placeholder="Job Title" />
                                    <!-- placeholder="Job title" /> -->
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="department">Department</label>
                                    <input [readonly]="!canEditBusiness" (focus)="departmentFocusVar=true" type="text"
                                        formControlName="department" class="form-control "
                                        placeholder="Department" />
                                    <!-- placeholder="Department" /> -->
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="lineManager">Line Manager</label>
                                    <input [readonly]="!canEditBusiness" (focus)="lineManagerFocusVar=true" type="text"
                                        formControlName="lineManager" class="form-control "
                                        placeholder="Line Manager" />
                                    <!-- placeholder="Line manager" /> -->
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="lineManagerContactNo">Line Manager Contact
                                        Number</label>
                                    <input [readonly]="!canEditBusiness" (focus)="lineManagerContactNoFocusVar=true"
                                        type="text" mask='000 000 0000' formControlName="lineManagerContactNo"
                                        class="form-control " placeholder="Line Manager Contact Number" />
                                    <!-- placeholder="000 000 0000" /> -->
                                </div>

                                <div *ngIf="canEditBusiness" class="d-flex justify-content-between"
                                    style="padding-top: 1em;">
                                    <div>
                                        <a class="blue-link clickable" (click)="editBusinessDetails()"
                                            tabindex="-1">Cancel</a>
                                    </div>
                                    <button [disabled]="loading" class="rounded btn btn-success w-10"
                                        (click)="saveBusinessDetails()">
                                        <span *ngIf="bEditLoading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Save Changes
                                    </button>
                                </div>
                                <div *ngIf="showBusinessError" class="mt-3 alert alert-dismissible error-alert">
                                    <button type="button" class="close" data-dismiss="alert" style="color: #bf3a1c">
                                        &times;
                                    </button>
                                    {{errorMsg}}
                                </div>

                                <!-- </form>
                            <form [formGroup]="ccForm" (ngSubmit)="onSubmit()"> -->
                                <div *ngIf="showCCs" class="d-flex justify-content-between align-items-end" [hidden]="hideComponents || isFMU">
                                    <h4 class="mb-0" [hidden]="hideComponents || isFMU">Cash Centres</h4>
                                    <a *ngIf="(status=='Active' || status=='Disabled') && !canEditCC"
                                        class="text-align-centre p-2 blue-link"
                                        style="color: #589cd0; margin-bottom: -5px;" (click)="editCCDetails()">Edit</a>
                                </div>
                                <hr style="margin-top: 10px">

                                <div class="form-group" [hidden]="hideComponents || isFMU">
                                    <!-- (focus)="ccFocusVar=true"> -->


                                    <label *ngIf="showCCs" [hidden]="hideComponents || isFMU" class="control-label" for="cc">Cash Centres </label> <span
                                        *ngIf="ccLoading" class="spinner-border spinner-border-sm mr-1"></span>
                                    <ng-multiselect-dropdown [hidden]="hideComponents || isFMU" *ngIf="showCCs" [ngClass]="{'ms-disabled' : !canEditCC, '': canEditCC}"
                                        style="font-size: 12px; border-radius: 50px;" [placeholder]="'Cash centres'"
                                        [settings]="dropdownSettingsCC"  [data]="dropdownCCList"
                                        (onSelect)="onCCItemSelect($event)" [formControl]="f.cashCentres"
                                        formControlName="cashCentres" (onSelectAll)="onCCSelectAll($event)"
                                        (onDeSelect)="onCcItemDeSelect($event)"
                                        (onDeSelectAll)="onCCItemDeSelectAll($event)">
                                    </ng-multiselect-dropdown>


                                    <!-- <input type="text" formControlName="cc" class="form-control " placeholder="Cash centres" /> -->
                                </div>
                                <h4 class="mb-0">Comments</h4>
                                <hr style="margin-top: 10px">
                                <div class="form-group">
                                    <label class="control-label" for="comments">Comments</label>
                                    <input (focus)="commentsFocusVar=true" type="text" formControlName="comments"
                                        class="form-control " placeholder="Add text..." />
                                </div>

                                <div *ngIf="canEditCC" class="d-flex justify-content-between" style="padding-top: 1em;">
                                    <div>
                                        <a class="blue-link clickable" (click)="editCCDetails()"
                                            tabindex="-1">Cancel</a>
                                    </div>
                                    <button [disabled]="loading" class="rounded btn btn-success w-10"
                                        (click)="saveCCDetails()">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Save Changes
                                    </button>
                                </div>
                                <div *ngIf="showCcError" class="mt-3 alert alert-dismissible error-alert">
                                    <button type="button" class="close" data-dismiss="alert" style="color: #bf3a1c">
                                        &times;
                                    </button>
                                    {{errorMsg}}
                                </div>

                                <div *ngIf="(status=='SBV Pending' && !isBankAdmin) || (status=='Bank Pending' && isBankAdmin)"
                                    class="d-flex justify-content-between" style="padding-top: 1em;">
                                    <button [disabled]="loading" class="rounded btn btn-default w-10"
                                        (click)="rejectUser()">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Reject
                                    </button>
                                    <button [disabled]="loading" class="rounded btn btn-primary w-10"
                                        (click)="approveUser()">
                                        <span *ngIf="acceptLoading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Approve
                                    </button>
                                </div>
                                <div *ngIf="status=='Active'">
                                    <button [disabled]="loading" class="rounded btn btn-danger w-10"
                                        (click)="deactivateUser()">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Deactivate User
                                    </button>
                                </div>
                                <div *ngIf="status=='Update'" class="d-flex justify-content-between"
                                    style="padding-top: 1em;">
                                    <button [disabled]="loading || bsa" class="rounded btn btn-default w-10">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Reject
                                    </button>
                                    <button [disabled]="loading || bsa" class="rounded btn btn-primary w-10">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Approve
                                    </button>
                                </div>
                                <div *ngIf="status=='Disabled'">
                                    <button [disabled]="loading" class="rounded btn btn-primary w-10"
                                        (click)="activateUser()">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Re-activate User
                                    </button>
                                </div>
                                <div *ngIf="status=='Rejected'">
                                    <button [disabled]="loading" class="rounded btn btn-primary w-10"
                                        (click)="reinstateUser()">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Reinstate User
                                    </button>
                                </div>
                                <div *ngIf="showPageError" class="mt-3 alert alert-dismissible error-alert">
                                    <button type="button" class="close" data-dismiss="alert" style="color: #bf3a1c">
                                        &times;
                                    </button>
                                    {{errorMsg}}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- </div> -->