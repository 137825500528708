import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, CheckboxRequiredValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CashCentre } from 'src/app/_models/cash-centre';
import { CashCentres } from 'src/app/_models/cash-centres';
import { Profile } from 'src/app/_models/profile';
import { User } from 'src/app/_models';
import { Customer } from 'src/app/_models/customer';
import { environment } from 'src/app/environments/environment';
import { CookieService } from 'ngx-cookie';
import { Site } from 'src/app/_models/site';
import { Sites } from 'src/app/_models/sites';

@Component({
  selector: 'app-new-user-merchant',
  templateUrl: './new-user-merchant.component.html'
})
export class NewUserMerchantComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  public centres = new BehaviorSubject<Array<any>>(null);
  ccentres: any[];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  hideSiteBanner: boolean=true;

  hideErrorBanner: boolean=true;
errorMessage: string=" ";


  selectedASite:number;

  currentUserRole;
  currentUserGroup;
  group;

  customerChosen: boolean = true;
  roleCheck:number;

  dtOptions: DataTables.Settings = {};
  addOptions: DataTables.Settings = {};

  sites: Site[] = [];

  displayArray= [];
  displayRes:Site;

  groupName:string="group";
  name:string="name";
  code:string="code";
  accNum:string="accNum";
  cc:string="CC";

  ccChosen: boolean = true;
  selectedLoc: any;
  errorMsg: any;
  data: any[];
  isLoadingResult: boolean;

  invalidSites:boolean=false;

  addArray: number[]=[];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.ccentres = [];
  }

  dropdownSettingsGroup = {};
  dropdownGroups=["Group 1","Group 2","Group 3"];

  dropdownSettingsSite = {};
  dropdownSite=["Site 1","Site 2","Site 3"];

  dropdownSettingsLocs = {};
  dropdownListLocs = [
    { id: 0, name: "National" },
    { id: 1, name: "Regional" },
    { id: 2, name: "Local" }
  ];

  // cashCentres = [];

  ngOnInit() {

    let accessRequest = JSON.parse(localStorage.getItem('requestDetails'));
    console.log("accessRequest " + JSON.stringify(accessRequest));

    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "ordering": true,
      order: [0],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'groupName' },
        { data: 'name' },//0
        { data: 'code'},//1
        { data: 'accNum'},//2
        { data: 'ccName'}//3
      ],
      "columnDefs": [

      ]
    };
    

    this.addOptions = {
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "pageLength": 10,
      "autoWidth": true,
      "ordering": true,
      order: [0],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'customerName' },//0
        { data: 'name' },//1
        { data: 'citCode' },//2
        { data: 'accountNo'},//3
        { data: 'cashCentreName'},//4
        { data: 'status'}, //5
        { data: null, "defaultContent": '<input type="checkbox" id="addCB">' }
      ],
      "columnDefs": [
        {
          "targets": 5,
          "render": function (data, type, row) {
            if (row.status === 0) {
              return '<span class="label label-danger">Disabled</span>';
            }
            if (row.status === 1) {
              return '<span class="label label-success">Active</span>';
            }
            return data;
          }
        },
      ]
    };

    $('#addTable').on('click', 'tr', function () {
      this.selectedASite = addTable.row(this).data().id;
      if (this.selectedASite != localStorage.getItem('selectedASite')) {
        localStorage.removeItem('selectedASite');
        localStorage.setItem('selectedASite', this.selectedASite);
      }
    });

    let addTable;
    let Jsession=this.cookieService.get("JSESSIONID");
    this.accountService.getSites(Jsession)
    .subscribe(data => {
      console.log(JSON.stringify(data));
      for (let i: number =0; i < data.sites.site.length; i++) {
        let s =new Site;
        s.id=data.sites.site[i].id;
        s.group=data.sites.site[i].customerId;
        s.name=data.sites.site[i].name;
        s.code=data.sites.site[i].citCode;
        s.accNum=data.sites.site[i].accountNo;
        s.cc=data.sites.site[i].cashCentreId;
        s.groupName=data.sites.site[i].customerName;
        s.ccName=data.sites.site[i].cashCentreName;
        s.status=data.sites.site[i].status;
        this.sites.push(s);
      }

      setTimeout(() => {
        addTable = $('#addTable').DataTable({
          initComplete: function () {
            if (!this.fullTable) {
            }
          },
          retrieve: true,
        }).rows.add(data.sites.site).draw();
      }, 2000);

     },

     error => {
      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }
      console.log("ERROR");
     });

     $('#addTable ').on('click', 'tr', () => {
      var idx = addTable.row(this).index();
      this.addLS();
    });

     $('#addTable ').on('click', 'tr', function () {
    
      if ($(this).hasClass('selected')) {
        console.log("row has been deselected");
        $(this).removeClass('selected');
        $(this).find('#addCB').prop("checked", false);
      }
      else {
        console.log("row has been selected");
        $(this).addClass('selected');
        $(this).find('#addCB').prop("checked", true); 
      }
      localStorage.setItem('addRowId', addTable.row(this).index());
    });

    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"), JSession)
    .subscribe(data => {
     this.roleCheck=data.users.user.roles.role.id;
     if(this.roleCheck==6 || this.roleCheck==22 || this.roleCheck==50){//User is either SBV or Bank admin
        console.log("User is admin, role = "+this.roleCheck)
     }
     else{//User is nt an admin
      console.log("User is not an admin, role = "+this.roleCheck)
      this.router.navigate(['/forbidden-access']);
     }

    },
    error => {
      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }
        console.log(error)
    });
    let jsession=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"),jsession)
      .subscribe(data => {
        // console.log
        this.currentUserRole = data.users.user.roles.role.id;
        this.currentUserGroup = data.users.user.roles.role.groupId;
        localStorage.removeItem('currentUserRole');
        localStorage.removeItem('currentUserGroup');
        localStorage.setItem('currentUserRole', this.currentUserRole);
        localStorage.setItem('currentUserGroup', this.currentUserGroup);
        // console.log("this.currentUserRole "+this.currentUserRole);
      });

    // console.log("this.currentUserGroup "+this.currentUserGroup);


    this.dropdownSettingsGroup= {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      clearable: "false",
      closeDropDownOnSelection: "false",
      allowSearchFilter: true
    
    }

    this.dropdownSettingsSite= {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      clearable: "false",
      closeDropDownOnSelection: "false",
      allowSearchFilter: true
    }

    if (localStorage.getItem('group') == '1') { //SBV user
      this.group = 1;
    } else { //Bank user
      this.group = 2;
    }


    let jSession=this.cookieService.get("JSESSIONID");
    console.log(jSession);

    this.accountService.getCashCentres(jSession)
      .subscribe(data => {
        // console.log(data.root.cashCentre);
        // console.log(JSON.stringify(data.root.cashCentre));
        this.loading = false;
        this.dropdownList = data.root.cashCentre;
        // this.ccentres = data.root.cashCentre;
      },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }
          this.alertService.error(error.error.error.message);
          this.loading = false;
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
        });


    // this.dropdownList = this.ccentres;
    this.selectedItems = [
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };

    this.dropdownSettingsLocs = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    };

    this.form = this.formBuilder.group({
      //   email: ['', Validators.required],
      //   password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'new-user-finish';
  }

  // onItemSelectLoc(item: any) {
  //   this.selectedLoc = item.id;
  // }

  // onItemSelect(item: any) {
  //   // console.log(item.id);
  //   if ((this.allSelected == false) || (!this.selectedItems.includes(item.id))) {
  //     this.selectedItems.push(item.id);
  //     // console.log(this.selectedItems);
  //   }
  // }

  // allSelected: boolean = false;

  // onSelectAll(items: any[]) {
  //   // console.log(">>>");// + items);
  //   // console.log(items.length);
  //   // console.log(this.selectedItems.length + "<<<");

  //   if (items.length == this.selectedItems.length) {
  //     this.selectedItems = [];
  //     this.allSelected = false;
  //   }
  //   else {
  //     // this.selectedItems = items;
  //     for (let i = 0; i < items.length; i++) {
  //       if (!this.selectedItems.includes(items[i].id)) {
  //         this.selectedItems.push(items[i].id);
  //       }
  //     }
  //     this.allSelected = true;
  //   }
  //   // console.log(this.selectedItems);
  // }

  // onItemDeSelect(item: any) {
  //   // console.log("role already present in array - will be removed");
  //   // console.log("B4: " + this.selectedItems);
  //   if (this.allSelected == true) {
  //     this.selectedItems.splice(item.id, 1);
  //   }
  //   this.selectedItems.forEach((element, index) => {
  //     if (element == item.id)
  //       this.selectedItems.splice(index, 1);
  //   });
  //   // console.log("After: " + this.selectedItems);

  // }

  // onItemDeSelectAll(items: any[]) {
  //   this.selectedItems = [];
  // }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  addLS(){

    console.log("SITE BEING ADDED/REMOVED");
    let x=parseInt(localStorage.getItem("selectedASite"));
    console.log("X = "+x);

    if(this.addArray.includes(x)){
      const i=this.addArray.indexOf(x);
      if(i!==-1){
        this.addArray.splice(i,1);
      }
    }
    else{
      this.addArray.push(x);
    }
    console.log("AddArray after method -- "+this.addArray);

  }

  deleteItem(){

  }

  showSites(){
    this.hideSiteBanner=false;
  }

  addSites(){
    this.hideSiteBanner=true;
    console.log("Sites Added");
  }

  displayAS(){
    

    this.hideSiteBanner=true;
    this.displayArray.splice(0);

    console.log("Array length = "+this.addArray.length);
    console.log("Display Array = "+JSON.stringify(this.displayArray));

    $('#siteTable').on('click', 'tr', function () {
      console.log("SITE CLICKED");
    });

    let siteTable;

    siteTable=$('#siteTable').DataTable();
    siteTable.clear().draw();

    for (let i: number =0; i < this.addArray.length; i++) {
      this.displayRes= this.sites.find(({ id }) => id === this.addArray[i]);
      this.displayArray.push(this.displayRes);
    }

    console.log("Display Array After Loop = "+JSON.stringify(this.displayArray));


    setTimeout(() => {
      siteTable = $('#siteTable').DataTable({
        initComplete: function () {
        
        },
        retrieve: true,
      }).rows.add(this.displayArray).draw();
    }, 2000);
  }

  xButton(){
    this.hideSiteBanner=true;
  }
  onSubmit() {
    console.log("submitting")
    this.submitted = true;
    // this.router.navigate([this.returnUrl]);
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    // if (this.selectedItems.length == 0) {
    //   this.ccChosen = false;
    // } else {
    //   this.ccChosen = true;
    // }
    if (this.displayArray.length==0 || this.displayArray.length==null || this.displayArray.length==undefined) {
      this.invalidSites=true;
      return;
    }
    else{
      this.invalidSites=false;
    }

    // if (this.customerId == undefined) {
    //   this.customerChosen = false;
    //   return;
    // }

    // console.log("ARRAY LENGTH: ", this.selectedItems.length)
    // if (this.selectedItems.length === 0) {
    //   // console.log("AWARE");
    //   this.alertService.error("Please select at least one cash centre");
    //   return;
    // }

    console.log("submitting 1")
    console.log("localStorage.getItem('newUserDetails') " + localStorage.getItem('newUserDetails'))

    //SUBMIT INFO FROM ALL PAGES
    //1 - user details:
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.addNewUser(JSON.parse(localStorage.getItem('newUserDetails')), JSession)//, this.ipaddress)
      .subscribe(data => {

        console.log("submitting 2")
        //2 - IF USER DETAILS SUCCESSFUL THEN NEXT API TO REGISTER BUSINESS INFO

        let accessRequest = JSON.parse(localStorage.getItem('requestDetails'));
        console.log("accessRequest " + JSON.stringify(accessRequest));


        let customer = new Customer;
        customer.id = this.displayArray[0].group;
        // customer.code = this.customerCode;
        // customer.name = this.customerName;
        accessRequest.user.customer = customer;

        // accessRequest.user.customer = customer;
        console.log("submitting 3")
        let usr: User = JSON.parse(localStorage.getItem('newUserDetails'));
        // console.log("localStorage.getItem('newUserOrg') "+localStorage.getItem('newUserOrg'))
        // console.log("usr.organisation "+usr.organisation)
        console.log("usr " + JSON.stringify(usr));
        console.log("3 email " + usr.email);
        accessRequest.user.email = usr.email;

        accessRequest.user.contact.firstName = usr.firstName;
        accessRequest.user.contact.lastName = usr.lastName;
        // accessRequest.user.contact.email = usr.email;
        accessRequest.user.contact.contactNo = usr.contactNo;
        accessRequest.user.contact.lineManagerName = accessRequest.user.contact.lineManagerName;
        accessRequest.user.contact.lineManagerContactNo = accessRequest.user.contact.lineManagerContactNo;
        accessRequest.user.contact.organization = localStorage.getItem('newUserOrg');
        accessRequest.user.contact.locationType = 3;//this.selectedLoc;
        accessRequest.user.contact.locationId = 0;


    console.log("BEFORE SITES");
    let sites: Sites[] = [];

    for (let i : number = 0; i < this.addArray.length; i++) {
      let child = new Sites;
      let b = new Site;
      if (this.displayArray[i].id == null) {
        b.id = this.displayArray[i];
      } else {
        b.id = this.displayArray[i].id;
      }
      child.site=b;
      sites.push(child);
    }
    console.log("SITES - "+JSON.stringify(sites));
    let p=new Profile;
    p.sites=sites;

    accessRequest.user.profile=p;


        console.log(JSON.stringify(accessRequest));

        localStorage.removeItem('requestDetails');
        localStorage.setItem('requestDetails', accessRequest);

        localStorage.removeItem('addUserInProgress');
        // localStorage.setItem('addUserInProgress','false');
        localStorage.setItem('step1', 'false');
        localStorage.setItem('step2', 'false');
        localStorage.removeItem('groupObj');
        localStorage.removeItem('roleObj');

        // console.log(accessRequest);
        this.loading = true;

        let JSession=this.cookieService.get("JSESSIONID");
        this.accountService.requestAccessAdmin(accessRequest, JSession)
          .subscribe(data => {
           
            //SET USER STATUS AS ACTIVE IF SESSION USER IS SBV ADMIN
            let userR = parseInt(localStorage.getItem('currentUserRole'));
            //if session user is sbv admin
            if (userR == 6 || userR == 50) {


              console.log("USER IS SBV admin");
              let newStatus = {
                "user": {
                  "email": usr.email,
                  "status": 77
                }
              }

              console.log("Status - "+JSON.stringify(newStatus));

              let Jsession=this.cookieService.get("JSESSIONID");
              this.accountService.updateUserStatus(newStatus, Jsession)
                .subscribe(data => {
                  // this.alertService.success("User updated");

                },
                  error => {
                    if (error.status==500){
                      this.errorMessage="ERROR 500 OCCURRED";
                      this.hideErrorBanner=false;
                    }
                    if (error.status==408 || error.status==504){
                      this.errorMessage="ERROR 408/504 OCCURRED";
                      this.hideErrorBanner=false;
                    }
                    //   this.alertService.error("User not updated");
                  });
            }

            this.loading = false;
            setTimeout(() => this.router.navigate([this.returnUrl]), 2000);
          },
            error => {
              if (error.status==500){
                this.errorMessage="ERROR 500 OCCURRED";
                this.hideErrorBanner=false;
              }
              if (error.status==408 || error.status==504){
                this.errorMessage="ERROR 408/504 OCCURRED";
                this.hideErrorBanner=false;
              }

              this.alertService.error(error.error.error.message);
              this.loading = false;
            });

      },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          console.log("submitting 2 ERR")
          // console.log(error);
          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          this.form.enable();
          this.loading = false;
        });



    //ADD cash centres to payload

    this.loading = false;
  }

  getServerResponse(event) {

    console.log(event);
    let jSession=this.cookieService.get("JSESSIONID");
    console.log(jSession);
    this.isLoadingResult = true; 
    
    this.accountService.getCustomerAutocomplete(10, event,jSession)
      .subscribe(data => {
        // data = JSON.stringify(data['profile'].codes);
        console.log(data['customers'].customer)
        // if (data == undefined) {
        if (data['customers'].customer == undefined) {
          console.log("undefined " + data)
          this.data = [];
          // this.errorMsg = data['Error'];
        } else {
          this.data = data['customers'].customer;
        }

        this.isLoadingResult = false;
      });
  }

  searchCleared() {
    console.log('searchCleared');
    this.data = [];
  }

  onFocused(e) {
    // do something when input is focused
  }

  xButtton(){
    window.location.reload();
}


  customerId;
  customerCode;
  customerName;
  selectEvent(item) { //autocomplete
    // do something with selected item
    console.log(item)
    this.customerId = item.id;
    this.customerName = item.name;
    this.customerCode = item.code;
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  keyword = 'name';

}
