<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="container-fluid" style="padding: 25px">
    <!-- <div class="ps-4 pe-4 pt-4 container-fluid "> -->
        <div>
            <h6 style="margin-bottom: -20px">ORGANISATION</h6>
            <h2>Add a New User</h2>
            <h5>Manually register an account on behalf of a user.</h5>
        </div>
        <hr class="mt-0">

        <div class="d-flex flex-column align-items-center">
            <div class="row d-flex">
                <div class="p-2">
                    <ul class="nav nav-pills nav-underline">
                        <li><a class="pill-no-href">User Details</a></li>
                        <li><a class="pill-no-href">Role Details</a></li>
                        <li class="active"><a class="pill-no-href">Access Requirements</a></li>
                    </ul>
                </div>
            </div>


            <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">
                <div class="d-flex justify-content-between align-items-end">
                    <h3 class="h3-grey">Site Details</h3>
                    <p class="purple">Step 3/3</p>
                </div>
                <hr class="mt-0">

                Merchant users site details will be confirmed by the admin during approval.
            

                <div class="form-group mt-5">
                    <div class="d-flex justify-content-between" style="padding-top: 1em;">
                        <button [disabled]="loading" class="btn btn-default" routerLink="../user-management">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Back
                        </button>
                        <button [disabled]="loading" class="btn btn-primary" routerLink="../user-management">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Register New User
                        </button>
                    </div>
                    <!-- <button [disabled]="loading" class="rounded btn btn-primary w-100 ">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Verify Email
                        </button> -->
                </div>
            </div>
        </div>

    </div>

<!-- </div> -->