import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-forgot-password-finish',
  templateUrl: './forgot-password-finish.component.html'
})
export class ForgotPasswordFinishComponent implements OnInit {
  loading = false;
  submitted = false;
  resendNotAllowed: boolean = true;

  hideErrorBanner: boolean=true;
  errorMessage: string=" ";

  constructor(
    private accountService: AccountService,
    private alertService: AlertService,
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');

  }

  // resendAllowed:boolean = true;

  //  btn = document.getElementById("resendBtn");

  ngOnInit() {
    // setTimeout(() => resolve(this.getHeroes()), 2000);
    // btn.disabled = true;
    timer(60000).subscribe(x => (this.enableResend()));
  }

  enableResend() {
    this.resendNotAllowed = false;  //resend is allowed now
    // this.resendOtp();
  }

  xButton(){
    window.location.reload();
}

  // onSubmit() {
  //   this.submitted = true;
  //   this.alertService.clear();
  //   setTimeout(() => (this.onSubmit()), 60000);
  //   // reset alerts on submit
  //   // this.alertService.clear(); i u   


  // }

  resend() {

    this.loading = true;
    let ottId=sessionStorage.getItem("ottId");
    let ottCode=sessionStorage.getItem("ottCode");
    this.accountService.forgotPassword(sessionStorage.getItem('email'))
      .subscribe(data => {
        // console.log(data);
        // console.log(JSON.stringify(data));
        this.loading = false;
        // this.router.navigate([this.returnUrl]);
      },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.');
            this.loading = false;
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          this.loading = false;

        });
    this.loading = false;
    this.resendNotAllowed = true; //after api call resend is not allowed
    timer(60000).subscribe(x => (this.enableResend()));
  }
}
