import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import * as shajs from 'sha.js';

@Component({
  selector: 'app-reset-password-success',
  templateUrl: './reset-password-success.component.html'
})
export class ResetPasswordSuccessComponent implements OnInit {


  constructor(
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }

  ngOnInit(): void {

  }

}

