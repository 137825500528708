<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="container-fluid" style="padding: 25px">
    <!-- <div class="ps-4 pe-4 pt-4 container-fluid "> -->
        <div>
            <h6 style="margin-bottom: -20px">Switch Profile</h6>
            <h2>Create another Profile</h2>
            <h5>Manually register an account on behalf of a user.</h5>
        </div>
        <hr class="mt-0">

        <div class="d-flex flex-column min-vh-100 align-items-center">
            <div class="row d-flex">
                <div class="p-2">
                    <ul class="nav nav-pills nav-underline">
                        <li><a class="pill-no-href">User Details</a></li>
                        <li class="active"><a class="pill-no-href">Profile Switch</a></li>
                        
                    </ul>
                </div>
                
            </div>


            <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">
                <div class="d-flex justify-content-between align-items-end">
                    <h3 class="h3-grey">Special user settings</h3>
                    <p class="purple">Step 3/4</p>
                </div>
                <hr class="mt-0">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group" >
                    <input style="margin-left:10px;" type="checkbox" id="switchProfile"  formControlName="switchProfile"
                    (change)="onCheckboxChange($event)"
                    >
                    <label for="switchProfile" class="control-label" style="font-size: 1.65rem; margin-left: 10px;">Enable Profile Switch</label>
                
                <div class="form-group" *ngIf="showRoleDropdown">
                    <div style="height:10px">
                    </div>
                    <label class="control-label" for="switchProfile">Select Primary User</label>
                    <ng-multiselect-dropdown 
                     formControlName="selectedUser" 
                        style="font-size: 12px; border-radius: 50px;" [placeholder]="'Select Primary User'"
                        [settings]="dropdownSettingsUsers" [data]="dropdownListUsers"
                        (onSelect)="onItemSelectUser($event)"
                        (onDeSelect)="onItemDeSelectUser($event)"
                        (onDeSelectAll)="onDeSelectAll($event)"
                         class="singleselect" 
                        >
                    </ng-multiselect-dropdown>
                    <div *ngIf="!switchUserChosen" style="font-size: 80%; color: #dc3545">
                        <div *ngIf="!switchUserChosen || form.get('selectedUser').invalid">User is required</div>
                    </div>
            </div>
            <button [disabled]="loading  || (form.get('switchProfile').value && form.get('selectedUser').invalid)" class="btn btn-primary" (click)="onSubmit()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Next
            </button>
            </div>
        
        </form>
</div>
    </div>

<!-- </div> -->
