
import { MatSidenav } from '@angular/material/sidenav';
import { Component, ElementRef, Renderer2, ViewChild,  OnInit, Input, Sanitizer } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common';
import { EventEmitterService } from './_services/event-emitter.service';
import { CookieService } from 'ngx-cookie';
import { HostListener } from '@angular/core';
import { AccountService, AlertService } from 'src/app/_services'; 
import { Subject } from 'rxjs';
 

@Component({
  selector: 'app-root',

  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  path = '';
  timeoutId;
  userInactive: Subject<any> = new Subject();
  browserName = '';
  browserVersion = '';
  title = 'sbv';
  data;
  loggedIn: string;
  hideIM: boolean=true;
  isOp: boolean=true;
  isBillingUser:boolean=true;
  hideBP: boolean=true;
  hideBL:boolean=true;
  hideL:boolean=false;
  hideInfo:boolean=false;
  devUrl: SafeResourceUrl;
  hideRouter:boolean=false;
  hideIframe:boolean=true;
  hideOm:boolean=true;

  currentUserName:string="";
  currentUserRole:number;
  currentUserGroup:number;
  currentUserOrg:string;
  getScreenWidth: any;
  hideMenu:boolean=true;

  hideDashboard:boolean=false;
  hideTaskList:boolean=false;
  hideCaseList:boolean=false;
  hideLogCase:boolean=false;
  hideUserAccess:boolean=false;
  hideReport:boolean=false;
  hideNatDashboard:boolean=true;
  hideBankViews:boolean=true;
  hideMerchant:boolean=true
  roleChangeHidden:boolean=true;
  roleSwitchActivated:boolean;
  changeToDepartment:string;

  hideOptions:boolean=true;

  tooltip_disable:boolean=true;

  sessionTO:boolean=false;

  hideScrollbar:boolean=false;

  userInitials:string;
  userOrg:string;

  page:string=""
  page1:string="";
  page2:string="";

  superUserLoggedIn:boolean=false;

  count:number=0;
  currentSW:number=window.innerWidth;
  loadWidth:number=0;
  loadOpen:boolean=false;
  hideSuperUserLogout: boolean=true;
  videoParam:string;

  caseListURL:string="./bonita/apps/SBVConnect/case-list/";
  dashboardURL:string="./bonita/apps/SBVConnect/dashboard/";
  reportURL:string="./bonita/apps/SBVConnect/reports-individual/";
  imURL:string="./bonita/apps/SBVConnect/incident-process/";
  lcURL:string="./bonita/apps/SBVConnect/log-query/";
  bpURL:string="./bonita/apps/SBVConnect/billing-process/";
  taskListURL:string="./bonita/apps/SBVConnect/task-list/";
  @ViewChild('sidenav') sidenav: MatSidenav | undefined;
  

  constructor(
    private router: Router, private location: Location,
    private actRoute: ActivatedRoute,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    iconRegistry:MatIconRegistry,
    sanitizer:DomSanitizer,
    sanit:DomSanitizer,
    private accountService: AccountService,
    private cookieService: CookieService,
    private dashEventEmitterService: EventEmitterService,
    private caseListEventEmitterService: EventEmitterService,
    private taskListEventEmitterService: EventEmitterService,
    private logCaseEventEmitterService: EventEmitterService,
    private logCaseIncidentEventEmitterService: EventEmitterService,
    private reportEventEmitterService: EventEmitterService,
    private pDashEventEmitterService: EventEmitterService,
    private bankViewEventEmitterService: EventEmitterService,
    private billingEventEmitterService: EventEmitterService,
    private incidentEventEmitterService: EventEmitterService
  ) 

  {
  
    this.checkTimeOut();

    if("URL" in sessionStorage){ //User is logged in and navigating
      this.devUrl = sanit.bypassSecurityTrustResourceUrl(sessionStorage.getItem("URL"));
    }
    else{
      //this.devUrl = " ";
      this.devUrl = sanit.bypassSecurityTrustResourceUrl(" ");
    }

 
    this.router.events.subscribe((val) => {
      this.path = this.location.path();
    });

    //Organisation
    iconRegistry.addSvgIcon('OrganisationDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Organisation/Icons/Property 1=Default.svg'));
    iconRegistry.addSvgIcon('OrganisationBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Organisation/Icons/Property 1=blue.svg'));
    iconRegistry.addSvgIcon('OrganisationWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Organisation/Icons/Property 1=white.svg'));

    //Case list
    iconRegistry.addSvgIcon('CaselistDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Case List/Icons/Property 1=grey.svg'));
    iconRegistry.addSvgIcon('CaselistBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Case List/Icons/Property 1=blue.svg'));
    iconRegistry.addSvgIcon('CaselistWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Case List/Icons/Property 1=white.svg'));
     
    //Case Management
    iconRegistry.addSvgIcon('CaseManagementDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Case Management/Icons/Property 1=Default.svg'));
    iconRegistry.addSvgIcon('CaseManagementBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Case Management/Icons/Property 1=blue.svg'));
    iconRegistry.addSvgIcon('CaseManagementWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Case Management/Icons/Property 1=white.svg'));
    
    //Dashboard
    iconRegistry.addSvgIcon('DashboardDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Dashboard/Icons/Property 1=grey.svg'));
    iconRegistry.addSvgIcon('DashboardBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Dashboard/Icons/Property 1=blue.svg'));
    iconRegistry.addSvgIcon('DashboardWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Dashboard/Icons/Property 1=white.svg'));
    
    //Help Centre
    iconRegistry.addSvgIcon('HelpCentreDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Help Centre/Icons/Property 1=grey.svg'));
    iconRegistry.addSvgIcon('HelpCentreBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Help Centre/Icons/Property 1=blue.svg'));
    iconRegistry.addSvgIcon('HelpCentreWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Help Centre/Icons/Property 1=white.svg'));
    
    //Home
    iconRegistry.addSvgIcon('HomeDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Home/Icons/Property_1_grey.svg'));
    iconRegistry.addSvgIcon('HomeBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Home/Icons/Property_1_blue.svg'));
    iconRegistry.addSvgIcon('HomeWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Home/Icons/Property_1_white.svg'));
    
    //Logout
    iconRegistry.addSvgIcon('LogoutDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Logout/Icons/Property 1=grey.svg'));
    iconRegistry.addSvgIcon('LogoutBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Logout/Icons/Property 1=blue.svg'));
    iconRegistry.addSvgIcon('LogoutWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Logout/Icons/Property 1=white.svg'));
    
    //Manage Users
    iconRegistry.addSvgIcon('ManageUsersDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Manage Users/Icons/Property 1=grey.svg'));
    iconRegistry.addSvgIcon('ManageUsersBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Manage Users/Icons/Property 1=blue.svg'));
    iconRegistry.addSvgIcon('ManageUsersWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Manage Users/Icons/Property 1=white.svg'));
    
    //Menu
    iconRegistry.addSvgIcon('MenuDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Menu/Icons/Property 1=grey.svg'));
    iconRegistry.addSvgIcon('MenuBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Menu/Icons/Property 1=blue.svg'));
    iconRegistry.addSvgIcon('MenuWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Menu/Icons/Property 1=white.svg'));
    
    //New Case
    iconRegistry.addSvgIcon('NewCaseDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/New Case/Icons/Property 1=grey.svg'));
    iconRegistry.addSvgIcon('NewCaseBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/New Case/Icons/Property 1=blue.svg'));
    iconRegistry.addSvgIcon('NewCaseWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/New Case/Icons/Property 1=white.svg'));
    
    //Profile Settings
    iconRegistry.addSvgIcon('ProfileSettingsDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Profile Settings/Icons/Property 1=grey.svg'));
    iconRegistry.addSvgIcon('ProfileSettingsBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Profile Settings/Icons/Property 1=blue.svg'));
    iconRegistry.addSvgIcon('ProfileSettingsWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Profile Settings/Icons/Property 1=white.svg'));
    
    //Task List
    iconRegistry.addSvgIcon('TaskListDef',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Task List/Icons/Property 1=grey.svg'));
    iconRegistry.addSvgIcon('TaskListBlue',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Task List/Icons/Property 1=blue.svg'));
    iconRegistry.addSvgIcon('TaskListWhite',sanitizer.bypassSecurityTrustResourceUrl('assets/Icons/Task List/Icons/Property 1=white.svg'));
    
  }


  ngOnInit() {

    // this.actRoute.queryParams.subscribe( params=>{
    //   this.videoParam=params['caseId'];
    //   if(this.videoParam != undefined || this.videoParam !=null || this.videoParam !=''){
    //     this.videoParam=this.page;
    //     sessionStorage.setItem('videoParam',this.videoParam);
    //     console.log("appComponent ID - "+this.videoParam);
    //   }
    //   else{
    //     console.log("appComponent No ID");
    //     console.log("ID - "+this.videoParam);
    //   }
    //   });

    this.hideBL=true;
    this.hideL =false;

    if(this.cookieService.get("REF")=="Done"){
      this.cookieService.remove("REF");
    }
    if(this.cookieService.get("LOGGER")=="Done"){
      this.cookieService.remove("LOGGER");
    }
    if(this.cookieService.get("CITCODE")=="Done"){
      this.cookieService.remove("CITCODE");
    }
    if(this.cookieService.get("NOTES")=="Done"){
      this.cookieService.remove("NOTES");
    }
    if(this.cookieService.get("TYPE")=="Done"){
      this.cookieService.remove("TYPE");
    }
    if(this.cookieService.get("SUBTYPE")=="Done"){
      this.cookieService.remove("SUBTYPE");
    }

    if(sessionStorage.getItem("superUserLogin")=="true"){
      this.superUserLoggedIn=true;
    }
    else{
      this.superUserLoggedIn=false;
    }

    this.browserName = this.detectBrowserName();
    this.browserVersion = this.detectBrowserVersion();

    this.loadWidth=window.innerWidth;
    if(sessionStorage.getItem("first-load")=="true"){
      if(this.loadWidth>=1366){
        this.loadOpen=true;
        this.hideL=true;
        this.hideBL=false;
        this.tooltip_disable=true;
        this.hideInfo=false;
        sessionStorage.setItem("menu","open");
      }
      else{
        this.loadOpen=false;
        this.hideL=false;
        this.hideBL=true;
        this.hideInfo=true;
        this.tooltip_disable=false;
        sessionStorage.setItem("menu","closed");
      }
    }

    if(sessionStorage.getItem("first-load")=="false")
    {
        if(sessionStorage.getItem("menu")=="open"){
          this.loadOpen=true;
          this.hideL=true;
          this.hideBL=false;
          this.tooltip_disable=true;
          this.hideInfo=false;
          sessionStorage.setItem("menu","open");
      }
      if(sessionStorage.getItem("menu")=="closed"){
        this.loadOpen=false;
        this.hideL=false;
        this.hideBL=true;
        this.hideInfo=true;
        this.tooltip_disable=false;
        sessionStorage.setItem("menu","closed");
      }
    }
    
    sessionStorage.setItem("first-load","false");

    if(sessionStorage.getItem("login-status")=="logged in"){

    if (this.dashEventEmitterService.dashSubsVar==undefined) {    
      this.dashEventEmitterService.dashSubsVar = this.dashEventEmitterService.    
      invokeDashboardFunction.subscribe((name:string) => {    
        this.viewDashboard();    
      });    
    }

    if (this.pDashEventEmitterService.pDashSubsVar==undefined) {    
      this.pDashEventEmitterService.pDashSubsVar = this.pDashEventEmitterService.    
      invokePDashboardFunction.subscribe((name:string) => {    
        this.viewNatDashboard(); 
      });    
    }

    if (this.billingEventEmitterService.billingViewSubsVar==undefined) {    
      this.billingEventEmitterService.billingViewSubsVar = this.billingEventEmitterService.    
      invokeBillingFunction.subscribe((name:string) => {    
        this.viewBillingProcess(); 
      });    
    }

    if (this.caseListEventEmitterService.CLSubsVar==undefined) {    
      this.caseListEventEmitterService.CLSubsVar = this.caseListEventEmitterService.    
      invokeCaseListFunction.subscribe((name:string) => {    
        this.viewCaseList();  
      });    
    }

    if (this.taskListEventEmitterService.TLSubsVar==undefined) {    
      this.taskListEventEmitterService.TLSubsVar = this.taskListEventEmitterService.    
      invokeTaskListFunction.subscribe((name:string) => {    
        this.viewTaskList(); 
      });    
    }

    if (this.logCaseEventEmitterService.LCSubsVar==undefined) {    
      this.logCaseEventEmitterService.LCSubsVar = this.logCaseEventEmitterService.    
      invokeLogCaseFunction.subscribe((name:string) => {    
        this.viewLogCase();  
      });    
    }

    if (this.reportEventEmitterService.reportSubsVar==undefined) {    
      this.reportEventEmitterService.reportSubsVar = this.reportEventEmitterService.    
      invokeReportFunction.subscribe((name:string) => {    
        this.viewReports();  
      });    
    }

    if (this.bankViewEventEmitterService.bankViewSubsVar==undefined) {    
      this.bankViewEventEmitterService.bankViewSubsVar = this.bankViewEventEmitterService.    
      invokeBankViewFunction.subscribe((name:string) => {    
        this.viewBankViews();  
      });    
    }

    if (this.incidentEventEmitterService.incidentViewSubsVar==undefined) {    
      this.incidentEventEmitterService.incidentViewSubsVar = this.incidentEventEmitterService.    
      invokeIncidentFunction.subscribe((name:string) => {    
        this.viewIM();  
      });    
    }

  }
    
  this.data = localStorage.length;
    if (localStorage.getItem('loggedIn') == 'true') {
      this.loggedIn = 'true';
    } else {
      this.loggedIn = 'false';
    }

    if  ((this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')) && this.router.url !="/video-footage"){
     this.hideMenu = false;
    }else{
      this.hideMenu = true;
      this.hideRouter=false;
      this.hideIframe=true;
    }

      if(sessionStorage.getItem("Page")=="Bonita"){
        this.hideIframe=false;
        this.hideRouter=true;
        this.hideScrollbar=true;
      }
      else if(sessionStorage.getItem("Page")=="Router"){
        this.hideIframe=true;
        this.hideRouter=false;
        this.hideScrollbar=false;
      }
      else{
        this.hideIframe=true;
        this.hideRouter=false;
        this.hideScrollbar=false;

      }


    if(sessionStorage.getItem("login-status")=="logged in"){
      let JSession=this.cookieService.get("JSESSIONID");
      this.accountService.getCurrentUser(sessionStorage.getItem("email"), JSession)
                        .subscribe(data => {
                            this.currentUserName= data.users.user.firstName+" "+data.users.user.lastName;
                            console.log("NAME : "+this.currentUserName)
                            this.currentUserOrg=data.users.user.organization;
                            this.currentUserRole=data.users.user.roles.role.id;
                            this.currentUserGroup=data.users.user.roles.role.groupId;
                            this.roleSwitchActivated = data.users.user.hasSwitch;
                            console.log("Switch");
                            
                            console.log(this.roleSwitchActivated);
                            


                            const fullName = this.currentUserName.split(' ');
                            const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
                            this.userInitials=initials.toUpperCase();
                            console.log("INITIALS : "+this.userInitials)
                            this.activateRoleChange();
                            if(this.currentUserRole==6 || this.currentUserRole==50){//User is SBV admin
                              this.hideMerchant=false;
                            }
                            else{
                              this.hideMerchant=true;
                            }

                            if(this.currentUserRole ==14 || this.currentUserRole ==2 || this.currentUserRole ==16 || this.currentUserRole ==15){ //CLO user
                              this.hideIM=false;
                            }
                            else{
                              this.hideIM=true;
                            }

                            if(this.currentUserRole == 34 || this.currentUserRole ==35 || this.currentUserRole ==36 || this.currentUserRole ==37|| this.currentUserRole ==38 || this.currentUserRole ==39 || this.currentUserRole ==40 || this.currentUserRole == 55){ //Ops user
                              this.isOp=true;
                              this.reportURL="./bonita/apps/SBVConnect/reports-individual/";
                            }
                            else{
                              this.isOp=false;
                            }

                            if(this.currentUserRole == 50 || this.currentUserRole == 51 || this.currentUserRole == 52 || this.currentUserRole ==53 || this.currentUserRole ==54){
                              this.hideOm=false;
                            }
                            else{
                              this.hideOm=true
                            }


                            if((this.currentUserRole == 26 || this.currentUserRole ==27 || this.currentUserRole ==28 || this.currentUserRole ==29 || this.currentUserRole ==31 || this.currentUserRole ==33 || this.currentUserRole ==11)){
                              this.hideBP=false;
                              this.hideLogCase=true;
                            }
                            else{
                              this.hideBP=true;
                            }

                            if(this.currentUserGroup == 3){//Merchant User
                              this.hideBP=false;
                              this.hideLogCase=false;
                            }
                            if(this.currentUserRole==33 || this.currentUserRole==31){
                              this.hideBankViews=true;
                            }

                            if(this.currentUserRole == 26 || this.currentUserRole ==27){
                              this.reportURL="./bonita/apps/SBVConnect/reports-individual/";
                            }
                            else if(this.currentUserRole ==28 || this.currentUserRole ==29 || this.currentUserRole ==30){
                              this.reportURL="./bonita/apps/SBVConnect/reports/";
                            }
                            else{
                              console.log("No report access");
                            }

                            if(this.currentUserRole==6 || this.currentUserRole==22 || this.currentUserRole==50) { //SBV admin or Bank admin logged in 
                              this.hideUserAccess=false;//Only admins can access users
                              this.hideLogCase=true;
                             }
                   
                            else{
                               this.hideUserAccess=true;
                            }

                      

                          
                            if(this.currentUserGroup==1){  //SBV user (internal user)
                             if(this.currentUserRole!=6){ // Only show if internal user but NOT admin
                               this.hideReport=false;
                             }
                             this.caseListURL="./bonita/apps/SBVConnect/case-list-internal/";
                             this.dashboardURL="./bonita/apps/SBVConnect/dashboard-internal/";
                         } 
                       else{ //External user
                           this.hideReport=true; 
                           this.caseListURL="./bonita/apps/SBVConnect/case-list/";
                           this.dashboardURL="./bonita/apps/SBVConnect/dashboard/";
                   
                       }

                       if(this.currentUserGroup ==2 && this.currentUserRole !=33){  //Bank user and not finance manager
                          this.hideReport=false;
                          if(this.currentUserRole ==8 || this.currentUserRole ==7 || this.currentUserRole ==20 || this.currentUserRole ==22 || this.currentUserRole ==21 || this.currentUserRole ==10 || this.currentUserRole ==19){
                            this.reportURL="./bonita/apps/SBVConnect/reports/";
                          }
                          else if(this.currentUserRole ==9){
                            this.reportURL="./bonita/apps/SBVConnect/reports-regional/";
                          }
                          else{
                            this.reportURL="./bonita/apps/SBVConnect/reports-individual/";
                          }
                       }
                   
                       if(this.currentUserGroup==1){ //SBV user
                         if(this.currentUserRole==2 || this.currentUserRole==16 || this.currentUserRole==50){ //National SBV user
                           this.hideNatDashboard=false;
                           this.hideBankViews=false;
                           this.reportURL="./bonita/apps/SBVConnect/reports/";
                         }
                   
                         if(this.currentUserRole==15){ //Regional user (Regional Team Leader)
                           this.reportURL="./bonita/apps/SBVConnect/reports-regional/";
                         }
                       }

                       if(this.currentUserGroup==2 && this.currentUserRole==23){//Read only user
                          this.hideDashboard=false;
                          this.hideCaseList=false;
                          this.hideLogCase=true;
                          this.hideBankViews=true;
                          this.hideNatDashboard=true;
                          this.hideReport=true;
                          this.hideTaskList=true;
                       }

                       if(this.currentUserRole==50){
                        this.hideUserAccess=false;//Only admins can access users
                        this.hideLogCase=false;
                        this.hideReport=false;
                        this.hideBP=false;
                        this.hideBankViews=false;
                        this.hideNatDashboard=false;
                        this.hideIM=false;
                      }

                      if(this.currentUserRole == 4 || this.currentUserRole == 37 || this.currentUserRole ==38 || this.currentUserRole ==39 || this.currentUserRole ==40){
                        this.hideReport=true;
                      }

                      if(this.currentUserRole== 26|| this.currentUserRole== 27|| this.currentUserRole== 28|| this.currentUserRole== 29|| this.currentUserRole== 30|| this.currentUserRole== 36|| this.currentUserRole== 38|| this.currentUserRole== 39|| this.currentUserRole== 40 || this.currentUserRole == 55){
                        this.hideBankViews=false;
                      }

                      if(this.currentUserRole == 4 || this.currentUserRole == 36 || this.currentUserRole == 37 || this.currentUserRole ==38 || this.currentUserRole ==39 || this.currentUserRole ==40 || this.currentUserRole == 55){
                        this.hideLogCase=true;
                        this.hideBankViews=true;
                      }

                      if(this.currentUserRole == 30){
                        this.hideLogCase=true;
                        this.hideBP=true;
                      }
                      
                          this.hideOptions=false;

                          this.actRoute.queryParams.subscribe( params=>{
                            this.page=params['pageDirect'];
                            if(this.page=="log-new-case"){
                              sessionStorage.setItem("URL",this.lcURL);
                              this.page=""; 
                              window.location.href = `./log-new-case`;
                            }
                            if(this.page=="create-billing-case"){
                              sessionStorage.setItem("URL",this.bpURL);
                              this.page=""; 
                              window.location.href = `./create-billing-case`;
                            }
                            if(this.page=="case-list"){
                      
                              if(this.currentUserGroup==1){  //SBV user (internal user)
                                sessionStorage.setItem("URL","./bonita/apps/SBVConnect/case-list-internal/");
                            } 
                          else{ //External user
                              sessionStorage.setItem("URL","./bonita/apps/SBVConnect/case-list/");
                          }
                              //sessionStorage.setItem("URL",this.caseListURL);
                              this.page=""; 
                              window.location.href = `./case-list`;
                            }
                      
                            if(this.page=="dashboard"){
                      
                              if(this.currentUserGroup==1){  //SBV user (internal user)
                                sessionStorage.setItem("URL","./bonita/apps/SBVConnect/dashboard-internal/");
                            } 
                          else{ //External user
                            sessionStorage.setItem("URL","./bonita/apps/SBVConnect/dashboard/");
                          }
                              //sessionStorage.setItem("URL",this.dashboardURL);
                              this.page=""; 
                              window.location.href = `./dashboard`;
                            }
                            if(this.page=="task-list"){
                              sessionStorage.setItem("URL",this.taskListURL);
                              this.page=""; 
                              window.location.href = `./task-list`;
                            }
                            });

                            this.actRoute.queryParams.subscribe( params=>{
                              this.page1=params['action'];
                              if(this.page1=="videoSubmit"){
                                window.location.href = `./video-footage`;
                                this.showRouterOutlet();
                              }
                              });

                              // this.actRoute.queryParams.subscribe( params=>{
                              //   this.page2=params['caseId'];
                              //   if(this.page2!=undefined){
                              //     sessionStorage.setItem("caseId",this.page2.toString());
                              //   }
                              //sessionStorage.setItem("caseId",this.page2);
                              //   });

                        },
                        error => {
                            console.log(error)
                        });
    
     }

      this.hideOptions=false;

      if(sessionStorage.getItem("superUserLogin")=="true"){
        this.hideSuperUserLogout=false;
      }
      else{
        this.hideSuperUserLogout=true;
      }
      
}


// switchRole() {
//   let JSession=this.cookieService.get("JSESSIONID")
//   console.log('testing!')

//   this.accountService.doSwitch(JSession)
//   .subscribe((data)=> {
//     console.log('switch role activated')
//   }, error => {
//     console.log(error)
//   })
// }

activateRoleChange(){
  
  if(this.roleSwitchActivated){
    this.roleChangeHidden = false;
    if( this.currentUserRole==7 || this.currentUserRole==8 || this.currentUserRole==9 || this.currentUserRole==10 || this.currentUserRole==19 || this.currentUserRole==20 || this.currentUserRole==21 || this.currentUserRole==22 || this.currentUserRole==23){
      this.changeToDepartment="Switch to Billing";
    }else{
      this.changeToDepartment="Switch To Ops";
    }
  }else{
    this.roleChangeHidden = true;
  }
  
  
}

 


  showRouterOutlet(){
    this.hideIframe=true;
    this.hideRouter=false;
    // sessionStorage.setItem("Previous-Page",sessionStorage.getItem("Page"));
    // if(sessionStorage.getItem("Page")=="Bonita"){
    //   sessionStorage.setItem("prev-URL",sessionStorage.getItem("URL"));
    // }
    sessionStorage.setItem("Page","Router");
  }

  showIFrame(){
    this.hideIframe=false;
    this.hideRouter=true;
  }

  checkTimeOut() {
 
    this.timeoutId = setTimeout(() =>{

       this.hideIframe=true;
      this.hideRouter=false;
      this.router.navigate(['/logout'])}, 900000
      
    );
   
  }
 //screen activity
  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  @HostListener('window:mousemove') 
  @HostListener('window:scroll') 
  @HostListener('click') 
  @HostListener('scroll') 
  @HostListener('wheel') 
  @HostListener('touchmove') 

  checkUserActivity() {
 
    clearTimeout(this.timeoutId);
 
    this.checkTimeOut();
  }

  @HostListener('window:resize')
  onResize() {

    this.currentSW=window.innerWidth;

    if(this.currentSW>=1366){
      //console.log(""+this.currentSW);
      //console.log("resolution>=1366");
      if(sessionStorage.getItem("menu")=="open"){
        console.log("Menu already opened");
      }
      else{ 
      this.openMenu();
      }
    }

    else if(this.currentSW<1366 && this.currentSW>=1280){
      //console.log(""+this.currentSW);
      //console.log("1280<=resolution<1366");
      if(sessionStorage.getItem("menu")=="closed"){
        console.log("Menu already closed");
      }
      else{ 
      this.closeMenu();
      }
    }
    
    else if(this.currentSW<1280 && this.currentSW>=1024){
      //console.log(""+this.currentSW);
      //console.log("1024<=resolution<1288");
      if(sessionStorage.getItem("menu")=="closed"){
        console.log("Menu already closed");
      }
      else{ 
      this.closeMenu();
      }
    }

    else{
      //console.log("resolution less than 1024");
      //console.log(""+this.currentSW);
      if(sessionStorage.getItem("menu")=="closed"){
        console.log("Menu already closed");
      }
      else{ 
      this.closeMenu();
      }
    }

}

// @HostListener('window:popstate')
// onBrowserBackBtnClose() {

//   if(sessionStorage.getItem("Previous-Page")=="Router"){
//     this.hideRouter=false;
//     this.hideIframe=true;
//     sessionStorage.setItem("Page","Router");
//   }

//   if(sessionStorage.getItem("Previous-Page")=="Bonita"){
//     this.hideIframe=false;
//     this.hideRouter=true;
//     sessionStorage.setItem("Page","Bonita");
//     sessionStorage.setItem("URL",sessionStorage.getItem("prev-URL"));
//     window.location.reload();
//   }
// }


  viewDashboard(){
  
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
      this.hideRouter=true;
      // sessionStorage.setItem("Previous-Page",sessionStorage.getItem("Page"));

      // if(sessionStorage.getItem("Page")=="Bonita"){
      //   sessionStorage.setItem("prev-URL",sessionStorage.getItem("URL"));
      // }

      sessionStorage.setItem("Page","Bonita");
      sessionStorage.setItem("URL",this.dashboardURL);

      setTimeout(() => {
       window.location.reload();
        }, 100);

        
          
        }else{
          this.hideIframe=true;
          this.hideRouter=false;
          this.router.navigate(['/logout']);
        }
    
   
  }

  viewIM(){
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
      this.hideRouter=true;
      // sessionStorage.setItem("Previous-Page",sessionStorage.getItem("Page"));

      // if(sessionStorage.getItem("Page")=="Bonita"){
      //   sessionStorage.setItem("prev-URL",sessionStorage.getItem("URL"));
      // }

      sessionStorage.setItem("Page","Bonita");
      sessionStorage.setItem("URL",this.imURL); 
    
      setTimeout(() => {
        window.location.reload();
          }, 100);
        }else{
          this.hideIframe=true;
          this.hideRouter=false;
          this.router.navigate(['/logout']);
        }    
   
  }

  viewTaskList(){
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
      this.hideRouter=true;
      // sessionStorage.setItem("Previous-Page",sessionStorage.getItem("Page"));

      // if(sessionStorage.getItem("Page")=="Bonita"){
      //   sessionStorage.setItem("prev-URL",sessionStorage.getItem("URL"));
      // }

      sessionStorage.setItem("Page","Bonita");
      sessionStorage.setItem("URL","./bonita/apps/SBVConnect/task-list/");

    setTimeout(() => {
      window.location.reload(); 
        }, 100);

      }else{
        this.hideIframe=true;
        this.hideRouter=false;
        this.router.navigate(['/logout']);
      }
     
  
  }

  viewCaseList(){
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
      this.hideRouter=true;
      // sessionStorage.setItem("Previous-Page",sessionStorage.getItem("Page"));

      // if(sessionStorage.getItem("Page")=="Bonita"){
      //   sessionStorage.setItem("prev-URL",sessionStorage.getItem("URL"));
      // }

      sessionStorage.setItem("Page","Bonita");
      sessionStorage.setItem("URL",this.caseListURL);

    setTimeout(() => {
      window.location.reload();
        }, 100);

      }else{
        this.hideIframe=true;
        this.hideRouter=false;
        this.router.navigate(['/logout']);
      }
  
  }

  viewLogCase(){
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
      this.hideRouter=true;
      // sessionStorage.setItem("Previous-Page",sessionStorage.getItem("Page"));

      // if(sessionStorage.getItem("Page")=="Bonita"){
      //   sessionStorage.setItem("prev-URL",sessionStorage.getItem("URL"));
      // }

      sessionStorage.setItem("Page","Bonita");
      sessionStorage.setItem("URL","./bonita/apps/SBVConnect/log-query/"); 
      this.router.navigate(['/log-new-case']);
    
      setTimeout(() => {
        window.location.reload();
          }, 100);
        }else{
          this.hideIframe=true;
          this.hideRouter=false;
          this.router.navigate(['/logout']);
        }    
   
  }

  viewLogCaseIncident(){
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
      this.hideRouter=true;

      sessionStorage.setItem("Page","Bonita");
      sessionStorage.setItem("URL","./bonita/apps/SBVConnect/log-query/"); 
      this.router.navigate(['/log-new-case']);
    
      setTimeout(() => {
        window.location.reload();
          }, 100);
        }else{
          this.hideIframe=true;
          this.hideRouter=false;
          this.router.navigate(['/logout']);
        }    
   
  }

  viewBillingProcess(){
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
      this.hideRouter=true;
      // sessionStorage.setItem("Previous-Page",sessionStorage.getItem("Page"));

      // if(sessionStorage.getItem("Page")=="Bonita"){
      //   sessionStorage.setItem("prev-URL",sessionStorage.getItem("URL"));
      // }

      sessionStorage.setItem("Page","Bonita");
      sessionStorage.setItem("URL",this.bpURL); 
    
      setTimeout(() => {
        window.location.reload();
          }, 100);
        }else{
          this.hideIframe=true;
          this.hideRouter=false;
          this.router.navigate(['/logout']);
        }    
   
  }

  viewReports(){
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
      this.hideRouter=true;
      // sessionStorage.setItem("Previous-Page",sessionStorage.getItem("Page"));
      
      // if(sessionStorage.getItem("Page")=="Bonita"){
      //   sessionStorage.setItem("prev-URL",sessionStorage.getItem("URL"));
      // }
      
      sessionStorage.setItem("Page","Bonita");
      sessionStorage.setItem("URL",this.reportURL); 
  
    setTimeout(() => {
      window.location.reload();
        }, 100);

      }else{
        this.hideIframe=true;
        this.hideRouter=false;
        this.router.navigate(['/logout']);
      }

  }

  viewNatDashboard(){
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){

      this.hideRouter=true;
      // sessionStorage.setItem("Previous-Page",sessionStorage.getItem("Page"));
      
      // if(sessionStorage.getItem("Page")=="Bonita"){
      //   sessionStorage.setItem("prev-URL",sessionStorage.getItem("URL"));
      // }
      
      sessionStorage.setItem("Page","Bonita");
      sessionStorage.setItem("URL","./bonita/apps/SBVConnect/dashboard-national/"); 
  
    setTimeout(() => {
      window.location.reload();
        }, 100);

      }else{
        this.hideIframe=true;
        this.hideRouter=false;
        this.router.navigate(['/logout']);
      }

  }


  viewhome(){
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
      setTimeout(() => {
        window.location.reload();
       }, 100);
      this.hideIframe=true;
      this.hideRouter=false;
      sessionStorage.setItem("Page","Router");
     
      }else{
        this.hideIframe=true;
        this.hideRouter=false;
        this.router.navigate(['/logout']);
      }

  }


  viewBankViews(){
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
    this.hideRouter=true;
    // sessionStorage.setItem("Previous-Page",sessionStorage.getItem("Page"));
    sessionStorage.setItem("Page","Bonita");
    sessionStorage.setItem("URL","./bonita/apps/SBVConnect/cases/");

    setTimeout(() => {
      window.location.reload();
        }, 100);
    }else{
      this.hideIframe=true;
      this.hideRouter=false;
        this.router.navigate(['/logout']);
      }


  }

  viewOM(){
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
    this.hideRouter=true;
    let JSession=this.cookieService.get("JSESSIONID")
    sessionStorage.setItem("Page","Bonita");
    sessionStorage.setItem("URL","./order-management/?jsession="+JSession);

    setTimeout(() => {
      window.location.reload();
        }, 100);
    }else{
      this.hideIframe=true;
      this.hideRouter=false;
        this.router.navigate(['/logout']);
      }


  }


   imageHome(){ 
    if (this.cookieService.hasKey('JSESSIONID')||this.cookieService.hasKey('X-Bonita-API-Token')){
      if(sessionStorage.getItem("Page")=="Router"){ //Currently on an Angular page 
  
       sessionStorage.setItem("Page","Router")
      }
      else{
        sessionStorage.setItem("Page","Router")
        setTimeout(() => {
        window.location.reload();
        }, 50)
      }
    }else{
      this.hideIframe=true;
      this.hideRouter=false;
      this.router.navigate(['/logout']);
    }
  
 }

  animateSidenav() {
    this.loadOpen=false;
    const isSideNavOpened = this.sidenav?.opened;
    const $matSidenav = this.elementRef.nativeElement.querySelector(
      'mat-sidenav'
    );
    const $matSidenavContent = this.elementRef.nativeElement.querySelector(
      'mat-sidenav-content'
    );

    if(sessionStorage.getItem("menu")=="closed"){
      this.openMenu();
    }
    else{
      this.closeMenu();
    }
    
  }
   
//Menu open  animation and menu session storage set to open
 openMenu(){
  const isSideNavOpened = this.sidenav?.opened;
  const $matSidenav = this.elementRef.nativeElement.querySelector(
    'mat-sidenav'
  );
  const $matSidenavContent = this.elementRef.nativeElement.querySelector(
    'mat-sidenav-content'
  );

  this.sidenav?.toggle();
  window.setTimeout(() => {
    this.renderer.setStyle($matSidenav, 'width', '0px');
    this.renderer.setStyle(
      $matSidenav,
      'transform',
      'translate3d(0, 0, 0)'
    );
    window.setTimeout(() => {
      this.renderer.setStyle($matSidenav, 'width', '230px');
      this.renderer.setStyle($matSidenav, 'transition', 'width 0.0s');
      this.renderer.setStyle($matSidenav, 'visibility', 'visible');
      this.renderer.setStyle($matSidenavContent, 'margin-left', '230px');
      this.hideL=true;
      this.hideBL=false;
      this.tooltip_disable=true;
      this.hideInfo=false;
      sessionStorage.setItem("menu","open");

    }, 100);
  }, 400);

 }




//Menu close animation and menu session storage set to closed
 closeMenu(){

  const isSideNavOpened = this.sidenav?.opened;
  const $matSidenav = this.elementRef.nativeElement.querySelector(
    'mat-sidenav'
  );
  const $matSidenavContent = this.elementRef.nativeElement.querySelector(
    'mat-sidenav-content'
  );

  this.sidenav?.toggle();
  window.setTimeout(() => {
    this.renderer.setStyle($matSidenav, 'width', '0px');
    this.renderer.setStyle(
      $matSidenav,
      'transform',
      'translate3d(0, 0, 0)'
    );
    window.setTimeout(() => {
      this.renderer.setStyle($matSidenav, 'width', '60px');
      this.renderer.setStyle($matSidenav, 'transition', 'width 0.0s');
      this.renderer.setStyle($matSidenav, 'visibility', 'visible');
      this.renderer.setStyle($matSidenavContent, 'margin-left', '60px');
      this.hideL=false;
      this.hideBL=true;
      this.tooltip_disable=false;
      this.hideInfo=true;
      sessionStorage.setItem("menu","closed");

    }, 100);
  }, 400);

  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        console.log("edge");
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        console.log("opera");
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        console.log("chrome");
        return 'chrome';
      case agent.indexOf('trident') > -1:
        console.log("Internet Explorer");
        return 'ie';
      case agent.indexOf('firefox') > -1:
        console.log("firefox");
        return 'firefox';
      case agent.indexOf('safari') > -1:
        console.log("safari");
        return 'safari';
      default:
        console.log("not recognized");
        return 'other';
    }
  }
   

  detectBrowserVersion(){
      var userAgent = navigator.userAgent, tem, 
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      
      if(/trident/i.test(matchTest[1])){
          tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
          console.log('IE '+(tem[1] || ''));
          return 'IE '+(tem[1] || '');
      }

      if(matchTest[1]=== 'Chrome'){
          tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }

      matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];

      if((tem= userAgent.match(/version\/(\d+)/i))!= null) matchTest.splice(1, 1, tem[1]);
      console.log(matchTest)
      return matchTest.join(' ');
  }
}
