import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, CheckboxRequiredValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CashCentre } from 'src/app/_models/cash-centre';
import { CashCentres } from 'src/app/_models/cash-centres';
import { Bank } from 'src/app/_models/bank';
import { Banks } from 'src/app/_models/banks';
import { Profile } from 'src/app/_models/profile';
import { User } from 'src/app/_models';
import { Customer } from 'src/app/_models/customer';
import { environment } from 'src/app/environments/environment';
import { CookieService } from 'ngx-cookie';
import { Item } from 'angular2-multiselect-dropdown';
import { myProfiles} from 'src/app/_models/myProfile';
import { Profiles} from 'src/app/_models/Profiles';


@Component({
  selector: 'app-new-user-access',
  templateUrl: './new-user-access.component.html'
})
export class NewUserAccessComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  public centres = new BehaviorSubject<Array<any>>(null);
  ccentres: any[];
  roleCheck:number;
  hideLevel:boolean = false;

  dropdownList = [];
  dropdownListMulti = [];
  selectedItems = [];
  dropdownCCListSingle = [];
  // dropdownListLocs = []
  dropdownListRegions = [];
  dropdownSettings = {};
  dropdownSettingsLocs = {};
  dropdownSettingsRegions = {};
  dropdownSettingsBanks = {};
  dropdownSettingsSingleCc = {};
  isCC:boolean = false;

  selectedItemsMulti = [];
  allCCs = [];

  multiBanks=[];
  dropdownSettingsMultiBank={};
  
  dropdownSettingsMulti = {};

  showCCList: boolean;

  dropdownListLocs = [
    { id: 0, name: "National" },
    { id: 1, name: "Regional" },
    { id: 2, name: "Cash Centre" },
    { id: 3, name: "Site" }
  ];

  dropdownListBanks = [
    { id: 1, name: "ABSA" },
    { id: 2, name: "Nedbank" },
    { id: 3, name: "First National Bank" },
    { id: 4, name: "Standard Bank South Africa" },
    { id: 5, name: "African Bank" },
    { id: 6, name: "Capitec" },
  ];

//new code
currentUserCustomer: number;
//new code


  showRegions: boolean = false;
  showCCs: boolean = false;

  siteChosen: boolean = true;
  ccMultiChosen: boolean = true;
  ccSingleChosen: boolean = true;
  regionChosen: boolean = true;
  ccChosen: boolean = true;
  customerChosen: boolean = true;
  selectedLoc: any;
  selectedRegion: any;
  isLoadingResult: boolean;
  data: any;
  errorMsg: string = "There was an error";
  hideMerchBox: boolean=true;

  hideErrorBanner: boolean=true;
  errorMessage: string=" ";

  allSelectedMultiBank:boolean=false;
  hideMultiBank: boolean=true;
  hideComponents:boolean=true;
  isMultiBank: boolean=false;
  isFMU: boolean=false;
  banksChosen:boolean=true;
  allowMerchants:boolean=false;


  currentUserRole;
  currentUserGroup;

  group: number;
  role:number;

  userDropdownHeading: string = "Linked Banks"
  secondaryUserId: number;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.ccentres = [];
  }

  // cashCentres = [];

  ngOnInit() {

        //let accessRequest = JSON.parse(localStorage.getItem('requestDetails'));
        //let newUserRole=accessRequest.user.roles.role.id;
        //console.log("New User Role: "+newUserRole);
        // console.log(accessRequest);

    if(sessionStorage.getItem("multiBank")=="true"){
      console.log("Is Multibank");
      this.hideMultiBank=false;
      this.hideComponents=true;
      this.isMultiBank = true;
    }
    else{
      console.log("Is Not Multibank");
      this.hideMultiBank = true;
      this.hideComponents=false;
      this.isMultiBank = false;
    }

    if(sessionStorage.getItem("aaCredit")=="true"){
      this.hideMerchBox=false;
      this.isCC=true;
      this.userDropdownHeading = "Customer Groups"
      this.dropdownListBanks = [
        { id: 1, name: "ABSA" },
        { id: 2, name: "Nedbank" },
        { id: 3, name: "First National Bank" },
        { id: 4, name: "Standard Bank South Africa" },
        { id: 5, name: "African Bank" },
        { id: 6, name: "Capitec" },
        { id: 0, name: "Merchant"}
      ];

      this.selectedItemsMulti=this.allCCs;
      
    }
    else{
      this.hideMerchBox=true;
      this.isCC=false;
      this.dropdownListBanks = [
        { id: 1, name: "ABSA" },
        { id: 2, name: "Nedbank" },
        { id: 3, name: "First National Bank" },
        { id: 4, name: "Standard Bank South Africa" },
        { id: 5, name: "African Bank" },
        { id: 6, name: "Capitec" }
      ];
    }

    if(sessionStorage.getItem("FMU")=="true"){
      console.log("Finance Manager User");
      this.isFMU = true;
      this.hideLevel= true;

    }
    else{
      console.log("Not Finance Manager User");
      this.isFMU = false;
      this.hideLevel= false;
    }
    
    let Jsession=this.cookieService.get("JSESSIONID");

    this.accountService.getBanks(Jsession)
    .subscribe(data => {

      console.log(data);

    },
    error => {

        console.log(error)
    });

    this.accountService.getCurrentUser(sessionStorage.getItem("email"), Jsession)
    .subscribe(data => {
     this.roleCheck=data.users.user.roles.role.id;
     if(this.roleCheck==6 || this.roleCheck==22 || this.roleCheck==50){//User is either SBV or Bank admin
        console.log("User is admin, role = "+this.roleCheck)
     }
     else{//User is nt an admin
      console.log("User is not an admin, role = "+this.roleCheck)
      this.router.navigate(['/forbidden-access']);
     }

    },
    error => {

      if (error.status==0){
        this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
        this.hideErrorBanner=false;
      }

      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }
        console.log(error)
    });

    let jsession=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"), jsession)
      .subscribe(data => {
        // console.log
        this.currentUserRole = data.users.user.roles.role.id;
        this.currentUserGroup = data.users.user.roles.role.groupId;
      
        localStorage.removeItem('currentUserRole');
        localStorage.removeItem('currentUserGroup');
        localStorage.setItem('currentUserRole', this.currentUserRole);
        localStorage.setItem('currentUserGroup', this.currentUserGroup);
        console.log("data.users.user.roles.role.id; "+data.users.user.roles.role.id)
        console.log("this.currentUserRole "+this.currentUserRole);

        if (this.currentUserRole == 22){
          this.currentUserCustomer = data.users.user.customer.id
          console.log('this.currentUserCustomer',this.currentUserCustomer)

          switch (this.currentUserCustomer) {
            case 1:
              console.log('ABSA')
              this.dropdownListBanks = [{ id: 1, name: "ABSA" }]
              break;  
              case 2:
                console.log('NEDBANK')
                this.dropdownListBanks = [{ id: 2, name: "Nedbank" }]
                break;  
                case 3:
                  console.log('FNB')
                  this.dropdownListBanks = [{ id: 3, name: "First National Bank" }]
                  break;  
                  case 4:
                    console.log('SBSA')
                    this.dropdownListBanks = [{ id: 4, name: "Standard Bank South Africa" }]
                    break;  
                    case 5:
                      console.log('AFRICAN BANK')
                      this.dropdownListBanks = [{ id: 5, name: "African Bank" }]
                      break; 
                      case 6:
                        console.log('CAPITEC')
                        this.dropdownListBanks = [{ id: 6, name: "Capitec" }]
                        break;
                        default:
                          this.dropdownListBanks = [
                            { id: 1, name: "ABSA" },
                            { id: 2, name: "Nedbank" },
                            { id: 3, name: "First National Bank" },
                            { id: 4, name: "Standard Bank South Africa" },
                            { id: 5, name: "African Bank" },
                            { id: 6, name: "Capitec" },
                          ];    

          }

        } else return
      });

    // console.log("this.currentUserGroup "+this.currentUserGroup);


    if (localStorage.getItem('group') == '1') { //SBV user
      this.group = 1;
    } else { //Bank user
      this.group = 2;
    }

    console.log("New user group - "+this.group);

    if(localStorage.getItem('role') == '22'){ // User is bank admin
      this.role=22;
    }
    console.log("set group: " + this.group);
    console.log("set role: " + this.role);


    let jSession=this.cookieService.get("JSESSIONID");
    console.log(jSession);

    this.accountService.getCashCentres(jSession)
      .subscribe(data => {
        // console.log(data.root.cashCentre);
        // console.log(JSON.stringify(data.root.cashCentre));
        this.loading = false;
        this.dropdownCCListSingle = data.root.cashCentre;
        this.dropdownList = data.root.cashCentre;
        this.dropdownListMulti = data.root.cashCentre;
        // this.ccentres = data.root.cashCentre;
      },
        error => {

           // if (error.status==0){
          //   this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
          //   this.hideErrorBanner=false;
          // }

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          this.alertService.error(error.error.error.message);
          this.loading = false;
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
        });

    let JSession=this.cookieService.get("JSESSIONID");
    console.log(jSession);

    this.accountService.getRegions(JSession)
      .subscribe(data => {
        // console.log(data.root.cashCentre);
        // console.log(JSON.stringify(data.root.cashCentre));
        this.loading = false;
        this.dropdownListRegions = data.root.region;
        // this.ccentres = data.root.cashCentre;
      },
        error => {

           // if (error.status==0){
          //   this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
          //   this.hideErrorBanner=false;
          // }

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          this.alertService.error(error.error.error.message);
          this.loading = false;
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
        });


    // this.dropdownList = this.ccentres;
    this.selectedItems = [
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };

    this.selectedItemsMulti = [
    ];

    //this.allCCs = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27];

    this.dropdownSettingsMulti = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };

    this.dropdownSettingsMultiBank = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };



    this.dropdownSettingsLocs = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    };

    this.dropdownSettingsBanks = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    };

    this.dropdownSettingsRegions = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    };

    this.dropdownSettingsSingleCc = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    };

    this.form = this.formBuilder.group({
      //   email: ['', Validators.required],
      //   password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'user-management';

    console.log("Is Multibank - "+this.hideMultiBank);
  }

  onItemSelectMulti(item: any) {
    // console.log(item.id);
    if ((this.allSelectedMulti == false) || (!this.selectedItemsMulti.includes(item.id))) {
      this.selectedItemsMulti.push(item.id);
      // console.log(this.selectedItems);
    }
  }

  onItemSelectMultiBank(item: any) {
    // console.log(item.id);
    if ((this.allSelectedMultiBank == false) || (!this.multiBanks.includes(item.id))) {
      this.multiBanks.push(item.id);
      // console.log(this.selectedItems);
    }
    this.banksChosen = true;
    console.log("BANKS - "+JSON.stringify(this.multiBanks));
  }

  allSelected: boolean = false;
  allSelectedMulti: boolean = false;

  onSelectAllMulti(items: any[]) {
    // console.log(">>>");// + items);
    // console.log(items.length);
    // console.log(this.selectedItems.length + "<<<");

    if (items.length == this.selectedItemsMulti.length) {
      this.selectedItemsMulti = [];
      this.allSelectedMulti = false;
    }
    else {
      // this.selectedItems = items;
      for (let i = 0; i < items.length; i++) {
        if (!this.selectedItemsMulti.includes(items[i].id)) {
          this.selectedItemsMulti.push(items[i].id);
        }
      }
      this.allSelected = true;
    }
    // console.log(this.selectedItems);
  }

  onSelectAllMultiBanks(items: any[]) {
    
    if (items.length == this.multiBanks.length) {
      this.multiBanks = [];
      this.allSelectedMulti = false;
    }
    else {
      // this.selectedItems = items;
      for (let i = 0; i < items.length; i++) {
        if (!this.multiBanks.includes(items[i].id)) {
          this.multiBanks.push(items[i].id);
        }
      }
      this.allSelectedMultiBank = true;
    }
    this.banksChosen = true;
    console.log("BANKS - "+JSON.stringify(this.multiBanks));
    }

  onItemDeSelectMulti(item: any) {
    // console.log("role already present in array - will be removed");
    // console.log("B4: " + this.selectedItems);
    if (this.allSelectedMulti == true) {
      this.selectedItemsMulti.splice(item.id, 1);
    }
    this.selectedItemsMulti.forEach((element, index) => {
      if (element == item.id)
        this.selectedItemsMulti.splice(index, 1);
    });
    // console.log("After: " + this.selectedItems);

  }

  onItemDeSelectMultiBank(item: any) {
    // console.log("role already present in array - will be removed");
    // console.log("B4: " + this.selectedItems);
    if (this.allSelectedMultiBank == true) {
      this.multiBanks.splice(item.id, 1);
    }
    this.multiBanks.forEach((element, index) => {
      if (element == item.id)
        this.multiBanks.splice(index, 1);
    });
    console.log("BANKS - "+JSON.stringify(this.multiBanks));

  }

  onItemDeSelectAllMulti(items: any[]) {
    this.selectedItemsMulti = [];
  }

  onItemDeSelectAllMultiBank(items: any[]) {
    this.multiBanks = [];

    console.log("BANKS - "+JSON.stringify(this.multiBanks));
  }

  merchantSelected(){
    if (this.allowMerchants == false) {
      this.allowMerchants = true;
  }
  else {
      this.allowMerchants = false;
  }
  console.log("AllowMerchants - " + this.allowMerchants);
  }



  selectedSite: number;
  onItemSelectLoc(item: any) {
    this.selectedSite = item.id;
    console.log("selectedSite - "+item.id);
    this.showRegions = false;
    this.showCCList = false;
    this.regionChosen = true;
    this.ccChosen = true;

    if (item.id == 0) { //national
      this.selectedLoc = 0;
      // this.regionChosen = true;
      // this.ccChosen = true;
    }
    if (item.id == 1) { //regional
      //show regional dropdown
      this.showRegions = true;
      // this.ccChosen = true;
    }
    if (item.id == 2) { //cc
      //show cc dropdown
      this.showCCList = true;
      // this.regionChosen = true;
    }
    if (item.id == 3) { //site
      //customer id
      this.selectedLoc = 0;
    }
  }

  // onItemSelectLoc(item: any) {
  //   this.selectedLoc = item.id;
  //   console.log(this.selectedLoc)
  //   if (item.id == 0) {
  //     this.showRegions = true;
  //     this.showCCs = false;
  //   }
  //   if (item.id == 1) {
  //     this.showCCs = true;
  //     this.showRegions = false;
  //   }
  //   console.log("this.selectedLoc: " + this.selectedLoc)
  //   console.log("this.showRegions: " + this.showRegions)
  //   console.log("this.showCCs: " + this.showCCs)
  // }

  onBanksItemSelect(item: any) {
    this.customerId = item.id;
    console.log("this.customerId " + this.customerId)
  }

  onRegionsItemSelect(item: any) {
    this.selectedRegion = item.id;
    this.selectedLoc = item.id;
    this.regionChosen = true;
    console.log("selectedRegion - "+this.selectedRegion);
    console.log("selectedLoc- "+this.selectedLoc);


  }

  // selectedCC;
  onItemSelect(item: any) {
    this.ccChosen = true;
    //console.log("cc " + item.id);
    this.selectedLoc = item.id;
    console.log("selectedLoc- "+this.selectedLoc);
    // this.selectedCC = item.id;
    // if ((this.allSelected == false) || (!this.selectedItems.includes(item.id))) {
    //   this.selectedItems.push(item.id);
    //   // console.log(this.selectedItems);
    // }
  }

  // allSelected: boolean = false;

  onSelectAll(items: any[]) {
    // console.log(">>>");// + items);
    // console.log(items.length);
    // console.log(this.selectedItems.length + "<<<");

    if (items.length == this.selectedItems.length) {
      this.selectedItems = [];
      this.allSelected = false;
    }
    else {
      // this.selectedItems = items;
      for (let i = 0; i < items.length; i++) {
        if (!this.selectedItems.includes(items[i].id)) {
          this.selectedItems.push(items[i].id);
        }
      }
      this.allSelected = true;
    }
    // console.log(this.selectedItems);
  }

  onItemDeSelect(item: any) {
    this.selectedLoc = undefined;
    this.ccChosen = false;
    // // console.log("role already present in array - will be removed");
    // // console.log("B4: " + this.selectedItems);
    // if (this.allSelected == true) {
    //   this.selectedItems.splice(item.id, 1);
    // }
    // this.selectedItems.forEach((element, index) => {
    //   if (element == item.id)
    //     this.selectedItems.splice(index, 1);
    // });
    // // console.log("After: " + this.selectedItems);

  }

  onItemSelectCCSingle(item: any){
    this.selectedLoc = item.id;
  }

  onCCItemDeSelect(item: any){
    this.selectedLoc = undefined;
  }

  onItemDeSelectAll(items: any[]) {
    this.selectedItems = [];
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {

    sessionStorage.removeItem("aaCredit");
    sessionStorage.removeItem("multiBank");
    sessionStorage.removeItem("FMU");

    if(this.isFMU) {
      this.selectedRegion=0;
      this.selectedLoc=0;
      this.selectedSite=0;
      this.ccMultiChosen = true;
    }

    if(this.isFMU){
      this.ccMultiChosen = true;
    }
    // this.router.navigate([this.returnUrl]);
    // reset alerts on submit
    this.alertService.clear();
    
    //if bank user, check customer bank is selected
    console.log("this.customerId "+this.customerId);

    if(this.isMultiBank && this.hideMerchBox==true) {//Multi bank and not a credit controller 
      if(this.multiBanks.length==0){
        this.banksChosen = false;
        return;
      }
    }
    if (this.group == 2) {
      if (this.customerId == null || this.customerId == undefined) {
        this.customerChosen = false;
        return;
      } 
    }
    
    if (this.selectedSite == 1 && this.selectedRegion == undefined) {
      
        this.regionChosen = false;
        return;
      
    }
    
    //if site selection = 2 and cc not chosen
    //show validation
    //return
    if (this.selectedSite == 2 && this.selectedLoc == undefined) {
      
        this.ccSingleChosen = false;
        return;
        
      }
      
      if (this.selectedSite == undefined) {
       
          this.siteChosen = false;
          return;
          
      }
      
      if (this.selectedItemsMulti.length == 0) {
        
        if(this.isFMU || this.isMultiBank){
          this.ccMultiChosen = false;

        }
        else{
          this.ccMultiChosen = false;
          return;
        }
          
      }
        
        this.submitted = true;

    //SUBMIT INFO FROM ALL PAGES
    //1 - user details:
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.addNewUser(JSON.parse(localStorage.getItem('newUserDetails')), JSession)//, this.ipaddress)
      .subscribe(data => {
        // console.log(data);
        // console.log(JSON.stringify(data));

        // localStorage.setItem('newUserEmail', this.f.email.value);
        // localStorage.setItem('newUserOrg', this.f.org.value);
        // localStorage.setItem('newUserDetails', JSON.stringify(this.form.value));

        // this.loading = false;
        // this.router.navigate([this.returnUrl]);

        console.log("added new user")
        //2 - IF USER DETAILS SUCCESSFUL THEN NEXT API TO REGISTER BUSINESS INFO

        let accessRequest = JSON.parse(localStorage.getItem('requestDetails'));
        console.log(accessRequest);
        console.log("added new user 1")
        //pus cash centres into objects for array
        //ONE object called cash centres with many cash centre onjects. array now sit one level lower.
        let theCCParent = new CashCentres; //parent has array of many children
        let CCs: CashCentres[] = [];

        let BanksList:Profiles[]=[];

        if(this.isMultiBank){

          if(this.isMultiBank){
            //let b=new Banks;
      
            for (let i = 0; i < this.multiBanks.length; i++) {
              let child = new Profiles;
              let b = new myProfiles.Profile;
              
              b.targetId = this.multiBanks[i];

              if(b.targetId == 0){
                b.typeId = 2;
              }
              else{
                b.typeId=1;
              }
              //b.typeId=1;
              child.profile=b;
              BanksList.push(child);
            }
      
            console.log("Bank Array Created - "+JSON.stringify(BanksList));

            // if(this.allowMerchants == true && this.hideMerchBox==false) {
            //   let m=new myProfiles.Profile;
            //   let child=new Profiles();
            //   m.targetId=0;
            //   m.typeId=2;
            //   child.profile=m;
            //   BanksList.push(child);

            // }
      
          }
          
        }

        if(this.isCC==true || this.isMultiBank){
          this.selectedItemsMulti=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27];
        }
        
        console.log("added new user 2")
        //PROFILE HAS ARRAY OF CASH CENTRES
        let c = new CashCentres;
        for (let i = 0; i < this.selectedItemsMulti.length; i++) {
          let child = new CashCentres;
          let b = new CashCentre;
          b.id = this.selectedItemsMulti[i];
          child.cashCentre = b;
          CCs.push(child);
        }
        console.log("added new user 3")

        let p = new Profile();
        p.cashCentres = CCs;
        p.profiles=BanksList;
        // p.cashCentres = theCCParent;
        accessRequest.user.profile = p;
        console.log("added new user 4")

        let usr: User = JSON.parse(localStorage.getItem('newUserDetails'));
        // console.log("localStorage.getItem('newUserOrg') "+localStorage.getItem('newUserOrg'))
        // console.log("usr.organisation "+usr.organisation)

        console.log("2 email " + usr.email);

        console.log("this.selectedLoc " + this.selectedLoc)

        console.log("added new user 5")
        accessRequest.user.contact.firstName = usr.firstName;
        accessRequest.user.contact.lastName = usr.lastName;
        // accessRequest.user.contact.email = usr.email;
        accessRequest.user.contact.contactNo = usr.contactNo;
        accessRequest.user.contact.organization = localStorage.getItem('newUserOrg');
        accessRequest.user.contact.locationType = this.selectedSite;
        accessRequest.user.contact.locationId = this.selectedLoc;

        let customer = new Customer;
        // customer.id = this.customerId;
        if (localStorage.getItem('group') == '1') { //SBV user
          customer.id = 0;
        } else {
          customer.id = this.customerId;
        }
        // customer.name = this.customerName;
        // customer.code = this.customerCode;
        // let customer = new Customer;
        // customer.code = this.customerCode;
        // customer.name = this.customerName;
        accessRequest.user.customer = customer;

        localStorage.removeItem('requestDetails');
        localStorage.setItem('requestDetails', accessRequest);

        localStorage.removeItem('addUserInProgress');
        // localStorage.setItem('addUserInProgress','false');

        localStorage.setItem('step1', 'false');
        localStorage.setItem('step2', 'false');
        localStorage.removeItem('groupObj');
        localStorage.removeItem('roleObj');


        // accessRequest.user.customer.id = 1;
        // accessRequest.user.customer.code = "SBV";
        // accessRequest.user.customer.name = "SBV Services";

        // console.log(accessRequest);
        console.log("added new user 6")
        this.loading = true;

        console.log("PAYLOAD - "+JSON.stringify(accessRequest));
        
        let JSession=this.cookieService.get("JSESSIONID");
        this.accountService.requestAccessAdmin(accessRequest,JSession)
          .subscribe(data => {
            console.log("req access ")
            // console.log(data);
            // console.log(JSON.stringify(data));
            console.log("this.currentUserRole "+this.currentUserRole);




            //SET USER STATUS AS ACTIVE IF SESSION USER IS SBV ADMIN
            // let userR = parseInt(localStorage.getItem('currentUserRole'));
            // let userR = this.currentUserRole;
            let status;

            //let newUserRole=accessRequest.user.roles.role.id;

            console.log("1");
            //if session user is sbv admin
            if (this.currentUserRole == 6 || this.currentUserRole ==50) {


              // console.log("2");
              if (this.group == 1) {  //adding sbv user
                if(this.isMultiBank==true){ //billing user requires sbv approval
                  status=2; // Pending
                }
                else{
                  status = 3;
                }
                //status = 3;
                // console.log("status = 3;");
              }
              else if(this.group==2 &&(localStorage.getItem('new-user-role')=="22")){ //bank admin
                status=3;
              }
              
              else { //adding non-bank admin or merchant user
                status = 4;
                // console.log("status = 4;");
              }
              // console.log("3");

              //new user status = 3 for sbv and merch, 4 for bank
              let newStatus = {
                "user": {
                  "email": usr.email,
                  "status": status
                }
              }
              // console.log("4");
              let Jsession=this.cookieService.get("JSESSIONID");
              this.accountService.updateUserStatus(newStatus, Jsession)
                .subscribe((data: any) => {
                  localStorage.removeItem('new-user-role');
                  console.log(data)
                  this.secondaryUserId = data.user.id
                  // this.alertService.success("User updated");
                  // this.loading = false;
                  //setTimeout(() => this.router.navigate([this.returnUrl]), 2000);
                  // this.router.navigate([this.returnUrl]);

                  if (this.group==2){
                    let primaryUserId = parseInt(sessionStorage.getItem('primaryUserId'))
                    this.accountService.createProfileSwitch(Jsession, primaryUserId, this.secondaryUserId) 
                    .subscribe((data)=> {
                      console.log('profileswitch',data)
                    })
                  }
                  setTimeout(() => this.router.navigate([this.returnUrl]), 2500);

                },
                  error => {

                    if (error.status==0){
                      this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
                      this.hideErrorBanner=false;
                    }

                    if (error.status==500){
                      this.errorMessage="ERROR 500 OCCURRED";
                      this.hideErrorBanner=false;
                    }
                    if (error.status==408 || error.status==504){
                      this.errorMessage="ERROR 408/504 OCCURRED";
                      this.hideErrorBanner=false;
                    }

                    this.loading = false;
                    console.log("status ERR")
                    //   this.alertService.error("User not updated");
                  });
            }
            //add here

          },
            error => {

              if (error.status==0){
                this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
                this.hideErrorBanner=false;
              }

              if (error.status==500){
                this.errorMessage="ERROR 500 OCCURRED";
                this.hideErrorBanner=false;
              }
              if (error.status==408 || error.status==504){
                this.errorMessage="ERROR 408/504 OCCURRED";
                this.hideErrorBanner=false;
              }

              console.log("req access ERR")
              this.alertService.error(error.error.error.message);
              this.loading = false;
              // console.error('There was an error!', error.error.error.message);
              // console.error(error.message);
            });

            setTimeout(() => this.router.navigate([this.returnUrl]), 2000);

      },
        error => {

           // if (error.status==0){
          //   this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
          //   this.hideErrorBanner=false;
          // }
          
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          console.log("added new user ERR")
          // console.log(error);
          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          this.form.enable();
          this.loading = false;
        });



    //ADD cash centres to payload

    this.loading = false;
  }

  getServerResponse(event) {

    console.log(event);
    this.isLoadingResult = true;
    const params = new HttpParams()
      .set('query', event)
      .set('userId', sessionStorage.getItem('email'));

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    this.http.get(`${environment.apiUrl}/services/iam/data/users/ntombix@sbv.co.za/profiles`, { headers: headers, params: params })
      .subscribe(data => {
        // data = JSON.stringify(data['profile'].codes);
        console.log(data)
        // if (data == undefined) {
        if (data['profile'].codes == undefined) {
          console.log("undefined " + data)
          this.data = [];
          this.errorMsg = data['Error'];
        } else {
          this.data = data['profile'].codes;
        }

        this.isLoadingResult = false;
      });
  }

  searchCleared() {
    console.log('searchCleared');
    this.data = [];
  }

  onFocused(e) {
    // do something when input is focused
  }

  customerId;
  // customerCode;
  // customerName;
  selectEvent(item) {
    // do something with selected item
    // console.log(item)
    // this.customerId = item.customerId;
    // this.customerName = item.customer;
    // this.customerCode = item.customerCode;
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  xButton(){
    window.location.reload();
}

  keyword = 'customer';

}
