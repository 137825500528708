<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <div class="d-flex justify-content-between align-items-end">
                    <h3>Register</h3>
                    <p class="purple">Registration Complete</p>
                </div>
                <hr class="mt-0">
                <img src="assets/img/email-sent.png" height="80px">
                <h5 class="p-green">Thank you for registering with SBV Connect!</h5>
                <p>For security purposes, your account is being processed. Please allow up to 48 hours for your account
                    to be approved.</p>
                <p>You will receive a confirmation link to set up your password</p>


            </div>
            <div class="d-flex justify-content-between ms-5 me-5 p-3 mb-4">
                <a class="p-2 black-link" routerLink="../login">Return to Login</a>
                <a class="p-2 blue-link" routerLink="../help">Need help?</a>
            </div>
        </div>
    </div>
</div>