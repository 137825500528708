import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, AlertService } from 'src/app/_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { AccessRequestUser } from 'src/app/_models/access-request-user';
import { Contact } from 'src/app/_models/contact';
import { Profile } from 'src/app/_models/profile';
import { RequestAccess } from 'src/app/_models/request-access';
import { Role } from 'src/app/_models/role';
import { Roles } from 'src/app/_models/roles';
import { User } from 'src/app/_models';
import { CookieService } from 'ngx-cookie';
@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {

   loading = false;
   submitted = false;
   returnUrl: string;
   roleCheck:number;
   nameFocusVar: boolean=true;
   duplicateGroup:boolean=false;
  
   isChecked = false;
   closeResult = '';
   invalidGN:boolean=false;

   hideErrorBanner: boolean=true;
errorMessage: string=" ";


   form: FormGroup;

   groupName:string="";
 
 
   constructor(
     private modalService: BsModalService,
     private formBuilder: FormBuilder,
     private route: ActivatedRoute,
     private router: Router,
     private accountService: AccountService,
     private alertService: AlertService,
     private cookieService: CookieService
 
   ) {
   }
 
   return(){
     this.router.navigate(['/merchant-management']);
   }

   xButton(){
    window.location.reload();
}

   ngOnInit(): void {
 
     this.form=this.formBuilder.group({
      name: ['',[Validators.required, Validators.maxLength(64)]]
     })
   }

 
   get f() { return this.form.controls; }
 
   AddGroup(){
     this.loading=true;
     this.submitted=true;
     let Jsession=this.cookieService.get("JSESSIONID");
     let mail=sessionStorage.getItem("email");

     console.log("details : "+Jsession+", "+mail);

     //let escapeName="Tes't'";
     //console.log("escapeName : "+escapeName);
     this.groupName=(this.f.name.value).replace("'","^");

     if(this.groupName=="" || this.groupName==null || this.groupName==undefined){
      this.invalidGN=true;
      this.loading=false;
      return;
     }

     this.accountService.createGroup(this.groupName,mail,Jsession)
     .subscribe(data => {
      console.log("Group Created");
      this.router.navigate(['/merchant-management']);
     },
     error => {
      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }

      if(error.status==409){
        this.duplicateGroup=true;
      }
      else{
        console.log(error);
      }
      this.loading=false;
      this.submitted=false;
     });
   }
 
}
