import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CashCentreComponent } from './account/cash-centre/cash-centre.component';
import { FinalisePasswordSetComponent } from './account/finalise-password-set/finalise-password-set.component';
import { FinaliseComponent } from './account/finalise/finalise.component';
import { ForgotPasswordFinishComponent } from './account/forgot-password/forgot-password-finish.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { HelpFinishComponent } from './account/help/help-finish.component';
import { HelpComponent } from './account/help/help.component';
import { LoginComponent } from './account/login/login.component';
import { LogoutComponent } from './account/logout/logout.component';
import { NewUserComponent } from './user-management/new-user/new-user.component';
import { NotFoundComponent } from './account/not-found/not-found.component';
import { OtpComponent } from './account/otp/otp.component';
import { OttComponent } from './account/ott/ott.component';
// import { ProcessingComponent } from './account/processing/processing.component';
import { RegisterDetailsProcessingComponent } from './account/register-details/register-details-processing.component';
import { RegisterDetailsComponent } from './account/register-details/register-details.component';
// import { RegisterPasswordComponent } from './account/register-password/register-password.component';
import { RequestAccessProcessingComponent } from './account/request-access/request-access-processing.component';
import { RequestAccessComponent } from './account/request-access/request-access.component';
import { ResetPasswordSuccessComponent } from './account/reset-password/reset-password-success.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
// import { UserManagementComponent } from './user-management/user-management.component';
import { HomeComponent } from './pages/home/home.component';
import { UserManagementComponent } from './user-management/user-management/user-management.component';
import { UserManagementAwaitingApprovalComponent } from './user-management/user-management-awaiting-approval/user-management-awaiting-approval.component';
import { UserManagementExistingUsersComponent } from './user-management/user-management-existing-users/user-management-existing-users.component';
import { NewUserRoleComponent } from './user-management/new-user-role/new-user-role.component';
import { NewUserAccessComponent } from './user-management/new-user-access/new-user-access.component';
import { NewUserFinishComponent } from './user-management/new-user-finish/new-user-finish.component';
import { ProfileSettingsComponent } from './user-management/profile-settings/profile-settings.component';
import { NewUserMerchantComponent } from './user-management/new-user-merchant/new-user-merchant.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TaskListComponent } from './task-list/task-list.component';
import { CaseListComponent } from './case-list/case-list.component';
import { LogNewCaseComponent } from './log-new-case/log-new-case.component';
import { ReportingComponent } from './reporting/reporting.component';
import { NationalDashboardComponent } from './national-dashboard/national-dashboard.component';
import { HelpCentreComponent } from './help-centre/help-centre.component';
import { BankViewsComponent } from './bank-views/bank-views.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { HelpCaseManagementComponent } from './help-case-management/help-case-management.component';
import { HelpOrganisationComponent } from './help-organisation/help-organisation.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ForbiddenAccessComponent } from './account/forbidden-access/forbidden-access.component';
import { ServerErrorComponent } from './account/server-error/server-error.component';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
import { MerchantManagementComponent } from './user-management/merchant-management/merchant-management.component';
import { MerchantManagementExistingComponent } from './user-management/merchant-management-existing/merchant-management-existing.component';
import { MerchantManagementAwaitingApprovalComponent } from './user-management/merchant-management-awaiting-approval/merchant-management-awaiting-approval.component';
import { EditMerchantGroupComponent } from './user-management/edit-merchant-group/edit-merchant-group.component';
import { AllSitesComponent } from './user-management/all-sites/all-sites.component';
import { LinkNewSitesComponent } from './user-management/link-new-sites/link-new-sites.component';
import { AddGroupComponent } from './user-management/add-group/add-group.component';
import { AddSiteComponent } from './user-management/add-site/add-site.component';
import { PasswordSetCompleteComponent } from './account/password-set-complete/password-set-complete.component';
import { CaseTypesComponent } from './case-types/case-types.component';
import { EditCaseTypeComponent } from './edit-case-type/edit-case-type.component';
import { CreateBillingCaseComponent } from './create-billing-case/create-billing-case.component';
import { VideoFootageComponent } from './video-footage/video-footage.component';
import { CaseListVideoFootageComponent } from './case-list-video-footage/case-list-video-footage.component';
import { SuperUserLoginComponent } from './super-user-login/super-user-login.component';
import { SuperUserLogoutComponent } from './super-user-logout/super-user-logout.component';
import { IncidentProcessComponent } from './incident-process/incident-process.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { CaseRouterComponent } from './case-router/case-router.component';
import {HelpCaseManagementGuideComponent} from "./help-Case-Management-Guide/help-case-management-guide.component"
//import { AuthGuardService } from './_services/authguard.service';
import { AuthGuard } from './_helpers';
import { NewUserSwitchProfileComponent } from './user-management/new-user-switch-profile/new-user-switch-profile.component';
import { RoleChangeLoginComponent } from './role-change-login/role-change-login.component';
import { RoleChangeLogoutComponent } from './role-change-logout/role-change-logout.component';





const routes: Routes = [

    { path: '', component: LoginComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterDetailsComponent },
    { path: 'register-details-processing', component: RegisterDetailsProcessingComponent },
    {path:'role-change-login',component:RoleChangeLoginComponent},
    {path:'role-change-logout',component:RoleChangeLogoutComponent},
    // { path: 'register-password', component: RegisterPasswordComponent },
    { path: 'cash-centre', component: CashCentreComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent},
    { path: 'forgot-password-finish', component: ForgotPasswordFinishComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'reset-password-success', component: ResetPasswordSuccessComponent },
    { path: 'ott/:token', component: OttComponent },
    { path: 'otp', component: OtpComponent },
    // { path: 'processing', component: ProcessingComponent },
    { path: 'request-access', component: RequestAccessComponent },
    { path: 'request-access-processing', component: RequestAccessProcessingComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'help', component: HelpComponent },
    { path: 'help-finish', component: HelpFinishComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'finalise', component: FinaliseComponent },
    { path: 'finalise-password-set', component: FinalisePasswordSetComponent },
    { path: 'not-found', component: NotFoundComponent },

    { path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuard]  },
    { path: 'user-management-awaiting-approval', component: UserManagementAwaitingApprovalComponent, canActivate: [AuthGuard]  },
    { path: 'user-management-existing-users', component: UserManagementExistingUsersComponent, canActivate: [AuthGuard] },
    { path: 'new-user', component: NewUserComponent, canActivate: [AuthGuard]  },
    { path: 'new-user-role', component: NewUserRoleComponent, canActivate: [AuthGuard]  },
    { path: 'new-user-switch-profile', component: NewUserSwitchProfileComponent, canActivate: [AuthGuard]  },
    { path: 'new-user-access', component: NewUserAccessComponent, canActivate: [AuthGuard]  },
    { path: 'new-user-finish', component: NewUserFinishComponent, canActivate: [AuthGuard]  },
    { path: 'new-user-merchant', component: NewUserMerchantComponent, canActivate: [AuthGuard]  },
    { path: 'profile-settings', component: ProfileSettingsComponent, canActivate: [AuthGuard]  },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]  },
    { path: 'task-list', component: TaskListComponent, canActivate: [AuthGuard]  },
    { path: 'case-list', component: CaseListComponent, canActivate: [AuthGuard] },
    { path: 'log-new-case', component: LogNewCaseComponent, canActivate: [AuthGuard]  },
    { path: 'reporting', component:ReportingComponent, canActivate: [AuthGuard] },
    { path: 'national-dashboard', component:NationalDashboardComponent, canActivate: [AuthGuard] },
    { path: 'help-centre', component:HelpCentreComponent, canActivate: [AuthGuard] },
    { path: 'customer-views', component:BankViewsComponent, canActivate: [AuthGuard] },
    { path: 'getting-started', component:GettingStartedComponent, canActivate: [AuthGuard] },
    { path: 'help-case-management', component:HelpCaseManagementComponent, canActivate: [AuthGuard] },
    { path: 'help-case-management-guide', component:HelpCaseManagementGuideComponent, canActivate: [AuthGuard] },
    { path: 'help-organisation', component:HelpOrganisationComponent, canActivate: [AuthGuard] },
    { path: 'contact-us', component:ContactUsComponent, canActivate: [AuthGuard] },
    { path: 'forbidden-access', component:ForbiddenAccessComponent, canActivate: [AuthGuard] },
    { path: 'server-error', component:ServerErrorComponent, canActivate: [AuthGuard] },
    { path: 'under-maintenance', component: UnderMaintenanceComponent},
    { path: 'merchant-management', component: MerchantManagementComponent, canActivate: [AuthGuard] },
    { path: 'merchant-management-existing', component: MerchantManagementExistingComponent, canActivate: [AuthGuard] },
    { path: 'merchant-management-awaiting-approval', component: MerchantManagementAwaitingApprovalComponent, canActivate: [AuthGuard] },
    { path: 'edit-merchant-group', component: EditMerchantGroupComponent, canActivate: [AuthGuard] },
    { path: 'all-sites', component: AllSitesComponent, canActivate: [AuthGuard] },
    { path: 'link-new-sites', component: LinkNewSitesComponent, canActivate: [AuthGuard] },
    { path: 'add-group', component: AddGroupComponent, canActivate: [AuthGuard] },
    { path: 'add-site', component: AddSiteComponent, canActivate: [AuthGuard] },
    { path: 'password-set-complete', component: PasswordSetCompleteComponent},
    { path: 'case-types', component: CaseTypesComponent, canActivate: [AuthGuard] },
    { path: 'edit-case-type', component: EditCaseTypeComponent, canActivate: [AuthGuard] },
    { path: 'create-billing-case', component: CreateBillingCaseComponent, canActivate: [AuthGuard] },
    { path: 'video-footage', component: VideoFootageComponent, canActivate: [AuthGuard] },
    { path: 'case-list-video-footage', component: CaseListVideoFootageComponent, canActivate: [AuthGuard] },
    { path: 'super-user-login', component: SuperUserLoginComponent},
    { path: 'super-user-logout', component: SuperUserLogoutComponent},
    { path: 'incident-process', component: IncidentProcessComponent, canActivate: [AuthGuard] },
    { path: 'order-management', component: OrderManagementComponent, canActivate: [AuthGuard] },
    { path: 'case-router', component: CaseRouterComponent, canActivate: [AuthGuard] },
    
    



    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
     
})
export class AppRoutingModule { }