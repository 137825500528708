import { Component, ElementRef, OnInit, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import * as shajs from 'sha.js';
import { throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { environment } from 'src/app/environments/environment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BehaviorSubject, Subject } from 'rxjs';


import {
    NgcCookieConsentService,
    NgcNoCookieLawEvent,
    NgcInitializeEvent,
    NgcStatusChangeEvent,
  } from "ngx-cookieconsent";

import { Subscription } from "rxjs";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {

    //keep refs to subscriptions to be able to unsubscribe later
    // private popupOpenSubscription: Subscription;
    // private popupCloseSubscription: Subscription;
    // private initializeSubscription: Subscription;
    // private statusChangeSubscription: Subscription;
    // private revokeChoiceSubscription: Subscription;
    // private noCookieLawSubscription: Subscription;
    //
    helpForm:FormGroup;
    form: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    rememberMeChecked: boolean = false;

    acceptedCookies: boolean=true;
    showPreferances: boolean=true;
    cookieResult: boolean=true;

    fCheck:boolean=true;
    mCheck:boolean=false;
    hideCB:boolean=false;
    show:boolean=true;
    hideResBanner:boolean=true;
    hideLogin:boolean=false;
    requestSubmitted:boolean=false;

    prefButton: string = 'Preferences';
    hideButton: string="hide";

    hideErrorBanner: boolean=true;
    errorMessage: string=" ";

    public getScreenWidth: any;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService,
        private cookieService: CookieService,
        private cookie:CookieService,
        private renderer: Renderer2,
        private actRoute: ActivatedRoute,
        //private ccService: NgcCookieConsentService

    ) {
        console.log(this.router.url)
        this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
    }

    ngOnInit() {

        this.actRoute.queryParams.subscribe( params=>{

            if (Object.keys(params).length > 0){

                let logger=params['LOGGER'];
                let iRef=params['REF'];
                let cit=params['CODE'];
                let notes=params['NOTES'];
                let type=params['TYPE'];
                let subtype=params['SUBTYPE'];

                this.cookieService.put('LOGGER', logger,{
                    secure:true,
                    sameSite:'none'
                  });

                this.cookieService.put('REF', iRef,{
                    secure:true,
                    sameSite:'none'
                  });

                  this.cookieService.put('CITCODE', cit,{
                    secure:true,
                    sameSite:'none'
                  });

                  this.cookieService.put('NOTES', notes,{
                    secure:true,
                    sameSite:'none'
                  });

                  this.cookieService.put('TYPE', type,{
                    secure:true,
                    sameSite:'none'
                  });

                  this.cookieService.put('SUBTYPE', subtype,{
                    secure:true,
                    sameSite:'none'
                  });


                sessionStorage.setItem('incidentLogging','true');
            }

            });

        if(localStorage.getItem("loggedin")=="true"){
            if("cookieBannerDisplayed" in localStorage){ //user accepted cookie policy 
                this.cookieResult=true;
            }
            window.sessionStorage.clear();
            window.localStorage.clear();
            this.cookieService.removeAll;
            this.cookieService.remove('JSESSIONID');
            this.cookieService.remove('X-Bonita-API-Token');
        }
        if(this.cookieResult==true){
            localStorage.setItem('cookieBannerDisplayed','true')
        }        
        sessionStorage.removeItem("login-status");
        sessionStorage.setItem("first-load","true");
        this.hideResBanner=true;
        this.getScreenWidth=window.innerWidth;
        console.log("WIDTH : "+this.getScreenWidth)

        if (this.getScreenWidth < 1024) {
            console.log("Invalid Screen Width Of "+this.getScreenWidth)
            this.hideResBanner = false; //show res banner since resolution is not valid 
            this.hideLogin=true; // hide the login element when resolution is not valid 
         } else {
            this.hideResBanner =true; //add to hide res banner when resolution is valid 
            this.hideLogin=false;

            //this.hideResBanner =false; //Testing res banner
            //this.hideLogin=true; //Testing res banner 
         }

         if("requestSubmitted" in sessionStorage){
             this.requestSubmitted=true;

         }
            console.log("TEST")
                     //if cookie is present forward direct to bonita
            // console.log(this.cookieService.getAll.length)
            if (this.cookieService.getAll.length > 1) {
                // console.log("redirecting")
                window.location.href = `${environment.bonitaUrl}/bonita/apps/SBVConnect/home/`;
                // window.location.href = localStorage.getItem('redirectUrl');
                }

        // console.log("REMEMBER ME: ", this.rememberMeChecked);
        if (localStorage.getItem('RememberMe') !== null) {
            // console.log("REMEMBER ME should be null: ", localStorage.getItem('RememberMe'));
            // this.f.email.setValue(localStorage.getItem('email'));
            localStorage.getItem('RememberMe');
            // console.log("REMEMBER ME from storage should be val: ", localStorage.getItem('RememberMe'));
        }

        let email = '';
        if (localStorage.getItem('RememberMe') == "true") {
            email = localStorage.getItem('login_email');
        }

        this.form = this.formBuilder.group({
            // email: [localStorage.getItem('email') ? localStorage.getItem('email') : '', [Validators.required, Validators.maxLength(254)]],//, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],//("([A-Za-z0-9\.|-|_]{1,60})([\.{2}])([A-Za-z]{2,3})$")]],
            email: [email != '' ? email : '', [Validators.required, Validators.maxLength(254)]],//, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],//("([A-Za-z0-9\.|-|_]{1,60})([\.{2}])([A-Za-z]{2,3})$")]],
            password: ['', Validators.required]
        });

        this.helpForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],
            name: ['',[Validators.required, Validators.maxLength(64)]],
            contactNo: ['', [Validators.required, Validators.pattern("^[0-9]{10}$")]],
            description: ['', Validators.required]
          });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'otp';
        //this.acceptedCookies=false;

          setTimeout(() => {
              if("cookieBannerDisplayed" in localStorage){
                this.acceptedCookies=true;
                console.log("Approved")
              }
              
               else if("rejectCookies" in sessionStorage){
                  this.acceptedCookies=true;
                  console.log("User dismissed cookies")
              }
              else{
                  this.acceptedCookies=false;
                  console.log("User input not done")
              }

              if( this.hideResBanner == false){
                this.acceptedCookies=true;
                this.show=false;
              }
             
         }, 2000);

         this.show=true;
    }

    get h() { return this.helpForm.controls; }

    request(){

        //
    this.submitted = true;
    // this.router.navigate([this.returnUrl]);
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.helpForm.invalid) {
         console.log("INVALID DATA")
         return;
    }

    this.loading = true;

    this.accountService.requestHelp(this.h.name.value, this.h.email.value, this.h.contactNo.value, this.h.description.value)
      .subscribe(data => {
         console.log("SUBMITTED");
         sessionStorage.setItem("requestSubmitted","true");
        // console.log(JSON.stringify(data));
        this.loading = false;

        window.location.reload();
      },
        error => {

            if (error.status==500){
                this.errorMessage="ERROR 500 OCCURRED";
                this.hideErrorBanner=false;
              }
              if (error.status==408 || error.status==504){
                this.errorMessage="ERROR 408/504 OCCURRED";
                this.hideErrorBanner=false;
              }

          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
        });
    this.loading = false;
        //
    }

    acceptCookies(){

        this.acceptedCookies=true;
        localStorage.setItem("cookieBannerDisplayed","true");
        //this.cookie.put("",""); //Add a cookie to browser and assign value 
    }
    hideBanner(){

        this.acceptedCookies=true;
        this.show=false;
    }

    showBanner(){
        this.show=true;
        this.acceptedCookies=false;
    }

    marketingCheck(event){
        if(event.target.checked){
            this.mCheck=true;
            console.log("MARKETING CHECKED")
        }
        else{
            this.mCheck=false;
            console.log("MARKETING UNCHECKED")
        }
    }

    xButton(){
        window.location.reload();
    }

    rejectCookies(){
        this.acceptedCookies=true;
        sessionStorage.setItem("rejectCookies","true");
    }
    displayPref(){
        this.showPreferances=!this.showPreferances;
        //this.cookie.put("TEST","true");

        if(this.prefButton === 'Preferences') { 
            this.prefButton = 'Save '
          } else {
            this.prefButton = 'Preferences'
          }
    }

    rememberMeCheck() {
        // console.log("REMEMBERMECHECKED method");
        if (this.rememberMeChecked == false) {
            // console.log("REMEMBERMECHECKED before: ", this.rememberMeChecked);
            this.rememberMeChecked = true;
            // console.log("REMEMBERMECHECKED after: ", this.rememberMeChecked);
        }
        else {
            // console.log("REMEMBERMECHECKED before: ", this.rememberMeChecked);
            this.rememberMeChecked = false;
            // console.log("REMEMBERMECHECKED after: ", this.rememberMeChecked);
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }
    readonly: boolean;

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }


        // sessionStorage.setItem('token', 'needatoken');
        // console.log("REMEMBER ME check: ", this.rememberMeChecked);
        if (this.rememberMeChecked) {
            // console.log("REMEMBER ME selected now: ", this.rememberMeChecked);
            localStorage.setItem('login_email', this.f.email.value);
            localStorage.setItem('RememberMe', "true");
        }

        const hashed = shajs('sha256').update(this.f.password.value).digest('hex')
        console.log("Hashed - "+hashed);

        this.form.disable();
        this.loading = true;
        this.accountService.loginCredentials(this.f.email.value, hashed)
            // .pipe(
            //     // catchError(this.getError),
            //     first())
            .subscribe(data => {
                console.log("First login attempt successful")
                if("cookieBannerDisplayed" in localStorage){
                    this.cookie.put("cookiesAccepted","true"); //adds information to cookie storage 
                  }
        
                if(this.mCheck){
                    localStorage.setItem('marketingApproval','true');
                }
                if(this.fCheck){
                    localStorage.setItem('functionalApproval','true');
                    this.cookie.put("User-email",this.f.email.value); //save user email to cookie storage
                }


                sessionStorage.removeItem("email");
                sessionStorage.setItem("email", this.f.email.value);
                sessionStorage.removeItem('ottId');
                sessionStorage.setItem('ottId', data.token.ottId);

                // this.accountService.getCurrentUser(this.f.email.value)
                // .subscribe(data => {
                //  console.log("USER : "+JSON.stringify(data));
                //sessionStorage.setItem('login_email', this.f.email.value);
                 //sessionStorage.setItem('login_email', this.f.email.value);
                 //sessionStorage.setItem('passwd', hashed);
                //  sessionStorage.setItem("org",data.users.user.organization)
                // },
                // error => {
                //     console.log(error)
                // });

                this.router.navigate([this.returnUrl]);
            },
                error => {
                    
                    if (error.status==500){
                        console.log("error 500");
                        this.errorMessage="ERROR 500 OCCURRED";
                        this.hideErrorBanner=false;
                      }
                      if (error.status==408 || error.status==504){
                        this.errorMessage="ERROR 408/504 OCCURRED";
                        this.hideErrorBanner=false;
                      }

                    if (error.status == 0) {
                        this.alertService.error('Something went wrong. Please contact technical support.')
                    }
                    else {
                        this.alertService.error(error.error.error.message);
                    }

                    this.form.enable();
                    this.loading = false;
                }
            );
        this.loading = false;
    }
}
