import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie';
import { AccountService } from '../_services';
import { Case } from '../_models/case';

@Component({
  selector: 'app-case-list-video-footage',
  templateUrl: './case-list-video-footage.component.html',
  styleUrls: ['./case-list-video-footage.component.scss']
})
export class CaseListVideoFootageComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  fullTable: boolean = true;
  form: FormGroup;
  disclaimerForm: FormGroup;
  canEdit:boolean=false;
  rowSelected: boolean = false;
  selectedCase:number;
  hideCard:boolean=false;
  result:Case;
  userID:string="901";
  startDate:string='2023-01-01';
  endDate:string='2023-05-16';

  videoURL;
  caseVideoUrls = ["vid1","vid2","vid3","vid4","vid5"];
  hideRemoveBtn:boolean=true;
  hideForm:boolean=false;
  hideND:boolean=true;
  hideAT:boolean=true;
  hideAdmin:boolean=true;
  hideModal:boolean=true;
  hideTCS:boolean=true;

  disablePlayback:boolean=true;

  cases: Case[] = [];

  constructor(
    private cookieService: CookieService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    
  ) { }

  ngOnInit(): void {

    this.hideCard=true;

    this.form=this.formBuilder.group({
      videoInput: ['',[Validators.required]],
    });

    this.disclaimerForm=this.formBuilder.group({
      initSurname: ['',[Validators.required]],
      idNum: ['',[Validators.required]],
      POE: ['',[Validators.required, Validators.maxLength(64)]]
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "ordering": false,
      //order: [1],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'STARTEDBY' },//0
        { data: 'LASTCLONAME' },//1
        { data: 'QUERYID' },//2
        { data: 'COMPANY' },//3
        { data: 'CASHCENTRE' },//4
        { data: 'TYPE' },//5
        { data: 'PRIORITY' },//6
        { data: 'CASESTATUS' },//7
      ],
      "columnDefs": [
        
      ]
    };

    $('#casesTable').on('click', 'tr', function () {
      this.selectedCase = casesTable.row(this).data().QUERYID;
      console.log("selectedCase - "+this.selectedCase);
      if (this.selectedCase != localStorage.getItem('selectedCase')) {
        localStorage.removeItem('selectedCase');
        localStorage.setItem('selectedCase', this.selectedCase);
      }
    });

    let casesTable;
    let JSession=this.cookieService.get("JSESSIONID");
    // this.accountService.getUserCases(this.userID,this.startDate,this.endDate,JSession)
    //       .subscribe(data => {
    //         console.log(JSON.stringify(data.root.case));

    //         for (let i: number =1; i < data.root.case.length; i++) {
    //           let c=new Case;
    //           c.STARTEDBY = data.root.case[i].STARTEDBY;
    //           c.LASTCLONAME=data.root.case[i].LASTCLONAME;
    //           c.QUERYID = data.root.case[i].QUERYID;
    //           c.COMPANY=data.root.case[i].COMPANY;
    //           c.CASHCENTRE = data.root.case[i].CASHCENTRE;
    //           c.TYPE=data.root.case[i].TYPE;
    //           c.PRIORITY = data.root.case[i].PRIORITY;
    //           c.CASESTATUS=data.root.case[i].CASESTATUS;
              
    //           if(c.TYPE=="VIDEO FOOTAGE"){
    //             this.cases.push(c);
    //           }
    //           //this.cases.push(c);
    //         }

    //         console.log(JSON.stringify(this.cases));

    //         setTimeout(() => {
    //           casesTable = $('#casesTable').DataTable({
    //             initComplete: function () {
    //               if (!this.fullTable) {
    //               }
    //             },
    //             retrieve: true,
    //           }).rows.add(this.cases).draw();
    //         }, 2000);

    //     },
    //     error => {
    //         console.log(error)
    //     });

        $('#casesTable ').on('click', 'tr', () => {
          this.rowSelected=true;
          //console.log("TEST");
          this.showDetails();
        });
    
        $('#casesTable ').on('click', 'tr', function () {
          if ($(this).hasClass('selected')) {
            $(this).removeClass('selected');
          }
          else {
            casesTable.$('tr.selected').removeClass('selected');
            $(this).addClass('selected');
          }
          localStorage.setItem('caseRowId', casesTable.row(this).index());
        });


  }

  clearVideo(){
    this.f.videoInput.reset();
    this.videoURL = null;
    this.hideRemoveBtn=true;
    console.log("DATE"+this.f.date.value);
  }

  onSelectFile(event) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.videoURL = (<FileReader>event.target).result;
        console.log("URL - "+this.videoURL);
      }
    }
    this.hideRemoveBtn=false;
  }

  testRemove(url){
    console.log("TEST VID DELETE - "+url);
  }

  showDetails(){

    this.hideCard=false;
    if (this.rowSelected == false) {
      return;
    }

    this.result = this.cases.find(({ QUERYID }) => QUERYID === String(localStorage.getItem('selectedCase')));
    console.log(JSON.stringify(this.result));

    if (this.fullTable == true) {
      this.fullTable = !this.fullTable;
    }

    this.rowSelected = true;

    let caseID=localStorage.getItem('selectedCase');
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.getCaseDetails(JSession,caseID)
          .subscribe(data => {
            console.log(JSON.stringify(data));
        },
        error => {
            console.log(error)
        });

    let table = $('#casesTable').DataTable();
    table.column(3).visible(false);
    table.column(4).visible(false);
    table.column(5).visible(false);

    this.openForm();

  }
  hideDetails(){

    this.hideCard=true;
    this.fullTable = true;
    let table = $('#casesTable').DataTable();
    table.column(3).visible(true);
    table.column(4).visible(true);
    table.column(5).visible(true);

  }
  get f() { return this.form.controls; }

  openForm() {
    console.log("TEST");
    this.hideForm   =false;
    this.hideAT     = true;
    this.hideND     = true;
    this.hideAdmin  = true;
  }
  openND() {
    console.log("TEST");
    this.hideForm   =true;
    this.hideAT     = true;
    this.hideND     = false;
    this.hideAdmin  = true;
}
openAdmin() {
  console.log("TEST");
  this.hideForm   =true;
  this.hideAT     = true;
  this.hideND     = true;
  this.hideAdmin  = false;
}
openAT() {
  console.log("TEST");
  this.hideForm   =true;
  this.hideAT     = false;
  this.hideND     = true;
  this.hideAdmin  = true;
}

openVideoModal(){
  console.log("Works");
  this.f.videoInput.reset();
  this.videoURL = null;
  this.hideModal = false;
}

openTermsModal(){
  this.hideTCS=false;
}

videoClick(){
  console.log("Video Clicked");
}

closeVideoModal(){
  console.log("Works");
  this.hideModal = true;
  this.clearVideo();
}

closeTermsModal(){
  console.log("Works");
  this.hideTCS = true;
  this.closeVideoModal();
}

acceptTerms(){
  localStorage.setItem("videoTC","true");
  this.hideTCS=true
  this.openVideoModal();
}

rejectTerms(){
  //localStorage.setItem("videoTC","true");
  this.closeTermsModal();
  this.closeVideoModal();
}

}
