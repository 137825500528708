<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">
        <div class="container-fluid shadow card border-light" style="width: 660px; border-radius: 10px; margin-left: -130px;">
            <div class="ps-4 pe-4 pt-4 container-fluid">
                <div class="row">
                    <div class="col-md-4">
                        <h3 class="notfound-text">Oops, looks like something went wrong!</h3>
                        <p>You do not have access to view ths page.</p>
                        <p><small>Error 403: Forbidden error. You do not have access to view this service</small></p>
                        <button class="rounded btn btn-primary w-30 mb-5 mt-5" (click)="loadHome()">
                            Take Me Back
                        </button>
                    </div>
                    <div class="col-md-8 d-flex flex-column justify-content-center align-items-center">
                        <img class="notfound-image" src="assets/img/403 icon.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
