import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AccountService } from '../_services';
import { EventEmitterService } from '../_services/event-emitter.service';
import { Type } from 'src/app/_models/type';
import { Subtype } from 'src/app/_models/subtype';

@Component({
  selector: 'app-case-router',
  templateUrl: './case-router.component.html',
  styleUrls: ['./case-router.component.scss']
})
export class CaseRouterComponent implements OnInit {

  form: FormGroup;
  dropdownListTypes =[];
  filteredDropdownListTypes =[];
  dropdownListSubTypes =[];
  caseTypes=[];
  subTypes=[];
  submitted: boolean = false;
  loading: boolean=false;
  selectedType: string='';
  caseId: string='';
  selectedSubType: string='';
  typeSelected: boolean=false;
  subTypeSelected: boolean=false;
  caseTypeSelected: boolean=true;
  routerUrl: string='';

  hideLC:boolean=true;
  hideIM:boolean=true;
  hideBP:boolean=true;

  caseTypeChosen:boolean=true;

  constructor(

    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private caseSelectionEventEmitterService: EventEmitterService,
    private cookieService: CookieService,
    private logCaseEventEmitterService: EventEmitterService,
    private logCaseIncidentEventEmitterService: EventEmitterService,
    private billingEventEmitterService: EventEmitterService,
    private incidentEventEmitterService: EventEmitterService,
    private accountService: AccountService,
    
  ) { }

  logCaseFunction(){
    
    this.logCaseEventEmitterService.onLogCaseButtonClick();
  }
  
  logCaseIncidentFunction(){
    
    this.incidentEventEmitterService.onIncidentButtonClick();
  }

  billingFunction(){
    
    this.billingEventEmitterService.onBillinbgButtonClick();
  }

  ngOnInit(): void {

    this.caseTypes = [
      {"id":2,"name":"BILLING CASE"},
      {"id":3,"name":"INCIDENT CASE"},
      {"id":1,"name":"OPS CASE"}
  ];

  let JSession=this.cookieService.get("JSESSIONID");
  this.accountService.getTypes(JSession)
      .subscribe(data => {
        console.log(JSON.stringify(data));
        console.log("Num types - "+data.caseTypes.caseType.length);
        for(let i : number = 0; i < data.caseTypes.caseType.length; i++){
          let x=new Type;
          x.id=data.caseTypes.caseType[i].id;
          x.description=data.caseTypes.caseType[i].description;
          x.label=data.caseTypes.caseType[i].label;
          x.name=data.caseTypes.caseType[i].name;
          x.case_category_id=data.caseTypes.caseType[i].case_category_id;
          this.dropdownListTypes.push(x);
        }
        console.log(JSON.stringify(this.dropdownListTypes));
      },
      error => {

        });

  
  }

  changeType(value){
    this.selectedType=value.name;
    this.caseId=value.id;

    this.typeSelected=true;
    this.caseTypeChosen=false;

    this.filteredDropdownListTypes = this.dropdownListTypes;
    this.filteredDropdownListTypes = this.filteredDropdownListTypes.filter(item => item.case_category_id == this.caseId);

    this.manageChoices();
  }

  changeCaseSubtype(value){
    console.log("Type selected - "+value.name);
  }

  changeCaseType(value){
    console.log("Type selected - "+value.name);
    let caseTypeId=value.id;
    this.caseTypeSelected=false;


    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.getSubTypes(JSession,caseTypeId)
        .subscribe(data => {
          
          console.log("Subtypes - "+JSON.stringify(data));
          this.dropdownListSubTypes.splice(0);

          if(data.caseSubTypes.caseSubType.length == undefined || data.caseSubTypes.caseSubType.length == 1){
            let x=new Subtype;
            x.id=data.caseSubTypes.caseSubType.id;
            x.caseTypeId=data.caseSubTypes.caseSubType.caseTypeId;
            x.name=data.caseSubTypes.caseSubType.name;
            this.dropdownListSubTypes.push(x);
          }
          else{
            for(let i : number = 0; i < data.caseSubTypes.caseSubType.length; i++){
              let x=new Subtype;
              x.id=data.caseSubTypes.caseSubType[i].id;
              x.caseTypeId=data.caseSubTypes.caseSubType[i].caseTypeId;
              x.name=data.caseSubTypes.caseSubType[i].name;
              this.dropdownListSubTypes.push(x);
            }
          }

          console.log(JSON.stringify(this.dropdownListSubTypes));
        },
        error => {
  
          });

  }

  manageChoices(){
    if(this.selectedType == "OPS CASE"){
      this.hideLC=false;
      this.hideIM=true;
      this.hideBP=true;
    }
    else if(this.selectedType == "INCIDENT CASE"){
      this.hideLC=true;
      this.hideIM=false;
      this.hideBP=true;
    }
    else if(this.selectedType == "BILLING CASE"){
      this.hideLC=true;
      this.hideIM=true;
      this.hideBP=false;
    }
    else{
      this.hideLC=true;
      this.hideIM=true;
      this.hideBP=true;
    }
  }

}
