<div class="container-fluid" style="padding: 25px">

    <div class="videoModal" [hidden]="hideTCS">
        <div class="termsModal-content" [hidden]="hideTCS">
            <button class="close" (click)="closeTermsModal()">&times;</button>
            <br>
            <p style="display: inline-block; margin-right: 10px;">I</p><input formControlName="initSurname" style="width: 200px;" class="form-control" placeholder="Initials and Surname"/>
            <br>
            <p style="display: inline-block; margin-right: 10px;">with ID number</p><input formControlName="idNum" style="width: 200px;" class="form-control" placeholder="ID Number"/>  
            <p style="display: inline-block; margin-right: 10px; margin-left: 10px;">employed at </p><input formControlName="POE" style="width: 200px;" class="form-control" placeholder="Place of Employment"/>
            <p>hereby acknowledge and agree that I am bound by the obligations placed on Standard Bank of South Africa Limited (“Standard Bank”) by the Business Relationship   Agreement together with the Data Sharing Agreement and the Addendum thereto (“the Agreements”) entered into and between Standard Bank and SBV Services (Pty) Ltd (“SBV”).</p>
            <br>
            <p>I agree that I am bound by the obligations imposed by the Agreements, in line with the applicable data protection legal frameworks, on Standard Bank and its employees to protect and safeguard the personal information contained in the video-recordings supplied by SBV to Standard Bank.</p>
            <br>
            <p>I agree that, in accordance with the obligations imposed by the Agreements, I will protect the personal information of SBV’s employees contained in the video recording and that such personal information will only be viewed, downloaded and processed only to the extent that such viewing, downloading and processing complies with the obligations outlined in the Agreements and the data protection legislation.</p>
            <br>
            <p>I further agree that the personal information of SBV’s employees contained in this video recording and under control or possession of Standard Bank will not be utilised in a manner that will compromise the integrity, security or confidentiality of the said employees.</p>
            <br>
            <p>I acknowledge that Standard Bank will be liable for any claims or actions suffered by SBV arising from the unlawful processing of the personal information contained in this video recording which occurred as a result of Standard Bank’s negligence whilst such personal information was under Standard Bank’s or its customer’s possession or control.</p>
            <br>
            <button class="btn btn-primary"  style ="float: right; margin-right: 5px; width:fit-content" (click)="acceptTerms()">
               Accept
            </button>
            <button class="btn btn-danger" style = "text-align: justify; margin-right: 15px; width:fit-content" (click)="rejectTerms()"> 
               Reject
            </button>  
        </div>
    </div>

    <div class="videoModal" [hidden]="hideModal">
        <div class="videoModal-content" [hidden]="hideModal" id="videoModal">
            <button class="close" (click)="closeVideoModal()">&times;</button>
            <br>
            <h3 style="text-align:center">VIDEO FOOTAGE</h3>
            <h5 style="text-align:center">CASE REFERENCE NUMBER</h5>
            <br>
            <div style="margin-left: 15px; display: inline-block;" *ngFor="let url of caseVideoUrls">
                <ng-container>
                    <button class="video-remove-btn" (click)="testRemove(url)">&times;</button>
                    <!-- <label>{{url}}</label> -->
                    <video id="videoPlayer" style="margin-right: 10px; margin-bottom: 10px;" width="200px" height="150px"[src]="url" controls></video>
                </ng-container>
            </div>

            <br>
            <br>
        <label style="margin-left: 400px;" class="control-label" for="videoInput">Upload Video</label>
            <div class="form-group" style="display: flex; flex-direction: row; margin-left: 165px;">
                <input formControlName="videoInput" style="width: 300px; margin-left: 90px;" class="form-control"type="file" accept="video/mp4,image/png,image/jpeg"  (change)="onSelectFile($event)"/>
                <button class="btn btn-danger" [hidden]="hideRemoveBtn" (click)="clearVideo()">X</button>
                <button class="btn btn-primary"style="margin-left:10px;" [hidden]="hideRemoveBtn">
                    Upload
                 </button>
                <br>
            </div>

            <video width = "560px" height = "328px" controlsList="nodownload" controls [src]="videoURL" [hidden]="hideRemoveBtn" style="margin-left: 200px;"></video>

        </div>
        
    </div>

    <div>
        <h6>CASE MANAGEMENT</h6>
        <h2>Case List</h2>
    </div>

    <div>
        <h5>All your cases in one place.</h5>
        <h5>Action them, resolve them</h5>
    </div>

    <hr class="mt-0">

    <div class="row d-flex ml-1">
        <div class="p-2">

        <button class="btn btn-primary">
            Select Filters
        </button>

            <ul class="nav nav-pills nav-underline">
                <li class="active"><a href="/case-list-video-footage">All cases</a></li>
                <li ><a href="/case-list-video-footage1">Open cases</a></li>
                <li ><a href="/case-list-video-footage2">Resolved cases</a></li>
                <li ><a href="/case-list-video-footage3">Closed cases</a></li>
            </ul>
        </div>
    </div>

    <div class="card card-blue-shadow" >

        <div class="row" style="border-radius: 14px;">

            <div class="col" style="border-radius: 14px;" [ngClass]="{'table-no-details' : fullTable, 'table-user-details': !fullTable}">

                <div class="card-body " style="border-radius: 14px;">
                    <table id="casesTable" class="table  table-hover" datatable [dtOptions]="dtOptions" style="width: 100%">
                        <thead>
                            <tr>
                                <th scope="col" class="user-table-headings">Started By</th>
                                <th scope="col" class="user-table-headings">Assigned To</th>
                                <th scope="col" class="user-table-headings">Case ID</th>
                                <th scope="col" class="user-table-headings">Site</th>
                                <th scope="col" class="user-table-headings">Cash Centre</th>
                                <th scope="col" class="user-table-headings">Case Type</th>
                                <th scope="col" class="user-table-headings">Priority</th>
                                <th scope="col" class="user-table-headings">SLA</th>
                               
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>

            <div [ngClass]="{'no-details' : fullTable, 'user-details': !fullTable}">

                <div class="col" *ngIf="fullTable">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <p class="clickable" (click)="showDetails()" style="margin-top: 10px;">
                            <i class="fa fa-angle-left fa-2x "></i>
                        </p>
                    </div>
                </div>


                <div class="col"  *ngIf="!fullTable" style="width:50%; background-color: #d5e6f3;" [hidden]="hideCard">

                    <p class="clickable" (click)="hideDetails()"
                            style="margin-top: 10px; margin-left: -2px; margin-bottom: -10px;">
                            <i class="fa fa-angle-right fa-2x "></i>
                    </p>

                    <h3 class="h3-grey user-detail-card">CASE DETAILS</h3>
                        <!-- <h4 class="user-detail-card row"><b>ORG</b></h4> -->

                        <div class="tab">
                            <button class="tablinks" (click)="openForm()">Form</button>
                            <button class="tablinks" (click)="openND()">Notes and Documents</button>
                            <button class="tablinks" (click)="openAT()">Audit Trail</button>
                            <button class="tablinks" (click)="openAdmin()">Admin</button>
                        </div>
                        
                        <div class="p-5 card-blue-shadow user-detail-card">
                              
                              <div [hidden]="hideForm">
                                <h3>FORM</h3>
                                <p>Contents for Form.</p>
                                <button class="linkButton" (click)="openVideoModal()">Video Footage</button>
                              </div>
                              
                              <div [hidden]="hideAT">
                                <h3>AUDIT TRAIL</h3>
                                <p>Contents for Audit Trail.</p>
                              </div>
                              
                              <div [hidden]="hideND">
                                <h3>NOTES & DOCUMENTS</h3>
                                <p>Content for Notes and Documents.</p>
                              </div>

                              <div [hidden]="hideAdmin">
                                <h3>ADMIN</h3>
                                <p>Contents for Admin.</p>
                              </div>


                            <!-- <form [formGroup]="form">

                                <hr style="margin-top: 10px">

                                <div class="form-group">
                                    <label class="control-label" for="name">FIELD 1</label>
                                    <input [readonly]="!(canEdit)" type="text" formControlName="name"
                                        [ngClass]="{'ms-disabled' : !(canEdit)}"
                                        class="form-control" placeholder="Please select group..." />
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="name">FIELD 2</label>
                                    <input [readonly]="!(canEdit)" type="text" formControlName="name"
                                        [ngClass]="{'ms-disabled' : !(canEdit)}"
                                        class="form-control" placeholder="Please select group..." />
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="name">FIELD 3</label>
                                    <input [readonly]="!(canEdit)" type="text" formControlName="name"
                                        [ngClass]="{'ms-disabled' : !(canEdit)}"
                                        class="form-control" placeholder="Please select group..." />
                                </div>
                             
                            </form> -->
                            
                        </div>
                
                </div>


            </div>
        
        
        </div>




    </div>

</div>

