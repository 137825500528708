<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButtton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="container-fluid" style="padding: 25px">

    <div class="errorBlock card card-blue-shadow w-50 border-0 p-5" [hidden]="hideSiteBanner">
        <button class="xButton" (click)="xButton()">X</button>

        <h5>Select sites to add</h5>
        <hr class="mt-0">

        <table id="addTable" class="table  table-hover" datatable [dtOptions]="addOptions"
        style="width: 100%">
        <thead>
            <tr>
                <th scope="col" class="user-table-headings">Organisation</th>
                <th scope="col" class="user-table-headings">Site Name</th>
                <th scope="col" class="user-table-headings">CIT Code</th>
                <th scope="col" class="user-table-headings">Account Number</th>
                <th scope="col" class="user-table-headings">Cash Centre</th>
                <th scope="col" class="user-table-headings">Status</th>
            </tr>
        </thead>
    </table>
    <br>
            <button class="btn btn-primary" style = "width: 150px;" (click)="displayAS()">Add Selected Sites</button>
    </div>
    <div class="errorBanner" [hidden]="hideSiteBanner"></div>

    <!-- <div class="ps-4 pe-4 pt-4 container-fluid "> -->
        <div>
            <h6 style="margin-bottom: -20px">ORGANISATION</h6>
            <h2>Add a New User</h2>
            <h5>Manually register an account on behalf of a user.</h5>
        </div>
        <hr class="mt-0">

        <div class="d-flex flex-column align-items-center">
            <div class="row d-flex">
                <div class="p-2">
                    <ul class="nav nav-pills nav-underline">
                        <li><a class="pill-no-href">User Details</a></li>
                        <li><a class="pill-no-href">User Account Details</a></li>
                        <li class="active"><a class="pill-no-href">Access Requirements</a></li>
                    </ul>
                </div>
            </div>


            <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">
                <div class="d-flex justify-content-between align-items-end">
                    <h3 class="h3-grey">User Setup</h3>
                    <p class="purple">Step 3/3</p>
                </div>
                <hr class="mt-0">
                <alert></alert>
                <form [formGroup]="form">

                    <!-- <div class="form-group">
                        <label class="control-label" for="customer">Customer</label>

                        <div class="ng-autocomplete">
                            <ng-autocomplete
                                [data]="data" [searchKeyword]="keyword" (selected)='selectEvent($event)'
                                (inputChanged)='getServerResponse($event)' (inputFocused)='onFocused($event)'
                                [itemTemplate]="itemTemplate" (inputCleared)="searchCleared()"
                                [notFoundTemplate]="notFoundTemplate" [debounceTime]="600" [isLoading]="isLoadingResult"
                                [minQueryLength]="3" placeHolder="Customer">
                            </ng-autocomplete>

                            <ng-template #itemTemplate let-item>
                                <a href="javascript:void(0)">
                                    <span [innerHTML]="item.code"></span> -  
                                    <span [innerHTML]="item.name"></span>
                                </a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                not found
                            </ng-template>
                        </div>
                        <div *ngIf="!customerChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!customerChosen">Customer is required</div>
                        </div>
                    </div> -->

                    <!-- <div class="form-group">
                        <label class="control-label" for="group">Groups</label>
                        <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;" 
                                        [placeholder]="'Groups'"
                                        [settings]="dropdownSettingsGroup" [data]="dropdownGroups"
                                        class="singleselect" clearable="true"
                                        formControlName="groups">
                         </ng-multiselect-dropdown>
                    </div> -->
                    <br>
                    <!-- <div class="form-group">
                        <label class="control-label" for="sites">Sites</label>
                        <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;" 
                                        [placeholder]="'Sites'"
                                        [settings]="dropdownSettingsSite" [data]="dropdownSite"
                                        class="singleselect" clearable="true"
                                        formControlName="sites">
                         </ng-multiselect-dropdown>
                    </div> -->
                    <br>
                    <h3 class="h3-grey">Linked Sites</h3>
                    <br>
                    <button class="btn btn-primary" style = "text-align: justify; margin-right: 15px;" (click)="showSites()">Add</button>
                       <table id="siteTable" class="table  table-hover" datatable [dtOptions]="dtOptions"
                            style="width: 100%">
                            <thead>
                                <tr>
                                    <th scope="col" class="user-table-headings">Organisation</th>
                                    <th scope="col" class="user-table-headings">Name</th>
                                    <th scope="col" class="user-table-headings">CIT Code</th>
                                    <th scope="col" class="user-table-headings">Account Number</th>
                                    <th scope="col" class="user-table-headings">Cash Centre</th>
                                </tr>
                            </thead>
                            <!-- <tbody>
                                <tr>
                                    <td>{{ name }}</td>
                                    <td>{{ code }}</td>
                                    <td>{{ accNum }}</td>
                                    <td>{{ cc }}</td>

                                    <td><button class="delete-button" (click)="deleteItem()">Remove</button></td>

                                </tr>
                            </tbody> -->
                        </table>
                        <div *ngIf="invalidSites" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="invalidSites">Please select sites</div>
                        </div>

                    <!-- <div class="form-gorup">
                        <label class="control-label" for="locType">Site Type</label>
                        <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;"
                            [placeholder]="'Select Site Type'" [settings]="dropdownSettingsLocs"
                            [data]="dropdownListLocs" (onSelect)="onItemSelectLoc($event)" formControlName="loc"
                            class="singleselect" clearable="true">
                        </ng-multiselect-dropdown>
                    </div> -->

                    <div class="form-group">
                        <div class="d-flex justify-content-between" style="padding-top: 1em;">
                            <button class="btn btn-default" routerLink="../new-user-role">
                                Back
                            </button>
                            <button [disabled]="loading" class="btn btn-primary" (click)="onSubmit()">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Register New User
                            </button>
                        </div>
                        <!-- <button [disabled]="loading" class="rounded btn btn-primary w-100 ">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Verify Email
                        </button> -->
                    </div>
                </form>
            </div>
        </div>

    </div>

<!-- </div> -->