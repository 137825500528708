

  <mat-sidenav-container class="example-container" >

    <mat-sidenav
      #sidenav
      mode="side"
      opened
      [position]="'start'"
      class="example-sidenav-1"
      [ngClass]="{'example-sidenav-2':loadOpen==true,'example-sidenav-1':loadOpen==false}"
      mat-sidenav [hidden]= "hideMenu"
      *ngIf="

      path!=''
      && path!='/login'
      && path!='/otp'
      && path!='/video-footage'
      && path!='/register'
      && path!='/register-details-processing'
      && path!='/cash-centre'
      && path!='/forgot-password'
      && path!='/forgot-password-finish'
      && path!='/reset-password'
      && path!='/reset-password-success'
      && path!='/ott/:token'
      && path!='/request-access'
      && path!='/request-access-processing'
      && path!='/help-finish'
      && path!='/help'
      && path!='/logout'
      && path!='/finalise'
      && path!='/finalise-password-set'
      && path!='/not-found'"

    >
      <div class= "mat-drawer-inner-container" [hidden]="hideOptions">
        <a routerLink="/home">
          <img [hidden]="hideL" class="Logobox"src="assets/img/favicon.png" alt="image" (click)="imageHome()">
        </a>

        <a routerLink="/home">
          <img [hidden]="hideBL"class="box"src="assets/img/Connect-Logo2_11x4.5.svg" alt="image" (click)="imageHome()">
        </a>

        <a mat-button (click)="animateSidenav()">
          <mat-icon style="color: rgba(0, 0, 0, 0.87) !important;">menu</mat-icon>
        </a>

        <div style="background: #bcd7ec; height: 100px; color: white; cursor: pointer; position: relative; padding-right: 7px;" >
          <mat-list-item routerLink="/profile-settings" mat-button (click)="showRouterOutlet()">
              <div class="circle">{{userInitials}}</div>
          </mat-list-item>
          <div class="text-container" [hidden]="hideInfo">
              <a style="font-size: 14px; color: rgba(0, 0, 0, 0.603); font-weight: bold; line-height: 17px;">{{currentUserName}}</a>
              <br>
              <a style="font-size: 10px; color: rgba(0, 0, 0, 0.603); line-height: 12px;">{{currentUserOrg}}</a>
              <!-- <hr> -->
              <br>
              <button [hidden]="roleChangeHidden" routerLink="/role-change-login" (click)="showRouterOutlet()" style="background-color: #6495ed; border: none; color: white; border-radius: 12px; padding: 5px 10px; font-size: 10px; margin-top: 5px;">{{changeToDepartment}}</button>
          </div>
      </div>
      

        <a class="aOptions" routerLink="/home" routerLinkActive="active-list-item" mat-button (click)="showRouterOutlet()">
          <mat-list-item>
            <mat-icon matTooltip="Home" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="HomeDef"></mat-icon>
              Home
          </mat-list-item>
        </a>

        <div [hidden]="hideL" style="background: #445660 ; opacity:20% !important; margin-left: 10px; width: 37px;  height: 1px;"></div>
        <p  [hidden]="hideBL" style="font-size:11px; font-family: Helvetica ; font-weight:bold; color:#C4C4C4; margin-left: 17px ">CASE MANAGEMENT</p>


        <a class="aOptions" routerLink="/dashboard" routerLinkActive="active-list-item" mat-button [hidden]="hideDashboard" (click)="viewDashboard()">
          <mat-list-item>
            <mat-icon matTooltip="Dashboard" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="DashboardDef"></mat-icon>
              Dashboard
          </mat-list-item>
        </a>

        <a class="aOptions" routerLink="/task-list" routerLinkActive="active-list-item" mat-button [hidden]="hideTaskList" (click)="viewTaskList()">
          <mat-list-item>
            <mat-icon matTooltip="Task List" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="TaskListDef"></mat-icon>
              Task List
          </mat-list-item>
        </a>

        <a class="aOptions" routerLink="/case-list" routerLinkActive="active-list-item" mat-button [hidden]="hideCaseList" (click)="viewCaseList()">
          <mat-list-item>
            <mat-icon matTooltip="Case List" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="CaselistDef"></mat-icon>
              Case List
          </mat-list-item>
        </a>

        <a class="aOptions" routerLink="/log-new-case" routerLinkActive="active-list-item" mat-button [hidden]="hideLogCase" (click)="viewLogCase()">
          <!-- <iframe width="100%" height="300" [src]="devUrl"></iframe> -->
          <mat-list-item>
            <mat-icon matTooltip="Log New Case" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="NewCaseDef"></mat-icon>
              Log New Case
          </mat-list-item>
        </a>

        <a class="aOptions" routerLink="/create-billing-case" routerLinkActive="active-list-item" mat-button [hidden]="hideBP" (click)="viewBillingProcess()">
          <mat-list-item>
            <mat-icon matTooltip="Create Billing Case" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="DashboardDef"></mat-icon>
              Create Billing Case
          </mat-list-item>
        </a>

        
        <p  [hidden]="hideBL || hideNatDashboard" style="font-size:11px; font-family: Helvetica ; font-weight:bold; color:#C4C4C4; margin-left: 17px  ">REPORTING</p>

        <a class="aOptions" routerLink="/reporting" routerLinkActive="active-list-item" mat-button [hidden]="hideReport" (click)="viewReports()">
          <mat-list-item>
            <mat-icon  matTooltip="Reports" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="TaskListDef"></mat-icon>
              Reports
          </mat-list-item>
        </a>

        <a class="aOptions" routerLink="/incident-process" routerLinkActive="active-list-item" mat-button [hidden]="hideIM" (click)="viewIM()">
          <mat-list-item>
            <mat-icon  matTooltip="Incident Management" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="CaselistDef"></mat-icon>
              Incident Management
          </mat-list-item>
        </a>

        <a class="aOptions" routerLink="/order-management" routerLinkActive="active-list-item" mat-button [hidden]="hideOm" (click)="viewOM()">
          <mat-list-item>
            <mat-icon  matTooltip="Order Management" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="CaselistDef"></mat-icon>
              Order Management
          </mat-list-item>
        </a>


        <a class="aOptions" routerLink="/customer-views" routerLinkActive="active-list-item" mat-button [hidden]="hideBankViews" (click)="viewBankViews()">
          <mat-list-item>
            <mat-icon  matTooltip="Customer Views" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="CaselistDef"></mat-icon>
              Customer Views
          </mat-list-item>
        </a>
        

        <a class="aOptions" routerLink="/national-dashboard" routerLinkActive="active-list-item" mat-button [hidden]="hideNatDashboard" (click)="viewNatDashboard()">
          <mat-list-item>
            <mat-icon matTooltip="Performance Dashboard" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="DashboardDef"></mat-icon>
              Performance Dashboard
          </mat-list-item>
        </a>

        <div [hidden]="hideL" style="background: #445660 ; opacity:20%  !important; margin-left: 10px; width: 37px;  height: 1px;"></div>
        <p  [hidden]="hideBL || hideUserAccess" style="font-size:11px; font-family: Helvetica ; font-weight:bold; color:#C4C4C4; margin-left: 17px ">ORGANISATION</p>

        <a class="aOptions" routerLink="/user-management" routerLinkActive="active-list-item" mat-button [hidden]="hideUserAccess" (click)="showRouterOutlet()">
          <mat-list-item>
            <mat-icon  matTooltip="Manage User Access" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="ManageUsersDef"></mat-icon>
              Manage User Access
          </mat-list-item>
        </a>

        <!-- <a class="aOptions" routerLink="/case-router" routerLinkActive="active-list-item" mat-button [hidden]="false" (click)="showRouterOutlet()">
          <mat-list-item>
            <mat-icon  matTooltip="Manage User Access" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="ManageUsersDef"></mat-icon>
              Case Router
          </mat-list-item>
        </a> -->

        <a class="aOptions" routerLink="/merchant-management" routerLinkActive="active-list-item" mat-button [hidden]="hideMerchant" (click)="showRouterOutlet()">
          <mat-list-item>
            <mat-icon  matTooltip="Manage Customers" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="ManageUsersDef"></mat-icon>
              Manage Organisation
          </mat-list-item>
        </a>

        <p  [hidden]="hideBL" style="font-size:11px; font-family: Helvetica ; font-weight:bold; color:#C4C4C4; margin-left: 17px  ">SYSTEM SETTINGS</p>

        <!-- <a class="aOptions" routerLink="/case-types" routerLinkActive="active-list-item" mat-button [hidden]="hideMerchant" (click)="showRouterOutlet()">
          <mat-list-item>
            <mat-icon  matTooltip="Configure Case Types" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="TaskListDef"></mat-icon>
              Configure Case Types
          </mat-list-item>
        </a> -->

        <!-- <a class="aOptions" routerLink="/edit-case-type" routerLinkActive="active-list-item" mat-button [hidden]="hideMerchant" (click)="showRouterOutlet()">
          <mat-list-item>
            <mat-icon  matTooltip="Configure SLAs" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="CaselistDef"></mat-icon>
              Configure SLAs
          </mat-list-item>
        </a> -->


        <div [hidden]="hideL" style="background: #445660; opacity:20%  !important; margin-left: 10px; width: 37px;  height: 1px;"></div>

        <a class="aOptions" routerLink="/super-user-logout" routerLinkActive="active-list-item" mat-button (click)="showRouterOutlet()" [hidden]="hideSuperUserLogout">
          <mat-list-item>
            <mat-icon  matTooltip="Super User Logout" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="HelpCentreDef"></mat-icon>
              Super User Logout
          </mat-list-item>
        </a>

        <a class="aOptions" routerLink="/help-centre" routerLinkActive="active-list-item" mat-button (click)="showRouterOutlet()">
          <mat-list-item>
            <mat-icon  matTooltip="Help Centre" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="HelpCentreDef"></mat-icon>
              Help Centre
          </mat-list-item>
        </a>

        <a class="aOptions" routerLink="/logout" routerLinkActive="active-list-item" mat-button (click)="showRouterOutlet()" [hidden]="!hideSuperUserLogout">
          <mat-list-item>
            <mat-icon  matTooltip="Logout" matTooltipPosition="right" [matTooltipDisabled]="tooltip_disable" svgIcon="LogoutDef"></mat-icon>
              Logout
          </mat-list-item>
        </a>
      </div>

    </mat-sidenav>
    <mat-sidenav-content>

      <div [hidden]="hideRouter">
        <router-outlet></router-outlet>
      </div>
      <iframe id="myFrame" [hidden]="hideIframe" width="100%" height="99%" style="border:none;" [src]="devUrl" sandbox="allow-same-origin allow-scripts allow-forms allow-downloads allow-top-navigation allow-top-navigation-by-user-activation"></iframe>

    </mat-sidenav-content>
  </mat-sidenav-container>
<app-footer></app-footer>
