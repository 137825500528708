<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="res-banner" [hidden]="hideResBanner">
    <h1 style="color: crimson;">Oops, looks like your device's resolution isn't currently supported!</h1>
    <h4 style="color: #445661">We only support resolutions greater than 1024px wide.</h4>
    <h4 style="color: #445661">To access this platform, please ensure your device/browser meets the minimum specifications of 1024px wide. Also ensure that you are using the latest version of your chosen browser, preferably Chrome, Firefox or Edge.</h4>
    <h3>Need Help?</h3>
    <h4 style="color: #445661">If you cannot increase your resolution, please use the contact form below to let us know! We're continuously looking to improve our service offering, and would appreciate your feedback.</h4>
    
    <!-- Details -->
    <div class="ps-4 pe-4 pt-4 container-fluid ">
        <div class="d-flex justify-content-start align-items-end">
        </div>
        <h5>Your Details</h5>
        <hr class="mt-0">
        <alert></alert>
        <form [formGroup]="helpForm">
            <div class="form-group">
                <label class="control-label" for="name">Name</label><span style="color:#ff0000"> *</span>
                <input type="text" formControlName="name" class="form-control " placeholder="Name"
                    [ngClass]="{ 'is-invalid': submitted && h.name.errors }" />
                <div *ngIf="submitted && h.name.errors" class="invalid-feedback">
                    <div *ngIf="h.name.errors.required">Name is required</div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label" for="email">Email</label><span style="color:#ff0000"> *</span>
                <input type="text" formControlName="email" class="form-control " placeholder="Email"
                    [ngClass]="{ 'is-invalid': submitted && h.email.errors }" />
                <div *ngIf="submitted && h.email.errors" class="invalid-feedback">
                    <div *ngIf="h.email.errors.required">Email is required</div>
                </div>
                <div class="invalid-feedback" *ngIf="h.email.errors?.pattern">
                    Invalid email format
                </div>
            </div>
            <div class="form-group">
                <label class="control-label" for="contactNo">Contact Number</label><span style="color:#ff0000">
                    *</span>
                <input type="text" mask='(000) 000-0000' formControlName="contactNo" class="form-control "
                    placeholder="Contact number"
                    [ngClass]="{ 'is-invalid': submitted && h.contactNo.errors }" />
                <div *ngIf="submitted && h.contactNo.errors" class="invalid-feedback">
                    <div *ngIf="h.contactNo.errors.required">Contact number is required</div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label" for="description">Describe Your Issue</label><span
                    style="color:#ff0000"> *</span>
                <input type="text" formControlName="description" class="form-control"
                    placeholder="Please describe the issue that you are experiencing"
                    [ngClass]="{ 'is-invalid': submitted && h.description.errors }" />
                <div *ngIf="submitted && h.description.errors" class="invalid-feedback">
                    <div *ngIf="h.description.errors.required">Description is required</div>
                </div>
            </div>

            <div class="w-100">
                <div class="form-group">
                    <button [disabled]="loading" [disabled]="requestSubmitted" class="rounded btn btn-primary w-100 " (click)="request()">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Request Help
                    </button>
                </div>
            </div>
        </form>
    </div>
    <!-- Details -->


</div>

<div [hidden]="hideLogin" class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <img src="assets/img/SBV-Connect_Connect-Logo-Light.png" height="40px">
                <div class="mt-0">
                    <h3 style="font-size: 30px;" class="mt-3">Welcome!</h3>
                </div>
                <p>Your digital window into our physical world</p>

                <h5 class="mt-4 pt-4">Login</h5>
                <p>Enter your user details below</p>

                <hr class="mt-0">
                <alert></alert>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">


                    <div class="form-group">
                        <label class="control-label" for="inputDefault">Email</label>
                        <input class="form-control" id="focusedInput" type="text" id="email" formControlName="email"
                            placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="inputDefault">Password</label>
                        <!-- <show-hide-password icon="entypo" btnStyle="info" [btnOutline]="false"> -->
                        <input type="password" formControlName="password" class="form-control" id="password" placeholder="Password"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" data-toggle="password" />
                        <!-- </show-hide-password> -->
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Password must be at least 8 characters</div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center ms-5 me-5 pt-2 mb-4">
                        <div [hidden]="hideLogin" class="checkbox">
                            <label> <input type="checkbox" class="form-check-input" id="rememberMe"
                                    (change)="rememberMeCheck()">
                                <span style="vertical-align: sub">Remember me</span> </label>
                        </div>
                        <a class="p-0 blue-link text-align-right" routerLink="../forgot-password">Forgot password</a>
                    </div>
                    <div class="w-100">
                        <div class="form-group">
                            <button [disabled]="loading" id="loginButton" class="rounded btn btn-primary w-100 ">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Login
                            </button>
                        </div>
                    </div>
                    <hr class="pb-0 mb-3">
                    <div class="d-flex flex-column justify-content-center align-items-center ms-5 me-5 pt-0 mt-0 mb-4">
                        <p class="p-green">Don't have an account?</p>
                        <a class="a-green bold-link" routerLink="../register">Register Now</a>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>

<!-- Cookie Consent Banner  -->
<div id="cookieBanner" [hidden]="acceptedCookies" class="cookie-container">

    <div class="hide-container">
        <button class="hide-button hH" (click)="hideBanner()">{{hideButton}}</button>
    </div>

    <p class="p-cookie">We use cookies to optimize our website and our service.</p>
    <p><a href="https://www.sbv.co.za/cookie-policy/" target="_blank" class="p-aref">Cookie Policy</a></p>

    <div [hidden]="showPreferances" class="preferances">

        <input type="checkbox" style="pointer-events: none;" checked/>
        <label for="myinput" class="indented-checkbox-text" >FUNCTIONAL</label>

        <input type="checkbox" (change)="marketingCheck($event)" />
        <label for="myinput" class="indented-checkbox-text">MARKETING</label>

    </div>

    <button class="cookie-btn aH" (click)="acceptCookies()">
        Accept all
    </button>

   <div class="buttonContainer">

    <button class="dismiss-button h" (click)="rejectCookies()">
        Dismiss
    </button>
    <button class="pref-button h" (click)="displayPref()">
        {{prefButton}}
    </button>

   </div>
</div>

<div class="show-container" [hidden]="show">
    <button class="show-button hH" (click)="showBanner()">
        show
    </button>
</div>