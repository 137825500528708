import { Contact } from "./contact"
import { Profile } from "./profile";
import { Roles } from "./roles";
// import { Role } from "./roles"

export class AccessRequestUser 
{  
    email: string;
    contact: Contact;
    roles: Roles[];
    // roles: Role;
    profile: Profile; //cashCentres
}