<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="container-fluid" style="padding: 25px">

    <!-- <div class="ps-4 pe-4 pt-4 container-fluid "> -->

        <div>

            <!-- <h6 style="margin-bottom: -20px">ORGANISATION</h6> -->

            <h2>New Case</h2>
  
            <h5>Add Case</h5>

            

        </div>

        <hr class="mt-0">



        <div class="d-flex flex-column min-vh-100 align-items-center">

           





            <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">

                <div class="d-flex justify-content-between align-items-end">

                    <h3 class="h3-grey">Case Details</h3>

                    <p class="purple"></p>

                </div>

                <hr class="mt-0">

                <form [formGroup]="form">

                 <div class="form-group">
                        <label class="control-label" for="group">Case Type</label><span style="color:#ff0000"> *</span>
                        <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;" 
                                        [placeholder]="'Groups'"
                                        [settings]="dropdownSettingsGroup" [data]="dropdownGroups"
                                        class="singleselect" clearable="true"
                                        formControlName="groups"
                                        (onSelect)="onGroupSelect($event)"
                                        (onDeSelect)="onGroupDeselect()">

                         </ng-multiselect-dropdown>
                         <div *ngIf="submitted && !groupChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!groupChosen">Organisation is required</div>
                        </div>
                    </div>

                  <div class="form-group">
                    <label class="control-label" for="siteName">Case ID</label><span style="color:#ff0000"> *</span>
                    <input [readonly]="false" type="text"
                        formControlName="siteName" class="form-control "
                        placeholder="Enter Name"
                        [ngClass]="{ 'is-invalid': submitted && f.siteName.errors }" />
                        <div *ngIf="submitted && f.siteName.errors" class="invalid-feedback">
                            <div *ngIf="f.siteName.errors.required">Name is required</div>
                        </div>
                </div>

                <!-- <div class="form-group">
                    <label class="control-label" for="CIT">CIT Code</label><span style="color:#ff0000"> *</span>
                    <input [readonly]="false" type="text"
                    formControlName="CIT" class="form-control "
                    placeholder="Enter CIT Code"
                    [ngClass]="{ 'is-invalid': submitted && f.CIT.errors }" />
                    <div *ngIf="submitted && f.CIT.errors" class="invalid-feedback">
                        <div *ngIf="f.CIT.errors.required">CIT Code is required</div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label" for="AccNo">Account Number</label><span style="color:#ff0000"> *</span>
                    <input [readonly]="false" type="text"
                    formControlName="AccNo" class="form-control "
                    placeholder="Enter Account Number"
                    [ngClass]="{ 'is-invalid': submitted && f.AccNo.errors }" />
                    <div *ngIf="submitted && f.AccNo.errors" class="invalid-feedback">
                        <div *ngIf="f.AccNo.errors.required">Account Number is required</div>
                    </div>
                </div> -->

                <label class="control-label" for="cc">Customer </label><span style="color:#ff0000"> *</span>
                <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;" 
                                [placeholder]="'Cash centres'"
                                [settings]="dropdownSettingsCC" [data]="dropdownCCList"
                                class="singleselect" clearable="true"
                                formControlName="cashCentres"
                                (onSelect)="onCCSelect($event)"
                                (onDeSelect)="onCCDeselect()">
                 </ng-multiselect-dropdown>
                 <div *ngIf="submitted && !ccChosen" style="font-size: 80%; color: #dc3545">
                    <div *ngIf="!ccChosen">Cash Centre is required</div>
                </div>

                </form>

                <div *ngIf="submitted && duplicateSite" style="font-size: 12px; color: #dc3545">
                    <div *ngIf="duplicateSite">Error, a site with these details already exists</div>
                </div>
                
                <div class="p-5 ; width:100% margin-bottom: 12px; margin-left: 16px;">

                <button class="btn btn-default" style = "text-align: justify; margin-right: 15px; width:fit-content" (click)="return()"> 
                    <!-- <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> -->
                    Back 
                </button>  
                <!-- <button class="btn btn-primary" style = " text-align: justify; margin-right: 15px; width:fit-content"> Save Changes </button> -->
                <button class="btn btn-primary"  style ="float: right; margin-right: 15px; width:fit-content" (click)="addSite()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Add
                </button>        
            </div>
            </div>

             

            </div>

        </div>

