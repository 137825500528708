import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../_models';
import { environment } from '../environments/environment';
import { Claim } from '../_models/claim';
import { Ott } from '../_models/ott';
import { RequestAccess } from '../_models/request-access';


// import { environment } from '@environments/environment';
// import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    // private tokenSubject: BehaviorSubject<Token>;
    // public token: Observable<Token>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
        // this.token = this.tokenSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    createGroup(groupName, email, jsession){
        const params = new HttpParams()
            .set('userId', email)
            .set('jsession', jsession);

            const body = {
                "customer": {
                    "name": groupName,
                }
            };

        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post<any>(`./services/mdmCustomer/customers`,body, {headers:headers, params: params });
    }

    getMerchantGroups(email, jsession){

        const params = new HttpParams()
            .set('userId', email)
            .set('jsession', jsession);
         
        return this.http.get<any>(`./services/mdmCustomer/customers`, { params: params });

    }

    updateMerchantGroup(newName,jsession){
        const params = new HttpParams()
            
            .set('jsession', jsession);

            const body =newName;
            const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.put(`./services/mdmCustomer/customers`, body, { headers: headers, params: params });
    }

    createSite(site,jsession){

        const params = new HttpParams()
            .set('jsession', jsession);

            const body = site;

        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post<any>(`./services/mdmSites/sites`,body, {headers:headers, params: params });

    }

    getSites(jsession){
        const params = new HttpParams()
            .set('jsession', jsession);
         
        return this.http.get<any>(`./services/mdmSites/sites`, { params: params });
    }

    updateSite(details,jsession){
        const params = new HttpParams()
            
            .set('jsession', jsession);

            const body =details;
            const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.put(`./services/mdmSites/sites`, body, { headers: headers, params: params });
    }

    // resendOTP(ottId){

    //     const params = new HttpParams()
        
    //     const body =ottId;
    //     const headers = new HttpHeaders().set('Content-Type', 'application/json');
    //     return this.http.put<any>(`./services/iam/auth/tokens/`, body, { headers: headers, params: params });
    // }

    getTypes(jsession){
        const params = new HttpParams()
            .set('jsession', jsession);
            return this.http.get<any>(`./services/cm/common/caseTypes`, { params: params });
    }

    getSubTypes(jsession,caseTypeId){
        const params = new HttpParams()
            .set('jsession', jsession)
            .set('caseTypeId', caseTypeId);
            return this.http.get<any>(`./services/cm/common/caseSubTypes`, { params: params });
    }

    getGroupSites(jsession,id){
        const params = new HttpParams()
            .set('jsession', jsession);

        return this.http.get<any>(`./services/mdmCustomer/customers/`+id+'/sites', { params: params });
    }

    getUser(email, jsession) {
        const params = new HttpParams()
            .set('userId', email)
            .set('jsession', jsession);
        return this.http.get<any>(`./services/iam/data/users`, { params: params });
    }

    getUserBanks(userId: string, jsession) {
        const param = new HttpParams()
            .set('jsession', jsession);
        return this.http.get<any>(`./services/iam/profile/users/` + userId + `/profiles/`, { params: param });
    }

    getCurrentUser(email, jsession) {
        const params = new HttpParams()
            .set('userId', email)
            .set('jsession',jsession);
        return this.http.get<any>(`./services/iam/info/users`, { params: params });
    }

    getAllUsers(jsession) {
        const params = new HttpParams()
        .set('jsession',jsession);
        return this.http.get<any>(`./services/iam/info/users`, { params: params });
    }

    getAllUsersByToken(token, jsession) {
        const params = new HttpParams()
            .set('token', token)
            .set('jsession',jsession);
        return this.http.get<any>(`./services/iam/info/users`, { params: params });
    }

    getAllUsersByTokenAndStatus(token, status, jsession) {
        console.log(status);
        const params = new HttpParams()
            .set('status', status)
            .set('token', token)
            .set('jsession',jsession);
        return this.http.get<any>(`./services/iam/info/users`, { params: params });
    }

    getCustomerAutocomplete(limit, query, jSessionID) {
        // console.log(status);
        const params = new HttpParams()
            // .set('tier', tier)
            .set('limit', limit)
            .set('query', query)
            .set('jsession', jSessionID);
        return this.http.get<any>(`./services/mdm/customers`, { params: params });
    }

    loginCredentials(email, password): Observable<any> {
        let claim = new Claim();
        claim.email = email;
        claim.password = password;
        const body = {
            "claim": {
                "email": email,
                "password": password
            }
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        // return this.http.post<TokenApi>(`./services/iam/tokens`, JSON.stringify(claim), {headers: headers})
        return this.http.post<Ott>(`./services/iam/auth/tokens`, body, { headers: headers });
    }

    resendOTP(ottId){
        
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get<any>(`./services/iam/auth/tokens/` + ottId, { headers: headers, observe: 'response' })
    }


    loginOtp(code): Observable<HttpResponse<any>> {  //RETURNS JSESSON FOR BONITA

        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const params = new HttpParams()
            .set('ottId', sessionStorage.getItem('ottId'))
            .set('ottCode', code);
        headers.set('access-control-allow-origin', `.`);
        // return this.http.post<TokenApi>(`./services/iam/tokens`, JSON.stringify(claim), {headers: headers})

        return this.http.get<any>(`./services/iam/auth/tokens`, { headers: headers, params: params, observe: 'response' })
        // return this.http.get<any>(`./services/iam/auth/tokens`, { headers: headers, params: params, responseType: 'json' })
        // // .pipe(
        // //     map((res) => {
        // //     console.log(res);
        // //     res.json()})

        // // );   
        // .pipe(
        //         map((res: Response) => {
        //             if (res) {
        //                 if (res.status === 201) {
        //                     console.log('GOT 201');
        //                     // return [{ status: res.status, json: res }]
        //                     return res.json;
        //                 }
        //                 else if (res.status === 200) {
        //                     console.log('GOT 200')
        //                     console.log(res);
        //                     return res.json;
        //                 }
        //             }
        //         }
        //         ),
        //         catchError((err) => {
        //             console.log(err.status);
        //             return Observable.throw(err.status);
        //         })
        //     );
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/login']);
    }

    // requestAccess(userDetails: RequestAccess) {
    //     const params = new HttpParams()
    //         .set('type', "3");

    //     const body = userDetails;
    //     // console.log(body);
    //     const headers = new HttpHeaders().set('Content-Type', 'application/json');
    //     return this.http.put(`./services/iam/identity/users`, body, { headers: headers, params: params });
    // }

    requestAccess(userDetails: RequestAccess, ottId, ottCode) {
        const params = new HttpParams()
            .set('type', "3")
            .set('ottId', ottId)
            .set('ottCode', ottCode);

        const body = userDetails;
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(`./services/public/connect/users`, body, { headers: headers, params: params });
    }

    requestAccessAdmin(userDetails: RequestAccess, jsession) {
        const params = new HttpParams()
            .set('type', "1")
            .set('jsession', jsession);

        const body = userDetails;
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(`./services/iam/management/users`, body, { headers: headers, params: params });
    }




    getErrorTest(){
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`./services/test/errors/`); 
    }

    submitComment(caseId,commentObject,jSession) {
        const params = new HttpParams()
        .set('jsession',jSession);
        const body = commentObject;

        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`./services/cm/data/cases/` + caseId + `/comments/`,body, { headers: headers, params: params });
        
    }

    submitUpdateRequest(updateObject, jSession) {
        const params = new HttpParams()
        .set('jsession',jSession);
        const body = updateObject;

        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`./services/iam/maintenance/updates/`, body, { params: params });
    }

    getUpdateRequest(userId: string, jSession) {
        const params = new HttpParams()
        .set('userId', userId)
        .set('jsession',jSession);

        return this.http.get<any>(`./services/iam/maintenance/updates`,{ params: params });
    }

    getUserCases(userId: string,startDate:string,endDate:string,jSession){
        const params = new HttpParams()
        .set('jsession',jSession);

        return this.http.get<any>(`./services/rpt33/myCaseListAdvancedSearchOpen/`+userId+`/`+startDate+`/`+endDate, { params: params });
    }

    getCaseDetails(jsession,caseId){
        const params = new HttpParams()
        .set('caseId', caseId)
        .set('jsession',jsession);

        return this.http.get<any>(`./services/cm/data/cases`,{ params: params });
    }

    deleteVideo(jsession,videoId){

        const params = new HttpParams()
        .set('jsession',jsession);

        return this.http.delete(`./services/cm/media/videos/`+videoId,{ params: params });
    }

    uploadVideo(caseId,jsession,videoBlob){
        const params = new HttpParams()
        .set('caseId', caseId)
        .set('jsession',jsession);

        const body=videoBlob;
        const headers = new HttpHeaders().set('Content-Type', 'application/octet-stream');

        return this.http.post<any>(`./services/cm/media/videos`, body, { params: params });

    }

    getCaseVideos(jsession,caseId){
        const params = new HttpParams()
        .set('jsession',jsession)
        .set('caseId', caseId)

        return this.http.get<any>(`./services/cm/media/videos/`,{ params: params });
    }

    approveUpdate(approvalObject, jSession){
        const params = new HttpParams()
        .set('jsession',jSession);

        const body=approvalObject;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(`./services/iam/maintenance/updates/`, body, { params: params });
        
    }

    // updateUser(userDetails) {
    //     const params = new HttpParams()
    //         .set('type', "3");

    //     const body = userDetails;
    //     // console.log(body);
    //     const headers = new HttpHeaders().set('Content-Type', 'application/json');
    //     return this.http.put(`./services/iam/identity/users`, body, { headers: headers, params: params });
    // }

    updateUserContact(userDetails, jsession) {
        const params = new HttpParams()
            .set('type', "1")
            .set('jsession',jsession);
        const body = userDetails;
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(`./services/iam/management/users`, body, { headers: headers, params: params });
        // return this.http.put(`./services/iam/identity/users`, body, { headers: headers, params: params });
    }

    updateUserRoles(userDetails, jsession) {
        const params = new HttpParams()
            .set('type', "2")
            .set('jsession',jsession);
        const body = userDetails;
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(`./services/iam/management/users`, body, { headers: headers, params: params });
    }

    updateUserCC(userDetails, jsession) {
        const params = new HttpParams()
            .set('type', "3")
            .set('jsession',jsession);

        const body = userDetails;
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(`./services/iam/management/users`, body, { headers: headers, params: params });
    }

    updateUserStatus(userDetails, jsession) {
        const params = new HttpParams()
            .set('type', "10")
            .set('jsession',jsession);

        const body = userDetails;
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(`./services/iam/management/users`, body, { headers: headers, params: params });
    }

    logicalDelete(jsession,email){

        const params = new HttpParams()
        .set('jsession',jsession)
        return this.http.delete(`./services/iam/super/users/`+email,{ params: params });
    }

    autocompleteCustomer(letters, jsession) {
        const params = new HttpParams()
            .set('query', letters)
            .set('jsession', jsession)

        // const body = userDetails;
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(`./iam/data/users/'+userId+'/profiles`, { headers: headers, params: params });
    }

    getUserSites(userId,jsession){
        const params = new HttpParams()
            .set('jsession', jsession)
           
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`./services/iam/data/users/` + userId + `/sites`, { headers: headers, params: params });
    }


    getSuperUserSession(email,jsession){

        const params = new HttpParams()
            .set('jsession', jsession)

        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        const body = {
            "targetUser": email
        };

        return this.http.post<any>(`./services/iam/super/supers`, body, { headers: headers, params: params});
    }

    registerDetails(user: User): Observable<any> {  //), ip: string
        
        const body = {
            "user": {
                "firstName": user.firstName,
                "lastName": user.lastName,
                "email": user.email,
                "contactNo": user.contactNo
            }
        };
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers.set('access-control-allow-origin', `.`);
        return this.http.post<any>(`./services/public/connect/users`, body, { headers: headers});
    }

    addNewUser(user: User, jsession): Observable<any> {  //), ip: string
        const body = {
            "user": {
                "firstName": user.firstName,
                "lastName": user.lastName,
                "email": user.email,
                "contactNo": user.contactNo
            }
        };
        // console.log(body);
        const params = new HttpParams()
            .set('jsession',jsession);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers.set('access-control-allow-origin', `.`);
        return this.http.post<any>(`./services/iam/management/users`, body, { headers: headers, params: params});
    }

    registerPassword(email: string, password: string,ottId,ottCode) {
        const params = new HttpParams()
            .set('type', "2")
            .set('ottId', ottId)
            .set('ottCode', ottCode);
            
        const body = {
            "user": {
                "email": email,
                "password": password,
            }
        };
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(`./services/public/connect/users`, body, { headers: headers, params: params });
    }

    sendOtp(user: User) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers.set('access-control-allow-origin', `.`);
        return this.http.post(`./users/send-otp`, user);
    }

    forgotPassword(email: string): Observable<any> {
        const params = new HttpParams()
            .set('type', "1");

        const body = {
            "user": {
                "email": email
            }
        };
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(`./services/public/connect/users`, body, { headers: headers, params: params });
    }

    ott(token: string) {
        return this.http.get<any>(`./services/public/connect/otts/` + token);
    }

    getCashCentresRevised(){
        const param = new HttpParams()
            .set('type', 'cashCentres');
        return this.http.get<any>(`./services/public/utility/crds`, { params: param });
    }

    resetPassword(password: string, ottId, ottCode) {
        const params = new HttpParams()
            .set('type', "2")
            .set('ottId', ottId)
            .set('ottCode', ottCode);
        const body = {
            "user": {
                "email": sessionStorage.getItem('email'),
                "password": password
            }
        };
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(`./services/public/connect/users`, body, { headers: headers, params: params });
    }

    getCashCentres(jSession) {

        const params = new HttpParams()
        .set('jsession',jSession);
        //const headers = new HttpHeaders().set('access-control-allow-origin', `.`);
        return this.http.get<any>(`./services/crd/cashCentres`, {params: params});
    }

    getRegions(jSession) {
        const params = new HttpParams()
            .set('jsession',jSession);
        // const headers = new HttpHeaders().set('access-control-allow-origin', `.`);
        return this.http.get<any>(`./services/crd/regions`, {params: params});
    }

    authenticateBonita() {
        const body = new HttpParams()
            .set('username', "grego")
            .set('password', "bpm");
        return this.http.post<any>(`https://360.sbv.co.za/bonita/loginservice?`, body.toString(),
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
            })
    }

   
    requestHelp(name: string, email: string, contactNo: string, description: string) {
        const body = {
            "message": {
                "to":email,
                "message": "<p>Name: " + name + "</p><p>Email: " + email + "</p><p>Contact No: " + contactNo + "</p><p>Issue: " + description + "</p>"
            }
        };
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers.set('access-control-allow-origin', `.`);
        return this.http.post<any>(`./services/public/utility/messages`, body, { headers: headers });
    }

    // authBonita() {
    //     const payload = new HttpParams()
    //         .set('username', "grego")
    //         .set('password', "bpm");
    //     const headers = new HttpHeaders().set('Content-Type', 'x-www-form-urlencoded');
    //     return this.http.post(`https://360.sbv.co.za/bonita/loginservice?`, payload);

    // }

    // getIpAddress() {
    //     return this.http
    //         .get('https://api.ipify.org/?format=json') //1st
    //         // .get('https://api.my-ip.io/ip') //2nd
    //         // .get('https://api.myip.com')
    //         .pipe(
    //             catchError(this.handleError)
    //         );
    // }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            // console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            // console.error(
            // `Backend returned code ${error.status}, ` +
            // `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }

    // getCashCentresRevised(){
    //     const param = new HttpParams()
    //         .set('type', 'cashCentres');
    //     return this.http.get<any>(`./services/public/utility/crds`, { params: param });
    // }

    getGroups() {
        return this.http.get<any>(`./services/iam/data/groups`);
    }

    getRoles(group: number) {
        // console.log(group);
        const param = new HttpParams()
            .set('groupId', group);
        return this.http.get<any>(`./services/iam/data/roles`, { params: param });
    }

    getBanks(jsession) {
        const params = new HttpParams()
            .set('jsession',jsession);
        //const headers = new HttpHeaders().set('access-control-allow-origin', `.`);
        return this.http.get<any>(`./services/mdm/banks`, {params: params});
    }

    getAll() {
        return this.http.get<User[]>(`./users`);
    }

    getById(id: string) {
        return this.http.get<User>(`./users/${id}`);
    }

    getUserCashCentres(userId: string, jsession) {
        const param = new HttpParams()
            .set('jsession', jsession);
        return this.http.get<any>(`./services/iam/data/users/` + userId + `/cashCentres/`, { params: param });
    }

    getFinanceManagerUsers(groupId: number, jsession) {
        const param = new HttpParams()
        .set('jsession', jsession)
        .set('groupId', groupId)
        .set('status', 3);
        return this.http.get<any>(`./services/iam/info/users/` , { params: param });

    }

    createProfileSwitch( jsession, primaryUserId, secondaryUserId) {
        const param = new HttpParams()
        .set('jsession', jsession);
        const body = {
            "switch": {
                "primaryUserId": primaryUserId,
                "secondaryUserId": secondaryUserId
            }
        };
        // console.log(body);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<any>(`./services/iam/tmp1/switches` , body, { params: param, headers: headers, });
    }

    doSwitch (jsession) {
        const param = new HttpParams()
        .set('jsession', jsession);
        const body = {
            "mode": 1
        }
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put<any>(`./services/iam/tmp1/switches` , body, { params: param, headers: headers, });
    }


    // update(id, params) {
    //     return this.http.put(`./users/${id}`, params)
    //         .pipe(map(x => {
    //             // update stored user if the logged in user updated their own record
    //             if (id == this.userValue.id) {
    //                 // update local storage
    //                 const user = { ...this.userValue, ...params };
    //                 localStorage.setItem('user', JSON.stringify(user));

    //                 // publish updated user to subscribers
    //                 this.userSubject.next(user);
    //             }
    //             return x;
    //         }));
    // }

    // delete(id: string) {
    //     return this.http.delete(`./users/${id}`)
    //         .pipe(map(x => {
    //             // auto logout if the logged in user deleted their own record
    //             if (id == this.userValue.id) {
    //                 this.logout();
    //             }
    //             return x;
    //         }));
    // }
}