<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">

        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <div class="d-flex justify-content-between align-items-end">
                    <!-- <h3>Password Reset</h3> -->
                    <h3>Finish Account Setup</h3>
                    <!-- <p class="purple">Set New Password</p> -->
                </div>
                <hr class="mt-0">
                <h5 class="p-green">Set your new password.</h5>
                <p>Your account has been verified. Please choose a new password to access to your account.</p>

                <!-- <h5>Set Your Password</h5> -->
                <p>In order to protect your account, make sure that your password:</p>
                <ul>
                    <li>is at least 8 characters </li>
                    <li>contains at least one symbol</li>
                    <li>contains at least 1 upper and 1 lower case letter</li>
                </ul>
                <hr>

                <alert></alert>


                <form [formGroup]="form" (ngSubmit)="onSubmit()">


                    <div class="form-group">
                        <label for="inputPassword" class="control-label">Password</label><span style="color:#ff0000">
                            *</span>
                        <input required type="password" class="form-control" formControlName="password"
                            id="inputPassword" placeholder="Password"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" data-toggle="password">
                        <div *ngIf="f.password.errors?.minlength" class="invalid-feedback">
                            Password must be at least 8 characters long.
                        </div>
                        <div class="invalid-feedback" *ngIf="f.password.errors?.passwordStrength">
                            Your password must have one lower case, one upper case and one special character.
                        </div>
                    </div>



                    <div class="form-group">
                        <label for="inputPassword" class="control-label">Re-Enter Password</label><span
                            style="color:#ff0000">
                            *</span>
                        <input type="password" class="form-control" formControlName="passwordConfirm"
                            id="inputPasswordConfirm" placeholder="Re-enter password"
                            [ngClass]="{ 'is-invalid': submitted && f.passwordConfirm.errors }" data-toggle="password">

                        <div class="invalid-feedback" *ngIf="f.passwordConfirm.errors?.matching">
                            Passwords do not match
                        </div>
                    </div>


                    <div class="w-100">
                        <div class="form-group">
                            <button [disabled]="loading" class="rounded btn btn-primary w-100">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Set New Password
                            </button>
                        </div>

                    </div>
                </form>
            </div>
            <div class="d-flex justify-content-between  ms-5 me-5 p-3 mb-4">
                <a class="p-2 black-link" routerLink="../login">Return to Login</a>
                <a class="p-2 blue-link" routerLink="../help">Need help?</a>
            </div>
        </div>
    </div>

</div>
<!-- </div>   -->
<!-- </div> -->