<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <div class="d-flex justify-content-between align-items-end">
                    <h3>Finalise Account</h3>
                    <p class="purple">Password</p>
                </div>
                <hr class="mt-0">
                <img src="assets/img/security.png" height="80px">
                <h5 class="p-green">Password successfully set!</h5>
                <p>Your password has been successfully set. Please log-in with your new details.</p>

                <div class="w-100">
                    <div class="form-group">
                        <button routerLink="../login" class="rounded btn btn-primary w-100">
                            Return To Login
                        </button>
                    </div>

                </div>
            </div>
            <div class="d-flex justify-content-between  ms-5 me-5 p-3 mb-4">
                <a class="p-2 black-link" routerLink="../login">Return to Login</a>
                <a class="p-2 blue-link" routerLink="../help">Need help?</a>
            </div>
        </div>
    </div>
</div>