import { Component, OnInit } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import * as shajs from 'sha.js';
import { throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.scss']
})
export class UnderMaintenanceComponent implements OnInit {

  helpForm:FormGroup;
  loading = false;
  submitted = false;
  hideContact:boolean=true;
  hideMaintenance:boolean=false;

  constructor(
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }

  ngOnInit(): void {
    this.helpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],
      name: ['', Validators.required],
      contactNo: ['', Validators.required],
      description: ['', Validators.required]
    })
  }
  get h() { return this.helpForm.controls; }

  contact(){
    this.hideMaintenance=!this.hideMaintenance;
    this.hideContact=!this.hideContact;
  }

  sendHelp(){

    this.submitted = true;
    // this.router.navigate([this.returnUrl]);
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.helpForm.invalid) {
         console.log("INVALID DATA")
         return;
    }

    this.loading = true;

    this.accountService.requestHelp(this.h.name.value, this.h.email.value, this.h.contactNo.value, this.h.description.value)
    .subscribe(data => {
       console.log("SUBMITTED");
      //sessionStorage.setItem("requestSubmitted","true");
      // console.log(JSON.stringify(data));
      this.loading = false;

      window.location.reload();
    },
      error => {
        if (error.status == 0) {
          this.alertService.error('Something went wrong. Please contact technical support.')
        }
        else {
          this.alertService.error(error.error.error.message);
        }
        
      });
      this.loading = false;
  }
}
