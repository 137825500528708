<div class="container-fluid" style="padding: 25px">
    <hr class="mt-0">

    <div class="videoModal" [hidden]="hideTCS">
        <button class="tablinks tablink" style="background-color: white;" (click)="openPage1()">Page 1</button>
        <button class="tablinks tablink" style="background-color: white;" (click)="openPage2()">Page 2</button>

        <div class="termsModal-content">
            <div [hidden]="showPage1">
                <div class="logoContent">
                    <img src="assets/img/disclaimerLogo.png"/>
                </div>
                <br>
                <button class="close" (click)="closeTermsModal()">&times;</button>
                <br>
                <h4 style="text-align: center !important; color: cornflowerblue;">SBV SERVICES (PTY) VIEWING OF VIDEO FOOTAGE</h4>
                <h4 style="text-align: center !important; color: cornflowerblue;">LEGAL DISCLAIMER</h4>
                <br>
                <form [formGroup]="disclaimerForm1">
                    
                    <p style="display: inline-block; margin-right: 6px;">I</p>
                    <input type="text" formControlName="initSurname" [readonly]="true" style="width: 150px;" placeholder="Initials and Surname"/>  
                    <br>
                    <p style="display: inline-block !important; margin-right: 6px; margin-right: 6px;">with ID number</p><input type="text" formControlName="idNum" style="width: 150px;" placeholder="ID Number"/>  
                    <p style="display: inline-block !important; margin-left: 6px; margin-right: 6px;">employed at </p><input type="text" formControlName="POE" [readonly]="true" style="width: 180px;" placeholder="Place of Employment"/>
                    <br>
                    <div class="row form-group">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">hereby acknowledge and agree that I am bound by the obligations placed on Standard Bank of South Africa Limited (“Standard Bank”) by the Business Relationship   Agreement together with the Data Sharing Agreement and the Addendum thereto (“the Agreements”) entered into and between Standard Bank and SBV Services (Pty) Ltd (“SBV”).</p>
                        <input type="text" formControlName="init1" [readonly]="true" style="width: 60px; border: 2px solid cornflowerblue; text-align: center;" class="form-control" placeholder="Initials"/>
                    </div>
                    <br>
                    <div class="row form-group">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">I agree that I am bound by the obligations imposed by the Agreements, in line with the applicable data protection legal frameworks, on Standard Bank and its employees to protect and safeguard the personal information contained in the video-recordings supplied by SBV to Standard Bank.</p>
                        <input type="text" formControlName="init2" [readonly]="true" style="width: 60px; border: 2px solid cornflowerblue; text-align: center;" class="form-control" placeholder="Initials"/>
                    </div>
                    <br>
                    <div class="row form-group">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">I agree that, in accordance with the obligations imposed by the Agreements, I will protect the personal information of SBV’s employees contained in the video recording and that such personal information will only be viewed, downloaded and processed only to the extent that such viewing, downloading and processing complies with the obligations outlined in the Agreements and the data protection legislation.</p>
                        <input type="text" formControlName="init3" [readonly]="true" style="width: 60px; border: 2px solid cornflowerblue; text-align: center;" class="form-control" placeholder="Initials"/> 
                    </div>
                    <br>
                    <div class="row form-group">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">I further agree that the personal information of SBV’s employees contained in this video recording and under control or possession of Standard Bank will not be utilised in a manner that will compromise the integrity, security or confidentiality of the said employees.</p>
                        <input type="text" formControlName="init4" [readonly]="true" style="width: 60px; border: 2px solid cornflowerblue; text-align: center;" class="form-control" placeholder="Initials"/>
                    </div>
                    <br>
                    <div class="row form-group">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">I acknowledge that Standard Bank will be liable for any claims or actions suffered by SBV arising from the unlawful processing of the personal information contained in this video recording which occurred as a result of Standard Bank’s negligence whilst such personal information was under Standard Bank’s or its customer’s possession or control.</p>
                        <input type="text" formControlName="init5" [readonly]="true" style="width: 60px; border: 2px solid cornflowerblue; text-align: center;" class="form-control" placeholder="Initials"/>
                    </div>
                    <br>
                </form>
                <button style="float: right; text-align: center; margin-bottom: 15px; border-radius: 50%;" (click)="openPage2()">&#8594;</button>

            </div>
            <div [hidden]="showPage2">
                <div class="logoContent">
                    <img src="assets/img/disclaimerLogo.png"/>
                </div>
                <br>
                <button class="close" (click)="closeTermsModal()">&times;</button>
                <br>
                <h4 style="text-align: center !important; color: cornflowerblue;">SBV SERVICES (PTY) VIEWING OF VIDEO FOOTAGE</h4>
                <h4 style="text-align: center !important; color: cornflowerblue;">LEGAL DISCLAIMER</h4>
                <br>
                <form [formGroup]="disclaimerForm2">
                    <div class=" form-group">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">Dated at</p>
                        <input type="text" formControlName="place" style="width: 180px;" class="form-control" placeholder="Place"/>
                    </div>
                    <br>
                    <div class="form-group" style="display: inline-block;">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">This(day)</p>
                        <input type="text" formControlName="day" style="width: 180px;" class="form-control" placeholder="Day"/>
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">of</p>
                        <input type="text" formControlName="month" style="width: 180px;" class="form-control" placeholder="Month"/>
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">Year</p>
                        <input type="text" formControlName="year" style="width: 180px;" class="form-control" placeholder="Year"/>
                    </div>
                    
                    <div class=" form-group">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">Witness</p>
                        <input type="text" formControlName="witnessSign" style="width: 180px;" class="form-control" placeholder="Witness Sign"/>
                        <br>
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">Signature – the downloading agent</p>
                        <input type="text" formControlName="userSign" style="width: 180px;" class="form-control" placeholder="Sign"/>
                    </div>
                </form>
                <button class="btn btn-primary"  style ="float: right;" (click)="acceptTerms()">
                    Accept
                </button>
                <br>
                <button style="float: left; text-align: center; margin-bottom: 15px; border-radius: 50%;" (click)="openPage1()">&#8592;</button>
            </div>
        </div>
    </div>

    <div class="videoModal" [hidden]="hideVR">
        <button class="tablinks tablink" style="background-color: white;" (click)="openVRPage1()">Page 1</button>
        <button class="tablinks tablink" style="background-color: white;" (click)="openVRPage2()">Page 2</button>

        <div class="termsModal-content">
            <div [hidden]="showVRPage1">
                <div class="logoContent">
                    <img src="assets/img/disclaimerLogo.png"/>
                </div>
                <br>
                <button class="close" (click)="closeVRModal()">&times;</button>
                <br>
                <h4 style="text-align: center !important; color: cornflowerblue;">SBV SERVICES (PTY) VIEWING OF VIDEO FOOTAGE</h4>
                <h4 style="text-align: center !important; color: cornflowerblue;">LEGAL DISCLAIMER</h4>
                <br>
                <form [formGroup]="responseForm1">

                    <br>
                    <div class="row" style="align-items: center;margin-bottom: 10px;">
                        <p style="display: inline-block;margin-left: 15px;padding-top: 5px;">Case Number:</p>
                        <input type="text" formControlName="caseNum" style="width: 180px;"/>  
                    </div>
                    <div class="row" style="align-items: center;margin-bottom: 10px;">
                        <p style="display: inline-block;margin-left: 15px;padding-top: 5px;">Bank:</p>
                        <input type="text" formControlName="bank" style="width: 180px;"/> 
                    </div>
                    <div class="row" style="align-items: center;margin-bottom: 10px;">
                        <p style="display: inline-block;margin-left: 15px;padding-top: 5px;">Client Name/Branch:</p>
                        <input type="text" formControlName="client" style="width: 180px;"/>
                    </div>
                    <div class="row" style="align-items: center;margin-bottom: 10px;">
                        <p style="display: inline-block;margin-left: 15px;padding-top: 5px;">Date of Incident:</p>
                        <input type="text" formControlName="incDate" style="width: 180px;"/>
                    </div>
                    <div class="row" style="align-items: center;margin-bottom: 10px;">
                        <p style="display: inline-block;margin-left: 15px;padding-top: 5px;">Appointment Date:</p>
                        <input type="text" formControlName="appDate" style="width: 180px;"/>
                    </div>
                    <br>
                    
                    <p style="display: inline-block;margin-right: 6px;">I</p>
                    <input type="text" formControlName="initSurname" style="width: 150px;" placeholder="Initials and Surname"/>  
                    <br>
                    <p style="display: inline-block !important; margin-right: 6px;">with ID number</p><input type="text" formControlName="idNum" style="width: 150px;" placeholder="ID Number"/>  
                    <p style="display: inline-block !important; margin-right: 6px; margin-left: 6px;">employed at </p><input type="text" formControlName="POE" style="width: 180px;" placeholder="Place of Employment"/>
                    <br>
                    <div class="row form-group">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">hereby acknowledge that SBV Services (Pty) Ltd, ("SBV") has permitted me controlled and authorized access to view video footage pertaining to an incident concerning an alleged shortage or surplus of cash.</p>
                    </div>
                    <div class="row form-group">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">I acknowledge and agree that after having viewed the video footage,</p>
                        <input type="checkbox" formControlName="agreeCB" style="width: 20px; border: 1px solid cornflowerblue; text-align: center;"/>
                    </div>
                    <div class="row form-group">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">I am satisfied that all procedures were followeds and there is no evidence of bag tampering attributable to SBV. SBV is accordingly not liable for any discrepancies, shortages, surpluses, losses or damages arising from the above mentioned incident.</p>
                        <input type="checkbox" formControlName="satCB" style="width: 20px; border: 1px solid cornflowerblue; text-align: center;"/> 
                    </div>
                    <br>
                    <div class="row form-group">
                        <p style="width: 750px; display: inline-block; margin-left: 15px;">I am not satisfied with what I have seen with regards to the above mentioned amount/s and I hereby request that the video footage be kept for a further period of 5 weeks from the viewing date. If I do not respond within the five week period, the investigation is deemed closed.</p>
                        <input type="checkbox" formControlName="disCB" style="width: 20px; border: 2px solid cornflowerblue; text-align: center;"/>
                    </div>
                    <br>
                    <div class="form-group">
                        <p style="width: 750px; display: inline-block;">Herewith my reason for not being satisfied</p>
                        <input type="text" formControlName="init5" style="width: 100; border: 1px solid rgb(40, 40, 42);" class="form-control" placeholder=""/>
                    </div>
                    <br>
                </form>
                <button style="float: right; text-align: center; margin-bottom: 15px; border-radius: 50%;" (click)="openVRPage2()">&#8594;</button>

            </div>
            <div [hidden]="showVRPage2">
                <div class="logoContent">
                    <img src="assets/img/disclaimerLogo.png"/>
                </div>
                <br>
                <button class="close" (click)="closeVRModal()">&times;</button>
                <br>
                <h4 style="text-align: center !important; color: cornflowerblue;">SBV SERVICES (PTY) VIEWING OF VIDEO FOOTAGE</h4>
                <h4 style="text-align: center !important; color: cornflowerblue;">LEGAL DISCLAIMER</h4>
                <br>
                <form [formGroup]="responseForm2">
                    <div class="row" style="align-items: center;margin-bottom: 10px;">
                        <p style="display: inline-block;margin-left: 15px;padding-top: 5px;">Customer/Bank:</p>
                        <input type="text" formControlName="customer" style="width: 180px;"/>
                        <p style="display: inline-block;margin-left: 15px;margin-right: 10px;padding-top: 5px;">Date:</p>
                        <input type="text" formControlName="customerDate" style="width: 150px;"/>
                    </div>
                    <div class="row" style="align-items: center;margin-bottom: 10px;">
                        <p style="display: inline-block;margin-left: 15px;padding-top: 5px;">SBV CLO:</p>
                        <input type="text" formControlName="clo" style="width: 180px;"/>
                        <p style="display: inline-block;margin-left: 15px;margin-right: 10px;padding-top: 5px;">Date:</p>
                        <input type="text" formControlName="cloDate" style="width: 150px;"/>
                    </div>
                    <div class="row" style="align-items: center;margin-bottom: 10px;">
                        <p style="display: inline-block;margin-left: 15px;padding-top: 5px;">SBV Management:</p>
                        <input type="text" formControlName="management" style="width: 180px;"/>
                        <p style="display: inline-block;margin-left: 15px;margin-right: 10px;padding-top: 5px;">Date:</p>
                        <input type="text" formControlName="managementDate" style="width: 150px;"/>
                    </div>
                </form>
                <button class="btn btn-primary"  style ="float: right;" (click)="completeResponse()">
                    Accept
                </button>
                <br>
                <button style="float: left; text-align: center; margin-bottom: 15px; border-radius: 50%;" (click)="openVRPage1()">&#8592;</button>
            </div>
        </div>
    </div>

        <!-- <h5>Video Footage For Case {{caseId}}</h5> -->
        <div class="videoModal-content" [hidden]="hideModal" id="videoModal">
            <div class="video-container">

                <h3 style="text-align: left;" [hidden]="hideNVM"><b>No videos available</b></h3>
                <div class="confirmPopup" [hidden]="hidePopup">
                    <p>Are you sure you want to remove the selected video?</p>
                    <p><b>This footage cannot be retrieved</b></p>
                    <div class="text-right">
                      <button class="btn btn-cancel" (click)="cancelRemoval()">Cancel</button>
                      <button class="btn btn-primary" (click)="removeVideo()">Ok</button>
                    </div>
                </div>

                <div class="confirmPopup" [hidden]="hideUploadPopup">
                    <p>{{uploadMessage}}</p>
                    <div class="text-right">
                      <button class="btn btn-primary" (click)="reloadPage()">Ok</button>
                    </div>
                </div>

                <div style="display: inline-block;" *ngFor="let url of videos">
                    <ng-container>
                        <button title="Remove Video" class="video-remove-btn" (click)="testRemove(url.link)" [hidden]="hideRemoveVideoBtn">x</button>
                        <button title="Download Video" class="video-download-btn" (click)="downloadVideo(url.link)">&#8595;</button>
                        <span *ngIf="url.status==2" style="margin-left: 5px;color: rgb(255, 72, 0);">Archived</span>
                        <video (play)="validatePlayback($event, url.link)" (pause)="paused()" id="videoPlayer" style="margin-bottom: 12px;margin-right: 10px;" width="200px" height="150px"[src]="url.link" controls controlsList="nodownload" [ngClass]="url.status==2 ? 'archivedVideos' : '' "></video>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="upload-container" [hidden]="disableUpload">

            <label class="control-label" for="videoInput" style="margin-left: 5px;">Upload Video</label>
            <div class="form-group" style="display: flex; flex-direction: row;">
                <form [formGroup]="form">
                    <input formControlName="videoInput" style="width: 300px; margin-left: 5px;" class="form-control"type="file" accept="video/mp4"  (change)="onSelectFile($event)"/>  
                </form>
                <button class="btn btn-danger" [hidden]="hideRemoveBtn" (click)="clearVideo()">X</button>
                <button class="btn btn-primary"style="margin-left:10px;" [hidden]="hideRemoveBtn" (click)="uploadVideo()">
                    Upload
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                 </button>
                <br>
            </div>

            <mat-progress-bar mode="determinate" class="progressBar" [hidden]="noUpload"[value]="progressbarValue"></mat-progress-bar>

            <video id="playbackPlayer" width = "560px" height = "328px" controlsList="nodownload" controls [src]="displayURL" [hidden]="hideRemoveBtn" style="margin: 5px; border: 3px solid cornflowerblue;"></video>
            <!-- <label style="color:cornflowerblue !important" [hidden]="vidUploaded">Video Uploaded Successfully</label>
            <label style="color:red !important" [hidden]="vidError">An error has occurred, please try again</label> -->
        </div>

</div>