import { Contact } from "./contact";
import { Customer } from "./customer";
import { Profile } from "./profile";
import { Roles } from "./roles";

export class UpdatedUser {
    email:string;
    contact:Contact;
    profile:Profile;
    roles:Roles[];
    customer:Customer;
    // firstName: string;
    // lastName: string;
    // email: string;
    // contactNo: string;
    // remoteIP: string;
    // organisation: string;
    // // password: string;
    // // token: string;
    // jobTitle: string;
    // organization: string;
    // lineManagerName: string;
    // lineManagerContactNo: string;
    // department: string;
    // role: string;
    // status: number;
}