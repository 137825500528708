import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, AlertService } from 'src/app/_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { theUser } from 'src/app/_models/theUser';
import { Role } from 'src/app/_models/role';
import { Roles } from 'src/app/_models/roles';
import { Contact } from 'src/app/_models/contact';
import { Profile } from 'src/app/_models/profile';
import { CashCentre } from 'src/app/_models/cash-centre';
import { CashCentres } from 'src/app/_models/cash-centres';
import { UpdatedUser } from 'src/app/_models/updated-user';
import { UpdatedUserUser } from 'src/app/_models/updated-user-user';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { Customer } from 'src/app/_models/customer';
import { Video } from 'src/app/_models/video';
import { changeReq } from 'src/app/_models/changeReq';
import { CookieService } from 'ngx-cookie';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { Buffer } from 'buffer';
import * as fileSaver from 'file-saver';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { interval, timer } from 'rxjs';

@Component({
  selector: 'app-video-footage',
  templateUrl: './video-footage.component.html',
  styleUrls: ['./video-footage.component.scss']
})
export class VideoFootageComponent implements OnInit {

  hidePopup:boolean=true;
  canEdit:boolean=false;
  hideCard:boolean=false;
  rowSelected: boolean = false;
  loading: boolean=false;
  hideRemoveBtn:boolean=true;
  hideForm:boolean=false;
  hideND:boolean=true;
  hideAT:boolean=true;
  hideAdmin:boolean=true;
  hideRemoveVideoBtn:boolean=false;
  hideModal:boolean=true;
  hideTCS:boolean=true;
  hideVR:boolean=true;
  vidUploaded:boolean=true;
  vidError:boolean=true;
  hideNVM: boolean=true;
  showPage1:boolean = false;
  showPage2:boolean = true;
  showVRPage1:boolean = false;
  showVRPage2:boolean = true;
  disableUpload:boolean=false;
  hideUploadPopup:boolean=true;
  noUpload:boolean=true;

  form: FormGroup;
  disclaimerForm1: FormGroup;
  disclaimerForm2: FormGroup;
  responseForm1: FormGroup;
  responseForm2: FormGroup;
  selectedCase:number;
  currentUserSurname:string="";
  currentUserMail:string="";
  currentUserInitSurname:string="";
  currentUserInitials:string="";
  currentUserPOE:string="";
  currentUserId:string="";
  uploadMessage:string="";
  videoId:number;
  currentDay:number;
  currentMonth:string;
  currentYear:number;
  currentDate:string;
  page:string="";
  caseId:string="";
  today:string="";
  fileName:string="";
  currentUserGroup:number;
  decodedDownloadURL:string="";
  playBackCaseVideoUrls = [];
  videos: Video[]= [];
  caseIds=[];
  commentArray = [];

  interval;
  progressbarValue=0;
  curSec: number = 100;

  videoURL;
  displayURL;
  decodedURL;
  videoBlob;

  constructor(
  private formBuilder: FormBuilder,
  private disclaimerFormBuilder1: FormBuilder,
  private disclaimerFormBuilder2: FormBuilder,
  private responseFormBuilder1: FormBuilder,
  private responseFormBuilder2: FormBuilder,
  private accountService: AccountService,
  private cookieService: CookieService,
  private actRoute: ActivatedRoute,
  private router: Router,
  urlSanit:DomSanitizer,
  ) { }

  ngOnInit(): void {

    //console.log("URL - "+this.router.url);
    this.caseId=this.cookieService.get("selectedCaseId");
    console.log("selectedCaseId"+this.caseId);
    this.hideNVM=true;

  //   this.currentDay=new Date().getDate();
  //   this.currentYear=new Date().getFullYear();
  //   let month=(new Date().getMonth())+1;

  //   switch (month) {
  //     case 1:
  //         this.currentMonth="January";
  //         break;
  //     case 2:
  //         this.currentMonth="February";
  //         break;
  //     case 3:
  //         this.currentMonth="March";
  //         break;
  //     case 4:
  //         this.currentMonth="April";
  //         break;
  //     case 5:
  //         this.currentMonth="May";
  //         break;
  //     case 6:
  //         this.currentMonth="June";
  //         break;
  //     case 7:
  //         this.currentMonth="July";
  //         break;
  //     case 8:
  //         this.currentMonth="August";
  //         break;
  //     case 9:
  //         this.currentMonth="September";
  //         break;
  //     case 10:
  //         this.currentMonth="October";
  //         break;
  //     case 11:
  //         this.currentMonth="November";
  //         break;
  //     case 12:
  //         this.currentMonth="December";
  //         break;
  // }

  // this.currentDate=this.currentDay+" "+this.currentMonth+" "+this.currentYear;


    this.currentUserMail=sessionStorage.getItem("email");
    let JSession=this.cookieService.get("JSESSIONID");

      this.accountService.getCurrentUser(this.currentUserMail, JSession)
        .subscribe(data => {
          console.log("Details: "+JSON.stringify(data));

          this.currentUserGroup=data.users.user.roles.role.groupId;
          this.currentUserId=data.users.user.id;
          // this.currentUserSurname=data.users.user.lastName;
          // this.currentUserInitSurname=(data.users.user.firstName).charAt(0) + " "+this.currentUserSurname;
          // this.currentUserPOE=data.users.user.organization;

          // let tempName=data.users.user.firstName+" "+data.users.user.lastName;
          // const fullName = tempName.split(' ');
          // const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
          // this.currentUserInitials=initials.toUpperCase();
          
          // console.log(this.currentUserSurname);
          // console.log(this.currentUserInitials);
          // console.log(this.currentUserInitSurname);
          // console.log(this.currentUserPOE);

          console.log("Group - "+this.currentUserGroup);
          if(this.currentUserGroup==1){
            this.disableUpload=false;
            this.hideRemoveVideoBtn=false;
          }
          else{
            this.disableUpload=true;
            this.hideRemoveVideoBtn=true;
          }

        },
        error => {
          console.log(error)
        });

    let Jsession=this.cookieService.get("JSESSIONID");
    this.accountService.getCaseVideos(Jsession,this.caseId)
        .subscribe(data => {
          //console.log(JSON.stringify(data));
      
          if(data.videos.video.length==undefined){//Only 1 video returned

            if(data.videos.video.id==0 || data.videos.video.id==null || data.videos.video.id==undefined){
              console.log('Case has no videos uploaded');
            }
            else{
              let obj={
                "id": data.videos.video.id,
                "status": data.videos.video.status
              };

              this.caseIds.push(obj);
            }
          }
          else{
            for(let i=0;i<data.videos.video.length;i++){
              let obj={
                "id": data.videos.video[i].id,
                "status": data.videos.video[i].status
              };

              this.caseIds.push(obj);
            }
          }
          console.log("Ids - "+JSON.stringify(this.caseIds));

          for(let j=0;j<this.caseIds.length;j++){
            let playbackLink="https://api.sbv.co.za/services/cm/media/videos/"+this.caseIds[j].id+"?jsession="+Jsession+"&type=2";
            let id=this.caseIds[j].id;
            let status=this.caseIds[j].status;

            let videoObj=new Video;
            videoObj.id=id;
            videoObj.link=playbackLink;
            videoObj.status=status;

            this.videos.push(videoObj);
            this.playBackCaseVideoUrls.push(playbackLink);
          }

          //console.log(JSON.stringify(this.videos));

          if(this.videos.length ==0 || this.videos.length ==null || this.videos.length ==undefined){
            this.hideNVM=false;
          }
          else{
            this.hideNVM=true;
          }
        },

        error => {
          console.log(error)
        });

    this.hideModal=false;

    this.form=this.formBuilder.group({
      videoInput: ['',[Validators.required]],
    });

    this.disclaimerForm1=this.disclaimerFormBuilder1.group({
      initSurname: ['',[Validators.required]],
      idNum: ['',[Validators.required,Validators.maxLength(13)]],
      POE:   ['',[Validators.required]],
      init1: ['',[Validators.required]],
      init2: ['',[Validators.required]],
      init3: ['',[Validators.required]],
      init4: ['',[Validators.required]],
      init5: ['',[Validators.required]]
    });

    this.disclaimerForm2=this.disclaimerFormBuilder2.group({
      place: ['',[Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      day: ['',[Validators.required,Validators.maxLength(4), Validators.pattern('^[a-zA-Z \-\']+')]],
      month: ['',[Validators.required, Validators.maxLength(9)]],
      year: ['',[Validators.required, Validators.maxLength(9)]],
      witnessSign: ['',[Validators.required, Validators.maxLength(50)]],
      userSign: ['',[Validators.required, Validators.maxLength(50)]]
    });

    this.responseForm1=this.responseFormBuilder1.group({
      caseNum: ['',[Validators.required,Validators.maxLength(10)]],
      bank: ['',[Validators.required,Validators.maxLength(4),]],
      incDate: ['',[Validators.required, Validators.maxLength(50)]],
      appDate: ['',[Validators.required, Validators.maxLength(50)]],
      initSurname: ['',[Validators.required, Validators.maxLength(50)]],
      idNum: ['',[Validators.required, Validators.maxLength(50)]],
      POE: ['',[Validators.required, Validators.maxLength(50)]],
      agreeCB: [true, Validators.requiredTrue],
      satCB: [true, Validators.requiredTrue],
      disCB: [true, Validators.requiredTrue]
    });

    this.responseForm2=this.responseFormBuilder2.group({
      customer: ['',[Validators.required,Validators.maxLength(10)]],
      customerDate: ['',[Validators.required,Validators.maxLength(4),]],
      clo: ['',[Validators.required, Validators.maxLength(50)]],
      cloDate: ['',[Validators.required, Validators.maxLength(50)]],
      management: ['',[Validators.required, Validators.maxLength(50)]],
      managementDate: ['',[Validators.required, Validators.maxLength(50)]]
    });

  }

  get f() { return this.form.controls; }
  get v() { return this.disclaimerForm1.controls; }
  get c() { return this.disclaimerForm2.controls; }
  get r() { return this.responseForm1.controls; }
  get b() { return this.responseForm2.controls; }

  clearVideo(){
    this.f.videoInput.reset();
    this.videoURL = null;
    this.displayURL=null;
    this.hideRemoveBtn=true;
    this.loading=false;

    this.vidUploaded=true;
    this.vidError=true;
  }

  onSelectFile(event) {
    
    this.vidUploaded=true;
    this.vidError=true;

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    this.fileName=String(file.name);

    reader.onload = () => {
      this.displayURL = String(reader.result);

      const parts = this.displayURL.split(';base64,');
      const vidType='application/octet-stream';
      const decodedData = window.atob(parts[1]);
      const uInt8Array = new Uint8Array(decodedData.length);

      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }

      this.videoBlob = new Blob([uInt8Array], { type: vidType });
      
    };
    this.hideRemoveBtn=false;
  }

  testRemove(url){

    for(let i=0; i<this.videos.length; i++){
      if(url==this.videos[i].link){
        this.videoId=this.videos[i].id;
        break;
      }
    }

    //console.log("videoId - : "+this.videoId);
    this.hidePopup=false;
  }

  startProgressBar() {
    const timer$ = interval(1000);

    const sub = timer$.subscribe((sec) => {
      console.log("second - "+sec);
      this.progressbarValue = this.progressbarValue + 1;
      this.curSec = sec;

      if (this.progressbarValue == 100) {
        sub.unsubscribe();
      }
    });
}



  removeVideo(){

    let jsession=this.cookieService.get("JSESSIONID");
    let currentDT=new Date();
    let now=currentDT.toISOString();
    let user=sessionStorage.getItem("email");

    this.accountService.deleteVideo(jsession,this.videoId)
    .subscribe(data => {
      let jsession=this.cookieService.get("JSESSIONID");
      let id=this.caseId.replace("-","%2D");

      let body={
      "content": "[VIDEO_DELETED, "+now.toString()+", "+this.currentUserId+", "+this.videoId+"]" + " Video " +" was deleted by "+user
        }
      this.accountService.submitComment(id, body, jsession)
      .subscribe(data => {
      console.log("Successfully made call");
      window.location.reload();
        },

    error => {
      console.log(error)
    });
      //window.location.reload();
    },

    error => {
      console.log(error)
    });

  }


  cancelRemoval(){
    //console.log("Cancelled video removal");
    this.videoId=undefined;
    this.hidePopup=true;
  }

  downloadVideo(url){

    let downloadLink=url.replace("&type=2","&type=1");
    console.log("Link to download - "+downloadLink);
    let videoId=(downloadLink.split("?")[0]).split("/")[7];
    window.location.href = downloadLink;

    let jsession=this.cookieService.get("JSESSIONID");
    let currentDT=new Date();
    let now=currentDT.toISOString();
    let user=sessionStorage.getItem("email");
    let id=this.caseId.replace("-","%2D");
    
    let body={
      "content": "[VIDEO_ACCESSED, "+now.toString()+", "+this.currentUserId+", "+videoId+"]" + " Video " +" was accessed by "+user
   }

    this.accountService.submitComment(id, body, jsession)
    .subscribe(data => {
      console.log("Successfully made call");
    },

    error => {
      console.log(error)
    });
    
  }
 
  openForm() {
    console.log("TEST");
    this.hideForm   =false;
    this.hideAT     = true;
    this.hideND     = true;
    this.hideAdmin  = true;
  }
  openND() {
    console.log("TEST");
    this.hideForm   =true;
    this.hideAT     = true;
    this.hideND     = false;
    this.hideAdmin  = true;
}
openAdmin() {
  console.log("TEST");
  this.hideForm   =true;
  this.hideAT     = true;
  this.hideND     = true;
  this.hideAdmin  = false;
}
openAT() {
  console.log("TEST");
  this.hideForm   =true;
  this.hideAT     = false;
  this.hideND     = true;
  this.hideAdmin  = true;
}

openVideoModal(){
  console.log("Works");
  this.f.videoInput.reset();
  this.videoURL = null;
  this.hideModal = false;
}

validatePlayback(event, url){

  let videoId=((url.split("?")[0]).split("/")[7]).toString();
  console.log("videoId - "+videoId);

  this.commentArray=JSON.parse(this.cookieService.get('AccessArray'));
  console.log("commentArray - "+this.commentArray);
  
  if(this.commentArray.includes(videoId)){ 
    console.log("Access comment already posted");

  }

  else{

    let jsession=this.cookieService.get("JSESSIONID");
    let currentDT=new Date();
    let now=currentDT.toISOString();
    let user=sessionStorage.getItem("email");
    let id=this.caseId.replace("-","%2D");

    let body={
      "content": "[VIDEO_ACCESSED, "+now.toString()+", "+this.currentUserId+", "+videoId+"]" + " Video " +" was accessed by "+user
    }

   console.log("BODY - "+JSON.stringify(body));

    this.accountService.submitComment(id, body, jsession)
    .subscribe(data => {
      console.log("Successfully made call");
    },

    error => {
      console.log(error)
    });

    this.commentArray.push(videoId.toString());
    console.log("After push - "+JSON.stringify(this.commentArray));
    this.cookieService.put('AccessArray',JSON.stringify(this.commentArray));

  }

  // let temp=JSON.parse(this.cookieService.get('AccessArray'));
  // console.log(JSON.stringify(temp));

}

paused(){
  console.log("Paused");
}

openTermsModal(){
  this.openPage1();
  this.disclaimerForm1=this.disclaimerFormBuilder1.group({
    initSurname: [this.currentUserInitSurname],
    idNum: ['',[Validators.required,Validators.maxLength(13)]],
    POE:   [this.currentUserPOE],
    init1: [this.currentUserInitials],
    init2: [this.currentUserInitials],
    init3: [this.currentUserInitials],
    init4: [this.currentUserInitials],
    init5: [this.currentUserInitials]
  });

  this.disclaimerForm2=this.disclaimerFormBuilder2.group({
    place: ['',[Validators.required]],
    day: [this.currentDay],
    month: [this.currentMonth],
    year: [this.currentYear],
    witnessSign: ['',[Validators.required, Validators.maxLength(50)]],
    userSign: ['',[Validators.required, Validators.maxLength(50)]]
  });

  this.hideTCS=false;
}

videoClick(){
  console.log("Video Clicked");
}

closeVideoModal(){
  console.log("Works");
  this.hideModal = true;
  this.clearVideo();
}

closeTermsModal(){
  console.log("Works");
  this.hideTCS = true;
  //this.closeVideoModal();
}

closeVRModal(){
  console.log("Works");
  this.hideVR = true;
  //this.closeVideoModal();
}

openRVModal(){

  this.responseForm1=this.responseFormBuilder1.group({
    caseNum: ['',[Validators.required,Validators.maxLength(10)]],
    bank: ['',[Validators.required,Validators.maxLength(4),]],
    incDate: ['',[Validators.required, Validators.maxLength(50)]],
    appDate: ['',[Validators.required, Validators.maxLength(50)]],
    initSurname: [this.currentUserInitSurname],
    idNum: ['',[Validators.required, Validators.maxLength(50)]],
    POE: [this.currentUserPOE],
    agreeCB: [true, Validators.requiredTrue],
    satCB: [true, Validators.requiredTrue],
    disCB: [true, Validators.requiredTrue]
  });

  this.responseForm2=this.responseFormBuilder2.group({
    customer: ['',[Validators.required,Validators.maxLength(10)]],
    customerDate: [this.currentDate,[Validators.required]],
    clo: ['',[Validators.required, Validators.maxLength(50)]],
    cloDate: [this.currentDate,[Validators.required]],
    management: ['',[Validators.required, Validators.maxLength(50)]],
    managementDate: [this.currentDate,[Validators.required]]
  });

  this.hideVR = false;

}

acceptTerms(){
  localStorage.setItem("disclaimer","accepted");
  this.hideTCS=true;
  //this.openVideoModal();
}

completeResponse(){
  this.hideVR=true;
  window.location.reload();
}

openPage1(){
  this.showPage1 = false;
  this.showPage2 =true;
}

openPage2(){
  this.showPage1 = true;
  this.showPage2 =false;
}

openVRPage1(){
  this.showVRPage1 = false;
  this.showVRPage2 =true;
}

openVRPage2(){
  this.showVRPage1 = true;
  this.showVRPage2 =false;
}

rejectTerms(){
  //localStorage.setItem("videoTC","true");
  this.closeTermsModal();
  this.closeVideoModal();
}

reloadPage(){
  this.noUpload=true;
  this.clearVideo();
  window.location.reload();
}

uploadVideo(){
  this.noUpload=false;
  this.startProgressBar();
  this.vidUploaded=true;
  this.vidError=true;
  let JSession=this.cookieService.get("JSESSIONID");
      this.accountService.uploadVideo(this.caseId,JSession, this.videoBlob)
        .subscribe(data => {
          this.progressbarValue=100;
          this.loading=false;
          this.uploadMessage="Video Successfuly Uploaded";

          let jsession=this.cookieService.get("JSESSIONID");
          let currentDT=new Date();
          let now=currentDT.toISOString();
          let user=sessionStorage.getItem("email");
          let id=this.caseId.replace("-","%2D");
          let videoId=data.video.id;

          let body={
              "content": "[VIDEO_UPLOADED, "+now.toString()+", "+this.currentUserId+", "+videoId+"]" + " Video " + this.fileName+" was uploaded by "+user
           }

          console.log(body);
          this.accountService.submitComment(id, body, jsession)
          .subscribe(data => {
              console.log("Successfully made call");
              },

          error => {
            console.log(error)
          });

          setTimeout(() => {
            this.hideUploadPopup=false;
          }, 1000);
        },

        error => {
          console.log(error);
          this.uploadMessage="An Error Has Occurred";
          this.hideUploadPopup=false;
        });
}

}
