import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/_models';
import { AccountService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitterService } from 'src/app/_services/event-emitter.service';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user: User;

  hideUserAccess:boolean=true;
  hideReports:boolean=true;
  hideNatButton:boolean=true;
  hideBankViews:boolean=true;
  hideLogCase:boolean=false;
  hideTaskList:boolean=false;
  hideMerchant:boolean=true;
  superUserLoggedIn:boolean=false;
  hideBP:boolean=true;

  hideUserAccessBlock:boolean=false;
  hideCMBlock:boolean=false;
  hideReportBlock:boolean=true;

  currentUserRole:number=0;
  currentUserGroup:number=0;

  hideErrorBanner: boolean=true;
  errorMessage: string=" ";
  
  ngOnInit(): void {

    if(sessionStorage.getItem("superUserLogin")=="true"){
      this.superUserLoggedIn=true;
    }
    else{
      this.superUserLoggedIn=false;
    }

    if(sessionStorage.getItem("incidentLogging")=="true"){
      sessionStorage.removeItem("incidentLogging");
      this.logCaseFunction();
    }

    if(sessionStorage.getItem("login-status")=="logged in"){
      let JSession=this.cookieService.get("JSESSIONID");
      this.accountService.getCurrentUser(sessionStorage.getItem("email"), JSession)
      .subscribe(data => {
        console.log("CURRENT USER LOGGED IN : "+JSON.stringify(data));
        this.currentUserRole=data.users.user.roles.role.id;
        this.currentUserGroup=data.users.user.roles.role.groupId;

        if(this.currentUserRole==6 ||this.currentUserRole==50){
          this.hideMerchant=false;
        }
        else{
          this.hideMerchant=true;
        }

        if(this.currentUserRole==6 || this.currentUserRole==22) { //SBV admin or Bank admin logged in 
          this.hideUserAccess=false
          this.hideLogCase=true;
          this.hideNatButton=true;
          this.hideReports=true;
          this.hideReportBlock=true;
        }

        else{
          this.hideUserAccess=true;
          this.hideLogCase=false;
        }


   if(this.currentUserGroup==1 && (this.currentUserRole!=6)){ //Internal user not admin
      this.hideReportBlock=false;
      this.hideReports=false;

      if(this.currentUserRole==2 || this.currentUserRole==16){ //National SBV user
        this.hideNatButton=false;
        this.hideBankViews=false;
      }
      else{
        this.hideNatButton=true;
        this.hideBankViews=true;
      }
   }
   else{ //External user
     this.hideReportBlock=true;
     this.hideReports=true;
   }

   if(this.currentUserRole == 26 || this.currentUserRole == 27 || this.currentUserRole == 28 || this.currentUserRole == 29 || this.currentUserRole == 31 || this.currentUserRole == 33 || this.currentUserRole == 11){
    this.hideBP=false;
    this.hideLogCase=true;
  }
  else{
    this.hideBP=true;
  }

  if(this.currentUserGroup == 3){//Merchant User
    this.hideBP=false;
    this.hideLogCase=false;
  }

  if(this.currentUserRole==33 || this.currentUserRole==31){
    this.hideBankViews=true;
  }

   if(this.currentUserGroup==2 && this.currentUserRole==23){//Read Only User
    this.hideUserAccess=true;
    this.hideLogCase=true;
    this.hideNatButton=true;
    this.hideReportBlock=true;
    this.hideBankViews=true;
    this.hideTaskList=true;
   }

   if(this.currentUserRole ==30){
    this.hideReportBlock=false;
  }

  //  if(this.currentUserGroup!=1){ //Not internal user, deny access to reportBlock and options
  //   this.hideReportBlock=true;
  //  }

   if(this.currentUserGroup==2 && this.currentUserRole !=33){  //Bank user
    this.hideReportBlock=false;
    this.hideReports=false;
   }

   if(this.currentUserRole==50){
    this.hideUserAccess=false
    this.hideNatButton=false;
    this.hideLogCase=false;
    this.hideReports=false;
    this.hideReportBlock=false;
    this.hideBankViews=false;
    this.hideBP=false;
  }

  if(this.currentUserRole == 4 || this.currentUserRole == 37 || this.currentUserRole ==38 || this.currentUserRole ==39 || this.currentUserRole ==40){
    this.hideReportBlock=true;
  }

  if(this.currentUserRole== 26|| this.currentUserRole== 27|| this.currentUserRole== 28|| this.currentUserRole== 29|| this.currentUserRole== 30|| this.currentUserRole== 36|| this.currentUserRole== 38|| this.currentUserRole== 39|| this.currentUserRole== 40){
    this.hideBankViews=false;
  }

  if(this.currentUserRole == 4 || this.currentUserRole == 36 || this.currentUserRole == 37 || this.currentUserRole ==38 || this.currentUserRole ==39 || this.currentUserRole ==40){
    this.hideLogCase=true;
    this.hideBankViews=true;
  }

  if(this.currentUserRole == 30){
    this.hideLogCase=true;
    this.hideBP=true;
  }

    },
      error => {
        if (error.status==500){
          this.errorMessage="ERROR 500 OCCURRED";
          this.hideErrorBanner=false;
        }
        if (error.status==408 || error.status==504){
          this.errorMessage="ERROR 408/504 OCCURRED";
          this.hideErrorBanner=false;
        }
        console.log(error)
        });
   }
    
}

  constructor(private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private dashEventEmitterService: EventEmitterService,
    private taskListEventEmitterService: EventEmitterService,
    private caseListEventEmitterService: EventEmitterService,
    private logCaseEventEmitterService: EventEmitterService,
    private logCaseIncidentEventEmitterService: EventEmitterService,
    private reportEventEmitterService: EventEmitterService,
    private pDashEventEmitterService: EventEmitterService,
    private bankViewEventEmitterService: EventEmitterService,
    private billingEventEmitterService: EventEmitterService,
    private incidentEventEmitterService: EventEmitterService,
    private cookieService: CookieService
     ) 
    
    {
    this.accountService.user.subscribe(x => this.user = x);
}
  
  logout() {
    this.accountService.logout();
}
showProfile(){
  this.router.navigate(['/profile-settings']);
}

appDashboardFunction(){    
  this.dashEventEmitterService.onDashboardButtonClick();    
} 

xButton(){
  window.location.reload();
}

appPDashboardFunction(){
  this.pDashEventEmitterService.onPDashboardButtonClick();
}

taskListFunction(){
  this.taskListEventEmitterService.onTaskListButtonClick();
}

caseListFunction(){
  this.caseListEventEmitterService.onCaseListButtonClick();
}

logCaseFunction(){
  this.logCaseEventEmitterService.onLogCaseButtonClick();
}

logCaseIncidentFunction(){
  this.incidentEventEmitterService.onIncidentButtonClick();
}

reportFunction(){
  this.reportEventEmitterService.onReportButtonClick();
}

bankViewFunction(){
  this.bankViewEventEmitterService.onbankViewButtonClick();
}

billingFunction(){
  this.billingEventEmitterService.onBillinbgButtonClick();
}

}
