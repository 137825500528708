import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-help-centre',
  templateUrl: './help-centre.component.html',
  styleUrls: ['./help-centre.component.scss']
})
export class HelpCentreComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

}
