<!--
Case management  page html code. including page links/info
--> =

<div class="container-fluid" style="text-align:left; background: #f6fafd">

    <h6>HELP CENTRE</h6>
    <h1 style="margin-top:0px;">Getting Started</h1>
    <h5>Your online guide to SBV Connect</h5>
    <hr/>
    
</div>

<nav aria-label="breadcrumb" style =" margin: 10px ">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/help-centre">Help Centre</a></li>
          <li class="breadcrumb-item"><a routerLink="/getting-started">Getting Started</a></li>
          <li class="breadcrumb-item active" aria-current="page">Case Management</li>
        </ol>
      </nav>


<div class="box">
    <h4>Case Management</h4>
    <p>Securely log all of your queries for efficient resolution</p>
    <div class="divider"></div>
   

      <p>Connect to SBV's online support service, with effective management of queries, including feedback loops. 
        The Case Management service of SBV Connect offers a ticketing system that allows you to log all queries into 
        workflow managed and tracked Cases. The SBV Connect Case Management module is designed to provide you with quick 
        and easy navigation to the actions and information you need. SBV Connect maintains a history of all cases logged 
        for future reference and analysis.  
       </p>
   

    <p><b>How to Log a New Case:</b></p>
  <ol>
    <li>Select the Dashboard menu option under Case Management and click the New Case button in the Overview section OR </li>
    <li>Select the Log a New Case shortcut option from the menu. </li>
    <li>A step-by-step logging form will open. Using the drop-down menus, fill in as much information as you can about the 
        type of query, customer details, transaction details, and additional notes and documents. All boxes in grey will automatically 
        populate based on the preceding information you capture. </li>
    <li>At the bottom of each form page, click Next to progress. If you do not know all the details or are unsure which menu 
        item to choose, please choose the closest description of your issue to open the case. Our team will review and be in touch
        to help clarify and update the case accordingly. 
    </li>
    <li>Click on the Submit button.</li>
    <li>A confirmation that your Case has been successfully submitted will appear. You will also receive an email confirmation
        with the Case reference number (the Case ID). This will be used as the unique identifier of the case for the life of the 
        case in SBV Connect.
    </li>
    <li>The logged case will now appear on your Case List with the status In Progress.</li>
  </ol>

  
  
    <p><b>How a Case is Managed:</b></p>
    <p>
    <ol>
    <li>When you have successfully logged a case, it is automatically allocated to an available SBV Customer Liaison Officer 
      (CLO) to be resolved. If the CLO requires more information, they will update the case accordingly and the case status 
      will be changed to Awaiting Feedback.
    </li>
    <li>It will then be added to your Task List, which can be accessed via the Dashboard or via the Task List shortcut option
       from the menu, showing that action is required
    </li>
    <li>In the Task List, click on the Action Required hyperlink and a form will open up showing you all the details of 
      your case, including the additional information being requested by the CLO.
    </li>
    <li>Please add any notes or files as requested. You can update the listed fields of the case and add additional notes 
      and documents at the bottom of the page.
    </li>
    <li>Choose <i>Submit</i>.</li>
    <li>The case will automatically move off the Task List and continue in the workflow.</li>
    <li>Once the CLO has identified a resolution in order to resolve your case, they will update the case accordingly 
        and the case status will be changed to Resolved. It will again be added to your Task List, showing that action is required</li>
    <li>Click on the Action Required hyperlink and a form will open up showing you all the details and proposed resolution of your case</li>
    <li>You can choose to Accept Resolution or Reject Resolution at the bottom of the page. Accepting the resolution closes
         the case, whilst rejecting the resolution keeps the case open for further review by an SBV Manager</li>
    <li>Once you have accepted the resolution and the case is closed, it will again be listed on the Task List as Action Required
         inviting you to complete a short satisfaction survey. We appreciate your feedback in helping us to continually improve our service to you.</li>
    </ol>
    
    <br>
    <p><b>Understanding the Types of Case Status:</b></p>
    <p>Alongside each case in your case list, you will see a status displayed.</p>
    <p>There are four different case statuses. These are:</p>
    
    <p><i>In Progress</i></p>
    <ul>
    <li>Once you have successfully logged a case, it shows as In Progress. </li>
    <li>It remains as In Progress during the initial steps of the workflow, from allocation to review</li>
    <li>Our case resolution SLA is two business days. Should this time elapse, or should the SBV CLO deem it necessary, the case will be escalated to 
       a Manager to intervene. The case status will remain as In Progress for a customer user during this time. There are additional case statuses that
       are used internally to track and measure our performance.</li>
    </ul>
    
    
    <p><i>Awaiting Feedback</i></p>
    <ul>
    <li>If more information is required in order to resolve your case, the case status will be changed to Awaiting Feedback and the case will be listed on your Task List</li>
    <li> Please note that the SLA timer is paused whilst an action sits with the customer, as this turnaround time is out of SBVs contro.You will also receive an email 
         advising you that action is required.</li>
    <li> Selecting the case from your Task List will open the case detailform, where the detail of what is needed from you will be listed. </li>
    <li> If you do not respond to the request for feedback within two working days, the case will be returned to the SBV CLO to escalate, and the status will revert 
         to In Progress</li>
    </ul>
    
    
    
    <p><i>Resolved</i></p>
    <ul>
    <li>When a resolution is posted, the case status will move to Resolved and the case will be added to your Task List</li>
    <li>You will also receive an email advising you that action is required</li>
    <li>Selecting the case from your Task List will open the case detail block, where the proposed resolution will be listed. </li>
    <li>If you are happy with the posted resolution, select Accept Resolution at the bottom of the page. The case will move to Closed status.</li>
    <li>If you are not happy with the posted resolution, select Reject Resolution at the bottom of the page. The case will remain in Resolved 
        status but will be escalated for further review.</li>
    </ul>
   
    <p><i>Closed</i></p>
    <ul>
    <li>Once a resolution is accepted by the user, the case will move to Closed status. Once in this status, the workflow terminates and closes
        the enquiry, and no further action can be taken on the case.</li>
    <li>It will remain in the SBV Connect Case Management database  and can still be viewed on the Case List .</li>
    </ul>

    <br/>
   <b><p>Understanding the Case Management Dashboard</b><p>

    <p>The Dashboard gives you everything you need to know about cases for your business, with filterable overviews and insights. 
        You can also manage all your cases and tasks from the dashboard and log a new case using the shortcut button.</p>
    
    <p>
      The dashboard provides an overview of how we are tracking in managing your cases according to Service Level Agreement (SLA). 
      It also gives you insights into the key performance indicators (KPIs) of all cases for your business. Through the filters at the top of the page,
      you can choose to view different time periods, and different segments of cases – be it just the cases you have logged,
      the cases each of your team have logged, or all the cases logged for your business or region. Please note that the filters do not affect
      the Task List or Case List summary tables contained in the dashboard.
    </p>
    <br/>
  <b><p> Understanding Dashboard Insights</b><p>
 
  <p>
    In this section of the dashboard, there are four graphs that will help you to track case KPIs: 
  </p>
  <ul>
    <li>Cases by Type - you can view the relevant cases by query type such as deposits, payroll orders or CIT incident, up to a maximum of the 5 most common types</li>
    <li>Cases by Channel - this describes the number of cases per channel (Retail, Wholesale, CIT). The category Pending refers to cases that have been logged 
        but where the SBV CLO has not yet confirmed which channel it belongs to.</li>
    <li>Cases by Cash Centre - this chart shows how many cases have been opened per centre, up to a maximum of the top 5 centres </li>
    <li>Cases by Age - this charts how many days each case has remained open for.</li>
    
  </ul>
  <br/>
<b><p>Working with the Case List</b><p>
 
<p>
  The Case List allows you to access of all cases logged by yourself, your colleagues and SBV staff members relating to the Cash Centre,
   Region or National Group you work with.
</p>

<p>
  There are two ways to access your Case List:
</p>

<ol>
  <li>On the dashboard, you will find a table view of the Case List showing the latest 5 cases. It is independent of the view filters 
      that you have selected at the top of the page. You can change the view of the Case List using the Sort By drop down filter in the
      Case List. To open up the full Case List, click on See All OR</li>
  <li>Select the Case List shortcut menu option.</li>
</ol>
  <p>
    Once in the Case List, you can choose one of four viewing tabs of the Case List 
  </p>
  <ul>
    <li>All Cases – this contains all cases for the total group that you have permission to access </li>
    <li>Open Cases – this contains all cases In Progress and Awaiting Feedback</li>
    <li>Resolved Cases – this contains all cases where a resolution has been posted, but not yet accepted by the case logger</li>
    <li>Closed Cases – this contains all cases where the resolution has been accepted and the case is now closed</li>
  </ul> 
  <p>
      You can sort any of these views according to Insight Level
  </p>
  <ul>
    <li>Mine – this contains only the cases that were logged by you </li>
    <li>Local – this contains the cases logged against a specific SBV Cash Centre you select from the Location drop down</li>
    <li>Regional – this contains the cases logged against a specific SBV Region you select from the Location drop down</li>
    <li>National – this contains all the cases for the total group that you have permission to access</li>

  </ul>

  <p>
    You can also sort by a number of fields, both descending and ascending across Case ID, Customer (the Site Name), 
    Case Type, Cash Centre, Log Date (the date the case was first logged on SBV Connect), SLA Status (based on how long the case has been open for),
     Case Status
  </p>

  <p>
    To view further details of a case, simply click on the case in the Case List. The case row will highlight, 
    and a detail form will open to the right of the Case List. Scroll down to see all the associated information.
     Note that you cannot edit any case details from within this form.
  </p>

  <p>
    Each Case List page shows 10 cases. Click on the page numbers or arrows at the bottom of the Case List to view the next 10 cases.
  </p>
  <br/>
<b><p>Working with the Task List</b><p>

<p>
The Task List is designed for you to easily identify and perform any actions required by you for specific cases.
</p>
 
<p>
  There are two ways to access your Task List:
</p>
  
<ol>
  <li>On the dashboard, you will find a table view of the Task List showing the latest 5 cases requiring action.
     It is independent of the view filters that you have selected at the top of the page. 
     You can change the view of the Task List using the Sort By drop down filter in the Task List. To open up the full Task List, 
     click on See All OR</li>
  <li>Select the Task List shortcut menu option.</li>
</ol>
<p>
  You can search for a specific case using the Search By bar, which searches across all columns in the List
</p>

<p>
  You can also sort by a number of fields, both descending and ascending across Case ID, Action Required, Date Assigned, Cash Centre.
</p>

<p>
  Each Task List page shows 10 cases. Click on the page numbers or arrows at the bottom of the Task List to view the next 10 cases.
</p>

<p>
  During the case resolution workflow, the case will appear in the Task List when the following actions are required.
</p>

<ul>
  <li>When Feedback is required – this is when we require further information from you, which may be in the form of notes or document attachments</li>
  <li>When you need to review completion – this is when we have posted a resolution for your case and the status has moved to Resolved.
     You need to accept or reject the resolution. If you reject a resolution, the case may come through to you again to 
     review completion once the Manager has updated the case information</li>
  <li>When you have a survey to complete – once a case is closed, SBV Connect provides you an opportunity to complete 
    a short survey to determine your satisfaction with our service</li>
</ul>

<p>
  To action the request, simply click on the action required hyperlink in the Task List.
   A detail box will open detailing the action required. Please complete the action as select Submit
</p>


</div>