
import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-finalise-password-set',
  templateUrl: './finalise-password-set.component.html'
})
export class FinalisePasswordSetComponent implements OnInit {


  constructor(
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }

  ngOnInit(): void {
  }
}
