import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    cookieResult:boolean=false;

    constructor(
        private cookieService: CookieService,
        private router: Router,
        private renderer: Renderer2
    ) {
        this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
    }

    ngOnInit() {
        if("cookieBannerDisplayed" in localStorage){ //user accepted cookie policy 
            this.cookieResult=true;
        }
        window.sessionStorage.clear();
        window.localStorage.clear();
        localStorage.setItem('loggedIn', 'false');
        if(this.cookieResult==true){
            localStorage.setItem('cookieBannerDisplayed','true')
        }
        this.cookieService.removeAll;
        this.cookieService.remove('JSESSIONID');
        this.cookieService.remove('X-Bonita-API-Token');
        this.router.navigate(['/login']);

    }


}
