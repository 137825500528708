import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-help-finish',
  templateUrl: './help-finish.component.html'
})
export class HelpFinishComponent implements OnInit {


  constructor(
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }


  ngOnInit() {

  }


}
