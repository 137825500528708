import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, AlertService } from 'src/app/_services';
import { AccessRequestUser } from 'src/app/_models/access-request-user';
import { Contact } from 'src/app/_models/contact';
import { Profile } from 'src/app/_models/profile';
import { RequestAccess } from 'src/app/_models/request-access';
import { Role } from 'src/app/_models/role';
import { Roles } from 'src/app/_models/roles';
import { User } from 'src/app/_models';
import { UpdatedUser } from 'src/app/_models/updated-user';
import { CashCentres } from 'src/app/_models/cash-centres';
import { CashCentre } from 'src/app/_models/cash-centre';
import { UpdatedUserUser } from 'src/app/_models/updated-user-user';
import { Customer } from 'src/app/_models/customer';
import { CookieService } from 'ngx-cookie';
import { JsonPipe } from '@angular/common';
import { Tooltip, TooltipEventArgs } from '@syncfusion/ej2-popups';
import { Site } from 'src/app/_models/site';
import { Bank } from 'src/app/_models/bank';
@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html'
})
export class ProfileSettingsComponent implements OnInit {
  toggleStatus:boolean = false;
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrlCC: string;
  returnUrlComplete: string;
  rolesLoading: boolean = false;
  roleId:number;
  // profileType: string;
  // therole: string;
  // roles: any[];
  requestAccessObj: RequestAccess;
  // selectedRoles: number[] = [];
  selectedGroup: number = 0;
  selectedRole: number = 0;
  // requestDetails: AccessRequestUser;
  locId;
  locType;
  hideErrorBanner: boolean=true;
errorMessage: string=" ";

  userResult;

  userID:number;
  selectedBanks:number[]=[];
  selectedBankNames:any;

  updatedUser: UpdatedUser;
  updatedUserUser: UpdatedUserUser;

  groupChosen: boolean = true;
  roleChosen: boolean = true;

  singleCC:boolean=false;
  singleCentre:CashCentre;

  // dropdownList = [];
  // selectedItems = [];
  // dropdownSettings = {};
  dropdownCCList = [];
  dropdownSettingsCC = {};

  dropdownListBanks = [];
  dropdownSettingsMultiBank={};
  hideMultiBank:boolean = true;
  sBanks:Bank[]=[];
  multiBanks=[];
  singleBankreturned:boolean=false;
  SB=[];
  merchChecked:boolean=false;

  dropdownSiteList = [];
  dropdownSettingsSite = {};

  merchSites:any;
  sites: Site[] = [];
  userSitesID: number[]=[];


  dtOptions: DataTables.Settings = {};

  updateError: boolean = false;

  pEdit: boolean = false;
  bEdit: boolean = false;
  ccEdit: boolean = false;
  //updateError: boolean = false;

  isMerchant: boolean=false;
  userGroup: number;
  showMerchBox:boolean = true;
  allowMerchants:boolean = false;

  public accessRequest: AccessRequestUser;
  // result: any;
  ccLoading: boolean;
  selectedCCItems: any;
  originalCCs: any;
  ccReady: boolean;

  linkedSites: any;
  linkedUserSites=[];

  roleChange:boolean=false;
  ccChange:boolean=false;

  customerId;
  customerCode;
  customerName;

  personalEditSuccess: boolean = false;
  businessEditSuccess: boolean = false;
  roleEditRequest: boolean = false;
  ccEditRequest: boolean = false;
  ccEditSuccess: boolean = false;

  originalRole: number;

  pageReload:boolean=false;

  userDropdownheading: string = "Linked Banks"

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private cookieService: CookieService
  ) {
  }

  changeUser(){
    console.log("User Change");
    
  }


  onItemSelectGroup(item: any) {
    this.resetForm();
    this.selectedRole = 0;
    this.selectedGroup = item.id;
    this.dropdownListRoles = [];
    if (item.id != 3) {
      this.accountService.getRoles(item.id)//this.groupId) // item
        .subscribe(data => {
          this.dropdownListRoles = data.roles.role;
        },
          error => {
            if (error.status==500){
              this.errorMessage="ERROR 500 OCCURRED";
              this.hideErrorBanner=false;
            }
            if (error.status==408 || error.status==504){
              this.errorMessage="ERROR 408/504 OCCURRED";
              this.hideErrorBanner=false;
            }
            this.alertService.error(error.error.error.message);
          });
    } else {
      this.dropdownListRoles = [{ id: 11, name: 'Direct User', description: '' }];
    }
  }

  onGroupItemDeSelect(item: any) {
    //clear role selection
    this.selectedRole = 0;
    //reset roles list
    this.dropdownListRoles = [];
    this.resetForm();
    // Call to clear
    this.onDeSelectAll();
  }

  onItemDeSelect(item: any) {
    this.selectedRole = 0;
  }

  @ViewChild('rolesMultiSelect') multiSelect;

  onDeSelectAll() {
    this.dropdownListRoles = null;
  }

  resetForm() {
    this.form.get("role").setValue([]);
  }

  onItemSelectRole(item: any) {
    this.selectedRole = item.id;
  }

  settings = {};
  dropdownListGroups = [];
  dropdownListRoles = [];
  // selectedItems = [];
  dropdownSettingsGroups = {};
  dropdownSettingsRoles = {};

  user: User;
  ngOnInit() {

    this.form = this.formBuilder.group({
      linkedSites:[this.dropdownSiteList]
    });

    this.dtOptions={
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "ordering": true,
      order: [0],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'name' },//0
        { data: 'code' },//1
        { data: 'accNum' },//2
        { data: 'ccName' },//3
      ],

    };
    //get users details
    this.form = this.formBuilder.group({

      //cashCentres: [this.selectedCCItems.length == 1 ? a : this.selectedCCItems],

      cashCentres: [],
      firstName: [],
      lastName: [],
      email: [],
      contactNo: [],
      org: [],
      // // group: [groupArr],
      // // role: [roleArr],
      jobTitle: [],
      department: [],
      lineManager: [],
      lineManagerContact: []
    });

    let JSe=this.cookieService.get("JSESSIONID");

    this.accountService.getBanks(JSe)
    .subscribe(data => {

      console.log("Banks - "+JSON.stringify(data));
      this.dropdownListBanks = data.banks.bank;

    },
    error => {

        console.log(error)
    });

    let JSession=this.cookieService.get("JSESSIONID");

    this.accountService.getCurrentUser(sessionStorage.getItem("email"), JSession)
      .subscribe(data => {
        console.log(JSON.stringify(data));
        this.user = data.users.user;
        this.locId = data.users.user.locationId;
        this.locType = data.users.user.locationType;
        this.roleId=data.users.user.roles.role.id;
        let roleDetail = data.users.user.roles;
        this.userResult = roleDetail;
        this.userID=data.users.user.id;

        //
        let a = [];

        this.userGroup=parseInt(data.users.user.roles.role.groupId);
        console.log('group ID - '+this.userGroup);

        if(this.userGroup==3){
        let siteTable;
        let js=this.cookieService.get("JSESSIONID");

          console.log('user is a merchant');//Merchant
          this.isMerchant=true;

          this.accountService.getUserSites(sessionStorage.getItem("email"),js)
          .subscribe((data :any) => {
            let siteLength=data.user.sites.site.length;
            console.log('numSites - '+siteLength);

            if(siteLength==undefined){
              console.log("ONLY 1 SITE LINKED");
              let x=new Site;
              x.id=data.user.sites.site.id;
              x.name=data.user.sites.site.name;
              x.code=data.user.sites.site.citCode;
              x.accNum=data.user.sites.site.accountNo;
              x.ccName=data.user.sites.site.cashCentreName;
              let status=data.user.sites.site.status;
              console.log('Single site - '+JSON.stringify(x));
              if(status ==1){
                this.sites.push(x);
              }
              else{
                console.log("Deactivated Site");
              }
              //this.sites.push(x);

              setTimeout(() => {
                siteTable = $('#siteTable').DataTable({
                  initComplete: function () {
                  },
                  retrieve: true,
                }).rows.add(this.sites).draw();
              }, 2000);
            }

            else
            {
              console.log("MULTIPLE SITES LINKED");
              for (let i = 0; i <siteLength ; i++) {
                let x=new Site;
                x.id=data.user.sites.site[i].id;
                x.name=data.user.sites.site[i].name;
                x.code=data.user.sites.site[i].citCode;
                x.accNum=data.user.sites.site[i].accountNo;
                x.ccName=data.user.sites.site[i].cashCentreName;
                let status=data.user.sites.site[i].status;
                console.log('Single site - '+JSON.stringify(x));

                if(status ==1){
                  this.sites.push(x);
                }
                else{
                  console.log("Dectivated Site");
                }

                //this.sites.push(x);
              }
              setTimeout(() => {
              siteTable = $('#siteTable').DataTable({
                initComplete: function () {
                },
                retrieve: true,
              }).rows.add(this.sites).draw();
            }, 2000);
          }

          },
            error => {
              if (error.status==500){
                this.errorMessage="ERROR 500 OCCURRED";
                this.hideErrorBanner=false;
              }
              if (error.status==408 || error.status==504){
                this.errorMessage="ERROR 408/504 OCCURRED";
                this.hideErrorBanner=false;
              }
              console.log("ERROR FETCHING SITES");
            });

            
        }//END MERCHANT USER CHECK

        if( this.roleId==26 ||  this.roleId==27  ||  this.roleId==28  ||  this.roleId==29  ||  this.roleId==30){


          this.hideMultiBank=false;
          this.multiBanks.splice(0);


      let js=this.cookieService.get("JSESSIONID");
      this.accountService.getUserBanks(sessionStorage.getItem("email"),js)//this.groupId) // item
      .subscribe(data => {

        if(this.roleId==26 || this.roleId == 30){
          this.userDropdownheading = "Customer Groups"
          this.dropdownListBanks=[
            { id: 1, name: "ABSA" },
            { id: 2, name: "Nedbank" },
            { id: 3, name: "First National Bank" },
            { id: 4, name: "Standard Bank South Africa" },
            { id: 5, name: "African Bank" },
            { id: 6, name: "Capitec" },
            { id: 0, name: "Merchants"}
          ];

        }
        else{
          this.dropdownListBanks=[
            { id: 1, name: "ABSA" },
            { id: 2, name: "Nedbank" },
            { id: 3, name: "First National Bank" },
            { id: 4, name: "Standard Bank South Africa" },
            { id: 5, name: "African Bank" },
            { id: 6, name: "Capitec"}
          ];
        }

        console.log("profiles - "+JSON.stringify(data));

        for(let i : number = 0; i < data.user.profiles.profile.length; i++){
        
          this.selectedBanks.push(data.user.profiles.profile[i].targetId);
            
        }

        console.log("selectedBanks: " + this.selectedBanks);

        for(let i : number = 0; i < this.selectedBanks.length; i++){
          let b=new Bank;

          for(let j : number = 0; j < this.dropdownListBanks.length; j++){
            
            if(this.selectedBanks[i]==this.dropdownListBanks[j].id){
              b.id = this.selectedBanks[i];
              b.name = this.dropdownListBanks[j].name;
              console.log(JSON.stringify(b));

              this.sBanks.push(b);
              console.log("sBanks - "+JSON.stringify(this.sBanks));

              this.selectedBankNames.push(b.name);
              console.log("selectedBankNames - "+JSON.stringify(this.selectedBankNames));
              break;
          }    
          this.multiBanks=this.selectedBanks;
        }
      }

      this.multiBanks=this.selectedBanks;
      //console.log("sBanks - "+JSON.stringify(this.sBanks));
      //console.log("Bank Names - "+JSON.stringify(this.selectedBankNames));

      this.form = this.formBuilder.group({
        department: [this.user.department],
        lineManager: [this.user.lineManagerName],
        lineManagerContact: [this.user.lineManagerContactNo],
        firstName: [this.user.firstName],
        lastName: [this.user.lastName],
        email: [this.user.email],
        contactNo: [this.user.contactNo],
        org: [this.user.organization],
        group: [groupArr],
        role: [roleArr],
        jobTitle: [this.user.jobTitle],
        linkedBanks: [this.sBanks],
      });


      let a = [];
      if (data.user.profiles.profile.length == undefined || data.user.profiles.profile.length==1) {
          this.multiBanks.splice(0);
          this.singleBankreturned = true;
          let singleBank = new Bank;
          let name=((this.dropdownListBanks.find(({ id }) => id === data.user.profiles.profile.targetId).name));
          console.log("Bank Name - "+name);
          singleBank.id=data.user.profiles.profile.targetId;
          singleBank.name=name;
          console.log("1 bank"+JSON.stringify(singleBank));

          a.push(singleBank);
          this.sBanks = JSON.parse("[" + (JSON.stringify(singleBank)) + "]");
          this.sBanks = a;
          this.multiBanks.push(singleBank.id);
          this.SB=a;

          this.form = this.formBuilder.group({
            department: [this.user.department],
            lineManager: [this.user.lineManagerName],
            lineManagerContact: [this.user.lineManagerContactNo],
            firstName: [this.user.firstName],
            lastName: [this.user.lastName],
            email: [this.user.email],
            contactNo: [this.user.contactNo],
            org: [this.user.organization],
            group: [groupArr],
            role: [roleArr],
            jobTitle: [this.user.jobTitle],
            linkedBanks: [a],
          });

      }


      },
        error => {

          this.alertService.error(error.error.error.message);
        });


        }

        let JSession=this.cookieService.get("JSESSIONID");
        this.accountService.getUserCashCentres(sessionStorage.getItem("email"), JSession)
          .subscribe(data => {

            console.log("Users cash centres : "+JSON.stringify(data));
            this.selectedCCItems = data.user.cashCentres.cashCentre;
            this.originalCCs = this.selectedCCItems;
            //let a = [];

            if (this.selectedCCItems.length == undefined) {
              this.singleCC=true;
              let singleCCentre = new CashCentre;
              singleCCentre.id=this.selectedCCItems.id;
              singleCCentre=this.selectedCCItems;
              this.singleCentre=singleCCentre;
              
              console.log("User only has 1 CC")
              console.log("Single CC : "+JSON.stringify(singleCCentre))

              console.log("CC to use  : "+JSON.stringify(this.singleCentre))

              a.push(this.selectedCCItems)
              this.selectedCCItems = JSON.parse("[" + (JSON.stringify(this.selectedCCItems)) + "]");
              this.selectedCCItems = a;
              console.log("Cash Centres" + JSON.stringify(a));
            }
            //cashCentres: [this.selectedCCItems.length == 1 ? a : this.selectedCCItems]

            this.form = this.formBuilder.group({

              department: [this.user.department],
              lineManager: [this.user.lineManagerName],
              lineManagerContact: [this.user.lineManagerContactNo],
              firstName: [this.user.firstName],
              lastName: [this.user.lastName],
              email: [this.user.email],
              contactNo: [this.user.contactNo],
              org: [this.user.organization],
              group: [groupArr],
              role: [roleArr],
              jobTitle: [this.user.jobTitle],
              cashCentres: [this.selectedCCItems.length == 1 ? a : this.selectedCCItems],
              linkedSites:[this.dropdownSiteList]
            });

            this.selectedCCItems = [];
            console.log("CP")

            for (let i = 0; i < data.user.cashCentres.cashCentre.length; i++) {
              this.selectedCCItems.push(data.user.cashCentres.cashCentre[i].id);
              console.log(data.user.cashCentres.cashCentre[i].id);
            }
            console.log(" >>> " + this.selectedCCItems)
          },
          error => {
            if (error.status==500){
              this.errorMessage="ERROR 500 OCCURRED";
              this.hideErrorBanner=false;
            }
            if (error.status==408 || error.status==504){
              this.errorMessage="ERROR 408/504 OCCURRED";
              this.hideErrorBanner=false;
            }
            this.form = this.formBuilder.group({
              department: [this.user.department],
              lineManager: [this.user.lineManagerName],
              lineManagerContact: [this.user.lineManagerContactNo],
              firstName: [this.user.firstName],
              lastName: [this.user.lastName],
              email: [this.user.email],
              contactNo: [this.user.contactNo],
              org: [this.user.organization],
              group: [groupArr],
              role: [roleArr],
              jobTitle: [this.user.jobTitle],
              cashCentres: [this.selectedCCItems.length == 1 ? a : this.selectedCCItems],
              linkedSites:[this.dropdownSiteList]
            });
          }
          );//END CC


        let groupArr = JSON.parse('[{"id":' + roleDetail.role.groupId + ',"name":"' + roleDetail.role.groupName + '", "description": "" }]');
        let roleArr = JSON.parse('[{"id":' + roleDetail.role.id + ',"name":"' + roleDetail.role.name + '"}]');
        this.selectedRole = roleDetail.role.id;

        console.log("groupArr " + JSON.stringify(groupArr))

        console.log("this.user.lineManagerName: " + this.user.lineManagerName);

        this.updatedUser = new UpdatedUser;
        this.updatedUser.email = this.user.email;

        console.log("original updatedUser: " + JSON.stringify(this.updatedUser));

        let contact = new Contact;
        contact.contactNo = this.user.contactNo;
        contact.jobTitle = this.user.jobTitle;
        contact.department = this.user.department;
        contact.lineManagerName = this.user.lineManagerName;
        contact.lineManagerContactNo = this.user.lineManagerContactNo;

        console.log("original updatedUser: " + JSON.stringify(this.updatedUser));

        let roles: Roles[] = [];
        let rs = new Roles;
        let r = new Role;
        r.id = this.selectedRole;
        rs.role = r;
        roles.push(rs);

        console.log("original updatedUser: " + JSON.stringify(this.updatedUser));

        this.updatedUser.contact = contact;
        this.updatedUser.roles = roles;

        // this.updatedUserUser = new UpdatedUserUser;
        // this.updatedUserUser.user = this.updatedUser;

        // if (parseInt(roleDetail.role.groupId) != 3) {
          this.accountService.getRoles(roleDetail.role.groupId)//this.groupId) // item
            .subscribe(data => {
              this.dropdownListRoles = data.roles.role;
            },
              error => {
                if (error.status==500){
                  this.errorMessage="ERROR 500 OCCURRED";
                  this.hideErrorBanner=false;
                }
                if (error.status==408 || error.status==504){
                  this.errorMessage="ERROR 408/504 OCCURRED";
                  this.hideErrorBanner=false;
                }
                this.alertService.error(error.error.error.message);
              });
        // } else {
        //   this.dropdownListRoles = [{ id: 11, name: 'Direct User', description: '' }];
        // }

        console.log("original updatedUser: " + JSON.stringify(this.updatedUser));

      },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }
          this.alertService.error(error.error.error.message);
        }
      );

    // this.accountService.getUserCashCentres(sessionStorage.getItem("email"))
    //   .subscribe(data => {
    //     this.ccLoading = true;
    //     // console.log("user ccs: " + JSON.stringify(data.user.cashCentres.cashCentre));
    //     // this.dropdownCCList = [data.user.cashCentres];
    //     this.selectedCCItems = data.user.cashCentres.cashCentre;
    //     this.originalCCs = this.selectedCCItems;
    //     // console.log("ALL CCS: " + this.dropdownCCList)
    //     let a = [];
    //     // console.log("this.selectedCCItems.length "+this.selectedCCItems.length)
    //     if (this.selectedCCItems.length == undefined) {
    //       a.push(this.selectedCCItems)
    //       this.selectedCCItems = JSON.parse("[" + (JSON.stringify(this.selectedCCItems)) + "]");
    //       this.selectedCCItems = a;
    //       // console.log("USER CCS a1: " + JSON.stringify(a))
    //     }
    //     // console.log("USER CCS before: " + JSON.stringify(this.selectedCCItems))

    //     // console.log("USER CCS a2: " + JSON.stringify(a))


    //     this.formBuilder.group({
    //       cashCentres: [this.selectedCCItems.length == 1 ? a : this.selectedCCItems],
    //     });


    //     let CCs: CashCentres[] = [];
    //     for (let i = 0; i < this.selectedCCItems.length; i++) {
    //       let child = new CashCentres;
    //       let b = new CashCentre;
    //       b.id = this.selectedCCItems[i].id;
    //       child.cashCentre = b;
    //       CCs.push(child);
    //     }
    //     // let profile = new Profile();
    //     // profile.cashCentres = CCs;

    //     // this.updatedUser.profile = profile;
    //     // this.updatedUserUser = new UpdatedUserUser;
    //     // this.updatedUserUser.user = this.updatedUser;

    //     // console.log("original updatedUserUser: " + JSON.stringify(this.updatedUserUser));

    //     // console.log("USER CCS during: " + JSON.stringify(this.selectedCCItems))
    //     this.ccReady = true;
    //     this.ccLoading = false;
    //   },
    //     error => {
    //       this.alertService.error(error.error.error.message);
    //     }
    //   );
    this.ccLoading = false;
    // console.log("USER CCS after: " + JSON.stringify(this.selectedCCItems))

    this.settings = {
      singleSelection: true,
      text: "Select Group",
      enableSearchFilter: false,
      classes: "myclass custom-class"
    };

    this.dropdownSettingsMultiBank = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };

    this.dropdownSettingsGroups = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true"
    };
    this.dropdownSettingsRoles = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      clearable: "true",
      closeDropDownOnSelection: "true",
      itemsShowLimit: 1,
      enableCheckAll: false,
      // noDataLabel: 'Please select group first',
      noDataAvailablePlaceholderText: "Please select group first",
      // allowSearchFilter: true
    };
    this.selectedCCItems = [
    ];
    this.dropdownSettingsCC = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };

    this.dropdownSettingsSite = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };

    this.selectedBankNames = [
    ];

    this.dropdownSiteList=["SITE 1","SITE 2","SITE 3","SITE 4","SITE 5"];

    //1 - get groups
    this.accountService.getGroups()
      .subscribe(data => {
        this.dropdownListGroups = data.groups.group;
      },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }
          this.alertService.error(error.error.error.message);
        });

    // get return url from route parameters or default to '/'
    this.returnUrlCC = this.route.snapshot.queryParams['returnUrl'] || 'new-user-access';
    this.returnUrlComplete = this.route.snapshot.queryParams['returnUrl'] || 'new-user-finish';


    let jSession=this.cookieService.get("JSESSIONID");
    console.log(jSession);

    this.accountService.getCashCentres(jSession)
      .subscribe(data => {
        this.loading = false;
        this.dropdownCCList = data.root.cashCentre;
        console.log('CASHCENTRES',this.dropdownCCList)
        // this.ccentres = data.root.cashCentre;
      },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }
          this.alertService.error(error.error.error.message);
          this.loading = false;
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
        });
  }

  populatePage() {

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onCCItemSelect(item: any) {
    this.ccChange=true;

    // console.log(item.id);
    if ((this.allSelected == false) || (!this.selectedCCItems.includes(item.id))) {
      this.selectedCCItems.push(item.id);
      // console.log(this.selectedItems);
    }
  }

  allSelected: boolean = false;

  onCCSelectAll(items: any[]) {
    this.ccChange=true;

    if (items.length == this.selectedCCItems.length) {
      this.selectedCCItems = [];
      this.allSelected = false;
    }
    else {
      // this.selectedItems = items;
      for (let i = 0; i < items.length; i++) {
        if (!this.selectedCCItems.includes(items[i].id)) {
          this.selectedCCItems.push(items[i].id);
        }
      }
      this.allSelected = true;
    }
    // console.log(this.selectedItems);
  }

  onCcItemDeSelect(item: any) {
    this.ccChange=true;

    if (this.allSelected == true) {
      this.selectedCCItems.splice(item.id, 1);
    }
    this.selectedCCItems.forEach((element, index) => {
      if (element == item.id)
        this.selectedCCItems.splice(index, 1);
    });
    // console.log("After: " + this.selectedItems);
  }

  onCCItemDeSelectAll(items: any[]) {
    this.ccChange=true;

    this.selectedCCItems = [];
  }

  saveCCEdit() {
    let CCs: CashCentres[] = [];
    for (let i = 0; i < this.selectedCCItems.length; i++) {
      let child = new CashCentres;
      let b = new CashCentre;
      b.id = this.selectedCCItems[i];
      child.cashCentre = b;
      CCs.push(child);
    }
    let profile = new Profile();
    profile.cashCentres = CCs;
  }

  savePersonalInfoEdit() {
    let contact = new Contact;
    this.updatedUser = new UpdatedUser;
    this.updatedUserUser = new UpdatedUserUser;

    contact.contactNo = this.f.contactNo.value;
    contact.jobTitle = this.f.jobTitle.value;
    contact.department = this.f.department.value;
    contact.organization = this.f.org.value;
    contact.lineManagerName = this.f.lineManager.value;
    contact.lineManagerContactNo = this.f.lineManagerContact.value;
    contact.locationId = this.locId;
    contact.locationType = this.locType;

    this.updatedUser.email = this.f.email.value;
    this.updatedUser.contact = contact;
    this.updatedUserUser.user = this.updatedUser;

    console.log("savePersonalInfoEdit(): " + JSON.stringify(this.updatedUserUser))

    let Jsession=this.cookieService.get("JSESSIONID");
    this.accountService.updateUserContact(this.updatedUserUser, Jsession)
      .subscribe(data => {
        this.personalEditSuccess = true;
        // this.alertService.info("Your changes have been saved.")
      },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }
          this.alertService.error(error.error.error.message);
        });
  }

  saveBusinessDetailsEdit() {
    let contact = new Contact;
    this.updatedUser = new UpdatedUser;
    this.updatedUserUser = new UpdatedUserUser;


    contact.jobTitle = this.f.jobTitle.value;
    contact.department = this.f.department.value;
    contact.organization = this.f.org.value;
    contact.lineManagerName = this.f.lineManager.value;
    contact.lineManagerContactNo = this.f.lineManagerContact.value;
    contact.locationId = this.locId;
    contact.locationType = this.locType;

    this.updatedUser.email = this.f.email.value;
    this.updatedUser.contact = contact;
    this.updatedUserUser.user = this.updatedUser;

    console.log("saveBusinessDetailsEdit(): " + JSON.stringify(this.updatedUserUser))

    //if stuff (not incl role) changed 
    if (this.user.jobTitle != this.f.jobTitle.value ||
      this.user.department != this.f.department.value ||
      this.user.organization != this.f.org.value ||
      this.user.lineManagerName != this.f.lineManager.value ||
      this.user.lineManagerContactNo != this.f.lineManagerContact.value) {

      let Jsession=this.cookieService.get("JSESSIONID");
      this.accountService.updateUserContact(this.updatedUserUser, Jsession)
        .subscribe(data => {
          this.businessEditSuccess = true;
        },
          error => {
            if (error.status==500){
              this.errorMessage="ERROR 500 OCCURRED";
              this.hideErrorBanner=false;
            }
            if (error.status==408 || error.status==504){
              this.errorMessage="ERROR 408/504 OCCURRED";
              this.hideErrorBanner=false;
            }
            // this.alertService.error("User not updated");
          }
        );
    }

    let roles: Roles[] = [];
    let rs = new Roles;
    let r = new Role;
    r.id = this.selectedRole;
    rs.role = r;
    roles.push(rs);

    this.updatedUser.roles = roles;
    this.updatedUserUser.user = this.updatedUser;

    //if role changed
    if (this.userResult.role.id != this.selectedRole) {
      let updateObject = {
        "update": {
          "email": this.user.email,
          "changes": [{
            "change": {
              "type": 1,
              "value": this.selectedRole
            }
          }]
        }
      }

      console.log("ROLE UPDATE : "+JSON.stringify(updateObject))
      
      // this.accountService.updateUserRoles(this.updatedUserUser)
      let JSession=this.cookieService.get("JSESSIONID");
      this.accountService.submitUpdateRequest(updateObject, JSession)
        .subscribe(data => {
          // this.alertService.success("User updated successfully");
          //if role change
          this.roleEditRequest = true;
        },
          error => {

            if(error.status==409){
              this.updateError=true;
            }

            if (error.status==500){
              this.errorMessage="ERROR 500 OCCURRED";
              this.hideErrorBanner=false;
            }
            if (error.status==408 || error.status==504){
              this.errorMessage="ERROR 408/504 OCCURRED";
              this.hideErrorBanner=false;
            }
            this.alertService.error("User not updated");
          }
        );
    }
  }

  editDetails(){

    this.pEdit = true;

    this.bEdit=true;

    this.ccEdit=true;

    if(this.singleCC==true){
      this.selectedCCItems.push(this.singleCentre.id);
    }

  }

  merchantSelected(){
    if (this.allowMerchants == false) {
      this.allowMerchants = true;
  }
  else {
      this.allowMerchants = false;
  }
  console.log("AllowMerchants - " + this.allowMerchants);
  }

  cancelEdit(){
    
    this.pEdit = false;
    this.personalEditSuccess=false;

    this.bEdit=false;
    this.businessEditSuccess=false;

    this.ccEdit=false;
    this.ccEditSuccess=false;

    this.ngOnInit();

  }

  saveDetails(){
    this.pageReload=false;
    this.updateError=false;
    let contact = new Contact;
    this.updatedUser = new UpdatedUser;
    this.updatedUserUser = new UpdatedUserUser;
    let profile = new Profile;

    contact.firstName =this.f.firstName.value;
    contact.lastName =this.f.lastName.value;

    contact.contactNo = this.f.contactNo.value;
    contact.jobTitle = this.f.jobTitle.value;
    contact.department = this.f.department.value;
    contact.organization = this.f.org.value;
    contact.lineManagerName = this.f.lineManager.value;
    contact.lineManagerContactNo = this.f.lineManagerContact.value;
    contact.locationId = this.locId;
    contact.locationType = this.locType;

    this.updatedUser.email = this.f.email.value;
    this.updatedUser.contact = contact;
    this.updatedUserUser.user = this.updatedUser;

    console.log("savePersonalInfoEdit(): " + JSON.stringify(this.updatedUserUser))

    let Jsession=this.cookieService.get("JSESSIONID");
    this.accountService.updateUserContact(this.updatedUserUser, Jsession)
      .subscribe(data => {
        this.personalEditSuccess = true;

        let roles: Roles[] = [];
        let rs = new Roles;
        let r = new Role;
        r.id = this.selectedRole;
        rs.role = r;
        roles.push(rs);
    
        this.updatedUser.roles = roles;
        this.updatedUserUser.user = this.updatedUser;
    
        //if role changed
        if (this.userResult.role.id != this.selectedRole) {
          this.roleChange=true;
          console.log("Role was changed")
        }

        //Cash centres changed
        else if(this.ccChange==true){
          console.log("Cash centres were changed");
          let CCs: CashCentres[] = [];
  
      //PROFILE HAS ARRAY OF CASH CENTRES
      // let c = new CashCentres;
      console.log("count : " +this.selectedCCItems.length)
      console.log("Selected CCs"+JSON.stringify(this.selectedCCItems))
  
      for (let i : number = 0; i < this.selectedCCItems.length; i++) {
        let child = new CashCentres;
        let b = new CashCentre;
        if (this.selectedCCItems[i].id == null) {
          b.id = this.selectedCCItems[i];
        } else {
          b.id = this.selectedCCItems[i].id;
        }
        child.cashCentre = b;
        CCs.push(child);
      }


      profile.cashCentres = CCs;
      this.updatedUser.profile = profile;

      console.log("saveCCDetails(): " + JSON.stringify(this.updatedUserUser))
        }

        else{// Changes did not include role change or CC change 
          window.location.reload();
        }

        if(this.roleChange==true && this.ccChange!=true){ //Only role change occurring
          console.log("Only Role changed")
          let updateObject = {
              "update": {
              "email": this.f.email.value,
              "requestorUserId": sessionStorage.getItem('email'),
              "comment":"User requested role change",
              "changes": [{
                "change": {
                  "type": 1,
                  "value": this.selectedRole
                }
              }]
            }
          }
          console.log("OBJECT : "+JSON.stringify(updateObject))
    
          let JSession=this.cookieService.get("JSESSIONID");
          this.accountService.submitUpdateRequest(updateObject,JSession)
          .subscribe(data => {
            console.log("submition complete")
            window.location.reload();
          },
            error => {
              if(error.status==409){
                this.updateError=true;
              }
            }
          );
    
        }
    
        if(this.ccChange==true && this.roleChange!=true){ //Only CC change occurring
          console.log("Only CCs changed")
          let ccChanges = [];    
          let ccChangeObj;
    
          for (let i = 0; i < this.selectedCCItems.length; i++) {
    
            ccChangeObj =  {
                  "change": {
                  "type": 2,
                  "value": this.selectedCCItems[i]
                }
              }
              ccChanges.push(ccChangeObj);
    
          }
      
       let updateObject = {
              "update": {
              "email": this.f.email.value,
              "requestorUserId": sessionStorage.getItem('email'),
              "comment":"User requested cash centre change",
              "changes": ccChanges
            }
          }
    
    
          console.log("updateObject : "+JSON.stringify(updateObject))
    
          let JSession=this.cookieService.get("JSESSIONID");
          this.accountService.submitUpdateRequest(updateObject, JSession)
          .subscribe(data => {
            window.location.reload();
          },
            error => {
              if(error.status==409){
                this.updateError=true;
              }
            }
          );
    
        }
    
        if(this.roleChange==true && this.ccChange==true){//Both changes 
          console.log("Both role & CCs changed")
          let changeArray=[];
          let tempObj;
    
          tempObj =  {
              "change": {
              "type": 1,
              "value": this.selectedRole
            }
          }
    
          changeArray.push(tempObj);
    
          for (let i = 0; i < this.selectedCCItems.length; i++) {
            tempObj =  {
                  "change": {
                  "type": 2,
                  "value": this.selectedCCItems[i]
                }
              }
            changeArray.push(tempObj);
          }

    
          let updateObject = {
              "update": {
              "email": this.f.email.value,
              "requestorUserId": sessionStorage.getItem('email'),
              "comment":"User requested double change, role and cash centre",
              "changes": changeArray
            }
          }
    
          console.log("OBJECT : "+JSON.stringify(updateObject));
    
          let JSession=this.cookieService.get("JSESSIONID");
          this.accountService.submitUpdateRequest(updateObject,JSession)
          .subscribe(data => {
            window.location.reload();
          },
            error => {
              if(error.status==409){
                this.updateError=true;
              }
            }
          );
    
        }

        

      },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }
          this.alertService.error(error.error.error.message);
        });


  }//End save details












  saveCcDetailsEdit() {
    let profile = new Profile;
    let contact = new Contact;
    this.updatedUser = new UpdatedUser;
    this.updatedUserUser = new UpdatedUserUser;

    contact.jobTitle = this.f.jobTitle.value;
    contact.department = this.f.department.value;
    contact.organization = this.f.org.value;
    contact.lineManagerName = this.f.lineManager.value;
    contact.lineManagerContactNo = this.f.lineManagerContact.value;
    contact.locationId = this.locId;
    contact.locationType = this.locType;

    let CCs: CashCentres[] = [];

    //PROFILE HAS ARRAY OF CASH CENTRES
    // let c = new CashCentres;
    for (let i = 0; i < this.selectedCCItems.length; i++) {
      let child = new CashCentres;
      let b = new CashCentre;
      if (this.selectedCCItems[i].id == null) {
        b.id = this.selectedCCItems[i];
      } else {
        b.id = this.selectedCCItems[i].id;
      }
      child.cashCentre = b;
      CCs.push(child);
    }
    profile.cashCentres = CCs;

    this.updatedUser.profile = profile;
    this.updatedUser.email = this.f.email.value;
    this.updatedUser.contact = contact;
    this.updatedUserUser.user = this.updatedUser;

    console.log("saveCcDetailsEdit(): " + JSON.stringify(this.updatedUserUser))

    let ccChanges = [];
    let ccChangeObj;
    for (let i = 0; i < this.selectedCCItems.length; i++) {
      ccChangeObj =  {
          "change": {
            "type": 2,
            "value": this.selectedCCItems[i]
          }
        }
      ccChanges.push(ccChangeObj);
    }

 let updateObject = {
      "update": {
        "email": this.user.email,
        "changes": ccChanges
      }
    }

    //if cc actually changed
    // this.accountService.updateUserCC(this.updatedUserUser)
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.submitUpdateRequest(updateObject,JSession)
      .subscribe(data => {
        // this.ccEditSuccess = true;
        this.ccEditRequest = true;

        // window.location.reload();s
        // this.alertService.success("User updated successfully");

      },
        error => {

          if(error.status==409){
            this.updateError=true;
          }

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }
          // this.alertService.error("User not updated");
        }
      );
  }

  personalCancel() {
    this.pEdit = false;
    this.personalEditSuccess = false;

    this.form = this.formBuilder.group({

      department: [this.user.department],
      lineManager: [this.user.lineManagerName],
      lineManagerContact: [this.user.lineManagerContactNo],

      // cashCentres: [this.selectedCCItems.length == 1 ? a : this.selectedCCItems],

      firstName: [this.user.firstName],
      lastName: [this.user.lastName],
      email: [this.user.email],
      contactNo: [this.user.contactNo],
      org: [this.user.organization],
      // group: [groupArr],
      // role: [roleArr],
      jobTitle: [this.user.jobTitle]
    });

    this.pEdit = false;
    this.personalEditSuccess = false;

  }

  businessCancel() {
    this.bEdit = false;
    this.businessEditSuccess = false;

    let roleDetail = this.userResult;
    console.log(JSON.stringify(this.user));
    // console.log("this.user.firstName " + this.user.firstName)
    let a = [];
    // console.log("this.selectedCCItems.length "+this.selectedCCItems.length)
    if (this.selectedCCItems.length == undefined) {
      a.push(this.selectedCCItems)
      this.selectedCCItems = JSON.parse("[" + (JSON.stringify(this.selectedCCItems)) + "]");
      this.selectedCCItems = a;
    }

    console.log("USER CCS : " + this.selectedCCItems)// cash centre IDS stored in this.selectedCCItems

    let groupArr = JSON.parse('[{"id":' + roleDetail.role.groupId + ',"name":"' + roleDetail.role.groupName + '", "description": "" }]');
    let roleArr = JSON.parse('[{"id":' + roleDetail.role.id + ',"name":"' + roleDetail.role.name + '"}]');
    this.selectedRole = roleDetail.role.id;

    console.log("groupArr " + JSON.stringify(groupArr))

    console.log("this.user.lineManagerName: " + this.user.lineManagerName);
    this.form = this.formBuilder.group({

      department: [this.user.department],
      lineManager: [this.user.lineManagerName],
      lineManagerContact: [this.user.lineManagerContactNo],

      //cashCentres: [this.selectedCCItems.length == 1 ? a : this.selectedCCItems],

      firstName: [this.user.firstName],
      lastName: [this.user.lastName],
      email: [this.user.email],
      contactNo: [this.user.contactNo],
      org: [this.user.organization],
      group: [groupArr],
      role: [roleArr],
      jobTitle: [this.user.jobTitle]
    });

    this.updatedUser = new UpdatedUser;
    this.updatedUser.email = this.user.email;

    console.log("original updatedUser: " + JSON.stringify(this.updatedUser));

    let contact = new Contact;
    contact.contactNo = this.user.contactNo;
    contact.jobTitle = this.user.jobTitle;
    contact.department = this.user.department;
    contact.lineManagerName = this.user.lineManagerName;
    contact.lineManagerContactNo = this.user.lineManagerContactNo;

    console.log("original updatedUser: " + JSON.stringify(this.updatedUser));

    let roles: Roles[] = [];
    let rs = new Roles;
    let r = new Role;
    r.id = this.selectedRole;
    rs.role = r;
    roles.push(rs);

    // let CCs: CashCentres[] = [];
    // for (let i = 0; i < this.selectedCCItems.length; i++) {
    //   let child = new CashCentres;
    //   let b = new CashCentre;
    //   b.id = this.selectedCCItems[i];
    //   child.cashCentre = b;
    //   CCs.push(child);
    // }
    // let profile = new Profile();
    // profile.cashCentres = CCs;

    console.log("original updatedUser: " + JSON.stringify(this.updatedUser));

    this.updatedUser.contact = contact;
    this.updatedUser.roles = roles;

    // this.updatedUserUser = new UpdatedUserUser;
    // this.updatedUserUser.user = this.updatedUser;

    if (parseInt(roleDetail.role.groupId) != 3) {
      this.accountService.getRoles(roleDetail.role.groupId)//this.groupId) // item
        .subscribe(data => {
          this.dropdownListRoles = data.roles.role;
        },
          error => {
            if (error.status==500){
              this.errorMessage="ERROR 500 OCCURRED";
              this.hideErrorBanner=false;
            }
            if (error.status==408 || error.status==504){
              this.errorMessage="ERROR 408/504 OCCURRED";
              this.hideErrorBanner=false;
            }
            this.alertService.error(error.error.error.message);
          });
    } else {
      this.dropdownListRoles = [{ id: 11, name: 'Direct User', description: '' }];
    }

    console.log("original updatedUser: " + JSON.stringify(this.updatedUser));

    //reset form to original
    // this.form = this.formBuilder.group({

    //   department: [this.user.department],
    //   lineManager: [this.user.lineManagerName],
    //   lineManagerContact: [this.user.lineManagerContactNo],

    //   // cashCentres: [this.selectedCCItems.length == 1 ? a : this.selectedCCItems],

    //   firstName: [this.user.firstName],
    //   lastName: [this.user.lastName],
    //   email: [this.user.email],
    //   contactNo: [this.user.contactNo],
    //   org: [this.user.organization],
    //   // group: [groupArr],
    //   // role: [roleArr],
    //   jobTitle: [this.user.jobTitle]
    // });

    this.bEdit = false;
    this.businessEditSuccess = false;
  }

  xButton(){
    window.location.reload();
}


  ccCancel() {
    this.ccEdit = false;
    this.ccEditSuccess = false;

    // this.accountService.getUserCashCentres(sessionStorage.getItem("email"))
    //   .subscribe(data => {
    this.ccLoading = true;
    // console.log("user ccs: " + JSON.stringify(data.user.cashCentres.cashCentre));
    // this.dropdownCCList = [data.user.cashCentres];
    this.selectedCCItems = this.originalCCs;
    //  = this.selectedCCItems;
    // console.log("ALL CCS: " + this.dropdownCCList)
    let a = [];
    // console.log("this.selectedCCItems.length "+this.selectedCCItems.length)
    if (this.selectedCCItems.length == undefined) {
      a.push(this.selectedCCItems)
      this.selectedCCItems = JSON.parse("[" + (JSON.stringify(this.selectedCCItems)) + "]");
      this.selectedCCItems = a;
      // console.log("USER CCS a1: " + JSON.stringify(a))
    }
    // console.log("USER CCS before: " + JSON.stringify(this.selectedCCItems))

    // console.log("USER CCS a2: " + JSON.stringify(a))

    this.formBuilder.group({
      cashCentres: [this.selectedCCItems.length == 1 ? a : this.selectedCCItems],
    });

    this.selectedCCItems = [];

    // let c = new CashCentres;
    for (let i = 0; i < this.originalCCs.length; i++) {
      this.selectedCCItems.push(this.originalCCs[i].id);
      console.log(this.originalCCs[i].id);
    }
    console.log(" CC CANCEL>>> " + this.selectedCCItems)

    let CCs: CashCentres[] = [];
    for (let i = 0; i < this.selectedCCItems.length; i++) {
      let child = new CashCentres;
      let b = new CashCentre;
      b.id = this.selectedCCItems[i].id;
      child.cashCentre = b;
      CCs.push(child);
    }
    let profile = new Profile();
    profile.cashCentres = CCs;

    this.updatedUser.profile = profile;
    this.updatedUserUser = new UpdatedUserUser;
    this.updatedUserUser.user = this.updatedUser;

    console.log("original updatedUserUser: " + JSON.stringify(this.updatedUserUser));

    // console.log("USER CCS during: " + JSON.stringify(this.selectedCCItems))
    this.ccReady = true;
    this.ccLoading = false;
    // },
    //   error => {
    //     this.alertService.error(error.error.error.message);
    //   }
    // );
    console.log("4")
    this.ccEdit = false;
    this.ccEditSuccess = false;
  }
}
