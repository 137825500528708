<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius:10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <h2>You are logged out.</h2>

                <div>
                    <button class="rounded btn btn-primary w-100 mb-5 mt-5" routerLink="../login">
                        Login
                    </button>
                    <!-- <a class="d-flex justify-content-center" routerLink="../login">Login</a> -->
                </div>
            </div>
        </div>
    </div>
</div>