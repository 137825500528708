<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <div class="d-flex justify-content-between align-items-end">
                    <h3>Thank You</h3>
                    <!-- <p class="purple">Password Changed</p> -->
                </div>
                <hr class="mt-0">
                <!-- <img src="assets/img/security.png" height="80px"> -->
                <!-- <h5 class="p-green">Password successfully changed!</h5> -->
                <p>Thank you for your submission. An agent will be in touch shortly.</p>

                <button class="rounded btn btn-primary w-100 mt-4" routerLink="../login">
                    Return To Login
                </button>
                <div class="d-flex justify-content-end  ms-5 me-5 pt-4 mb-4">
                    <!-- <a class="p-2 black-link" routerLink="../login">Back to login</a> -->
                    <!-- <a class="p-2 blue-link" routerLink="../help">Need help?</a> -->
                </div>
            </div>
        </div>
    </div>
</div>