import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import * as shajs from 'sha.js';

@Component({
  selector: 'app-ott',
  templateUrl: './ott.component.html',
  styleUrls: ['./ott.component.scss']
})
export class OttComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  token: string;

  hideErrorBanner: boolean=true;
errorMessage: string=" ";

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
    this.token = this.route.snapshot.paramMap.get("token")
    console.log(this.token);  
  };



  ngOnInit(): void {

    //call api
    this.checkOtt();

    this.form = this.formBuilder.group({
      password: ['',
        //[Validators.required, Validators.pattern("/([a-zA-Z])[A-Za-z\d$@$!%*?&]{7,}/i")
        //  ]
      ],
      passwordConfirm: ['',
        //[Validators.required]
      ]
    });
  }

  checkOtt() {
    this.accountService.ott(this.token)
      .subscribe(data => {
        console.log("OTT : "+data)
        sessionStorage.setItem("ottId",data.ott.id);
        sessionStorage.setItem("ottCode",this.token);
        // console.log(data.ott.userId); //1st output (= 3rd output)
        // console.log(localStorage.getItem('email'));  //2nd output - old
        localStorage.removeItem('email');
        sessionStorage.setItem('email', data.ott.userId);  //email changed
        // console.log(localStorage.getItem('email'));  //3rd output
        if (data.ott.state == 1) {
          this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'otp';  //LOGIN
          this.router.navigate([this.returnUrl]);
        }
        if (data.ott.state == 3) {
          this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'request-access';  //REGISTRATION FORM
          this.router.navigate([this.returnUrl]);
        }
        if (data.ott.state == 2) {
          if (localStorage.getItem('password-set') == '0') {
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'finalise';  //FINALISE ACCOUNT SET PASSWORD
            this.router.navigate([this.returnUrl]);
          }
          else {
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'reset-password';  //FORGOT PASSWORD
            this.router.navigate([this.returnUrl]);
          }
        }


      },
        error => {

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          this.form.enable();
          this.loading = false;
        });
  }


  get f() { return this.form.controls; }

  xButton(){
    window.location.reload();
}

  errorMsg: any;

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    const hashed = shajs('sha256').update(this.f.password.value).digest('hex')

    // stop here if form is invalid
    if ((this.form.invalid) || (this.f.password.value != this.f.passwordConfirm.value)) {
      return;
    }

    this.loading = true;

    let ottId=sessionStorage.getItem("ottId");
    let ottCode=sessionStorage.getItem("ottCode");

    this.accountService.resetPassword(hashed, ottId, ottCode)
      .pipe(catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }
        return throwError(errorMsg);
      })
        ,


        first())
      .subscribe(
        data => {
          this.router.navigate(['login'], { relativeTo: this.route });
        },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          this.form.enable();
          this.loading = false;
        });
    this.loading = false;
  }

  private getServerErrorMessage(error: HttpErrorResponse) {
    switch (error.status) {
      case 404: {
        // console.log(`Not Found: ${error.message}`);
      }
      case 403: {
        // console.log(`Access Denied: ${error.message}`);
      }
      case 500: {
        // console.log(`Internal Server Error: ${error.message}`);
      }
      default: {
        // console.log(`Unknown Server Error: ${error.message}`);
      }

    }
  }

}
