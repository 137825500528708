import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, CheckboxRequiredValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { AccessRequestUser } from 'src/app/_models/access-request-user';
import { Contact } from 'src/app/_models/contact';
import { Profile } from 'src/app/_models/profile';
import { RequestAccess } from 'src/app/_models/request-access';
import { Role } from 'src/app/_models/role';
import { Roles } from 'src/app/_models/roles';
import { User } from 'src/app/_models';
import { CookieService } from 'ngx-cookie';


@Component({
  selector: 'app-new-user-role',
  templateUrl: './new-user-role.component.html'
})
export class NewUserRoleComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrlCC: string;
  returnUrlSwitchProfile: string;
  returnUrlComplete: string;
  // profileType: string;
  // therole: string;
  // roles: any[];
  requestAccessObj: RequestAccess;
  // selectedRoles: number[] = [];
  selectedGroup: number = 0;
  selectedRole: number = 0;
  // requestDetails: AccessRequestUser;

  groupChosen: boolean = true;
  roleChosen: boolean = true;
  roleCheck:number;

  hideErrorBanner: boolean=true;
errorMessage: string=" ";

  public accessRequest: AccessRequestUser;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef
  ) {
  }

  // selected: any;


  onItemSelectGroup(item: any) {
    this.resetForm();


    this.selectedRole = 0;
    this.selectedGroup = item.id;
    this.dropdownListRoles = [];

    // if (item.id != 3) {
      localStorage.setItem('isMerchant', "false");
      this.accountService.getRoles(item.id)
        .subscribe(data => {
          this.dropdownListRoles = data.roles.role;
          if(item.id==3){
            this.dropdownListRoles = this.dropdownListRoles.filter(item => item.name !== "Administrator");
          }
        },
          error => {

            if (error.status==0){
              this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
              this.hideErrorBanner=false;
            }

            if (error.status==500){
              this.errorMessage="ERROR 500 OCCURRED";
              this.hideErrorBanner=false;
            }
            if (error.status==408 || error.status==504){
              this.errorMessage="ERROR 408/504 OCCURRED";
              this.hideErrorBanner=false;
            }
            this.alertService.error(error.error.error.message);
          });
    // } else {
    //   this.dropdownListRoles = [{ id: 11, name: 'Direct User', description: '' }];
    //   localStorage.setItem('isMerchant', "true");
    // }
  }

  onGroupItemDeSelect(item: any) {
    //clear role selection
    this.selectedRole = 0;
    //reset roles list
    this.dropdownListRoles = [];
    this.resetForm();

    // Call to clear
    this.onDeSelectAll();

  }

  onItemDeSelect(item: any) {
    this.selectedRole = 0;
    console.log("Role deselected");
    sessionStorage.setItem("multiBank","false");
  }

  @ViewChild('rolesMultiSelect') multiSelect;

  onDeSelectAll() {
    this.dropdownListRoles = null;
  }

  resetForm() {
    this.form.get("role").setValue([]);
  }

  xButton(){
    window.location.reload();
}


  onItemSelectRole(item: any) {
    this.selectedRole = item.id;

    if(item.id==26 || item.id==27  || item.id==28  || item.id==29  || item.id==30){
      console.log("New role selected - "+item.id);
      sessionStorage.setItem("multiBank","true");
    }
    else{
      sessionStorage.removeItem("multiBank");

    }

    if(item.id==26 || item.id==30){
      sessionStorage.setItem("aaCredit","true");
    }
    else{
      sessionStorage.removeItem("aaCredit");
    }

    if(item.id==31 || item.id==33){
      sessionStorage.setItem("FMU","true");
    }
    else{
      sessionStorage.removeItem("FMU");

    }
  }

  settings = {};

  dropdownListGroups = [
    {id: 2, name: "Bank User", description: ""},
    {id: 3, name: "Merchant", description: ""},
    {id: 1, name: "SBV User", description: ""}
  ];
  dropdownListRoles = [];
  dropdownSettingsGroups = {};
  dropdownSettingsRoles = {};


  ngOnInit() {
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"),JSession)
    .subscribe(data => {
     this.roleCheck=data.users.user.roles.role.id;
     if(this.roleCheck==6 || this.roleCheck==22 || this.roleCheck==50){//User is either SBV or Bank admin
        console.log("User is admin, role = "+this.roleCheck)
     }
     else{//User is nt an admin
      console.log("User is not an admin, role = "+this.roleCheck)
      this.router.navigate(['/forbidden-access']);
     }

     if (this.roleCheck===22) {
      this.dropdownListGroups = [{id: 2, name: "Bank User", description: ""}]
    }
    

    },
    error => {

      if (error.status==0){
        this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
        this.hideErrorBanner=false;
      }

      if (error.status==500){
        this.errorMessage="ERROR 500 OCCURRED";
        this.hideErrorBanner=false;
      }
      if (error.status==408 || error.status==504){
        this.errorMessage="ERROR 408/504 OCCURRED";
        this.hideErrorBanner=false;
      }

        console.log(error)
    });

    this.settings = {
      singleSelection: true,
      text: "Select Group",
      enableSearchFilter: false,
      classes: "myclass custom-class"
    };

    this.dropdownSettingsGroups = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      clearable: "false",
      closeDropDownOnSelection: "true",
    };
    this.dropdownSettingsRoles = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      clearable: "true",
      closeDropDownOnSelection: "true",
      itemsShowLimit: 1,
      enableCheckAll: false,
      noDataAvailablePlaceholderText: "Please select group first",
    };

    //1 - get groups
    this.accountService.getGroups()
      .subscribe(data => {

        // if (this.roleCheck===22) {
        //   this.dropdownListGroups = [{id: 2, name: "Bank User", description: ""}]
        // } else {
        //   this.dropdownListGroups = data.groups.group;
        // }
        // this.dropdownListGroups = data.groups.group;



      },
        error => {

           // if (error.status==0){
          //   this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
          //   this.hideErrorBanner=false;
          // }

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          this.alertService.error(error.error.error.message);
        }
      );



    // console.log("req: ---- "+JSON.stringify(req));
    if (localStorage.getItem('step2') == 'false') {
      this.form = this.formBuilder.group({
        jobTitle: ['', Validators.required],
        department: ['', Validators.required],
        lineManagerName: [''],
        lineManagerContact: [''],
        role: [[]]
      });
    } else { //user has pressed back
      let req = JSON.parse(localStorage.getItem('requestDetails'));
      console.log("req  ----- " + JSON.stringify(req));


      console.log("localStorage.getItem('groupObj') "+localStorage.getItem('groupObj'))
      console.log("localStorage.getItem('roleObj') "+localStorage.getItem('roleObj'));
      this.form = this.formBuilder.group({
        jobTitle: [req.user.contact.jobTitle, Validators.required],
        department: [req.user.contact.department, Validators.required],
        lineManagerName: [req.user.contact.lineManagerName],
        lineManagerContact: [req.user.contact.lineManagerContactNo],
        group: [JSON.parse('[{"id":' + JSON.parse(localStorage.getItem('groupObj')).id + ',"name":"' + JSON.parse(localStorage.getItem('groupObj')).name + '"}]')],
        role: [JSON.parse('[{"id":' + JSON.parse(localStorage.getItem('roleObj')).id + ',"name":"' + JSON.parse(localStorage.getItem('roleObj')).name + '"}]')],
        });
        this.selectedGroup = JSON.parse(localStorage.getItem('groupObj')).id;
        this.selectedRole = JSON.parse(localStorage.getItem('roleObj')).id;
    }


    // get return url from route parameters or default to '/'
    this.returnUrlCC = this.route.snapshot.queryParams['returnUrl'] || 'new-user-access';
    this.returnUrlSwitchProfile = this.route.snapshot.queryParams['returnUrl'] || 'new-user-switch-profile';
    this.returnUrlComplete = this.route.snapshot.queryParams['returnUrl'] || 'new-user-merchant';
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.selectedGroup == 0) {
      this.groupChosen = false;
    } else {
      this.groupChosen = true;
    }
    if (this.selectedRole == 0) {
      this.roleChosen = false;
    } else {
      this.roleChosen = true;
    }
    if (this.form.invalid) {
      return;
    }

    this.form.disable();
    this.loading = true;

    //build the body
    this.requestAccessObj = new RequestAccess;
    let requestDetails = new AccessRequestUser;  //USER section
    let contact = new Contact;
    let profile = new Profile;  // will have cash centres added on next screen if necessary. added empty for now.

    let usr: User = JSON.parse(localStorage.getItem('newUserDetails'));


    contact.firstName = usr.firstName;
    contact.lastName = usr.lastName;
    contact.contactNo = usr.contactNo;
    requestDetails.email = usr.email;

    // requestDetails.email = localStorage.getItem('newUserEmail');
    contact.jobTitle = this.f.jobTitle.value;
    contact.department = this.f.department.value;
    contact.lineManagerName = this.f.lineManagerName.value;
    contact.lineManagerContactNo = this.f.lineManagerContact.value;
    contact.organization = localStorage.getItem('newUserOrg');
    contact.location = "";
    requestDetails.contact = contact;
    requestDetails.profile = profile;
    console.log("ORG: " + contact.organization)

    //for each role id, put it in a role object and add to array
    let roles: Roles[] = [];
    //ARRAY IS FOR IN CASE IN FUTURE
    // this.selectedRoles.forEach((role) => {

    let groupObject = this.dropdownListGroups.find(({ id }) => id === this.selectedGroup);
    let roleObject = this.dropdownListRoles.find(({ id }) => id === this.selectedRole);
    localStorage.setItem('groupObj', JSON.stringify(groupObject));
    localStorage.setItem('roleObj', JSON.stringify(roleObject));
    // console.log(JSON.stringify(findRole));
    let rs = new Roles;
    let r = new Role;
    r.id = this.selectedRole;
    rs.role = r;
    roles.push(rs);
    // roles.push(r);
    // });

    requestDetails.roles = roles;
    // requestDetails.roles = r;

    // let role = new Role;
    // role.id = 
    // this.requestDetails.roles = 
    this.requestAccessObj.user = requestDetails;

    localStorage.setItem('group', (this.selectedGroup).toString());
    localStorage.setItem('new-user-role',(this.selectedRole).toString());

    if (this.selectedRole == 0 || this.selectedGroup == 0) { //if group is selected but not role the error alert and do nothing
      // console.log("HERE");
      // console.log(this.selectedGroup);
      // console.log(this.selectedRole);
      this.alertService.error("No role selected");
    } else if (this.selectedGroup == 3) { //if merchant group then finish
      localStorage.setItem('requestDetails', JSON.stringify(this.requestAccessObj));
      // console.log("Group: Merchant")
      // this.requestAccess();
      localStorage.setItem('step2', 'true');
      this.router.navigate([this.returnUrlComplete]);
    } else if (this.selectedGroup == 2 && (this.roleCheck === 6 || this.roleCheck === 50)) {

      localStorage.setItem('requestDetails', JSON.stringify(this.requestAccessObj));
      this.router.navigate([this.returnUrlSwitchProfile]);
    }
   else if (this.selectedGroup == 2 && this.roleCheck === 22) {
    localStorage.setItem('requestDetails', JSON.stringify(this.requestAccessObj));
    localStorage.setItem('step2', 'true');
    this.router.navigate([this.returnUrlCC]);
  }
    else if (this.selectedGroup == 1) { //if not merchant group then go to cash centre page
      // console.log("---NOT--- Merchant")
      // console.log(this.selectedGroup);
      localStorage.setItem('requestDetails', JSON.stringify(this.requestAccessObj));
      // console.log((this.requestAccessObj));
      localStorage.setItem('step2', 'true');
      this.router.navigate([this.returnUrlCC]);
    }
    this.loading = false;
    this.form.enable();

  }


  requestAccess() {

    //1 - user details:
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.addNewUser(JSON.parse(localStorage.getItem('newUserDetails')), JSession)//, this.ipaddress)
      .subscribe(data => {

        
        //2 - business details:
        let JSession=this.cookieService.get("JSESSIONID");
        this.accountService.requestAccessAdmin(this.requestAccessObj, JSession)
          .subscribe(data => {
            // this.loading = false;
            this.router.navigate([this.returnUrlComplete]);
            localStorage.setItem('password-set', '0');
          },
            error => {
              if (error.status == 0) {
                this.alertService.error('Something went wrong. Please contact technical support.')
              }
              else {
                this.alertService.error(error.error.error.message);
              }
              this.form.enable();
              // this.loading = false;
            });

      },
        error => {

           // if (error.status==0){
          //   this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
          //   this.hideErrorBanner=false;
          // }
          
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          // console.log(error);
          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          this.form.enable();
          this.loading = false;
        });

  }
}
