<!-- <div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: whitesmoke; margin-top: 200px;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div> -->

<div class="container-fluid" style="padding: 25px">
    <!-- <div class="ps-4 pe-4 pt-4 container-fluid "> -->
        <div>
            <h6 style="margin-bottom: -20px">ORGANISATION</h6>
            <h2>Add a New User</h2>
            <h5>Manually register an account on behalf of a user.</h5>
        </div>
        <hr class="mt-0">

        <div class="d-flex flex-column align-items-center">
            <div class="row d-flex">
                <div class="p-2">
                    <ul class="nav nav-pills nav-underline">
                        <li><a class="pill-no-href">User Details</a></li>
                        <li><a class="pill-no-href">User Account Details</a></li>
                        <li class="active"><a class="pill-no-href">Access Requirements</a></li>
                    </ul>
                </div>
            </div>

            <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">
                <div class="d-flex justify-content-between align-items-end">
                    <h3 class="h3-grey">User Setup</h3>
                    <p class="purple">Step 3/3</p>
                </div>
                <hr class="mt-0">
                <alert></alert>
                <form [formGroup]="form">


                    <div class="form-group" [hidden]="hideComponents">
                        <label *ngIf="group==2" class="control-label" for="customer">Customer</label>
                        <ng-multiselect-dropdown *ngIf="group==2" formControlName="banks"
                            style="font-size: 12px; border-radius: 50px;" [placeholder]="'Banks'"
                            [settings]="dropdownSettingsBanks" [data]="dropdownListBanks"
                            (onSelect)="onBanksItemSelect($event)" class="singleselect">
                        </ng-multiselect-dropdown>
                        <div *ngIf="!customerChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!customerChosen">Customer is required</div>
                        </div>
                    </div>

                    <div class="form-group" [hidden]="hideMultiBank">
                        <label *ngIf="group==2 || isMultiBank" class="control-label" for="customer">{{userDropdownHeading}}</label>
                        <ng-multiselect-dropdown *ngIf="group==2|| isMultiBank" formControlName="linkedBanks"
                            style="font-size: 12px; border-radius: 50px;" [placeholder]="'Banks'"
                            [settings]="dropdownSettingsMultiBank" [data]="dropdownListBanks"
                            (onSelect)="onItemSelectMultiBank($event)" (onSelectAll)="onSelectAllMultiBanks($event)"
                            (onDeSelect)="onItemDeSelectMultiBank($event)" (onDeSelectAll)="onItemDeSelectAllMultiBank($event)">
                        </ng-multiselect-dropdown>
                        <div *ngIf="!banksChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!banksChosen">Banks are required</div>
                        </div>
                    </div>

                    <!-- <div class="form-group" [hidden]="hideMerchBox">
                        <div class="checkbox">
                            <label> <input type="checkbox" class="form-check-input" id="allowMerchant"
                                    (change)="merchantSelected()">
                                <span style="vertical-align: sub">Merchants</span> </label>
                        </div>
                    </div> -->


                    <div class="form-gorup mt-1" [hidden]="hideLevel">
                        <label class="control-label" for="locType">Site Type</label><span *ngIf="showRegions"
                            style="color:#ff0000">
                            *</span>
                        <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;"
                            [placeholder]="'Select Site Type'" [settings]="dropdownSettingsLocs"
                            [data]="dropdownListLocs" (onSelect)="onItemSelectLoc($event)" formControlName="loc"
                            class="singleselect" clearable="true">
                        </ng-multiselect-dropdown>
                        <div *ngIf="!siteChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!siteChosen">Site Type is required</div>
                        </div>
                    </div>

                    <div class="form-group mt-1" [hidden]="hideLevel">
                        <label *ngIf="showRegions" class="control-label" for="site">Region</label><span
                            *ngIf="showRegions" style="color:#ff0000">
                            *</span>
                        <ng-multiselect-dropdown *ngIf="showRegions" style="font-size: 12px; border-radius: 50px;"
                            [placeholder]="'Regions'" [settings]="dropdownSettingsRegions" [data]="dropdownListRegions"
                            (onSelect)="onRegionsItemSelect($event)" class="singleselect">
                        </ng-multiselect-dropdown>
                        <div *ngIf="!regionChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!regionChosen">Region is required</div>
                        </div>
                    </div>

                    <div class="form-group" style="margin-top: -10px;" [hidden]="hideLevel">
                        <label *ngIf="showCCList" class="control-label" for="ccs">Cash Centre</label><span
                            *ngIf="showCCList" style="color:#ff0000">
                            *</span>
                        <ng-multiselect-dropdown *ngIf="showCCList" style="font-size: 12px; border-radius: 50px;"
                            [placeholder]="'Cash centres'" [settings]="dropdownSettingsSingleCc" [data]="dropdownList"
                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" class="singleselect"
                            (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                        <div *ngIf="!ccChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!ccChosen">Cash Centre is required</div>
                        </div>
                    </div>
                    <!-- </div> -->


                    <!-- <h5 class="mt-5">Cash Centres</h5> -->
                    <!-- <p class="mt-5">Select the cash centres that you work with:</p> -->
                    <!-- <div class="form-group" style="margin-top: -10px;"> -->
                    <!-- <label *ngIf="showCCList" class="control-label" for="ccs">Cash Centres</label><span
                            *ngIf="showCCList" style="color:#ff0000">
                            *</span> -->
                    <div class="form-group" style="margin-top: -10px;" [hidden]="hideComponents">
                        <label class="control-label mt-5" for="ccs">Cash Centres</label>
                        <p>Select the cash centres that you work with:</p>
                        <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;"
                            [placeholder]="'Cash centres'" [settings]="dropdownSettingsMulti" [data]="dropdownListMulti"
                            (onSelect)="onItemSelectMulti($event)" (onSelectAll)="onSelectAllMulti($event)"
                            (onDeSelect)="onItemDeSelectMulti($event)" (onDeSelectAll)="onItemDeSelectAllMulti($event)">
                        </ng-multiselect-dropdown>
                        <div *ngIf="!ccMultiChosen" style="font-size: 80%; color: #dc3545">
                            <div *ngIf="!ccMultiChosen">Cash Centre is required</div>
                        </div>
                    </div>
                    <!-- </div> -->

                    <div class="form-group">
                        <div class="d-flex justify-content-between" style="padding-top: 1em;">
                            <button class="btn btn-default" routerLink="../new-user-role">
                                Back
                            </button>
                            <button [disabled]="loading" class="btn btn-primary" (click)="onSubmit()">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Register New User
                            </button>
                        </div>
                        <!-- <button [disabled]="loading" class="rounded btn btn-primary w-100 ">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Verify Email
                        </button> -->
                    </div>
                </form>
            </div>
        </div>

    </div>

<!-- </div> -->