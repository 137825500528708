<script>

    const body = document.querySelector("body");

const dataRow = document.querySelectorAll("tbody tr");



let selectedRows = [];



function unselectAll() {

  selectedRows = [];

  dataRow.forEach( row => {

      row.classList.remove("activeRow")

  })

}



dataRow.forEach( row => {

  row.addEventListener("click", () => {

    if (event.ctrlKey) {

      selectedRows.push(row.dataset.id)

      row.classList.toggle("activeRow")

      console.log(row)

    } else {

      unselectAll();

      selectedRows.push(row.dataset.id)

      row.classList.toggle("activeRow")

    }

      console.log(selectedRows)

  })

})

</script>

<div class="container-fluid" style="padding: 25px">

    <!-- <div class="ps-4 pe-4 pt-4 container-fluid "> -->

        <div>

            <h6 style="margin-bottom: -20px">ORGANISATION</h6>

            <h2>Edit Merchant Group </h2>

            <h5>Add/Edit Merchant Group</h5>

        </div>

        <hr class="mt-0">



        <div class="d-flex flex-column min-vh-100 align-items-center">

           





            <div class="card card-blue-shadow w-50 border-0 p-5 " style="border-radius: 10px;">

                <div class="d-flex justify-content-between align-items-end">

                    <h3 class="h3-grey">Merchant Details</h3>

                    <p class="purple"></p>

                </div>

                <hr class="mt-0">

               

                <form action="/action_page.php">

                    <!-- <label class="control-label" for="name">Name Of Selected Merchant</label><span style="color:#ff0000"> </span>&nbsp;&nbsp;&nbsp;&nbsp; -->
                    <h4 class="h3-grey">Merchant Group Name</h4>
                    <br>
                    <br>
                    <!-- <input type="text" id="Name" name="Name" value="Enter Merchant"><br><br> -->

                    <label for="Client">Client Profile:</label> &nbsp;&nbsp;

                    <input type="text" placeholder="Search.." name="Client">

                    <button type="submit"><i class="fa fa-search"></i></button> <br>

                    <table class="table table-bordered table-striped" style="border: none;">

                       

                            <tr>

                                <td class="title-column">Id</td>

                                <td class="title-column">Name</td>

                                <td class="title-column">Client</td>

                                <td class="title-column">Account Number</td>

                                <td class="title-column">CIT Code</td>

                            </tr>



                            <tr>

                                <td >01</td>

                                <td >Name example 1</td>

                                <td >ABSA</td>

                                <td >897654</td>

                                <td >CIT Code</td>

                            </tr>

                            <tr>

                                <td >02</td>

                                <td >Name example 1</td>

                                <td >ABSA</td>

                                <td >197654</td>

                                <td >CIT Code</td>

                            </tr>

                          

                          

                       </table>

                      

                       



                    <button class="btn btn-primary" style = "text-align: justify;"> Add </button>  &nbsp;&nbsp; &nbsp;&nbsp;

                    <button class="btn btn-primary" style = "text-align: justify;"> Edit </button> &nbsp;&nbsp; &nbsp;&nbsp;

                    <button class="btn btn-primary" style = "text-align: justify;" > Remove </button> <br><br>

                    

                    <h5>Clients added to Group</h5>

 



                    <table class="table table-bordered table-striped" style="border: none;">

                       

                        <tr>

                            <td class="title-column">Id</td>

                            <td class="title-column">Name</td>

                            <td class="title-column">Client</td>

                            <td class="title-column">Account Number</td>

                            <td class="title-column">CIT Code</td>

                        </tr>



                        <tr>

                            <td >04</td>

                            <td >Name example 2</td>

                            <td >FNB</td>

                            <td >78844</td>

                            <td >CIT Code</td>

                        </tr>

                        <tr>

                            <td >05</td>

                            <td >Name example 2</td>

                            <td >FNB</td>

                            <td >755654</td>

                            <td >CIT Code</td>

                        </tr>

                      

                      

                   </table>





                   <button class="btn btn-primary" style = "text-align: justify;"> Save </button>  &nbsp;&nbsp; &nbsp;&nbsp;

                   <button class="btn btn-primary" style = "text-align: justify;"> Cancel </button> &nbsp;&nbsp; &nbsp;&nbsp;

                   <br>
                   <br>

                   <button class="btn btn-default" (click)="return()">
                    Back
                   </button>

                  </form> 

    

               

            </div>

        </div>



    </div>



<!-- </div> -->
