<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius:10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <div class="d-flex justify-content-between align-items-end">
                    <h3>Register</h3>
                    <p class="purple">Step 1/2</p>
                </div>
                <hr class="mt-0">
                <h4>Your Details</h4>
                <p>Enter your account details below</p>
                <hr class="mt-0">
                <alert></alert>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="control-label" for="name">Name</label><span style="color:#ff0000"> *</span>
                        <input type="text" formControlName="firstName" class="form-control " placeholder="Name"
                            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">First name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="surname">Surname</label><span style="color:#ff0000"> *</span>
                        <input type="text" formControlName="lastName" class="form-control " placeholder="Surname"
                            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                            <div *ngIf="f.lastName.errors.required">Last name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="email">Email</label><span style="color:#ff0000"> *</span>
                        <input type="text" formControlName="email" class="form-control " placeholder="Email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                        </div>
                        <div class="invalid-feedback" *ngIf="f.email.errors?.pattern">
                            Invalid email format
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="contactNo">Contact Number</label><span style="color:#ff0000">
                            *</span>
                        <input type="text" mask='(000) 000-0000' formControlName="contactNo" class="form-control "
                            placeholder="Contact number"
                            [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }" />
                        <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
                            <div *ngIf="f.contactNo.errors.required">Contact number is required</div>
                        </div>
                    </div>
                    <!-- <div class="form-group">
                <label class="control-label" for="organisation">Organisation</label>
                <input type="text" formControlName="organisation" class="form-control " placeholder="Organisation"
                    [ngClass]="{ 'is-invalid': submitted && f.organisation.errors }" />
                <div *ngIf="submitted && f.contorganisationctNo.errors" class="invalid-feedback">
                    <div *ngIf="f.organisation.errors.required">Contact number is required</div>
                </div>
            </div> -->

                    <div class="p-3 checkbox">
                        <label> <input [ngClass]="{ 'is-invalid': submitted && f.tandc.errors }" type="checkbox"
                                formControlName="tandc" id="tandc" class="ml-2 form-check-input"
                                class="form-check-input">
                            <span style="vertical-align: sub">Accept</span> </label>
                        <div *ngIf="submitted && f.tandc.errors" class="invalid-feedback">You must agree to all the terms and conditions before you can proceed.
                        </div>
                        <a class="p-2 linktc" style="font-size: 12px;" (click)="openModal(template)"><span
                                style="vertical-align: sub">Terms and
                                Conditions</span></a>
                        <div *ngIf="submitted && f.tandc.errors" style="color: red; font-size: 12px">You must agree to all the terms and conditions before you can proceed.</div>
                    </div>



                    <div class="w-100">
                        <div class="form-group">
                            <button [disabled]="loading" class="rounded btn btn-primary w-100 ">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Verify Email
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="d-flex justify-content-between ms-5 me-5 p-3 mb-4">
                <a class="p-2 black-link" routerLink="../login">Back</a>
                <!-- <a class="p-2 black-link" routerLink="../request-access">bypass</a> -->
                <a class="p-2 blue-link" routerLink="../help">Need help?</a>
            </div>
        </div>
    </div>
</div>
<!-- <button type="button" class="btn btn-primary" (click)="openModal(template)">Create template modal</button> -->

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Terms and Conditions of SBV Connect customer portal</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <ol class = "custom">
            
                <li><b>Introduction</b>
          

            <ol>
                <li>
                    These Terms and Conditions (Terms) form an agreement between 
                    the User and SBV Services (Pty) Ltd (SBV). The User agrees to 
                    only use the SBV Connect Portal in line with the Terms. The SBV 
                    Connect Portal is provided by SBV to enable the User to self-serve 
                    and access relevant services, information, and insights 
                </li>
            </ol>
        </li>
         
                <li><b>Definitions</b>
          
            
            <ol>
                <li>"Access Codes" – Any of the User’s secret numbers used to access 
                    the SBV Connect Portal, including the User’s personal 
                    identification number (PIN), one-time passcodes (OTP), passwords 
                    and usernames.
                </li>
                <li>"Account" – Any User account opened upon the Participating 
                    Customer’s authorisation for the nominated User to access the 
                    SBV Connect Portal on their behalf and for which the User is 
                    responsible.
                </li>
                <li>"SBV Connect Portal" - A digital platform, sharing mechanism 
                    and information centre to facilitate query logging and 
                    management, and other information for the Participating Banks 
                    and Merchants.
                </li>
                <li>"Device" – The device you use to access the SBV Connect Portal 
                    such as laptop, desktop, or any similar technology.
                </li>
                <li>"ISP" – An internet service provider, which is an organisation 
                    independent of SBV that provides access to the internet.
                </li>
                <li>"Participating Customer" – Any customer institution within the 
                    geographical areas that SBV operates in who is a customer of SBV 
                    and who has nominated employee Users to access the SBV 
                    Connect Portal on their behalf.
                </li>
                <li>"Personal Information" – Information about an identifiable, natural 
                    or juristic person, including but not limited to, information about 
                    race, gender, marital status, nationality, ethnic or social origin, 
                    sexual orientation, age, physical or mental health, religion, belief, 
                    disability, language, birth, education, identity number, telephone 
                    number, email, postal or street address, biometric information and 
                    financial, criminal or employment history as well as 
                    correspondence sent by the person that is implicitly or explicitly of 
                    a private or confidential nature or further correspondence that 
                    would reveal the contents of the original correspondence.
                </li>
                <li>"POPI Act" – Protection of Personal Information Act No. 4 of 
                    2013 including all associated regulations, directives, and orders.
                </li>
                <li>"Process" – Any operation or activity, whether automated or not, 
                    concerning Personal Information, including collection; receipt; 
                    recording; organisation; collation; storage; updating or 
                    modification; retrieval; alteration; consultation; use; dissemination 
                    by means of transmission, distribution or making available in any 
                    other form; merging, linking as well as blocking, degradation, 
                    erasure, or destruction of information. Processing will have a 
                    similar meaning.
                </li>
                <li> "Transaction" – Any debit or credit on the User’s Account 
                    including payment for goods or services, deposits, inter-account 
                    transfers, pre-paid purchases, withdrawals or use of any banking 
                    facilities available.
                </li>
                <li>"User" – Any person nominated by the Participating Customer to access the SBV Connect Portal on their 
                    behalf for the purpose of logging and tracking the progress of queries, or accessing information and 
                    insights</li>
                <li>"You" or "Your" – The User who registered to access the SBV 
                    Connect Portal.
                </li>
            </ol>
        </li>
            
                <li><b>Registration for the SBV Customer Portal</b>
            


            <ol>
                <li>
                    You must register to access the SBV Connect Portal by following 
                    the prompts displayed.
                </li>
                <li>
                    Registration will create your digital identity as a User of the SBV 
                    Connect Portal.
                </li>
            </ol>
        </li>

          
                <li> <b>Use of SBV Connect Portal</b>
        
            <ol>
                <li>
                    SBV will not be responsible for any losses suffered by you for 
                    entering the incorrect details of the Transaction when logging or 
                    tracking the progress of any queries.
                </li>
            </ol>
           </li>
        
                <li> <b>User Warranties</b>
           
            <ol>
                <li>
                    You represent and warrant that:
                <ol>
                    <li>you have full contractual capacity, and no court has declared you mentally unfit.</li>
                    <li>you have provided SBV with all the correct information.</li>
                    <li>you have read and understand the Terms before entering into this Agreement.</li>
                </ol>
              </li>
            </ol>
           </li>
           
                <li> <b>SBV Connect Portal Security </b>
         
            <ol>
                <li>
                    You must always look after your Access Codes and keep them 
                    secret and safe to prevent other persons from using them. If you do 
                    not, you give up any claim you have against SBV for any loss or 
                    damage you may suffer because you have not kept them safe.
                </li>
                <li>
                    If your Device is lost or stolen, or is no longer in your possession, 
                    SBV needs to be immediately informed to prevent unauthorized 
                    access to the SBV Connect Portal via the Device. SBV will not be 
                    held liable for any losses resulting from unauthorized access to the 
                    SBV Connect Portal.
                </li>
            </ol>

        </li>
          
                <li> <b> Minimum Requirements for the Use of the SBV Connect Portal</b>
           
            <ol>
                <li>You must use software and hardware suitable for the SBV Connect 
                    Portal. The suitable browsers for use of the Connect Portal are 
                    Microsoft Edge, Google Chrome, and Mozilla Firefox. Please note 
                    that Microsoft Explorer is not supported.
                </li>
                
                <li>The SBV Connect Portal is optimised for a minimum screen 
                    resolution of 1024 x 768. In the unlikely event that your Device’s 
                    resolution is set below this, you may experience layout and 
                    navigation issues when using the SBV Connect Portal. The most 
                    suitable screen resolution for use of the SBV Connect Portal is 
                    2560 x 1600 pixels.
                </li>

                <li>The current version of the SBV Connect Portal is optimised for 
                    laptop and desktop devices. You may experience resolution, 
                    layout, and navigation issues on other devices.
                </li>

                <li> You must have a valid and active work email address in order to 
                    use the SBV Connect Portal. Personal email addresses will not be 
                    permitted as user identification.
                </li>
            </ol>
        </li>
           
                <li> <b> Disclaimers</b>
           
            <ol>
                <li>Your use of the SBV Connect Portal is dependent on factors 
                    beyond SBV’s control, such as your network’s coverage or 
                    availability, your ISP’s availability or your Device’s capability and 
                    capacity. SBV are not liable for any loss or damages you may 
                    suffer if a factor beyond SBV’s control arises, and you cannot 
                    access the SBV Connect Portal.
                </li>
            </ol>
        </li>
         
                <li> <b>Data Protection</b>
           
            <ol>
                <li>SBV may, and you expressly consent to, the collecting and 
                    Processing of your Personal Information by SBV to enable the 
                    opening, administration and operation of your Account and 
                    provide you with the use of the SBV Connect Portal.
                </li>
            </ol>
        </li>
        </ol>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modalRef.hide()">
            OK
        </button>
    </div>
</ng-template>




<!-- <ng-template #content let-modal>
<div class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            &times;
          </button>
          <h4 class="modal-title">Modal title</h4>
        </div>
        <div class="modal-body">
          <p>One fine body…</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->