<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid">

                <div class="d-flex justify-content-between align-items-end">
                    <h3>Password Reset</h3>
                    <p class="purple">Forgot Password</p>
                </div>
                <hr class="mt-0">
                <h5>Forgot your password?</h5>
                <p>Don't worry, this happens to the best of us. </p>
                <p>Enter your email below and we'll send you an email to
                    reset your password</p>
                <hr>
                <alert></alert>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="control-label" for="email">Email</label>
                        <input type="text" class="form-control" id="inputDefault" formControlName="email"
                            data-toggle="email" placeholder="Email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" data-toggle="email">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors?.required">Valid email is required</div>
                        </div>
                        <div class="invalid-feedback" *ngIf="f.email.errors?.pattern">
                            Invalid email format
                        </div>
                    </div>




                    <div class="w-100">
                        <div class="form-group">
                            <button [disabled]="loading" class="rounded btn btn-primary w-100 ">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Reset Password
                            </button>
                        </div>

                    </div>
                </form>
            </div>
            <div class="d-flex justify-content-between  ms-5 me-5 p-3 mb-4">
                <a class="p-2 black-link" routerLink="../login">Back to login</a>
                <a class="p-2 blue-link" routerLink="../help">Need help?</a>
            </div>
        </div>
    </div>
</div>