<!--
Help centre  page and contactu us page html code
--> =

<div class="container-fluid" style="text-align:left; background: #f6fafd">

    <h6>SBV CONNECT</h6>
    <h1 style="margin-top:0px;">Help Centre</h1>
    <h5>Your online guide to SBV Connect</h5>
    <hr/>
    
</div>

<nav aria-label="breadcrumb" style =" margin: 10px ">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">Help Centre</li>
    </ol>
  </nav>

<div class="box">

    <div class="c-m" >
        <h3>Getting Started</h3>
        <hr/>
        <img class="imageBox" src="assets/img/ic_twotone-new-releases.png" alt="CASES IMAGE">
        <b class = "text">Everything you need to get started and work in SBV Connect</b>
        <div></div>
        <button class="rb r" routerLink="/getting-started" >Getting Started</button>


    </div>


    <div class="c-m" >
        <h3>Case Management Guide</h3>
        <hr/>
        <img class="imageBox" src="assets/img/ic_twotone-cases-hc.png" alt="CASES IMAGE">
        <b class = "text">Everything you need to know to use Case Management</b>
        <div></div>
        <button class="rb r" routerLink="/help-case-management-guide" >View Guide</button>


    </div>

    <div class="c-m" >
        <h3>Contact Us</h3>
        <hr/>
        <img class="imageBox" src="assets/img/ic_twotone-local-post-office.png" alt="CASES IMAGE">
        <b class = "text">Contact our Support Team and we'll do our best to assist</b>
        <button class="cmb cm" routerLink="/contact-us" >Contact Us</button>

    </div>

</div>