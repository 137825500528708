import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-merchant-management-existing',
  templateUrl: './merchant-management-existing.component.html',
  styleUrls: ['./merchant-management-existing.component.scss']
})
export class MerchantManagementExistingComponent implements OnInit {

  merchantName:string="Merchant Name";
  organisation:string="Organisation";
  dtOptions: DataTables.Settings = {};
  fullTable: boolean = false;
  rowId;
  userStatus:number=0;
  form: FormGroup;
  status:string="Active";
  hideCard:boolean=false;

  rowSelected: boolean = false;

  nameFocusVar: boolean=true;
  codeFocusVar: boolean=true;
  accFocusVar: boolean=true;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) { }

  editDetails(){

  }
  showDetails(){

    this.hideCard=false;
    this.fullTable = false;
    let name="ABSA";
    let code="AB889";
    let accNo="897987546846";

    this.form = this.formBuilder.group({
      name: [name],
      code: [[code]],
      accNo: [[accNo]],
      status: [[this.status]],
      
    });

    let table = $('#userTable').DataTable();
    table.column(3).visible(false);
    table.column(4).visible(false);
    table.column(5).visible(false);
  }

  hideDetails(){
    this.hideCard=true;
    this.fullTable = true;
    let table = $('#userTable').DataTable();
    table.column(3).visible(true);
    table.column(4).visible(true);
    table.column(5).visible(true);
  }

  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit(): void {

    this.hideDetails();
    localStorage.removeItem('selectedMerchId');

    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: '<"table-wrapper"flr>tip',
      "ordering": true,
      order: [3],
      language: {
        paginate: {
          next: '&#8250;', // or '→'
          previous: '&#8249;', // or '←'
          first: '&#171;',
          last: '&#187;'
        }
      },
      columns: [
        { data: 'name' },//0
        { data: 'code' },//1
        { data: 'acc_no'}, //2
        { data: 'cc'}, //3
        { data: 'updated' },//4
        { data: 'created' },//5
        { data: 'status' }//6
      ],
      "columnDefs": [
        {
          "targets": [4, 5],
          "render": function (data, type, row) {
            var dateSplit = data.split('-');
            return data;
          }
        },
        {
          "targets": 6,
          "render": function (data, type, row) {
            if (row.status === 0) {
              return '<span class="label label-danger">Disabled</span>';
            }
            if (row.status === 3) {
              return '<span class="label label-success">Active</span>';
            }
            return data;
          }
        },
      ]
    };

    $('#userTable').on('click', 'tr', function () {
      this.selectedUser = table.row(this).data().id;
      if (this.selectedUser != localStorage.getItem('selectedMerchId')) {
        localStorage.removeItem('selectedMerchId');
        localStorage.setItem('selectedMerchId', this.selectedUser);
      }
    });

    let table;


    $('#userTable ').on('click', 'tr', () => {
      var idx = table.row(this).index();
      if (localStorage.getItem('rowId') == idx) {
        this.hideDetails();
      }
      else {
        this.rowSelected=true;
        this.showDetails();
      }
    });

    $('#userTable ').on('click', 'tr', function () {
      if ($(this).hasClass('selected')) {
        $(this).removeClass('selected');
      }
      else {
        table.$('tr.selected').removeClass('selected');
        $(this).addClass('selected');
      }
      localStorage.setItem('rowId', table.row(this).index());
    });

  }

}
