<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="width: 400px; border-radius: 10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <div class="d-flex justify-content-between align-items-end">
                    <h3>Register</h3>
                    <p class="purple">Verify Email</p>
                </div>
                <hr class="mt-0">
                <img src="assets/img/email-sent.png" height="80px">
                <h5 class="p-green">Verification email sent!</h5>
                <p>An email has been sent to your inbox for you to verify your identity. Click on the link to complete
                    the
                    registration process.</p>
                <p><small>If you haven't received an email, please check your spam folder.</small></p>



                <div class="w-100 mt-4">
                    <button class="rounded btn btn-primary w-100 " [disabled]="resendNotAllowed"
                        (click)="resendEmail()">
                        <!-- <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> -->
                        Resend Verification Email

                        <countdown *ngIf="resendNotAllowed" [config]="{leftTime: 60}"></countdown>

                    </button>
                </div>
            </div>
            <div class="d-flex justify-content-between ms-5 me-5 p-3 mb-4">
                <a class="p-2 black-link" routerLink="../login">Back</a>
                <a class="p-2 blue-link" routerLink="../help">Need help?</a>
            </div>
        </div>

    </div>
</div>