import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';  

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeDashboardFunction = new EventEmitter();    
  dashSubsVar: Subscription; 

  invokePDashboardFunction = new EventEmitter();    
  pDashSubsVar: Subscription;

  invokeTaskListFunction = new EventEmitter();    
  TLSubsVar: Subscription;

  invokeCaseListFunction = new EventEmitter();    
  CLSubsVar: Subscription;

  invokeLogCaseFunction = new EventEmitter();    
  LCSubsVar: Subscription; 

  invokeLogCaseIncidentFunction = new EventEmitter();    
  LCISubsVar: Subscription;

  invokeReportFunction = new EventEmitter();    
  reportSubsVar: Subscription;

  invokeBankViewFunction = new EventEmitter();    
  bankViewSubsVar: Subscription;

  invokeBillingFunction = new EventEmitter();    
  billingViewSubsVar: Subscription;

  invokeIncidentFunction = new EventEmitter();    
  incidentViewSubsVar: Subscription;


  constructor() { }

  onDashboardButtonClick() {    
    this.invokeDashboardFunction.emit();    
  }

  onBillinbgButtonClick() {    
    this.invokeBillingFunction.emit();    
  }

  onPDashboardButtonClick() {    
    this.invokePDashboardFunction.emit();    
  }

  onTaskListButtonClick(){
    this.invokeTaskListFunction.emit();
  }

  onCaseListButtonClick(){
    this.invokeCaseListFunction.emit();
  }

  onLogCaseButtonClick(){
    this.invokeLogCaseFunction.emit();
  }

  onLogCaseIncidentButtonClick(){
    this.invokeLogCaseIncidentFunction.emit();
  }

  onReportButtonClick(){
    this.invokeReportFunction.emit();
  }

  onbankViewButtonClick(){
    this.invokeBankViewFunction.emit();
  }

  onIncidentButtonClick(){
    this.invokeIncidentFunction.emit();
  }
}
