<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="container-fluid" style="padding: 25px">

    <div>
        <h2>Manage Organisation</h2>
        <!-- <h5>Manage all SBV Connect users</h5> -->
    </div>
    
    <div>
        <h4>Add, edit or manage the sites within an organisation</h4>
        <!-- <h5>Manage all SBV Connect users</h5> -->
    </div>

    <hr class="mt-0">

    <div class="row d-flex ml-1">
        <div class="p-2">
            <ul class="nav nav-pills nav-underline">
                <li><a href="/merchant-management">Organisation</a></li>
                <li class="active"><a href="/all-sites">Sites</a></li>
            </ul>
        </div>
        <button class="rounded btn btn-primary ml-auto p-3 h-50 border-0" id="AddUser"style="width: 105px"
            routerLink="/add-site">
            <div class="row ml-2">
                <span> Add Site </span>
                <div class="circle"> + </div>
            </div>
        </button>
    </div>

    <div class="card card-blue-shadow" >

        <div class="row" style="border-radius: 14px;">

            <div class="col" style="border-radius: 14px;" [ngClass]="{'table-no-details' : fullTable, 'table-user-details': !fullTable}">

                <div class="card-header d-flex  user-management-card" style="border-radius: 14px;">
                    <h4 class="p-2">Sites</h4>   
                </div>

                <div class="card-body " style="border-radius: 14px;">
                    <!-- <div class="card-body pt-0 scroll-box-table table-responsive" style="overflow-x:auto;"> -->
                    <table id="siteTable" class="table  table-hover" datatable [dtOptions]="dtOptions"
                        style="width: 100%">
                        <thead>
                            <tr>
                                <th scope="col" class="user-table-headings">Organisation</th>
                                <th scope="col" class="user-table-headings">Site Name</th>
                                <th scope="col" class="user-table-headings">CIT Code</th>
                                <th scope="col" class="user-table-headings">Account Number</th>
                                <th scope="col" class="user-table-headings">Cash Centre</th>
                                <th scope="col" class="user-table-headings">Status</th>
                                <th scope="col" class="user-table-headings">Created On</th>
                                <th scope="col" class="user-table-headings">Last Updated</th>
                            </tr>
                        </thead>
                        
                    </table>
                </div>
            </div>

            <div [ngClass]="{'no-details' : fullTable, 'user-details': !fullTable}">

                <div class="col" *ngIf="fullTable">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <p class="clickable" (click)="showDetails()" style="margin-top: 10px;">
                            <i class="fa fa-angle-left fa-2x "></i>
                        </p>
                    </div>
                </div>

                <div class="col" *ngIf="!fullTable" style="width:50%; background-color: #d5e6f3;" [hidden]="hideCard">

                    <p class="clickable" (click)="hideDetails()"
                            style="margin-top: 10px; margin-left: -2px; margin-bottom: -10px;">
                            <i class="fa fa-angle-right fa-2x "></i>
                    </p>

                    <h3 class="h3-grey user-detail-card">{{siteName}}</h3>
                        <h4 class="user-detail-card row"><b>{{organisation}} -
                                <span *ngIf="status=='Disabled'">Deactivated Site</span>
                                <span *ngIf="status=='Active'">Active Site</span>
                                </b>
                        </h4>

                        <div *ngIf="status=='Disabled'" class="m-4"><span class="label label-danger">Disabled</span>
                        </div>

                        <div *ngIf="status=='Active'" class="m-4"><span class="label label-success">Active</span>
                        </div>

                        <div class="p-5 card-blue-shadow user-detail-card">

                            <form [formGroup]="form">

                                <div class="d-flex justify-content-between align-items-end">
                                    <h4 class="mb-0">Site Details</h4>
                                    <a *ngIf="(status=='Active' || status=='Disabled')"
                                        class="text-align-centre p-2 blue-link"
                                        style="color: #589cd0; margin-bottom: -5px;"
                                        [hidden]="!hideSaveButton"
                                        (click)="editDetails()">Edit</a>

                                    <a class="text-align-centre p-2 blue-link" 
                                        style="color: #589cd0; margin-bottom: -5px;" 
                                        [hidden]="hideSaveButton"
                                        (click)="cancelEdit()">Cancel</a>

                                </div>

                                <hr style="margin-top: 10px">

                                <div class="form-group">
                                    <label class="control-label" for="group">Organisation</label>
                                    <input [readonly]="true" type="text" formControlName="group"
                                        [ngClass]="{'ms-disabled' : true}"
                                        class="form-control" placeholder="Please select site..." />
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="name">Site Name</label>
                                    <input [readonly]="!(canEdit)" type="text" formControlName="name"
                                        [ngClass]="{'ms-disabled' : !(canEdit)}"
                                        class="form-control" placeholder="Please select site..." />

                                        <div *ngIf="invalidName" style="font-size: 80%; color: #dc3545">
                                            <div *ngIf="invalidName">{{nameError}}</div>
                                        </div>
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="code">CIT Code</label>
                                    <input [readonly]="!(canEdit)" type="text" formControlName="code"
                                        [ngClass]="{'ms-disabled' : !(canEdit)}"
                                        class="form-control " placeholder="Please select site..." />

                                     <div *ngIf="invalidCode" style="font-size: 80%; color: #dc3545">
                                            <div *ngIf="invalidCode">{{codeError}}</div>
                                        </div>
                                </div>

                                <div class="form-group">
                                    <label class="control-label" for="accNo">Account Number</label>
                                    <input [readonly]="!(canEdit)" type="text" formControlName="accNo"
                                        [ngClass]="{'ms-disabled' : !(canEdit)}"
                                        class="form-control " placeholder="Please select site..." />

                                        <div *ngIf="invalidAcc" style="font-size: 80%; color: #dc3545">
                                            <div *ngIf="invalidAcc">{{accError}}</div>
                                        </div>
                                </div>

                                <!-- <div class="form-group">
                                    <label class="control-label" for="cc">Cash Centre </label>
                                    <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;"
                                        [ngClass]="{'ms-disabled' : !(canEdit)}" 
                                        [placeholder]="'Cash centres'"
                                        [settings]="dropdownSettingsCC" 
                                        [data]="dropdownCCList"
                                        class="singleselect" clearable="false"
                                        formControlName="cashCentres"
                                        (onSelect)="onCCSelect($event)"
                                        (onDeSelect)="onCCDeselect()">
                                    </ng-multiselect-dropdown>

                                    <div *ngIf="invalidCC" style="font-size: 80%; color: #dc3545">
                                        <div *ngIf="invalidCC">Cash Centre is required</div>
                                    </div>
                                </div> -->
                                <div class="form-group">
                                    <label class="control-label" for="cc">Cash Centre </label>
                                    <mat-form-field appearance="fill" class="selectDetailsCard">
                                        <mat-select [disabled]="!(canEdit)" formControlName="CC" name="cashCentre" placeholder="Select A Cash Centre" (selectionChange)="changeCC($event.value)" [disableOptionCentering]="true">
                                            <mat-option *ngFor="let centre of dropdownCCList" [value]="centre">
                                                {{centre.name}}
                                              </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </form>
                            
                            <div *ngIf="duplicateSite" style="font-size: 12px; color: #dc3545">
                                <div *ngIf="duplicateSite">Error, a site with these details already exists</div>
                            </div>

                        <div class="d-flex justify-content-between" style="padding-top: 1em;">
                            <!-- <div>
                                <a class="blue-link clickable"tabindex="-1" [hidden]="hideSaveButton" (click)="cancelEdit()">Cancel</a>
                            </div> -->

                            <button class="rounded btn btn-success w-10" [hidden]="hideSaveButton" (click)="applyEdit()">
                                <span *ngIf="loading && submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                        Save Changes
                            </button>

                        </div>
                        <br>
                        <button class="rounded btn btn-danger w-10" (click)="deactivate()" [hidden]="hideDeactivate">
                            <span *ngIf="loading && cd" class="spinner-border spinner-border-sm mr-1"></span>
                            Deactivate
                        </button>
                        <button class="rounded btn btn-primary w-10" (click)="activate()" [hidden]="hideActivate">
                            <span *ngIf="loading && ca" class="spinner-border spinner-border-sm mr-1"></span>
                            Activate
                        </button>



                        </div>
                
                
                
                
                
                
                
                
                </div>







            </div>
        
        
        
        
        
        
        
        
        </div>






    </div>













</div>



