<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="min-vh-100 d-flex flex-column justify-content-center align-items-center">
    <div class="card-blue-shadow" style="min-height: fit-content; width: 400px; border-radius: 10px">
        <div class="container-fluid">
            <div class="ps-4 pe-4 pt-4 container-fluid ">
                <div class="d-flex justify-content-between align-items-end">
                    <h3>Register</h3>
                    <p id="steps" class="purple">Step 2/2</p>
                </div>
                <hr class="mt-0">
                <h4>User Account Details</h4>
                <p>Enter your role details below</p>
                <hr class="mt-0">
                <alert></alert>
                <form [formGroup]="form" (reset)="resetForm()" (ngSubmit)="onSubmit()">

                    <div class="form-group">
                        <label class="control-label" for="organisation">User Type</label><span style="color:#ff0000">
                            *</span>
                        <ng-multiselect-dropdown style="font-size: 12px; border-radius: 50px;"
                            [placeholder]="'Select Group'" [settings]="dropdownSettingsGroups"
                            [data]="dropdownListGroups" (onSelect)="onItemSelectGroup($event)"
                            (onDeSelect)="onGroupItemDeSelect($event)" class="singleselect" clearable="true">
                        </ng-multiselect-dropdown>

                        <div style="height:10px">
                        </div>
                        <label class="control-label" for="organisation">Role</label><span style="color:#ff0000">
                            * </span><span *ngIf="rolesLoading" class="spinner-border spinner-border-sm mr-1"></span>
                        <ng-multiselect-dropdown #rolesMultiSelect [formControl]="f.role" formControlName="role"
                            style="font-size: 12px; border-radius: 50px;" [placeholder]="'Select Role'"
                            [settings]="dropdownSettingsRoles" [data]="dropdownListRoles"
                            (onSelect)="onItemSelectRole($event)" class="singleselect"
                            (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll()">
                        </ng-multiselect-dropdown>


                    </div>

                    <div class="form-group">
                        <label class="control-label" for="organisation">Organisation</label><span style="color:#ff0000">
                            *</span>
                        <input type="text" formControlName="organisation" class="form-control "
                            placeholder="My Organisation"
                            [ngClass]="{ 'is-invalid': submitted && f.organisation.errors }" />
                        <div *ngIf="submitted && f.organisation.errors" class="invalid-feedback">
                            <div *ngIf="f.organisation.errors.required">Organisation is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="jobTitle">Job Title</label><span style="color:#ff0000">
                            *</span>
                        <input type="text" formControlName="jobTitle" class="form-control" placeholder="Job title"
                            [ngClass]="{ 'is-invalid': submitted && f.jobTitle.errors }" />
                        <div *ngIf="submitted && f.jobTitle.errors" class="invalid-feedback">
                            <div *ngIf="f.jobTitle.errors.required">Job title is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="department">Department</label><span style="color:#ff0000">
                            *</span>
                        <input type="text" formControlName="department" class="form-control" placeholder="Department"
                            [ngClass]="{ 'is-invalid': submitted && f.department.errors }" />
                        <div *ngIf="submitted && f.department.errors" class="invalid-feedback">
                            <div *ngIf="f.department.errors.required">Department is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="lineManagerName">Line Manager Name</label>
                        <input type="text" formControlName="lineManagerName" class="form-control"
                            placeholder="Line manager name" placeholder="Line Manager Name" />
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="lineManagerContact">Line Manager Contact Number</label>
                        <input type="text" mask='(000) 000-0000' formControlName="lineManagerContact"
                            class="form-control" placeholder="Line manager's contact number"
                            placeholder="Line Manager Contact Detail" />
                    </div>

                    <div class="w-100">
                        <div class="form-group">
                            <button [disabled]="loading" class="btn btn-primary w-100">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Next
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="d-flex justify-content-between  ms-5 me-5 pt-3 mb-4">
                <a class="p-2 black-link" routerLink="../login">Back</a>
                <a class="p-2 blue-link" routerLink="../help">Need help?</a>
            </div>
        </div>
    </div>
</div>