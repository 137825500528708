import { Component, OnInit } from '@angular/core';
import { ComponentFixture, TestBed } from '@angular/core/testing';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';




@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  form: FormGroup;
  helpForm:FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  hideCM:boolean=true;

  hideErrorBanner: boolean=true;
errorMessage: string=" ";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    //private renderer: Renderer2
  ) {
   // this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }

  // cashCentres = [];

  ngOnInit() {

    this.helpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],
      name: ['', Validators.required],
      contactNo: ['', Validators.required],
      description: ['', Validators.required]
    })
    
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'help-finish';

    if ("contact-us-request-Submitted" in sessionStorage){
      this.hideCM=false;
      sessionStorage.removeItem("contact-us-request-Submitted");
    }
  }

  get h() { return this.helpForm.controls; }

  request(){

      //
  this.submitted = true;
  // this.router.navigate([this.returnUrl]);
  // reset alerts on submit
  this.alertService.clear();

  // stop here if form is invalid
  if (this.helpForm.invalid) {
       console.log("INVALID DATA")
       return;
  }

  this.loading = true;

  this.accountService.requestHelp(this.h.name.value, this.h.email.value, this.h.contactNo.value, this.h.description.value)
    .subscribe(data => {
       sessionStorage.setItem("contact-us-request-Submitted","true");
       window.location.reload();
    },
      error => {
        if (error.status==500){
          this.errorMessage="ERROR 500 OCCURRED";
          this.hideErrorBanner=false;
        }
        if (error.status==408 || error.status==504){
          this.errorMessage="ERROR 408/504 OCCURRED";
          this.hideErrorBanner=false;
        }

        if (error.status == 0) {
          this.alertService.error('Something went wrong. Please contact technical support.')
        }
        else {
          this.alertService.error(error.error.error.message);
        }
        // console.error('There was an error!', error.error.error.message);
        // console.error(error.message);
      });
  this.loading = false;
      //
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  xButton(){
    window.location.reload();
}

  onSubmit() {
    // console.log("submitted");
    this.submitted = true;
    // this.router.navigate([this.returnUrl]);
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    console.log("button");
    this.accountService.requestHelp(this.f.FirstName.value, this.f.Email.value, this.f.ContactNumber.value, this.f.DescribetheIssue.value)
      .subscribe(data => {
        // console.log(data);
        // console.log(JSON.stringify(data));
        console.log("submitted");
        this.loading = false;
        this.router.navigate([this.returnUrl]);
      },
        error => {
          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
        });
    this.form.enable(); 
    this.loading = false;
  }

}
