import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  hideErrorBanner: boolean=true;
  errorMessage: string=" ";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'forgot-password-finish';
  }


  get f() { return this.form.controls; }


  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.form.disable();
    this.loading = true;

    //let ottId=sessionStorage.getItem("ottId");
    //let ottCode=sessionStorage.getItem("ottCode");

    sessionStorage.setItem('email', this.f.email.value);
    this.accountService.forgotPassword(this.f.email.value)
      .subscribe(data => {
        // console.log(data);
        // console.log(JSON.stringify(data));
        this.loading = false;
        this.router.navigate([this.returnUrl]);
      },
        error => {

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          if (error.status == 404 || error.status == 409) {
            // console.log(error);
            this.router.navigate([this.returnUrl]);
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          this.form.enable();
          this.loading = false;
        });
    this.loading = false;
  }

  xButton(){
    window.location.reload();
}

}
