<!--
Case management  page html code. including page links/info
--> 

<div class="container-fluid" style="text-align:left; background: #f6fafd">

    <h6>HELP CENTRE</h6>
    <h1 style="margin-top:0px;">Case Management</h1>
    <h5>Your online guide to Case Management</h5>
    <hr/>
    
</div>

<nav aria-label="breadcrumb" style =" margin: 10px ">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/help-centre">Help Centre</a></li>
          <li class="breadcrumb-item active" aria-current="page">Case Management</li>
        </ol>
      </nav>


<div class="box">
    <h3 style="font-size:36px">Securely log all of your cases for efficient resolution</h3>
 
    <div class="divider"></div>
   
      <p style="font-size:16px; text-align: left;">Connect to SBV's online support service, with effective management of cases, including feedback loops. 
        The Case Management service of SBV Connect offers a ticketing system that allows you to log all cases into 
        workflow managed and tracked Cases. The SBV Connect Case Management module is designed to provide you with quick 
        and easy navigation to the actions and information you need. SBV Connect maintains a history of all cases logged 
        for future reference and analysis.  
       </p>

       <!-- <p style="font-size:16px; text-align: left;" [hidden]="!isMerchant">The following text is being used to test the view for merchants. Only merchant users should be
        able to view this text box.</p> -->
   
       <!-- <a class="rf r" [hidden]='isMerchant' href="assets/img/SBV Connect-Training Manual V1.0.pdf"    >Download Full PDF Guide</a>
       <a class="rf r" [hidden]='!isMerchant' href="assets/img/Merchant-Manual.pdf"    >Download Full PDF Guide</a> -->
       <!--oncontextmenu="return false;"-->

      <!--MERCHANT VIDEOS-->
      <div *ngIf="isMerchant" > 
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to register, log in, change a password</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/MERCHANT/SBV Connect_How To Register_Log In_Change a Password (External).mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to log a new operational case</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/MERCHANT/Case Management_How to Log a New Operational Case (External).mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to manage and resolve an ops case</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/MERCHANT/Case Management_How to Manage and Resolve an Operations Case (External).mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to use your ops client dashboard</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/MERCHANT/Case Management_How to use your Dashboard (External).mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: Billing case management for merchant customers</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/MERCHANT/Billing_Case Management for Merchant Customers (External).mp4" type="video/mp4">
        </video>

      </div>

      <!--MERCHANT VIDEO-->
      <!-- <div *ngIf="isMerchant">
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How To Log a Billing Case</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/01_How-To-Log-A-New-Billing-Case-(BA+CC)_Final.mp4" type="video/mp4">
        </video>
      </div> -->

      <!--OPS BANK USERS--> 
      <div *ngIf="isBillingOpsUser || isCLO" >       
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to register, log in, change a password</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/BANK USERS/SBV Connect_How To Register_Log In_Change a Password_Bank Users (External).mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to log a new case</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/BANK USERS/Ops case management_How to Log a New Ops Case_Bank Users (External).mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to manage and resolve an ops case</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/BANK USERS/Ops Case Management_ How to Manage and Resolve a case_Bank Ops users (External).mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to use your client dashboard</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/BANK USERS/Ops case management_How to use your Client Dashboard_Bank Users (External).mp4" type="video/mp4">
        </video>
        
      </div>

      <!--BILLING BANK USERS-->
      <div *ngIf="isOpsUser" >       
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to register, log in, change a password</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/MERCHANT/SBV Connect_How To Register_Log In_Change a Password (External).mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: Billing case management for Bank customers</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/MERCHANT/Billing_Case Management for Bank Customers (External).mp4" type="video/mp4">
        </video>
      </div>

      <!--LOGISTICS USERS-->
      <div *ngIf="isLogistics" >       
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to register, log in, change a password</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/MERCHANT/SBV Connect_How To Register_Log In_Change a Password (External).mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How To Manage and Resolve a Billing Case</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/BANK USERS/Ops Case Management_ How to Manage and Resolve a case_Bank Ops users (External).mp4" type="video/mp4">
        </video>
      </div>

      <!--BILLING VIDEOS-->
      <div *ngIf="isBilling" >
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to register, log in, change a password</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
        <source src="../../assets/videos/INTERNAL/SBV Connect_How To Register _ Login_Change Password_SBV Users (Internal).mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: Understanding Your Dashboard</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
        <source src="../../assets/videos/23.12 - Billing Training Videos//Internal/04_ Billing-Dashboard-(All-Billing-Users)_Final.mp4" type="video/mp4">
        </video>

      <br /> <br /><br />
      <p  style="font-size:24px;font-weight: bold" >Watch: Billing Reports</p>
      <video controls width="583px" height="328px" controlsList="nodownload">
        <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/03_Billing-Reports-(All-Billing-Users)_Final.mp4" type="video/mp4">
        </video>
      </div>

      <!--Credit Controller Videos-->
      <div *ngIf="isCreditController || isBillingAnalyst" >
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How To Log a New Billing Case</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
        <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/01_How-To-Log-A-New-Billing-Case-(BA+CC)_Final.mp4" type="video/mp4">
        </video>

      <br /> <br /><br />
      <p  style="font-size:24px;font-weight: bold" >Watch: How To Manage and Resolve a Billing Case</p>
      <video controls width="583px" height="328px" controlsList="nodownload">
        <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/02_How-To-Manage-And-Resolve-A-Billing-Case-(BA+CC)_Final.mp4" type="video/mp4">
        </video>
      </div>

      <!--Cash Centre HOD Videos-->
      <div *ngIf="isCashCentreHOD" >
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: Billing Case Management</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
        <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/06_Manage-A-Billing-Case-(Ops)_Final.mp4" type="video/mp4">
        </video>
      </div>

      <!--Financial Accountant Videos-->
      <div *ngIf="isFinancialAccountant || isBillingHOD" >
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: Billing Case Management</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
        <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/05_Manage-A-Billing-Case-(FA)_Final.mp4" type="video/mp4">
        </video>
      </div>

      <!--SUPER USER VIDEOS-->
      <div *ngIf="isSuperUser">
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How to register, log in, change a password</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/MERCHANT/SBV Connect_How To Register_Log In_Change a Password (External).mp4" type="video/mp4">
        </video>
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: Understanding Your Dashboard (SBV Customers)</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/23.12 - Billing Training Videos/External/04_Client-Dashboard-(all)_Final.mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: Understanding Your Dashboard (Billing Users)</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/04_ Billing-Dashboard-(All-Billing-Users)_Final.mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: Billing Reports</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/03_Billing-Reports-(All-Billing-Users)_Final.mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How To Log a New Case</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/23.11 - Merchant Training Videos/2-How to Log a New Case_Updated.mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How To Manage and Resolve a Case</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/23.11 - Merchant Training Videos/3-How to Manage-Resolve a case_Updated.mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How To Log a New Billing Case</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
        <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/01_How-To-Log-A-New-Billing-Case-(BA+CC)_Final.mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: How To Manage and Resolve a Billing Case</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
          <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/02_How-To-Manage-And-Resolve-A-Billing-Case-(BA+CC)_Final.mp4" type="video/mp4">
        </video>

        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: Billing Case Management</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
        <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/05_Manage-A-Billing-Case-(FA)_Final.mp4" type="video/mp4">
        </video>
        <br /> <br /><br />
        <p  style="font-size:24px;font-weight: bold" >Watch: Billing Case Management</p>
        <video controls width="583px" height="328px" controlsList="nodownload">
        <source src="../../assets/videos/23.12 - Billing Training Videos/Internal/06_Manage-A-Billing-Case-(Ops)_Final.mp4" type="video/mp4">
        </video>
      </div>
</div>