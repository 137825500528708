import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../_services';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-bank-views',
  templateUrl: './bank-views.component.html',
  styleUrls: ['./bank-views.component.scss']
})
export class BankViewsComponent implements OnInit {
  roleCheck:number;
  groupCheck:number;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    let JSession=this.cookieService.get("JSESSIONID");
    this.accountService.getCurrentUser(sessionStorage.getItem("email"), JSession)
    .subscribe(data => {
    //  this.roleCheck=data.users.user.roles.role.id;
    //  this.groupCheck=data.users.user.roles.role.groupId;
     
    // if(this.groupCheck==1 && (this.roleCheck==2 || this.roleCheck==16)){
    //   console.log("valid user")
    // }
    // else{
    //   this.router.navigate(['/forbidden-access']);
    // }
    },
    error => {
        console.log(error)
    });
  }

}
