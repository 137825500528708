import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { concat, pipe, timer } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from 'src/app/environments/environment';
import { AccountService, AlertService } from 'src/app/_services';



@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  emailAddress: string;
  resendNotAllowed: boolean = true;
  code:number=0;

  hideErrorBanner: boolean=true;
  errorMessage: string=" ";

  success: boolean;

  constructor(
    private cookieService: CookieService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
  }

  ngOnInit(): void {
    sessionStorage.setItem("login-status","logged in");
    this.emailAddress = sessionStorage.getItem('email');
    this.form = this.formBuilder.group({
    });

    timer(60000).subscribe(x => (this.enableResend()));
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true
  };

  otp: string;
  handeOtpChange(value: string[]): void {
    // console.log(value);
    this.otp = value.join("");
    // console.log(this.otp);
    // if (value[5] != null) {
    if (this.otp.length == 6) {
      this.onSubmit();
    }
  }

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '25px',
      'height': '25px',
      'font-size': '12px'
    }
  };

  onOtpChange(value: string): void {
    // console.log(value);
    this.otp = value;
    // this.otp = value.join("");
    // console.log(this.otp);
    if (this.otp.length == 6) {
      this.onSubmit();
    }
  }

  @ViewChild('ngOtpInput') ngOtpInputRef: any;//Get reference using ViewChild and the specified hash
  clear() {
    this.config.disableAutoFocus = true;
    this.ngOtpInputRef.setValue("");//yourvalue can be any string or number eg. 1234 or '1234'
    // this.ngOtpInputRef.nativeElement.clearFocus();
  }

  showOtpComponent = true;
  async onSubmit() {
    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();
    this.loading = true;

    // let loggingIn: boolean;

    this.accountService.loginOtp(this.otp)
      .subscribe((res: HttpResponse<any>) => {

        this.success = true;
        // this.writer = res.body;
        console.log(res.body.root.jsessionId);
        console.log(res.body.root.xBonitaAPIToken);
        // console.log(res.body.root);
        // console.log(res.headers.get('Content-Type'));
          sessionStorage.setItem("login-status","logged in");
          localStorage.setItem("loggedin","true");
          sessionStorage.removeItem("ottId");
          this.cookieService.removeAll;
          this.cookieService.remove('JSESSIONID');
          this.cookieService.remove('X-Bonita-API-Token');
          sessionStorage.removeItem('ottId');

          this.cookieService.put('JSESSIONID', res.body.root.jsessionId,{
            secure:true,
            sameSite:'none'
          });
          
          this.cookieService.put('X-Bonita-API-Token', res.body.root.xBonitaAPIToken,{
            secure:true,
            sameSite:'none'
          });

          console.log("JSESSIONID :",res.body.root.jsessionId);
          console.log("X-Bonita-API-Token : ",res.body.root.xBonitaAPIToken);

          setTimeout(() => {
          window.location.href = `./home`;
              }, 100);

      },
        (error: any) => {
          // console.log(err);
          // console.log(err.status);
          this.alertService.clear();
          this.clear();
          this.loading = false;

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          if (error.status == 500) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          } else if (error.status == 404) {
            if (this.success != true) {
              this.alertService.error(error.error.error.message)
            }
          }
        }
      );

      if (this.success == true){
        this.loading = true;
      }

    //   .subscribe(data => {
    //     console.log('hello SUCCESS');
    //     console.log(data);
    //   // //COOKIES
    //   this.cookieService.removeAll;
    //   this.cookieService.put('JSESSIONID', data.root.jsessionId);
    //   this.cookieService.put('X-Bonita-API-Token', data.root.xBonitaAPIToken);

    //   localStorage.removeItem('ottId');
    //   console.log(data.root.redirectUrl);
    //   localStorage.setItem('redirectUrl',data.root.redirectUrl);
    //   // window.location.href = data.root.redirectUrl;
    //   window.location.href = `${environment.bonitaUrl}/bonita/apps/SBVConnect/home/`;
    // }
    // ,
    //   error => {
    //     console.log('hello ERROR');
    //     console.log(error.status);
    //     this.clear();
    //     this.loading = false;
    //     if (error.status == 500) {
    //       this.alertService.error('Something went wrong. Please contact technical support.')
    //     }
    //     else {
    //       //assuming 301
    //     }
    //     this.loading = false;
    //   }
    // )
  }


  //  btn = document.getElementById("resendBtn");

  // resendOTP(){
  //   if (this.resendNotAllowed == true) { //if resend not allowed, quit function
  //     return;
  //   } //else submit api call
  //   this.submitted = true;

  //   // reset alerts on submit
  //   this.alertService.clear();

  //   let body= {
  //     "claim": {
  //       "ottId": parseInt(sessionStorage.getItem('ottId'))
  //     }
  //   }

  //   this.accountService.resendOTP(body)
  //   .subscribe(data => {
  //    console.log("Success");
  //    sessionStorage.setItem('ottId',data.token.ottId);
  //   },
  //     error => {
  //       if (error.status == 404) {
  //         this.router.navigate(['/login']);
  //       }
  //       else {
  //         this.alertService.error(error.error.error.message);
  //       }
  //       this.form.enable();
  //       this.loading = false;
  //     }
  //   );

  //   this.resendNotAllowed = true; //after api call resend is not allowed
  //   timer(60000).subscribe(x => (this.enableResend()));
  // }

  enableResend() {
    this.resendNotAllowed = false;  //resend is allowed now
    // this.resendOtp();
  }

  xButton(){
    window.location.reload();
}
  resendOTP(){
    if (this.resendNotAllowed == true) { //if resend not allowed, quit function
      return;
    } //else submit api call
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    this.accountService.resendOTP(sessionStorage.getItem('ottId'))
    .subscribe(data => {
     console.log("Success");
     //sessionStorage.setItem('ottId',data.token.ottId);
    },
      error => {
        if (error.status == 404) {
          this.router.navigate(['/login']);
        }
        else {
          this.alertService.error(error.error.error.message);
        }
        this.form.enable();
        this.loading = false;
      }
    );

    this.resendNotAllowed = true; //after api call resend is not allowed
    timer(60000).subscribe(x => (this.enableResend()));
  }

  resendOtp() {
    if (this.resendNotAllowed == true) { //if resend not allowed, quit function
      return;
    } //else submit api call
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

   

    this.accountService.loginCredentials(sessionStorage.getItem('login_email'), sessionStorage.getItem('passwd'))
      .pipe(
        // catchError(this.getError),
        first())
      .subscribe(data => {
        // console.log(data.code);
        // console.log(data.status);
        // console.log(JSON.stringify(data));
        // data.json().then( )
        // console.log(data.token.ottId);
        sessionStorage.removeItem('ottId');
        sessionStorage.setItem('ottId', data.token.ottId);
        // localStorage.setItem('passwd', hashed);
        // console.log(localStorage.getItem('ottId'));

        // this.router.navigate([this.returnUrl]);
      },
        error => {

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }

          if (error.status == 0) {
            this.alertService.error('Something went wrong. Please contact technical support.')
          }
          else {
            this.alertService.error(error.error.error.message);
          }
          this.form.enable();
          this.loading = false;
        }
      );
    // this.loading = false;
    this.resendNotAllowed = true; //after api call resend is not allowed
    timer(60000).subscribe(x => (this.enableResend())); //after 1 minute resend is allowed
  }

}


