//import { LEADING_TRIVIA_CHARS } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { CashCentre } from 'src/app/_models/cash-centre';
import { CashCentres } from 'src/app/_models/cash-centres';
import { Profile } from 'src/app/_models/profile';
import { AccountService, AlertService } from 'src/app/_services';
import { CookieService } from 'ngx-cookie';
import { Profiles } from 'src/app/_models/Profiles';
import { myProfiles } from 'src/app/_models/myProfile';
//import { profile } from 'console';

@Component({
  selector: 'app-cash-centre',
  templateUrl: './cash-centre.component.html'
})
export class CashCentreComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  public centres = new BehaviorSubject<Array<any>>(null);
  ccentres: any[];

  dropdownList = [];
  selectedItems = [];
  selectedItemsMulti = [];
  dropdownSettings = {};
  allSelectedMultiBank:boolean=false;
  banksChosen: boolean=true;
  allowMerchants:boolean=false;


  hideErrorBanner: boolean=true;
  errorMessage: string=" ";

  multiBanks=[];
  dropdownSettingsMultiBank={};
  
  dropdownSettingsMulti = {};

  hideBankMultiUser:boolean=true;
  hideCashCentre:boolean=true;
  bankMultiUser: boolean=false;
  showMerchCheckbox:boolean=false;

  dropdownListBanks = [
    { id: 1, name: "ABSA" },
    { id: 2, name: "Nedbank" },
    { id: 3, name: "First National Bank" },
    { id: 4, name: "Standard Bank South Africa" },
    { id: 5, name: "African Bank" },
    { id: 6, name: "Capitec" }
  ];

  userDropdownHeading: string = "Linked Banks"
  h5: string = "Banks"
  p: string = "banks"


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private renderer: Renderer2,
    private cookieService: CookieService
    ) { 
      this.renderer.setStyle(document.body, 'background-image', 'url(/assets/img/lines.png)');
    this.ccentres = [];
  }

  // cashCentres = [];

  ngOnInit() {

    if(sessionStorage.getItem('SOMultiBank')=="true"){
      this.hideBankMultiUser=false;
      this.bankMultiUser=true;
      this.hideCashCentre=true;
    }
    else{
      this.hideBankMultiUser=true;
      this.bankMultiUser=false;
      this.hideCashCentre=false;
    }

    if(sessionStorage.getItem("creditController")=="true"){
      this.showMerchCheckbox=false;
      this.userDropdownHeading = "Customer Groups"
      this.h5 = "Customer Groups"
      this.p = "customer groups"
      this.dropdownListBanks = [
        { id: 1, name: "ABSA" },
        { id: 2, name: "Nedbank" },
        { id: 3, name: "First National Bank" },
        { id: 4, name: "Standard Bank South Africa" },
        { id: 5, name: "African Bank" },
        { id: 6, name: "Capitec" },
        { id: 0, name: "Merchant"}
      ];
    }
    else{
      this.showMerchCheckbox=true;
      this.dropdownListBanks = [
        { id: 1, name: "ABSA" },
        { id: 2, name: "Nedbank" },
        { id: 3, name: "First National Bank" },
        { id: 4, name: "Standard Bank South Africa" },
        { id: 5, name: "African Bank" },
        { id: 6, name: "Capitec" }
      ];
    }
    // console.log(localStorage.getItem('email'));

    // this.accountService.getCashCentres().subscribe(res => {
    //   this.ccentres = res;
    // });
    let jSession=this.cookieService.get("JSESSIONID");
    console.log(jSession);

    this.accountService.getCashCentresRevised()
      .subscribe(data => {
        // console.log(data.root.cashCentre);
        // console.log(JSON.stringify(data.root.cashCentre));
        this.loading = false;
        this.dropdownList = data.root.cashCentre;
        // this.ccentres = data.root.cashCentre;
      },
        error => {
          this.alertService.error(error.error.error.message);
          this.loading = false;
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
        });


        

        this.dropdownSettingsMulti = {
          singleSelection: false,
          idField: 'id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'Unselect All',
          itemsShowLimit: 3,
          // allowSearchFilter: true
        };
    
        this.dropdownSettingsMultiBank = {
          singleSelection: false,
          idField: 'id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'Unselect All',
          itemsShowLimit: 3,
          // allowSearchFilter: true
        };

    // this.dropdownList = this.ccentres;
    this.selectedItems = [
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };


    this.form = this.formBuilder.group({
      //   email: ['', Validators.required],
      //   password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'request-access-processing';
  }

  allSelectedMulti: boolean = false;


  onItemSelect(item: any) {
    // console.log(item.id);
    if ((this.allSelected == false) || (!this.selectedItems.includes(item.id))) {
      this.selectedItems.push(item.id);
      // console.log(this.selectedItems);
    }
  }

  onItemSelectMultiBank(item: any) {
    // console.log(item.id);
    if ((this.allSelectedMultiBank == false) || (!this.multiBanks.includes(item.id))) {
      this.multiBanks.push(item.id);
      // console.log(this.selectedItems);
    }
    this.banksChosen=true;
    console.log("BANKS - "+JSON.stringify(this.multiBanks));
  }

  onSelectAllMultiBanks(items: any[]) {
    
    if (items.length == this.multiBanks.length) {
      this.multiBanks = [];
      this.allSelectedMulti = false;
    }
    else {
      // this.selectedItems = items;
      for (let i = 0; i < items.length; i++) {
        if (!this.multiBanks.includes(items[i].id)) {
          this.multiBanks.push(items[i].id);
        }
      }
      this.allSelectedMultiBank = true;
    }
    this.banksChosen=true;
    console.log("BANKS - "+JSON.stringify(this.multiBanks));
    }

    onItemDeSelectMultiBank(item: any) {
      // console.log("role already present in array - will be removed");
      // console.log("B4: " + this.selectedItems);
      if (this.allSelectedMultiBank == true) {
        this.multiBanks.splice(item.id, 1);
      }
      this.multiBanks.forEach((element, index) => {
        if (element == item.id)
          this.multiBanks.splice(index, 1);
      });
      console.log("BANKS - "+JSON.stringify(this.multiBanks));
  
    }

    onItemDeSelectAllMultiBank(items: any[]) {
      this.multiBanks = [];
  
      console.log("BANKS - "+JSON.stringify(this.multiBanks));
    }
  
  

  allSelected:boolean = false;
  //allSelectedMulti: boolean = false;

  onSelectAll(items: any[]) {
    // console.log(">>>");// + items);
    // console.log(items.length);
    // console.log(this.selectedItems.length + "<<<");

    if (items.length == this.selectedItems.length) {
      this.selectedItems = [];
      this.allSelected = false;
    }
    else {
      // this.selectedItems = items;
      for (let i = 0; i < items.length; i++) {
        if (!this.selectedItems.includes(items[i].id)) {
          this.selectedItems.push(items[i].id);
        }
      }
      this.allSelected = true;
    }
    // console.log(this.selectedItems);
  }

  onItemDeSelect(item: any) {
    // console.log("role already present in array - will be removed");
    // console.log("B4: " + this.selectedItems);
    if (this.allSelected == true){
      this.selectedItems.splice(item.id, 1);
    }
    this.selectedItems.forEach((element, index) => {
      if (element == item.id)
        this.selectedItems.splice(index, 1);
    });
    // console.log("After: " + this.selectedItems);

  }

  onItemDeSelectAll(items: any[]) {
    this.selectedItems = [];
  }

  merchantSelected(){
    if (this.allowMerchants == false) {
      this.allowMerchants = true;
  }
  else {
      this.allowMerchants = false;
  }
  console.log("AllowMerchants - " + this.allowMerchants);
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  


  onSubmit() {

    sessionStorage.removeItem("creditController");
    sessionStorage.removeItem("SOMultiBank");
    this.submitted = true;
    // this.router.navigate([this.returnUrl]);
    // reset alerts on submit
    this.alertService.clear();

    if((this.bankMultiUser==true && this.multiBanks.length==0) && this.showMerchCheckbox==true) { 

      this.banksChosen=false;
      return;
    }

    if (this.selectedItems.length === 0) {
      if(this.bankMultiUser==true){
        console.log("Multi Bank user");
      }
      else{
         // console.log("AWARE");
      this.alertService.error("Please select at least one cash centre");
      return;
      }
    }

    //ADD cash centres to payload
    let accessRequest = JSON.parse(localStorage.getItem('requestDetails'));

    let BanksList:Profiles[]=[];

    let theCCParent = new CashCentres; //parent has array of many children
    let CCs: CashCentres[] = [];
    let c = new CashCentres;

    if(this.bankMultiUser==true){
      for(let i = 0; i < this.multiBanks.length; i++) {
        let child = new Profiles;
        let b = new myProfiles.Profile;
        b.targetId = this.multiBanks[i];
        if(b.targetId == 0){
          b.typeId = 2;
        }
        else{
          b.typeId=1;
        }
        child.profile=b;
        BanksList.push(child);
      }

      console.log("Bank Array Created - "+JSON.stringify(BanksList));
      
      // if(this.allowMerchants == true) {
      //   let m=new myProfiles.Profile;
      //   let child=new Profiles();
      //   m.targetId=0;
      //   m.typeId=2;
      //   child.profile=m;
      //   BanksList.push(child);

      // }

    }
    

    if(this.bankMultiUser==true || this.showMerchCheckbox == false){
      this.selectedItemsMulti=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27];
      this.selectedItems=this.selectedItemsMulti;
    }


      for (let i = 0; i < this.selectedItems.length; i++) {
        // console.log("loop: " + this.selectedItems[i]);
        let child = new CashCentres;
        let b = new CashCentre;
        b.id = this.selectedItems[i];
        child.cashCentre = b;
        // child.id = this.selectedItems[i];
        CCs.push(child);
        // c.cashCentre.push(child);
      }
     
    
   
    let p = new Profile();

    p.profiles=BanksList;
    p.cashCentres = CCs;

    //p.cashCentres = CCs;
    // p.cashCentres = theCCParent;
    accessRequest.user.profile = p;

    // accessRequest.user.profile = CCs;
    // accessRequest.profile = CCs;
    
    console.log("declare newStatus and set values")

    let newStatus = {
      "user": {
        "email": (sessionStorage.getItem('email')),
        "status": 2
      }
    }
    console.log("after setting new status values")


    this.loading = true;
    
    console.log("before accessRequest Call")

    let ottId=sessionStorage.getItem("ottId");
    let ottCode=sessionStorage.getItem("ottCode");
    
    this.accountService.requestAccess(accessRequest, ottId, ottCode)
      .subscribe(data => {
        console.log("after accessRequest Call")
        //START UPDATE
        // this.accountService.updateUserStatus(newStatus)
        // .subscribe(data => {
        //   console.log(data)
        // },
        //   error => {
        //     this.alertService.error("User not updated");
        //   });
          //END OF STATUS UPDATE  

        this.loading = false;
        this.router.navigate([this.returnUrl]);
      },
        error => {

           // if (error.status==0){
          //   this.errorMessage="PLEASE CHECK YOUR NETWORK CONNECTION";
          //   this.hideErrorBanner=false;
          // }

          if (error.status==500){
            this.errorMessage="ERROR 500 OCCURRED";
            this.hideErrorBanner=false;
          }
          if (error.status==408 || error.status==504){
            this.errorMessage="ERROR 408/504 OCCURRED";
            this.hideErrorBanner=false;
          }
          if(error.status==0){
            this.alertService.error('Something went wrong. Please contact technical support.');
          }
          else{
            this.alertService.error(error.error.error.message);
            this.loading = false;
          // console.error('There was an error!', error.error.error.message);
          // console.error(error.message);
          }
        });
        this.loading = false;
  }

  xButton(){
    window.location.reload();
}

}
