<div class="errorBlock" [hidden]="hideErrorBanner">
    <button class="xButton" (click)="xButton()">X</button>
    <p style="color: red;">{{errorMessage}}</p>
</div>
<div class="errorBanner" [hidden]="hideErrorBanner"></div>

<div class="confirm-box" [hidden]="hideCM">Your message has been sent</div>
<div class="container-fluid" style="text-align:left; background: #f6fafd">

    <h6>SBV CONNECT</h6>
    <h1 style="margin-top:0px;">Help Centre</h1>
    <h5>Your online guide to SBV Connect</h5>
    <hr/>
    
</div>

<nav aria-label="breadcrumb" style =" margin: 10px ">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/help-centre">Help Centre</a></li>
          <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
    </ol>
  </nav>

<div class="box">

    <div class="c-m2" >
        <h2>We're Here to Help!</h2>
      
        <b>We’ve got a dedicated support team waiting to answer any questions you may have. 
            Please fill out the form and we’ll contact you as soon as possible.</b>
     
        <h3>Operating Hours:</h3>
        <br>
  
        <table border=0>
            <tr>
            <td><b>Monday:     </b></td>
            <td>     </td>
            <td>8:00 AM - 5:00 PM</td>
            </tr>
            <tr>
            <td><b>Tuesday:     </b></td>
            <td>     </td>
            <td>8:00 AM - 5:00 PM</td>
            </tr>
            <tr>
            <td><b>Wednesday:     </b></td>
            <td>     </td>
            <td>8:00 AM - 5:00 PM</td>
            </tr>
            <tr>
            <td><b>Thursday:     </b></td>
            <td>     </td>
            <td>8:00 AM - 5:00 PM</td>
            </tr>
            <tr>
            <td><b>Friday:     </b></td>
            <td>     </td>
            <td>8:00 AM - 5:00 PM</td>
            </tr>
            </table>
            <br>
        
      
        <b>Alternatively, please feel free to email us directly, and we’ll be in touch.</b>
        <br>
        <table border=0>
            <tr>
            <td><b>Email Address:</b></td>
            <td>     </td>
            <td><a href="mailto:connect@sbv.co.za">connect@sbv.co.za</a></td>
            </tr>
            </table>
        </div>


        <div class="c-m">
            <form [formGroup]="helpForm">
                <div class="form-group">
                    <label class="control-label" for="name">Name</label><span style="color:#ff0000"> *</span>
                    <input type="text" formControlName="name" class="form-control " placeholder="Name"
                        [ngClass]="{ 'is-invalid': submitted && h.name.errors }" />
                    <div *ngIf="submitted && h.name.errors" class="invalid-feedback">
                        <div *ngIf="h.name.errors.required">Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" for="email">Email</label><span style="color:#ff0000"> *</span>
                    <input type="text" formControlName="email" class="form-control " placeholder="Email"
                        [ngClass]="{ 'is-invalid': submitted && h.email.errors }" />
                    <div *ngIf="submitted && h.email.errors" class="invalid-feedback">
                        <div *ngIf="h.email.errors.required">Email is required</div>
                    </div>
                    <div class="invalid-feedback" *ngIf="h.email.errors?.pattern">
                        Invalid email format
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" for="contactNo">Contact Number</label><span style="color:#ff0000">
                        *</span>
                    <input type="text" mask='(000) 000-0000' formControlName="contactNo" class="form-control "
                        placeholder="Contact number"
                        [ngClass]="{ 'is-invalid': submitted && h.contactNo.errors }" />
                    <div *ngIf="submitted && h.contactNo.errors" class="invalid-feedback">
                        <div *ngIf="h.contactNo.errors.required">Contact number is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" for="description">Describe Your Issue</label><span
                        style="color:#ff0000"> *</span>
                    <input type="text" formControlName="description" class="form-control"
                        placeholder="Please describe the issue that you are experiencing"
                        [ngClass]="{ 'is-invalid': submitted && h.description.errors }" />
                    <div *ngIf="submitted && h.description.errors" class="invalid-feedback">
                        <div *ngIf="h.description.errors.required">Description is required</div>
                    </div>
                </div>
    
                <div class="w-100">
                    <div class="form-group text-center">
                        <button [disabled]="loading" class="rounded btn btn-primary w-30 " (click)="request()">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Request Help
                        </button>
                    </div>
                </div>
            </form>
          </div>
          